import React, {useMemo, useCallback} from 'react';

import {SortOrder} from '../../components/Table';
import {Checkbox} from '../../components/ui/checkbox';
import {IAppliance} from '../../models/Appliance';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {LocationFeature} from '../../models/Location';
import {ITableField} from '../../models/Table';
import {useApplianceTypes, useAppliances} from '../../utils/FunctionalData';
import {useAutoRefresh} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useUser, useCardColumnSettings, useCardLocationDetails} from '../CardUtils';
import {Reload, ExportCsv} from '../components/actions';
import {CardActions} from '../components/CardActions';

import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {ApplianceList, getApplianceListTableColumns} from './ApplianceList';

const NoAppliances: IAppliance[] = [];

interface IAppliancesSettings extends ICardSettingsWithTable {
  showFindMe: boolean;
}

const AppliancesCard = (props: ICardProps<IAppliancesSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const applianceTypes = useApplianceTypes(fetch);
  const location = useCardLocation(settings);
  const locationDetails = useCardLocationDetails(settings);
  const me = useUser();
  const id = location && location.id;
  const timezone = location && location.timeZoneId;
  const electricityCurrency = (location && location.electricityCurrency) || 'EUR';
  const {showFindMe} = settings;

  const [appliances = NoAppliances, refreshAppliances] = useAppliances(fetch, id, showFindMe);
  const fields = useMemo<ITableField<IAppliance>[]>(
    () => getApplianceListTableColumns(applianceTypes, electricityCurrency, timezone, me),
    [electricityCurrency, timezone, me, applianceTypes]
  );

  const handleTableSettingsChanged = useCallback(table => updateSettings({table}), [updateSettings]);
  useAutoRefresh(refreshAppliances);

  const customSettings = useCardColumnSettings(fields);
  let error: string | undefined;
  if (locationDetails && !locationDetails.features.includes(LocationFeature.Appliances)) {
    error = T('appliances.error.noAppliances');
  }

  const actions: CustomActions = state => (
    <CardActions>
      {!error && <Reload onReload={refreshAppliances} />}
      {state.ready && <ExportCsv name={state.title} fields={fields} items={appliances} settings={settings.table} />}
      {state.ready && me.isServiceDesk() && (
        <Checkbox
          id="show-find-me"
          name="show-find-me"
          label={T('appliances.showFindMe')}
          checked={showFindMe}
          onCheckedChange={checked => updateSettings({showFindMe: checked})}
          testId="show-find-me"
        />
      )}
    </CardActions>
  );

  return (
    <CardView error={error} actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      <ApplianceList
        fields={fields}
        appliances={appliances}
        settings={settings}
        onTableSettingsChanged={handleTableSettingsChanged}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IAppliancesSettings = {
  table: {
    pageSize: 10,
    sortColumn: 'name',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'name', visible: true},
      {name: 'type', visible: true},
      {name: 'power', visible: true},
      {name: 'consumption', visible: false},
      {name: 'cost', visible: false},
      {name: 'source', visible: false},
      {name: 'creationTimestamp', visible: true}
    ]
  },
  showFindMe: false
};
const CARD: ICardType<IAppliancesSettings> = {
  type: CardTypeKey.Appliances,
  title: 'appliances.title',
  description: 'appliances.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: AppliancesCard
};
export default CARD;
