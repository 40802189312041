import React, {useMemo, useState, useEffect, useCallback} from 'react';

import {useAppContext} from '../../app/context';
import {Label, Input} from '../../components/bootstrap';
import {InfiniteScrollingTable} from '../../components/InfiniteScrollingTable';
import {Checkbox} from '../../components/ui/checkbox';
import {IApplianceType} from '../../models/ApplianceType';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IEvent} from '../../models/Event';
import {useApplianceTypes} from '../../utils/FunctionalData';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useUser, useCardLocation} from '../CardUtils';
import {ColumnChooser} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {CardActions} from '../components/CardActions';

import {CustomSettings, cardViewProps, CardView, CustomActions} from '../components/CardView';

import {getTableColumns, tableRowKey} from './Columns';

interface IEventsSettings extends ICardSettingsWithTable {
  showFindMe: boolean;
}

const Events = (props: ICardProps<IEventsSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const me = useUser();
  const {api} = useAppContext();

  const {showFindMe} = settings;
  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationTimezone = (location && location.timeZoneId) || 'UTC';

  const [refreshIndex, setRefreshIndex] = useState(0);

  const applianceTypesRaw = useApplianceTypes(fetch);
  const applianceTypes: {[key: string]: IApplianceType} = useMemo(() => {
    return applianceTypesRaw.reduce(
      (result, type) => {
        result[type.id] = type;
        return result;
      },
      {} as {[key: string]: IApplianceType}
    );
  }, [applianceTypesRaw]);
  const fields = useMemo(
    () => getTableColumns(locationTimezone, applianceTypes, me),
    [applianceTypes, me, locationTimezone]
  );

  const [items, setItems] = useState<IEvent[]>([]);
  const [cursor, setCursor] = useState<string>();

  const handleRefresh = useCallback(() => {
    setCursor(undefined);
    setRefreshIndex(index => index + 1);
    setItems([]);
  }, []);
  useEffect(handleRefresh, [handleRefresh]);

  const handleChangedShowFindMe = (checked: boolean) => {
    updateSettings({showFindMe: checked});
  };

  const loadPage = useCallback(async () => {
    if (!locationId) return {items: [], hasMore: false};

    let result = await api.getEvents(locationId, 50, cursor, showFindMe);
    setCursor(result.cursor);
    setItems(items => items.concat(result.events));
    return {
      items: result.events,
      hasMore: result.cursor !== undefined
    };
  }, [api, locationId, cursor, showFindMe]);

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={handleRefresh} />
      {state.ready && <ExportCsv name={state.title} fields={fields} items={items} settings={settings.table} />}
      {me.isServiceDesk() && (
        <Checkbox
          id="show-find-me"
          name="show-find-me"
          label="Show Find Me"
          checked={showFindMe}
          onCheckedChange={handleChangedShowFindMe}
          testId="show-find-me"
        />
      )}
    </CardActions>
  );

  const renderSettings: CustomSettings<IEventsSettings> = (settings, updateSettings) => {
    return (
      <ColumnChooser settings={settings.table} fields={fields} updateSettings={table => updateSettings({table})} />
    );
  };

  const key = `${location ? location.id : 0}-${refreshIndex}`;
  return (
    <CardView actions={actions} customSettings={renderSettings} {...cardViewProps(props)}>
      <InfiniteScrollingTable
        key={key}
        fields={fields}
        rowKey={tableRowKey}
        settings={settings.table}
        loadPage={loadPage}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: IEventsSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'sourceName', visible: true},
      {name: 'sourceCategory', visible: true},
      {name: 'type', visible: true}
    ]
  },
  showFindMe: false
};
const CARD: ICardType<IEventsSettings> = {
  type: CardTypeKey.Events,
  title: 'events.title',
  description: 'events.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: Events
};
export default CARD;
