import classnames from 'classnames';
import React, {useCallback} from 'react';

import {Spring} from '../cards/components/CardActions';

import {useAppSelector} from '../utils/Hooks';
import {PluralKey, quantity, languageFormulas, T} from '../utils/Internationalization';
import {classes, dashboardClasses} from '../utils/Styles';

import {testingClasses} from '../utils/TestingClasses';

import {Flex} from './Flex';
import styles from './Pagination.module.scss';
import {Button} from './ui/button';
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from './ui/select';
import {ChevronBackward, ChevronBackwardDouble, ChevronForward, ChevronForwardDouble} from './ui-lib/icons/small';

interface PaginationProps {
  className?: string;
  noun?: PluralKey;
  hideNounCountLabel?: boolean;
  offset: number;
  limit: number;
  total?: number;
  hasMore: boolean;
  onPaginate: (offset: number, limit: number) => void;
  firstLastNav?: boolean;
  selected?: number;
}

export default function Pagination(props: PaginationProps) {
  const {
    className,
    noun = 'row',
    offset,
    limit,
    total,
    hasMore,
    onPaginate,
    firstLastNav = false,
    selected = 0
  } = props;
  const presenting = useAppSelector(state => state.uiState.presenting);

  const changeOffset = useCallback(
    (offset: number) => {
      onPaginate(offset, limit);
    },
    [onPaginate, limit]
  );

  const firstPage = useCallback(() => changeOffset(0), [changeOffset]);

  const prevPage = useCallback(() => {
    changeOffset(offset - limit);
  }, [changeOffset, offset, limit]);

  const nextPage = useCallback(() => {
    changeOffset(offset + limit);
  }, [changeOffset, offset, limit]);

  const lastPage = useCallback(() => {
    if (total === undefined) return undefined;

    const remainder = total % limit;
    const diff = remainder !== 0 ? remainder : limit;
    changeOffset(total - diff);
  }, [changeOffset, limit, total]);

  const handleItemsPerPageChangedNew = useCallback(
    (f: string) => {
      const limit = parseInt(f, 10);
      const newOffset = ((offset / limit) | 0) * limit;
      onPaginate(newOffset, limit);
    },
    [onPaginate, offset]
  );

  // Format numbers
  const page = Math.ceil(offset / limit);
  const from = total === undefined || total > 0 ? page + 1 : 0;
  const to = total === undefined ? undefined : Math.ceil(total / limit);

  return (
    <Flex className={classes(styles.pagination, className)}>
      {total === undefined ? (
        <Spring />
      ) : (
        <span className={styles.count}>
          {quantity(noun, total)}
          {selected > 0 && <span>&nbsp;&middot; {T('paginator.selected', {count: selected.toString()})}</span>}
        </span>
      )}

      {presenting ? (
        <span className={styles.itemsPerPage}>{T('paginator.nItemsPerPage', {limit: limit.toString()})}</span>
      ) : (
        <span className={styles.itemsPerPage}>{T('paginator.itemsPerPage')}</span>
      )}

      {!presenting && (
        <div className="!tw-w-[4.5rem] !tw-max-w-[4.5rem]">
          <Select value={limit.toString()} onValueChange={field => handleItemsPerPageChangedNew(field)}>
            <SelectTrigger
              className={classnames('tw-pl-1', testingClasses.itemsPerPage)}
              data-testid={testingClasses.itemsPerPage}
            >
              <SelectValue placeholder="10" />
            </SelectTrigger>
            <SelectContent className="tw-z-[10000]">
              <SelectGroup>
                <SelectItem value="10">10</SelectItem>
                <SelectItem value="20">20</SelectItem>
                <SelectItem value="25">25</SelectItem>
                <SelectItem value="50">50</SelectItem>
                <SelectItem value="100">100</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      )}

      {!presenting && firstLastNav && (
        <Button
          variant="secondary_default"
          onClick={firstPage}
          className="tw-flex tw-flex-col tw-justify-center tw-items-center !tw-min-w-11 !tw-w-11 !tw-h-8 !tw-pt-2"
          disabled={offset <= 0}
        >
          <ChevronBackwardDouble width={16} height={16} />
        </Button>
      )}

      {!presenting && (
        <Button
          variant="secondary_default"
          onClick={prevPage}
          className="tw-flex tw-flex-col tw-justify-center tw-items-center !tw-min-w-11 !tw-w-11 !tw-h-8 !tw-pt-2"
          disabled={offset <= 0}
        >
          <ChevronBackward width={16} height={16} />
        </Button>
      )}

      {to === undefined ? (
        <span className={styles.page}>{languageFormulas.pageX(from)}</span>
      ) : (
        <span className={styles.page}>{languageFormulas.pageXofY(from, to)}</span>
      )}

      {!presenting && (
        <Button
          variant="secondary_default"
          onClick={nextPage}
          className="tw-flex tw-flex-col tw-justify-center tw-items-center !tw-w-11 !tw-h-8 !tw-pt-2"
          disabled={total === undefined ? !hasMore : offset + limit >= total}
        >
          <ChevronForward width={16} height={16} />
        </Button>
      )}

      {!presenting && total !== undefined && firstLastNav && (
        <Button
          variant="secondary_default"
          onClick={lastPage}
          disabled={offset + limit >= total}
          className="tw-flex tw-flex-col tw-justify-center tw-items-center !tw-w-11 !tw-h-8 !tw-pt-2"
        >
          <ChevronForwardDouble width={16} height={16} />
        </Button>
      )}
    </Flex>
  );
}
