import dayjs from 'dayjs';
import {NotificationManager} from 'react-notifications';

import {Button as RsButton} from '../../components/bootstrap';
import {PropertyEntry} from '../../components/Table/SettingsTable';
import {Button} from '../../components/ui/button';
import {Edit, Unlink} from '../../components/ui-lib/icons/small';
import API from '../../core/api';
import {FORMAT_DATETIME_S} from '../../core/constants';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {IPromiseModalTarget} from '../../modals/PromiseModal';
import {getOrganizationName, IActivationCode, IOrganizationActivationCode} from '../../models/ActivationCode';
import {AuthUser} from '../../models/AuthUser';
import {ICustomFile} from '../../models/CustomFile';
import {DeviceType, getDeviceTypeLabelFor, isConnect, isValidLocalityChild} from '../../models/DeviceType';
import {GPSCoordinate} from '../../models/GPSCoordinate';
import {
  ILocation,
  ILocationSearchResult,
  ILocationSummary,
  IUpdateLocationRequest,
  LocationFunctionType,
  LocationType
} from '../../models/Location';
import {LocationUpgradeStatus} from '../../models/LocationUpgradeStatus';
import {ISearchField, ISearchFieldValue, translateFieldName} from '../../models/Organization';
import {DynamicTariff, TariffType} from '../../models/Tariff';
import {ITimezone} from '../../models/Timezone';
import {AppStore} from '../../redux';
import {createUpdateLocation, loadLocation, setContextLocationId} from '../../redux/actions/location';
import {reportError} from '../../utils/Errors';
import {quantity, T} from '../../utils/Internationalization';

import EditParentModal from '../ChargingStationConfiguration/EditParentModal';

import {
  ChargingStationDisplayImagesSettings,
  ManageDisplayImagesModal
} from '../ChargingStationConfiguration/modals/ManageDisplayImagesModal';

import {DisplayImagesField} from '../ChargingStationConfiguration/propertyValueFields/DisplayImagesField';

import {ActivationCodeField} from './fields/ActivationCodeField';
import CurrencyEditor from './fields/CurrencyEditor';
import {CustomFilesField} from './fields/CustomFilesField';
import GPSCoordinateEditor from './fields/GPSCoordinateEditor';
import LocationFunctionTypeEditor from './fields/LocationFunctionTypeEditor';
import LocationTypeEditor from './fields/LocationTypeEditor';
import NameEditor from './fields/NameEditor';
import {NilmVersionField} from './fields/NilmVersion';
import {OrganizationField} from './fields/OrganizationField';
import SearchFieldEditor from './fields/SearchFieldEditor';
import {TariffField} from './fields/TariffField';
import TimezoneEditor from './fields/TimezoneEditor';
import PicturesModal from './properties/PicturesModal';

import {UploadCustomFileModal} from './UploadCustomFileModal';

export interface PropsState {
  location?: ILocation;
  locationSummary?: ILocationSummary;
  historicalDeviceType?: DeviceType;
  nilmVersion?: number;
  searchFieldValues: ISearchFieldValue[];
  activationCode?: IActivationCode;
  deviceActivationCode?: IActivationCode;
  installationPictures?: any[];
  customFiles: ICustomFile[];
  upgrading: LocationUpgradeStatus;
  dynamicTariff?: DynamicTariff;
}

function filterGeniusOnly(location: ILocationSearchResult): boolean {
  return location.serialNumber ? location.serialNumber.startsWith('5010') : false;
}

export function buildProperties(
  api: API,
  store: AppStore,
  modals: IPromiseModalTarget,
  me: AuthUser,
  readOnly: boolean,
  searchFields: ISearchField[],
  deviceType: DeviceType | undefined,
  isServiceDesk: boolean,
  canChangeActivationCode: boolean,
  parent: ILocation | undefined,
  chargingParkWithUltraV2: boolean,
  refreshActivationCode: () => void,
  refreshDeviceActivationCode: () => void,
  refreshLocation: (force?: boolean) => void,
  refreshSearchFieldValues: () => void,
  refreshUpgrading: () => void,
  refreshDynamicTariff: () => void,
  setLoading: (loading: boolean) => void,
  setNilmVersion: (number: number) => void,
  setCustomFiles: (files: ICustomFile[]) => void
): PropertyEntry<PropsState>[] {
  const performLocationUpdate = async (locationId: number, update: IUpdateLocationRequest): Promise<any> => {
    try {
      await api.updateLocation(locationId, update);
      NotificationManager.success(T('locationConfiguration.update.success'));
    } catch (err) {
      NotificationManager.error(T('locationConfiguration.update.failed'));
    }
    await loadLocation(store, api, locationId);
    refreshLocation(true);
  };

  const handleUpdateName = (locationId: number, name: string) => {
    return performLocationUpdate(locationId, {name});
  };

  const handleUpdateGps = (locationId: number, coordinate: GPSCoordinate) => {
    return performLocationUpdate(locationId, {
      latitude: coordinate.latitude || undefined,
      longitude: coordinate.longitude || undefined
    });
  };

  const handleUpdateCurrency = (locationId: number, electricityCurrency: string) => {
    return performLocationUpdate(locationId, {electricityCurrency});
  };

  const handleUpdateType = (locationId: number, type: LocationType) => {
    return performLocationUpdate(locationId, {type});
  };

  const handleUpdateFunctionType = (locationId: number, type: LocationFunctionType) => {
    return performLocationUpdate(locationId, {functionType: type}).then(() => refreshUpgrading());
  };

  const handleUpgradeNilm = async (location: ILocation) => {
    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locationConfiguration.upgradeNilm.title')}
        message={T('locationConfiguration.upgradeNilm.message')}
        acceptLabel={T('locationConfiguration.confirmTimezoneChange.accept')}
        rejectLabel={T('locationConfiguration.confirmTimezoneChange.reject')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      await api.updateLocation(location.id, {nilm: 2});
      NotificationManager.success(T('locationConfiguration.upgradeNilm.success'));
      setNilmVersion(2);
    } catch {
      NotificationManager.error(T('locationConfiguration.upgradeNilm.failure'));
    }
  };

  const handleSelectedTimezone = async (location: ILocation, timezone: ITimezone) => {
    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locationConfiguration.confirmTimezoneChange.title')}
        message={T('locationConfiguration.confirmTimezoneChange.message')}
        acceptLabel={T('locationConfiguration.confirmTimezoneChange.accept')}
        rejectLabel={T('locationConfiguration.confirmTimezoneChange.reject')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    const updatedLocation = {
      ...location,
      timeZoneId: timezone.id,
      timeZone: timezone.name
    };

    try {
      api.updateLocation(location.id, {timeZoneId: timezone.id});
      store.dispatch(createUpdateLocation(updatedLocation));
      NotificationManager.success(T('locationConfiguration.confirmTimezoneChange.success'));
    } catch {
      NotificationManager.error(T('locationConfiguration.confirmTimezoneChange.failed'));
    }
  };

  const handleUpdateSearchField = async (
    location: ILocation,
    name: string,
    value: string | number | boolean | undefined
  ) => {
    try {
      setLoading(true);
      if (value === undefined) {
        await api.deleteLocationSearchFieldValue(location.id, name);
      } else {
        await api.setLocationSearchFieldValue(location.id, name, value);
      }
      refreshSearchFieldValues();
    } finally {
      setLoading(false);
    }
  };

  const handleSetActivationCode = async (
    oldDeviceCode: IActivationCode | undefined,
    location: ILocation,
    code: IOrganizationActivationCode,
    withDevice: boolean
  ) => {
    try {
      setLoading(true);
      await api.setLocationActivationCode(location.id, code.code);
      refreshActivationCode();
      refreshLocation(true);
      NotificationManager.success(T('locations.moveToOrganization.moved'));
    } catch {
      NotificationManager.error(T('locations.moveToOrganization.failed'));
    } finally {
      setLoading(false);
    }
    if (withDevice && location.serialNumber && oldDeviceCode) {
      try {
        await api.activationCodes.setDeviceActivationCode(oldDeviceCode.code, location.serialNumber, code.code);
        refreshDeviceActivationCode();
        NotificationManager.success(T('locations.moveToOrganization.deviceMoved'));
      } catch {
        NotificationManager.error(T('locations.moveToOrganization.deviceMoveFailed'));
      }
    }
  };

  const handleSetDeviceActivationCode = async (
    oldDeviceCode: IActivationCode | undefined,
    location: ILocation,
    code: IOrganizationActivationCode
  ) => {
    if (!location.serialNumber) return;

    try {
      await api.activationCodes.setDeviceActivationCode(
        oldDeviceCode && oldDeviceCode.code,
        location.serialNumber,
        code.code
      );
      NotificationManager.success(T('locations.moveToOrganization.deviceMoved'));
      refreshDeviceActivationCode();
    } catch {
      NotificationManager.error(T('locations.moveToOrganization.deviceMoveFailed'));
    }
  };

  const handleClickedPictures = (pictures: any[]) => {
    if (pictures === undefined) return;

    modals.show<void>(props => <PicturesModal pictures={pictures} {...props} />);
  };

  const handleClickedAddCustomFile = async (locationId: number) => {
    const uploaded = await modals.show<ICustomFile[] | undefined>(props => (
      <UploadCustomFileModal locationId={locationId} {...props} />
    ));
    if (uploaded) setCustomFiles(uploaded);
  };

  const handleClickedRemoveCustomFile = async (locationId: number, file: ICustomFile) => {
    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locationConfiguration.removeCustomFile.title')}
        message={T('locationConfiguration.removeCustomFile.message', {
          name: file.tag
        })}
        acceptLabel={T('locationConfiguration.removeCustomFile.acceptLabel')}
        rejectLabel={T('locationConfiguration.removeCustomFile.rejectLabel')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      await api.deleteCustomFile(locationId, file.id);
      NotificationManager.success(T('locationConfiguration.removeCustomFile.success'));
      api.getCustomFiles(locationId).then(setCustomFiles);
    } catch {
      NotificationManager.error(T('locationConfiguration.removeCustomFile.failed'));
    }
  };

  const handleClickedParent = () => {
    if (!parent) return;

    setContextLocationId(store, api, parent.id);
  };

  const handleClickedEditParent = async (location: ILocation) => {
    if (location.id === undefined) return;
    const parentId = parent && parent.id;
    const selectedLocationId = await modals.show<number | undefined>(props => (
      <EditParentModal
        parentId={parentId}
        chargingStation={false}
        filter={filterGeniusOnly}
        location={location}
        {...props}
      />
    ));
    if (selectedLocationId !== undefined && selectedLocationId !== parentId) {
      try {
        await api.updateLocation(location.id, {
          parentId: selectedLocationId
        });
        await loadLocation(store, api, location.id);
      } catch (error) {
        reportError(error);
      }
    }
  };

  const handleClickedClearParent = async (location: ILocation) => {
    if (location.id === undefined) return;

    await api.updateLocationParent(location.id, null);
    await loadLocation(store, api, location.id);
  };

  const handleManageDisplayImages = async (location?: ILocation) => {
    if (!location) return;

    const settings: ChargingStationDisplayImagesSettings = {
      locationId: location.id,
      name: location.name || '',
      serialNumber: undefined,
      locationFunctionType: location.functionType,
      timeZoneId: location.timeZoneId ?? 'Europe/Brussels'
    };
    await modals.show<ChargingStationDisplayImagesSettings | undefined>(props => (
      <ManageDisplayImagesModal settings={settings} location={location} {...props} />
    ));
  };

  const result: PropertyEntry<PropsState>[] = [
    {
      key: 'LocationID',
      label: T('locationConfiguration.property.id'),
      value: state => state.location && state.location.id.toString()
    },
    {
      key: 'LocationUUID',
      label: T('locationConfiguration.property.uuid'),
      value: state => state.location && state.location.uuid
    },
    {
      key: 'LocationName',
      label: T('locationConfiguration.property.name'),
      value: state =>
        state.location && (
          <NameEditor
            value={(state.location.name || '') as string}
            placeholder={T('locationConfiguration.locationName.placeholder')}
            update={name => handleUpdateName(state.location!.id, name)}
            readOnly={readOnly}
          />
        )
    },
    {
      key: 'LocationType',
      label: T('locationConfiguration.property.type'),
      value: state =>
        state.location && (
          <LocationTypeEditor
            value={state.location!.type || LocationType.Residential}
            update={type => handleUpdateType(state.location!.id, type)}
            readOnly={readOnly}
          />
        )
    },
    {
      key: 'LocationFunctionType',
      label: T('locationConfiguration.property.functionType'),
      value: state =>
        state.location && (
          <LocationFunctionTypeEditor
            value={state.location.functionType || LocationFunctionType.Standard}
            deviceType={state.location.deviceType}
            upgrading={state.upgrading.gatewayFunctionTypeUpgrading}
            update={type => handleUpdateFunctionType(state.location!.id, type)}
            readOnly={readOnly}
          />
        )
    }
  ];
  if (
    (deviceType === 'DC_CAR_CHARGE_RFID_TRIPLEDISPLAY' ||
      deviceType === 'DC_CAR_CHARGE_TRIPLEDISPLAY' ||
      chargingParkWithUltraV2) &&
    (me.isPartnerUser() || me.isServiceDesk())
  ) {
    result.push({
      key: 'displayImages',
      label: T('chargingStationConfiguration.property.displayImages'),
      value: state => (
        <DisplayImagesField
          name={state.location?.name}
          readOnly={readOnly}
          handleClickEditDisplayImages={() => handleManageDisplayImages(state.location)}
        />
      )
    });
  }
  if (deviceType && isValidLocalityChild(deviceType)) {
    result.push({
      key: 'parent',
      label: T('locationConfiguration.property.parent'),
      value: state => (
        <>
          {parent ? (
            <Button
              variant="tertiary_link"
              onClick={handleClickedParent}
              className="!tw-p-0 !tw-mr-2 !tw-bg-transparent"
            >
              {parent.name}
            </Button>
          ) : (
            T('locationConfiguration.noParent')
          )}{' '}
          {state.location && !readOnly && (
            <Button
              variant="ghost_action_btn"
              size="icon"
              onClick={() => handleClickedEditParent(state.location!)}
              title="Edit parent"
            >
              <Edit />
            </Button>
          )}
          {state.location && parent && (
            <Button
              variant="ghost_action_btn"
              size="icon"
              onClick={() => handleClickedClearParent(state.location!)}
              title="Edit parent"
            >
              <Unlink />
            </Button>
          )}
        </>
      )
    });
  }
  result.push(
    {
      key: 'Organization',
      label: T('locationConfiguration.property.organization'),
      value: state =>
        state.location && (
          <OrganizationField
            value={state.activationCode}
            update={code => handleSetActivationCode(state.deviceActivationCode, state.location!, code, true)}
            readOnly={!canChangeActivationCode || isServiceDesk}
            displayedValue={(state.location.organization && getOrganizationName(state.location.organization)) || ''}
          />
        )
    },
    {
      key: 'LocationActivationCode',
      label: T('locationConfiguration.property.activationCode'),
      value: state =>
        state.location && (
          <ActivationCodeField
            location={state.locationSummary}
            value={state.activationCode}
            update={(code, withDevice) =>
              handleSetActivationCode(state.deviceActivationCode, state.location!, code, withDevice)
            }
            isDevice={false}
            readOnly={!isServiceDesk}
          />
        )
    },
    {
      key: 'DeviceActivationCode',
      label: T('locationConfiguration.property.deviceActivationCode'),
      value: state =>
        state.location && (
          <ActivationCodeField
            location={state.locationSummary}
            value={state.deviceActivationCode}
            update={code => handleSetDeviceActivationCode(state.deviceActivationCode, state.location!, code)}
            isDevice={true}
            readOnly={!isServiceDesk}
          />
        )
    }
  );

  for (var f of searchFields) {
    const field = f;
    result.push({
      key: `CustomField${field.name}`,
      label: translateFieldName(field),
      value: state =>
        state.location && (
          <SearchFieldEditor
            value={state.searchFieldValues.find(f => f.spec.name === field.name) || {spec: field, value: undefined}}
            readOnly={readOnly}
            update={(name, value) => handleUpdateSearchField(state.location!, name, value)}
            organization={state.location?.organization}
          />
        )
    });
  }

  result.push(
    {
      key: 'DeviceType',
      label: T('locationConfiguration.property.deviceType'),
      value: state => {
        const currentDeviceType = state.location && state.location.deviceType;
        const historicalDeviceType = state.historicalDeviceType;
        if (currentDeviceType) {
          return getDeviceTypeLabelFor(currentDeviceType);
        } else if (historicalDeviceType) {
          return T('locationConfiguration.property.deviceType.uninstalled', {
            type: getDeviceTypeLabelFor(historicalDeviceType)
          });
        } else return T('locationConfiguration.property.deviceType.none');
      }
    },
    {
      key: 'LocationGPS',
      label: T('locationConfiguration.property.location'),
      value: state => {
        const locationId = state.location && state.location.id;
        const latitude = state.location && state.location.latitude;
        const longitude = state.location && state.location.longitude;
        return locationId === undefined ? undefined : (
          <GPSCoordinateEditor
            value={{latitude, longitude}}
            update={position => handleUpdateGps(locationId, position)}
            readOnly={readOnly}
          />
        );
      }
    },
    {
      key: 'Timezone',
      label: T('locationConfiguration.property.timezone'),
      value: state =>
        state.location && (
          <TimezoneEditor
            api={api}
            value={(state.location && state.location.timeZoneId) || 'UTC'}
            onSelected={timezone => handleSelectedTimezone(state.location!, timezone)}
            readOnly={readOnly}
          />
        )
    },
    {
      key: 'Currency',
      label: T('locationConfiguration.property.currency'),
      value: state =>
        state.location && (
          <CurrencyEditor
            value={(state.location && state.location.electricityCurrency) || ''}
            update={currency => handleUpdateCurrency(state.location!.id, currency)}
            readOnly={readOnly}
          />
        )
    },
    {
      key: 'Tariffs',
      label: T('locationConfiguration.tariff'),
      value: state =>
        state.location && (
          <TariffField
            readOnly={readOnly}
            locationId={state.location.id}
            type={state.location.calendarType || TariffType.Flat}
            dynamic={state.dynamicTariff || undefined}
            onChanged={() => {
              refreshLocation(true);
              refreshDynamicTariff();
            }}
          />
        )
    },
    {
      key: 'Pictures',
      label: T('locationConfiguration.property.installationPictures'),
      value: state => {
        const value = state.installationPictures || [];
        const length = value.length;
        const text = quantity('picture', length);

        if (length > 0) {
          return (
            <RsButton color="edit" withoutPadding onClick={() => handleClickedPictures(value)}>
              {text}
            </RsButton>
          );
        } else {
          return text;
        }
      }
    },
    {
      key: 'InstallationDate',
      label: T('locationConfiguration.property.installationDate'),
      value: state => state.location && dayjs(state.location.installationDate).format(FORMAT_DATETIME_S)
    },
    {
      key: 'ClosingDate',
      label: T('locationConfiguration.property.closingDate'),
      value: state => {
        const closingDate = state.location && state.location.closingDate;
        if (closingDate === undefined) {
          return T('locationConfiguration.property.closingDate.stillActive');
        }

        const date = dayjs(closingDate);
        return date.format(FORMAT_DATETIME_S);
      }
    }
  );
  if (isServiceDesk) {
    result.push(
      {
        key: 'MacAddress',
        label: T('locationConfiguration.property.macAddress'),
        value: state => state.location && state.location.macAaddress,
        available: state => (state.location && state.location.macAaddress) !== undefined
      },
      {
        key: 'IPAddress',
        label: T('locationConfiguration.property.ipAddress'),
        value: state => state.location && state.location.ipaddress,
        available: state => (state.location && state.location.ipaddress) !== undefined
      }
    );
  }
  if (deviceType && !isConnect(deviceType)) {
    result.push({
      key: 'NilmVersion',
      label: T('locationConfiguration.property.nilmVersion'),
      value: state =>
        state.location && (
          <NilmVersionField
            value={state.nilmVersion}
            upgrade={() => handleUpgradeNilm(state.location!)}
            readOnly={readOnly}
          />
        )
    });
  }
  result.push({
    key: 'CustomFiles',
    label: T('locationConfiguration.property.customFiles'),
    value: state =>
      state.location && (
        <CustomFilesField
          readOnly={readOnly}
          files={state.customFiles}
          onClickedAdd={() => handleClickedAddCustomFile(state.location!.id)}
          onClickedRemove={file => handleClickedRemoveCustomFile(state.location!.id, file)}
        />
      )
  });
  return result;
}
