import React from 'react';

import {Input, Label, FormGroup, FormText} from '../../components/bootstrap';

import {CheckboxInput} from '../../components/inputs/CheckboxInput';
import {
  IPersistedTableSettings,
  ISelectedColumn,
  clearOrdering,
  getEffectiveColumns,
  isSelectedGroup
} from '../../components/Table';
import {Checkbox} from '../../components/ui/checkbox';
import {AuthUser} from '../../models/AuthUser';
import {HighOrLowLevel, CardDisplayType} from '../../models/CardSettings';
import {Device} from '../../models/Device';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {IHistoricalDataChannel} from '../../models/HistoricalChannel';
import {ILocation, isBigConsumer, LocationFunctionType} from '../../models/Location';
import {ITableField} from '../../models/Table';
import {isDualUltraAssembly} from '../../utils/DualUltra';
import {Fetcher} from '../../utils/Fetcher';
import {useChildLocations} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import ColumnChooser from '../components/ColumnChooser';
import {CardTypeSelector} from '../components/settings/CardTypeSelector';

import {getTableColumns} from './ElectricityValuesColumns';
import {IElectricityCardSettings} from './Settings';

const SPLIT_CHANNEL_NAME = /^channel_id_([0-9]+)([_a-z]*)$/;
function translateColumnsFromChannelsToLoads(loads: IHistoricalDataChannel[], currentColumns: ISelectedColumn[]) {
  const columns: ISelectedColumn[] = [];
  const handledLoads = new Set<string>();
  for (let column of currentColumns) {
    if (isSelectedGroup(column) || !column.name.startsWith('channel_id_')) {
      columns.push(column);
      continue;
    }

    const match = SPLIT_CHANNEL_NAME.exec(column.name);
    if (!match) continue; // invalid channel name, skip

    const channelId = parseInt(match[1]);
    const suffix = match[2];
    const load = loads.find(load => load.actuals.some(channel => channel.id === channelId));
    if (!load || handledLoads.has(load.id + suffix)) continue;

    const visible = load.actuals.some(channel => {
      const name = `channel_id_${channel.id}${suffix}`;
      return currentColumns.some(column => !isSelectedGroup(column) && column.name === name && column.visible);
    });
    columns.push({name: `load_${load.id}${suffix}`, visible});
    handledLoads.add(load.id + suffix);
  }

  return columns;
}

function translateSeriesFromChannelsToLoads(loads: IHistoricalDataChannel[], series: string[]) {
  const results: string[] = [];
  const handledLoads = new Set<string>();
  for (let serie of series) {
    if (!serie.startsWith('channel_id_')) {
      results.push(serie);
      continue;
    }

    const match = SPLIT_CHANNEL_NAME.exec(serie);
    if (!match) continue; // invalid channel name, skip

    const channelId = parseInt(match[1]);
    const suffix = match[2];
    const load = loads.find(load => load.actuals.some(channel => channel.id === channelId));
    if (!load || handledLoads.has(load.id + suffix)) continue;

    const visible = load.actuals.some(channel => {
      const name = `channel_id_${channel.id}${suffix}`;
      return series.includes(name);
    });

    if (visible) results.push(`load_${load.id}${suffix}`);

    handledLoads.add(load.id + suffix);
  }

  return results;
}

function translateSeriesFromLoadsToChannels(loads: IHistoricalDataChannel[], series: string[]) {
  const results: string[] = [];
  for (let serie of series) {
    if (!serie.startsWith('load_')) {
      results.push(serie);
      continue;
    }

    const match = SPLIT_LOAD_NAME.exec(serie);
    if (!match) continue; // invalid column name, skip

    const loadId = parseInt(match[1]);
    const suffix = match[2];
    const load = loads.find(load => load.id === loadId);
    if (!load) continue;

    for (let channel of load.actuals) {
      results.push(`channel_id_${channel.id}${suffix}`);
    }
  }

  return results;
}

const SPLIT_LOAD_NAME = /^load_([0-9]+)([_a-z]*)$/;
function translateColumnsFromLoadsToChannels(loads: IHistoricalDataChannel[], currentColumns: ISelectedColumn[]) {
  const columns: ISelectedColumn[] = [];
  for (let column of currentColumns) {
    if (isSelectedGroup(column) || !column.name.startsWith('load_')) {
      columns.push(column);
      continue;
    }

    const match = SPLIT_LOAD_NAME.exec(column.name);
    if (!match) continue; // invalid column name, skip

    const loadId = parseInt(match[1]);
    const suffix = match[2];
    const load = loads.find(load => load.id === loadId);
    if (!load) continue;

    for (let channel of load.actuals) {
      columns.push({
        name: `channel_id_${channel.id}${suffix}`,
        visible: column.visible
      });
    }
  }

  return columns;
}

interface IElectricityValuesSettingsProps {
  fetch: Fetcher;
  me: AuthUser;
  location: ILocation;
  canHaveSolar: boolean;
  hasSolarForecast: boolean;
  hasStorage: boolean;
  phaseType: PhaseType;
  loads: IHistoricalDataChannel[];
  channels: IHistoricalDataChannel[];
  editingSettings: IElectricityCardSettings;
  updateEditingSettings: (updates: Partial<IElectricityCardSettings>) => void;
}

export function updateSeries(
  series: string[],
  newTable: IPersistedTableSettings,
  oldTable: IPersistedTableSettings,
  fields: ITableField<unknown>[]
) {
  const oldColumns = getEffectiveColumns(oldTable, fields);
  const newColumns = getEffectiveColumns(newTable, fields);
  const addedVisibleColumnNames = newColumns
    .filter(
      newColumn =>
        newColumn.visible && !oldColumns.some(oldColumn => oldColumn.visible && oldColumn.name === newColumn.name)
    )
    .map(column => column.name);
  const cleanedSeries = series.filter(series => newColumns.some(column => column.visible && column.name === series));
  return [...cleanedSeries, ...addedVisibleColumnNames];
}

export const ElectricityValuesSettings = (props: IElectricityValuesSettingsProps) => {
  const {
    fetch,
    phaseType,
    location,
    loads,
    channels,
    me,
    editingSettings,
    updateEditingSettings,
    hasSolarForecast,
    hasStorage
  } = props;

  const [childs] = useChildLocations(fetch, location);
  const {deviceType = undefined} = location || {};
  const tableColumns = editingSettings.table.columns;
  const series = editingSettings.series;
  const functionType = location.functionType ?? LocationFunctionType.None;

  const handleTableSettingsChanged = (table: IPersistedTableSettings) => {
    const series = updateSeries(
      editingSettings.series,
      table,
      editingSettings.table,
      getTableColumnsForLevel(editingSettings.level)
    );

    updateEditingSettings({table, series});
  };

  const handleLowLevelChanged = (checked: boolean) => {
    const {grouped} = editingSettings.table;

    const level = checked ? HighOrLowLevel.Low : HighOrLowLevel.High;
    let newColumns: ISelectedColumn[], newSeries: string[];
    if (level === HighOrLowLevel.Low) {
      newColumns = translateColumnsFromLoadsToChannels(loads, tableColumns);
      newSeries = translateSeriesFromLoadsToChannels(loads, series);
    } else {
      newColumns = translateColumnsFromChannelsToLoads(loads, tableColumns);
      newSeries = translateSeriesFromChannelsToLoads(loads, series);
    }
    if (grouped) {
      newColumns = clearOrdering(newColumns, getTableColumnsForLevel(level));
    }

    const newSettings = getTableSettingsWithColumns(newColumns);
    updateEditingSettings({
      table: newSettings,
      series: newSeries,
      level
    });
  };

  const handleCardTypeChanged = (cardType: CardDisplayType) => {
    updateEditingSettings({cardType});
  };

  const getTableSettingsWithColumns = (columns: ISelectedColumn[]) => {
    return {...editingSettings.table, columns};
  };

  const getTableColumnsForLevel = (level?: HighOrLowLevel) => {
    let {showMinMax, interval} = editingSettings;
    if (level === undefined) level = editingSettings.level;

    const isInfinity = deviceType && Device.isInfinity(deviceType);
    if (!isInfinity) level = HighOrLowLevel.Low;

    return getTableColumns(
      functionType,
      deviceType,
      phaseType,
      location ? isBigConsumer(location) : false,
      location && location.timeZoneId,
      location.hasSolar ?? false,
      me.isServiceDesk(),
      location !== undefined && isDualUltraAssembly(location),
      level,
      channels,
      loads,
      showMinMax,
      hasSolarForecast,
      hasStorage,
      interval,
      childs
    );
  };

  let {cardType, level, showMinMax, table: tableSettings, interval} = editingSettings;

  const isInfinity = (deviceType && Device.isInfinity(deviceType)) || location.chargingStation !== undefined;
  if (!isInfinity) level = HighOrLowLevel.Low;

  const isLowLevel = level === HighOrLowLevel.Low;
  const fields = getTableColumns(
    functionType,
    deviceType,
    phaseType,
    location ? isBigConsumer(location) : false,
    location && location.timeZoneId,
    location.hasSolar ?? false,
    me.isServiceDesk(),
    location.chargingStation !== undefined,
    level,
    channels,
    loads,
    showMinMax,
    hasSolarForecast,
    hasStorage,
    interval,
    childs
  );

  return (
    <div>
      <CardTypeSelector value={cardType} onChange={handleCardTypeChanged} />

      <FormGroup>
        <Checkbox
          id="adjust-range-to-data"
          name="adjust-range-to-data"
          label={T('electricityValues.settings.adjustRangeToData')}
          checked={editingSettings.adjustRangeToActualData}
          onCheckedChange={checked => updateEditingSettings({adjustRangeToActualData: checked})}
          testId="adjust-range-to-data"
        />
        <Checkbox
          id="level"
          name="level"
          label={T('electricityValues.settings.splitLoadsIntoChannels')}
          defaultChecked={isLowLevel}
          onCheckedChange={handleLowLevelChanged}
          disabled={!isInfinity}
          testId="level"
        />

        {!isInfinity && <FormText>{T('electricityValues.settings.channelsOnly')}</FormText>}
      </FormGroup>

      <ColumnChooser fields={fields} settings={tableSettings} updateSettings={handleTableSettingsChanged} />
    </div>
  );
};
