import React from 'react';
import {ModalHeader as RsModalHeader, ModalHeaderProps} from 'reactstrap';

export class ModalHeader extends React.Component<ModalHeaderProps> {
  render() {
    let {className, ...props} = this.props;

    // Hook extra classes into the component
    className = ['dash-modal-header', 'modal-header', className || ''].join(' ');

    return (
      <RsModalHeader className={className} {...props}>
        {this.props.children}
      </RsModalHeader>
    );
  }
}
