import * as React from 'react';

import {getCurrencySymbol} from '../../utils/Currency';
import {useFormContext} from '../../utils/FormContext';
import {FormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {Hotkeys} from '../../utils/Keys';
import {minutesToHoursMinutes} from '../../utils/NumberUtils';
import {FieldValidator} from '../../utils/Validation';
import {Button as RsButton, FormFeedback, Label} from '../bootstrap';

import {IconButton} from '../IconButton';
import {DurationInput} from '../inputs/DurationInput';
import FormInputGroup from '../inputs/FormInputGroup';
import inputStyles from '../inputs/index.module.scss';
import {NumberInput, NumberValue} from '../inputs/NumberInput';
import {TimedInput} from '../pricingPolicies/configurePricingPolicy/FormState';

import {formatTimeComponentPricing} from './connectPricingGroup/DiscountedTariffView';
import tariffsStyles from './TariffsSelector.module.scss';
import styles from './TimeComponentsInput.module.scss';

const initialValue = [{afterMinutes: 0, cost: NumberValue.none()}];

export interface TimeComponentsInputProps {
  name: string;
  label: string;
  value: TimedInput[];
  baseValue?: TimedInput[];
  currency: string;
  onChange: (value: TimedInput[]) => void;
  onRemove: () => void;
  maxItems?: number;
  validate?: FieldValidator;
  effect?: (form: FormState, value: string, valid: boolean) => void;
  optional?: boolean;
  onEnter?: () => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  error?: string;
  max?: number;
  maxError?: string;
}

export function TimeComponentsInput(props: TimeComponentsInputProps) {
  const {
    label,
    name,
    validate,
    value: valueProp = initialValue,
    baseValue,
    onChange,
    onEnter,
    onKeyPress,
    currency,
    effect,
    maxItems = 2,
    onRemove,
    optional,
    error: customError,
    max,
    maxError
  } = props;
  const [value, setValue] = React.useState(valueProp);
  const form = useFormContext();
  const error = validate && validate(JSON.stringify(value), label ?? '', optional ?? false);
  form.setError(name, error);
  const currencySymbol = getCurrencySymbol(currency);
  console.log('Time components', value);

  function updateInput(index: number, input?: TimedInput) {
    const newValue = [...value];

    if (input === undefined) {
      newValue.splice(index, 1);
      setValue(newValue);
      onChange?.(newValue);
      return;
    }

    newValue[index] = input;

    setValue(newValue);
    onChange?.(newValue);
  }

  const actualOnKeyPress = onEnter
    ? (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === Hotkeys.ENTER) {
          if (form.hasErrors()) {
            form.setErrorVisible(name, error !== undefined);
            return;
          }
          onEnter();
        } else {
          onKeyPress && onKeyPress(e);
        }
      }
    : onKeyPress;

  React.useEffect(() => {
    effect && effect(form, JSON.stringify(value), error === undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effect, value, error]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => () => form.remove(name), [name]);

  const shownError = form.getShownError(name);

  return (
    <div className={styles.timedBasedNumberInput}>
      <FormInputGroup name={name} error={shownError || customError} className={inputStyles.formGroup}>
        <table className={styles.timeComponentsTable}>
          <tbody>
            <tr>
              <td>
                <Label>{label}</Label>
              </td>
              <td colSpan={2}>
                <Label>{T('pricingPolicies.add.tariffs.startsAfter')}</Label>
              </td>
            </tr>
            {value.map((input, index) => {
              const costInputName = `${name}-input-${index}`;
              const shownCostError = form.getShownError(costInputName);

              return (
                <>
                  <tr key={`input-${index}`}>
                    <td className={styles.costColumn}>
                      <NumberInput
                        name={costInputName}
                        label={T('pricingPolicies.add.tariffs.hourlyCost')}
                        value={input.cost}
                        className={styles.timedBasedNumberInputField}
                        onChange={cost => updateInput(index, {...input, cost})}
                        max={max}
                        maxError={maxError}
                        suffix={`${currencySymbol}/h`}
                      />
                    </td>
                    <td className={styles.timeColumn}>
                      <DurationInput
                        disabled={index === 0}
                        showLabels={index === 0}
                        name={`${name}-after-minutes-${index}`}
                        value={input.afterMinutes ?? 0}
                        onChange={afterMinutes => {
                          return updateInput(index, {...input, afterMinutes});
                        }}
                      />
                    </td>
                    <td className={styles.deleteColumn}>
                      <IconButton
                        action="delete"
                        color="link"
                        onClick={() => {
                          if (index === 0) return onRemove();
                          updateInput(index, undefined);
                        }}
                        style={{position: 'relative', top: '0.2rem'}}
                      />
                    </td>
                  </tr>
                  {shownCostError && (
                    <tr key={`error-${index}`}>
                      <td className={styles.error}>
                        <FormFeedback name={`${costInputName}-error`} valid={false} style={{display: 'block'}}>
                          {shownCostError}
                        </FormFeedback>
                      </td>
                    </tr>
                  )}
                  {baseValue && (
                    <tr key={`basevalue-${index}`}>
                      <td className={styles.basePrice}>
                        {formatTimeComponentPricing(
                          currency,
                          baseValue,
                          index >= baseValue.length ? baseValue.length - 1 : index
                        )}
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
            {baseValue && baseValue.length > value.length && (
              <tr>
                <td colSpan={3}>
                  {baseValue.slice(value.length).map((element, index) => (
                    <div key={index} className={tariffsStyles.tariffInputOldPrice}>
                      {formatTimeComponentPricing(currency, baseValue, value.length + index)}
                    </div>
                  ))}
                </td>
              </tr>
            )}

            {value.length < maxItems && (
              <tr>
                <td colSpan={2}>
                  <RsButton
                    style={{width: '100%'}}
                    onClick={() => {
                      updateInput(value.length, {afterMinutes: 0, cost: NumberValue.none()});
                    }}
                  >
                    <i className="fa fa-plus mr-2" />
                    {T('pricingPolicies.add.tariffs.addDelayed')}
                  </RsButton>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </FormInputGroup>
    </div>
  );
}
