import {getDay} from 'date-fns';

import {LoadManagementSchedule, LoadManagementSettings} from '../../../api/LoadManagementAPI';
import {T} from '../../../utils/Internationalization';

interface ScheduleFieldProps {
  loadConfig: LoadManagementSettings;
}

const DAY_TYPE_MAP: {[key: string]: string} = {
  MONDAY: T('dayOfWeek.monday'),
  TUESDAY: T('dayOfWeek.tuesday'),
  WEDNESDAY: T('dayOfWeek.wednesday'),
  THURSDAY: T('dayOfWeek.thursday'),
  FRIDAY: T('dayOfWeek.friday'),
  SATURDAY: T('dayOfWeek.saturday'),
  SUNDAY: T('dayOfWeek.sunday')
};

export function getDayTypeLabelFor(day: string): string {
  return DAY_TYPE_MAP[day];
}

export const ScheduleField = (props: ScheduleFieldProps) => {
  const schedules = props.loadConfig.schedules;
  let scheduleDetails: string[] | undefined;
  if (schedules && schedules.length > 0) {
    scheduleDetails = schedules?.map((s: LoadManagementSchedule) => {
      return T('smartCharging.scheduleValues', {
        days: s.dayTypes?.map(getDayTypeLabelFor).join(', ') || '?',
        hours: s.hours?.toString().padStart(2, '0') || '?',
        minutes: s.minutes?.toString().padStart(2, '0') || '?',
        kms: s.minimumDrivingRange?.toString() || '?'
      });
    });
    return <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{scheduleDetails}</div>;
  } else {
    return <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{T('smartCharging.noSchedulesSet')}</div>;
  }
};
