import React, {ReactNode, useState} from 'react';

import {Card} from 'reactstrap';

import {Button as RsButton} from '../../../components/bootstrap';
import {Button} from '../../../components/ui/button';
import {Filter} from '../../../components/ui-lib/icons/medium';
import {BrandColors} from '../../../utils/BrandColors';
import {T} from '../../../utils/Internationalization';

import styles from './FilterPanel.module.scss';

interface FilterPanelButtonProps {
  children: ReactNode;
  hasFilter: boolean;
}

export function FilterPanelButton(props: FilterPanelButtonProps) {
  const {children, hasFilter} = props;

  const [opened, setOpened] = useState(false);

  return (
    <div style={{position: 'relative'}}>
      <Button
        variant="ghost"
        title={T('locations.filter')}
        onClick={() => setOpened(true)}
        className="!tw-w-6 !tw-h-6 !tw-rounded-none !tw-justify-center !tw-p-0"
      >
        <Filter width={16} height={16} />
      </Button>
      <FilterPanel isOpen={opened} children={children} onClose={() => setOpened(false)} />
    </div>
  );
}

interface FilterPanelProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}
export function FilterPanel(props: FilterPanelProps) {
  const {isOpen, children, onClose} = props;

  if (!isOpen) return null;

  return (
    <div className={styles.filterPanelWrapper}>
      <Card body className={styles.filterPanel}>
        <div style={{float: 'right'}}>
          <RsButton color="link" withoutPadding title={T('locations.filter.close')} onClick={onClose}>
            <span className="fas fa-times" />
          </RsButton>
        </div>
        {children}
      </Card>
    </div>
  );
}
