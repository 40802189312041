import React, {CSSProperties} from 'react';
import {FormFeedback} from 'reactstrap';

import {classes} from '../../utils/Styles';
import {FormGroup, Label} from '../bootstrap';

import styles from './index.module.scss';

const STYLE_GRAY: CSSProperties = {color: 'gray'};

interface FormInputGroupProps {
  name: string;
  label?: string;
  children: React.ReactNode;
  className?: string;
  error?: string;
  info?: string | JSX.Element;
}

export default function FormInputGroup(props: FormInputGroupProps) {
  const {name, label, children, error, info, className} = props;
  return (
    <FormGroup className={classes(styles.formGroup, className)}>
      {label && <Label>{label}</Label>}
      {children}
      {error && (
        <FormFeedback name={`${name}-error`} valid={false} style={{display: 'block'}}>
          {error}
        </FormFeedback>
      )}
      {!error && info && <span style={STYLE_GRAY}>{info}</span>}
    </FormGroup>
  );
}
