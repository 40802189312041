import {Marker, MarkerClusterer, MarkerClustererProps, MarkerF, MarkerProps} from '@react-google-maps/api';
import dayjs from 'dayjs';
import {useMemo} from 'react';

import {ChargingStationStatus, getChargingStationStatus} from '../../models/ChargingStation';
import {isLastIntervalAvailable} from '../../utils/TimestampUtils';

import {ChargingHubSummary} from './ChargingHub';

function getHubStatus(loadedAt: dayjs.Dayjs, hub: ChargingHubSummary) {
  const status = hub.chargingStations.map(station => getChargingStationStatus(station));
  if (
    status.some(x => x === ChargingStationStatus.Unavailable) ||
    hub.chargingStations.some(x => !isLastIntervalAvailable(loadedAt, x.lastInterval))
  ) {
    return ChargingStationStatus.Unavailable;
  } else if (status.some(x => x === ChargingStationStatus.Disabled)) {
    return ChargingStationStatus.Disabled;
  } else if (status.some(x => x === ChargingStationStatus.Charging)) {
    return ChargingStationStatus.Charging;
  } else return ChargingStationStatus.Available;
}

interface ChargingHubMarkerProps {
  loadedAt: dayjs.Dayjs;
  hub: ChargingHubSummary;
  isCurrent: boolean;
  clusterer?: MarkerProps['clusterer'];
  onClick: () => void;
}

export function ChargingHubMarker(props: ChargingHubMarkerProps) {
  const {loadedAt, hub, isCurrent, onClick, clusterer} = props;
  const [icon, textColor]: [google.maps.Icon, string] = useMemo(() => {
    const status = getHubStatus(loadedAt, hub);
    const size = new google.maps.Size(32, 32);
    const scaledSize = new google.maps.Size(32, 32);
    const labelOrigin = new google.maps.Point(16, 15);
    switch (status) {
      case ChargingStationStatus.Available:
      default:
        return [
          {
            url: '/assets/markers/hub-available.png',
            size,
            scaledSize,
            labelOrigin
          },
          '#ffffff'
        ];
      case ChargingStationStatus.Disabled:
        return [
          {
            url: '/assets/markers/hub-unavailable.png',
            size,
            scaledSize,
            labelOrigin
          },
          '#ffffff'
        ];
      case ChargingStationStatus.Unavailable:
        return [{url: '/assets/markers/hub-error.png', size, scaledSize, labelOrigin}, '#ffffff'];
      case ChargingStationStatus.Charging:
        return [
          {
            url: '/assets/markers/hub-charging.png',
            size,
            scaledSize,
            labelOrigin
          },
          '#ffffff'
        ];
    }
  }, [hub, loadedAt]);

  if (hub.location.latitude === undefined || hub.location.longitude === undefined) {
    return null;
  }

  return (
    <MarkerF
      position={{lat: hub.location.latitude, lng: hub.location.longitude}}
      title={hub.location.name}
      icon={icon}
      label={{
        text: hub.count.toString(),
        fontSize: '14px',
        fontFamily: '"InterVariable", sans-serif',
        color: textColor
      }}
      clusterer={clusterer}
      onClick={onClick}
    />
  );
}
