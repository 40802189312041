import {CSSProperties} from 'react';

import {Alert, Button as RsButton, Col, FormGroup, Input, InputGroup, Label, Row} from '../../../components/bootstrap';
import {Icons, SMAPPEE_FONT_ICON_MAP} from '../../../components/Icon';
import MarkdownView from '../../../components/MarkdownView';
import {FormState} from '../../../utils/FormState';
import {T} from '../../../utils/Internationalization';

import {EditOrganizationFormState} from './EditOrganizationModels';
import styles from './styles.module.scss';

const STYLE_GRAY: CSSProperties = {color: 'gray'};

interface EditOrganizationSelfBillingTabProps {
  formState: FormState;
  state: EditOrganizationFormState;
  updateState: (updates: Partial<EditOrganizationFormState>) => void;
}

export default function EditOrganizationSelfBillingTab(props: EditOrganizationSelfBillingTabProps) {
  const {formState, state, updateState} = props;
  const {selfBillFooterDetails} = state;

  const handleChange = (index: number, value: string) => {
    const updated = selfBillFooterDetails.map((v, i) => (i === index ? value : v));
    updateState({selfBillFooterDetails: updated});
  };

  const handleDelete = (index: number) => {
    updateState({selfBillFooterDetails: selfBillFooterDetails.filter((_, i) => i !== index)});
  };

  const handleClickedAdd = () => {
    updateState({selfBillFooterDetails: [...selfBillFooterDetails, '']});
  };

  return (
    <div style={{marginTop: '0.5rem'}}>
      <Alert color="info">{T('organizations.tab.selfBilling.info')}</Alert>
      <Row>
        <Col xl={4}>
          <FormGroup>
            <Label>{T('organizations.field.selfBillFooterDetails')}</Label>
            {selfBillFooterDetails.map((value, index) => (
              <DetailEditor key={index} index={index} value={value} onChange={handleChange} onDelete={handleDelete} />
            ))}
            <p style={{textAlign: 'right'}}>
              <RsButton onClick={handleClickedAdd}>{T('organizations.field.selfBillFooterDetails.add')}</RsButton>
            </p>
            <p style={STYLE_GRAY}>{T('organizations.field.selfBillFooterDetails.info')}</p>
          </FormGroup>
        </Col>
      </Row>
      <Alert color="warning">
        <MarkdownView content={T('organizations.tab.selfBilling.warning')} />
      </Alert>
    </div>
  );
}

interface DetailEditorProps {
  index: number;
  value: string;
  onChange: (index: number, value: string) => void;
  onDelete: (index: number) => void;
}

function DetailEditor(props: DetailEditorProps) {
  const {index, value, onChange, onDelete} = props;
  const IconComponent = SMAPPEE_FONT_ICON_MAP['fal fa-trash'];

  return (
    <InputGroup style={{marginBottom: 4}}>
      <Input
        value={value}
        name={`self-bill-footer-detail-${index}`}
        onChange={e => onChange(index, e.currentTarget.value)}
      />
      <RsButton
        className={styles.addon}
        onClick={() => onDelete(index)}
        title={T('specialFunctions.purge.confirmation.delete')}
      >
        {IconComponent ? <IconComponent width={16} height={16} /> : 'X'}
      </RsButton>
    </InputGroup>
  );
}
