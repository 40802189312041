import {NotificationManager} from 'react-notifications';

import {LoadManagementSettings, OptimizationStrategy} from '../../api/LoadManagementAPI';
import {PropertyEntry} from '../../components/Table/SettingsTable';
import API from '../../core/api';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {IPromiseModalTarget} from '../../modals/PromiseModal';
import {getOrganizationName, IActivationCode, IOrganizationActivationCode} from '../../models/ActivationCode';
import {
  ChargingMode,
  ChargingStationFeature,
  ChargingStationModule,
  ChargingStationOperator,
  getActionForChargingMode,
  IChargingStationConfiguration,
  IChargingStationControllerConfiguration,
  SmartChargingMode,
  summaryChargingStationPublicCharging
} from '../../models/ChargingStation';

import {Address} from '../../models/Countries';
import {DeviceType} from '../../models/DeviceType';
import {GPSCoordinate} from '../../models/GPSCoordinate';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {LocationFunctionType} from '../../models/Location';
import {Phase} from '../../models/Phase';
import {IBaseSmartDevice, IConfigurationPropertyValue, SmartDeviceTypeCategory} from '../../models/SmartDevice';
import {ITimezone} from '../../models/Timezone';
import {AppStore} from '../../redux';
import {createUpdateLocation, loadLocation, setContextLocationId} from '../../redux/actions/location';
import {translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';

import {ActivationCodeField} from '../LocationConfiguration/fields/ActivationCodeField';
import {AddressEditor} from '../LocationConfiguration/fields/AddressEditor';
import GPSCoordinateEditor from '../LocationConfiguration/fields/GPSCoordinateEditor';
import NameEditor from '../LocationConfiguration/fields/NameEditor';

import NumberEditor from '../LocationConfiguration/fields/NumberEditor';

import {OperatorEditor} from '../LocationConfiguration/fields/OperatorEditor';
import {OrganizationField} from '../LocationConfiguration/fields/OrganizationField';

import TimezoneEditor from '../LocationConfiguration/fields/TimezoneEditor';

import {ChargingStationDisplayImagesSettings, ManageDisplayImagesModal} from './modals/ManageDisplayImagesModal';
import {SequenceNumberModal} from './modals/SequenceNumberModal';
import {ConnectorState, PropertiesState, StationDetails} from './models/ChargingStationConfiguration.model';
import MoveStationModal from './MoveStationModal';

import PhaseSelector from './PhaseSelector';
import {
  AvailabilityField,
  CableLockedField,
  ChargingModeField,
  OfflineChargingField,
  ParentField,
  PaymentTypesField,
  SmartStrategyField
} from './propertyValueFields';
import CommissioningField from './propertyValueFields/CommissioningField';
import {DisplayImagesField} from './propertyValueFields/DisplayImagesField';
import {PauseResumeField} from './propertyValueFields/PauseResumeField';
import {ScheduleField} from './propertyValueFields/ScheduleField';
import {SequenceNumberField} from './propertyValueFields/SequenceNumberField';

const ledControllerBrightness = 'etc.smart.device.type.car.charger.led.config.brightness';
const minCurrentPropertyName = 'etc.smart.device.type.car.charger.config.min.current';
const maxCurrentPropertyName = 'etc.smart.device.type.car.charger.config.max.current';
const maxPowerPropertyName = 'etc.smart.device.type.car.charger.config.max.power';
const minExcessPercentagePropertyName = 'etc.smart.device.type.car.charger.config.min.excesspct';
const chargingSpeedPropertyName = 'percentageLimit';
const brightnessActionName = 'setBrightness';

export enum Tab {
  GENERAL = 'general',
  CONFIGURATION = 'configuration',
  CONNECTORX = 'connector',
  SESSION_ACTIVATION = 'sessionActivation'
}

function getPhaseAssignmentForCode(code: string) {
  switch (code) {
    case '012':
      return [Phase.L1, Phase.L2, Phase.L3];
    case '021':
      return [Phase.L1, Phase.L3, Phase.L2];
    case '102':
      return [Phase.L2, Phase.L1, Phase.L3];
    case '120':
      return [Phase.L2, Phase.L3, Phase.L1];
    case '201':
      return [Phase.L3, Phase.L1, Phase.L2];
    case '210':
      return [Phase.L3, Phase.L2, Phase.L1];
    default:
      throw new Error(`Unknown phase assignment code: ${code}`);
  }
}

function getCurrent(state: ConnectorState) {
  const minCurrent = state.minCurrent || 0;
  const maxCurrent = state.maxCurrent || 0;
  const speed = (state.chargingSpeed || 0) / 100;
  return minCurrent + speed * (maxCurrent - minCurrent);
}

function updateStartCharging(
  api: API,
  locationId: number,
  smartDevice: IBaseSmartDevice,
  value: IConfigurationPropertyValue
) {
  const action = smartDevice.type.actions.find(action => action.name === 'setPercentageLimit');
  if (!action) {
    return Promise.reject('Action not found');
  }

  const property = {
    spec: action.parameterSpecs[0],
    values: [value]
  };
  return api.setPercentageLimit(locationId, smartDevice.id, [property]);
}

function updateControllerSetting(
  sides: ChargingStationModule[],
  state: PropertiesState,
  position: number,
  updates: Partial<IChargingStationControllerConfiguration>
): IChargingStationConfiguration {
  const modules: IChargingStationControllerConfiguration[] = sides
    //.filter(side => side.position === position)
    // - cloud bug: I have to send both configurations, or phase assignment of the other controller is cleared
    // - note: cloud will always send update messages to both controllers even if only 1 has changed anyways, so it doesn't matter
    .map(side => {
      const result: IChargingStationControllerConfiguration = {
        serialNumber: side.serialNumber,
        name: side.label || (side.position || 0).toString(),
        maxCurrent: state.connectors.get(side.smartDevice!.id)?.maxCurrent || 0,
        phases: getPhaseAssignmentForCode(state.connectors.get(side.smartDevice!.id)?.phaseAssignment || '012')
      };
      if (side.position === position) {
        Object.assign(result, updates);
      }
      return result;
    });
  return {controllers: modules};
}

function updateSmartChargingStrategy(
  api: API,
  stationDetails: StationDetails,
  deviceId: string,
  strategy: OptimizationStrategy
) {
  const locationId = stationDetails.location.id;
  const existing = stationDetails.loadManagementSettings[deviceId];
  return existing.optimizationStrategy
    ? api.loadManagement.updateLoadManagementSettings(locationId, deviceId, {
        optimizationStrategy: strategy,
        schedules: existing.schedules
      })
    : api.loadManagement.createLoadManagementSettings(locationId, deviceId, {
        active: true,
        optimizationStrategy: strategy,
        minimumProductionPercentage: 0,
        schedules: []
      });
}

interface PropertiesCallbacks {
  updateState: (state: Partial<PropertiesState>) => void;
  updateConnectorState: (deviceId: string, state: Partial<ConnectorState>) => void;

  refreshActivationCode: () => void;
  refreshLocation: (force?: boolean) => void;
  refreshDeviceActivationCode: () => void;
  refreshChargingStation: (force?: boolean) => void;
  refreshStationDetails: (force?: boolean) => void;

  handleClickedEditPaymentTypes: (state: PropertiesState) => void;
}

export function getProperties(
  api: API,
  store: AppStore,
  modals: IPromiseModalTarget,
  stationDetails: StationDetails,
  readOnly: boolean,
  isServiceDesk: boolean,
  activationCode: IActivationCode | undefined,
  deviceActivationCode: IActivationCode | undefined,
  callbacks: PropertiesCallbacks
): PropertyEntry<PropertiesState>[] {
  const {updateState} = callbacks;
  const chargingStation = stationDetails.chargingStation;
  const chargingStationGroup = stationDetails.chargingHub;
  const serialNumber = chargingStation.serialNumber;
  const parentId = chargingStationGroup.id;
  const location = stationDetails.location;
  const locationId = location.id;

  const moduleTypes = chargingStation.data.modules.map(module => module.type) || [];
  const hasDisplayImagesSupport =
    moduleTypes.includes(DeviceType.DCCarChargeRFIDTripleDisplay) ||
    moduleTypes.includes(DeviceType.DCCarChargeTripleDisplay);
  const ledController = chargingStation.getLedController();

  const handleClickedParent = () => {
    setContextLocationId(store, api, chargingStationGroup.id);
  };

  const handleClickedEditParent = () => {
    modals
      .show<boolean>(props => (
        <MoveStationModal location={location} parentId={chargingStationGroup.id} chargingStation={true} {...props} />
      ))
      .then(updated => {
        if (updated) {
          loadLocation(store, api, locationId);
          callbacks.refreshChargingStation(true);
        }
      });
  };

  const handleSetActivationCode = async (
    oldDeviceCode: IActivationCode | undefined,
    code: IOrganizationActivationCode,
    withDevice: boolean
  ) => {
    try {
      await api.setLocationActivationCode(location.id, code.code);
      callbacks.refreshActivationCode();
      await loadLocation(store, api, location.id);
      callbacks.refreshLocation(true);
      NotificationManager.success(T('locations.moveToOrganization.moved'));
    } catch {
      NotificationManager.error(T('locations.moveToOrganization.failed'));
    }
    if (withDevice && location.serialNumber && oldDeviceCode) {
      try {
        await api.activationCodes.setDeviceActivationCode(oldDeviceCode.code, location.serialNumber, code.code);
        callbacks.refreshDeviceActivationCode();
        NotificationManager.success(T('locations.moveToOrganization.deviceMoved'));
      } catch {
        NotificationManager.error(T('locations.moveToOrganization.deviceMoveFailed'));
      }
    }
  };

  const handleSetDeviceActivationCode = async (
    oldDeviceCode: IActivationCode | undefined,
    code: IOrganizationActivationCode
  ) => {
    if (!location.serialNumber) return;

    try {
      await api.activationCodes.setDeviceActivationCode(
        oldDeviceCode && oldDeviceCode.code,
        location.serialNumber,
        code.code
      );
      NotificationManager.success(T('locations.moveToOrganization.deviceMoved'));
      callbacks.refreshDeviceActivationCode();
    } catch {
      NotificationManager.error(T('locations.moveToOrganization.deviceMoveFailed'));
    }
  };

  const handleUpdateGps = async (position: GPSCoordinate) => {
    return api.updateLocation(locationId, {
      latitude: position.latitude,
      longitude: position.longitude
    });
  };

  const handleUpdateName = (name: string) => {
    return api.chargingStations.update(parentId, serialNumber, {name}).then(() => {
      NotificationManager.success(T('chargingStationConfiguration.property.name.updated'));
      callbacks.refreshChargingStation();
    });
  };

  const handleAddressChanged = (address: Address | undefined) => {
    return api
      .updateLocation(parentId, {
        address: address
          ? {
              streetAndNumber: address.streetAndNumber,
              city: address.city,
              postalCode: address.postalCode,
              country: address.countryCode
            }
          : undefined
      })
      .then(() => {
        callbacks.refreshLocation();
        callbacks.refreshStationDetails();
      });
  };

  const handleSelectedTimezone = async (timezone: ITimezone) => {
    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T('locationConfiguration.confirmTimezoneChange.title')}
        message={T('locationConfiguration.confirmTimezoneChange.message')}
        acceptLabel={T('locationConfiguration.confirmTimezoneChange.accept')}
        rejectLabel={T('locationConfiguration.confirmTimezoneChange.reject')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    const updatedLocation = {
      ...location,
      timeZoneId: timezone.id,
      timeZone: timezone.name
    };

    try {
      api.updateLocation(location.id, {timeZoneId: timezone.id});
      store.dispatch(createUpdateLocation(updatedLocation));
      callbacks.refreshLocation(true);
      NotificationManager.success(T('locationConfiguration.confirmTimezoneChange.success'));
    } catch {
      NotificationManager.error(T('locationConfiguration.confirmTimezoneChange.failed'));
    }
  };

  const handleAvailableChanged = async (available: boolean) => {
    const confirmed = await modals.show<ConfirmationResult>(props => (
      <ConfirmationPromiseModal
        title={T(
          available ? 'chargingStationConfiguration.enable.title' : 'chargingStationConfiguration.disable.title'
        )}
        message={T(
          available ? 'chargingStationConfiguration.enable.message' : 'chargingStationConfiguration.disable.message'
        )}
        acceptLabel={T(
          available ? 'chargingStationConfiguration.enable.action' : 'chargingStationConfiguration.disable.action'
        )}
        rejectLabel={T('card.cancel')}
        {...props}
      />
    ));
    if (confirmed !== ConfirmationResult.Accept) return;

    try {
      await api.chargingStations.update(parentId, serialNumber, {available});
      updateState({available});
      NotificationManager.success(
        available ? T('chargingStationConfiguration.enable.success') : T('chargingStationConfiguration.disable.success')
      );
    } catch (e) {
      NotificationManager.error(translateError(e, T('chargingStationConfiguration.propertyUpdateFailed')));
    }
  };

  const handleManageDisplayImages = (state: PropertiesState, serialNumber: string) => {
    const timeZoneId = location.timeZoneId;
    const {publiclyVisible, restrictedAccess} = state;
    const settings: ChargingStationDisplayImagesSettings = {
      visible: publiclyVisible,
      restrictedAccess,
      name: location.name || '',
      locationId,
      serialNumber,
      locationFunctionType: LocationFunctionType.ChargingStation,
      timeZoneId
    };
    return modals.show<ChargingStationDisplayImagesSettings | undefined>(props => (
      <ManageDisplayImagesModal settings={settings} location={location} {...props} />
    ));
  };

  const handleOfflineChargingAllowed = (state: PropertiesState, allowed: boolean) => {
    api.chargingStations.update(parentId, serialNumber, {
      offlineCharging: {
        enabled: allowed,
        failSafe: state.failSafeCurrent
      }
    });
    updateState({offlineChargingAllowed: allowed});
  };

  const handleFailSafeCurrentChanged = (state: PropertiesState, current: number) => {
    if (current < 6) {
      NotificationManager.error(T('chargingStationConfiguration.failSafeCurrent.error.lessThanSix'));
      return Promise.resolve(false);
    }

    return api.chargingStations
      .update(parentId, serialNumber, {
        offlineCharging: {
          enabled: state.offlineChargingAllowed,
          failSafe: current
        }
      })
      .then(() => {
        updateState({failSafeCurrent: current});
        return true;
      });
  };

  const handleBrightnessChanged = (brightness: number) => {
    return api.getAllSmartDevices(locationId).then(devices => {
      const smartDevice = devices.find(device => device.type.category === SmartDeviceTypeCategory.ChargingStation);
      if (!smartDevice) return false;

      const action = smartDevice.type.actions.find(p => p.name === brightnessActionName);
      if (action === undefined) return false;

      const brightnessValue: IConfigurationPropertyValue = {Integer: brightness};
      const updatedAction = {spec: action.parameterSpecs[0], values: [brightnessValue]};

      return api
        .runSmartDeviceAction(locationId, smartDevice.id, 'setBrightness', [updatedAction])
        .then(() => {
          updateState({brightness});
          NotificationManager.success(T('chargingStationConfiguration.property.brightness.updated'));
          return true;
        })
        .catch(error => {
          NotificationManager.error(translateError(error, T('chargingStationConfiguration.propertyUpdateFailed')));
          return false;
        });
    });
  };

  const handleOperatorChanged = (operator: ChargingStationOperator | undefined) => {
    const promise =
      operator === undefined ? api.locations.deleteCPO(parentId) : api.locations.setCPO(parentId, operator.id);
    return promise.then(() => {
      callbacks.refreshChargingStation(true);
      callbacks.refreshLocation(true);
    });
  };

  const handleFloorChanged = (floor: string) => {
    if (!serialNumber) return Promise.resolve();

    return api.chargingStations.update(parentId, serialNumber, {
      level: floor
    });
  };

  const result: PropertyEntry<PropertiesState>[] = [
    {
      key: 'parent',
      label: T('chargingStationConfiguration.property.parent'),
      value: () => (
        <ParentField
          label={chargingStationGroup.name || T('chargingStationConfiguration.noParent')}
          isSetChargingGroup={stationDetails.chargingParent === undefined}
          isSetChargingStation={chargingStationGroup?.chargingStation === undefined}
          handleClickedParent={handleClickedParent}
          handleClickedEditParent={handleClickedEditParent}
          canEdit={!readOnly}
        />
      )
    },
    {
      key: 'name',
      label: T('chargingStationConfiguration.property.name'),
      value: () => (
        <NameEditor
          value={chargingStation.name}
          placeholder={T('locationConfiguration.locationName.placeholder')}
          update={handleUpdateName}
          readOnly={readOnly}
        />
      )
    },
    {
      key: 'articleCode',
      label: T('chargingStationConfiguration.property.articleCode'),
      value: () => chargingStation.data.articleCode
    },
    {
      key: 'serialNumber',
      label: T('chargingStationConfiguration.property.serialNumber'),
      value: () => serialNumber
    },
    {
      key: 'available',
      label: T('chargingStationConfiguration.property.available'),
      tab: Tab.CONFIGURATION,
      value: state => (
        <AvailabilityField
          isAvailable={state.available}
          isReadOnly={readOnly || (!isServiceDesk && chargingStation.isAwaitingCommissioning())}
          handleAvailableChanged={handleAvailableChanged}
        />
      )
    }
  ];
  if (chargingStation.hasFeature(ChargingStationFeature.Commissionable)) {
    result.push({
      key: 'commissioning',
      label: T('chargingStationConfiguration.property.commissioning'),
      tab: Tab.SESSION_ACTIVATION,
      value: state => (
        <CommissioningField
          locationId={locationId}
          station={chargingStation}
          isServiceDesk={isServiceDesk}
          onCommissioned={() => callbacks.refreshChargingStation(true)}
        />
      )
    });
  }

  if (hasDisplayImagesSupport) {
    if (chargingStation.data.parts) {
      for (const part of chargingStation.data.parts) {
        result.push({
          key: `displayImages${part.position}`,
          label: T('chargingStationConfiguration.property.displayImages'),
          tab: Tab.CONFIGURATION,
          value: state => (
            <DisplayImagesField
              name={chargingStation.name}
              readOnly={readOnly}
              handleClickEditDisplayImages={() => handleManageDisplayImages(state, part.serialNumber)}
            />
          )
        });
      }
    } else {
      result.push({
        key: 'displayImages',
        label: T('chargingStationConfiguration.property.displayImages'),
        tab: Tab.CONFIGURATION,
        value: state => (
          <DisplayImagesField
            name={chargingStation.name}
            readOnly={readOnly}
            handleClickEditDisplayImages={() => handleManageDisplayImages(state, serialNumber!)}
          />
        )
      });
    }
  }
  if (ledController) {
    result.push({
      key: 'brightness',
      label: T('chargingStationConfiguration.property.brightness'),
      tab: Tab.CONFIGURATION,
      value: state => (
        <NumberEditor
          value={state.brightness || 0}
          update={value => handleBrightnessChanged(value)}
          readOnly={readOnly}
          suffix="%"
          min={0}
          max={100}
        />
      )
    });
  }
  result.push({
    key: 'paymentTypes',
    label: T('chargingStationConfiguration.property.paymentTypes'),
    tab: Tab.SESSION_ACTIVATION,
    value: state => (
      <PaymentTypesField
        paymentTypes={summaryChargingStationPublicCharging({paymentTypes: state.paymentTypes})}
        isReadOnly={readOnly}
        handleClickedEditPaymentTypes={() => callbacks.handleClickedEditPaymentTypes(state)}
      />
    )
  });
  result.push({
    key: 'pricingPolicy',
    label: T('chargingStationConfiguration.property.pricingPolicy'),
    tab: Tab.SESSION_ACTIVATION,
    value: () => location.chargingSettings?.pricingPolicy?.name
  });
  const carChargers = chargingStation.getControllers();

  for (let charger of carChargers) {
    result.push(...getPropertiesForCharger(api, stationDetails, charger, readOnly, modals, isServiceDesk, callbacks));
  }
  if (chargingStation.hasFeature(ChargingStationFeature.OfflineChargingSettings)) {
    result.push({
      key: 'offlineCharging',
      label: T('chargingStationConfiguration.property.offlineCharging'),
      tab: Tab.CONFIGURATION,
      value: state => (
        <OfflineChargingField
          isOfflineChargingAllowed={state.offlineChargingAllowed}
          isReadOnly={readOnly}
          handleOfflineChargingAllowed={allowed => handleOfflineChargingAllowed(state, allowed)}
        />
      )
    });

    const offlineChargingAllowed = chargingStation.data.offlineCharging?.enabled || false;
    if (offlineChargingAllowed) {
      result.push({
        key: `failSafeCurrent`,
        label: T('chargingStationConfiguration.property.failsafeCurrent'),
        tab: Tab.CONFIGURATION,
        value: (
          state // eslint-disable-line no-loop-func
        ) => (
          <NumberEditor
            value={state.failSafeCurrent}
            update={value => handleFailSafeCurrentChanged(state, value)}
            suffix="A"
            readOnly={readOnly}
            min={0}
            max={state.currentLimit}
          />
        )
      });
    }
  }

  if (isServiceDesk && !chargingStation.isThirdParty()) {
    if (location.macAaddress) {
      result.push({
        key: 'macAddress',
        label: T('locationConfiguration.property.macAddress'),
        value: () => location.macAaddress
      });
    }

    result.push(
      {
        key: 'LocationID',
        label: T('chargingStationConfiguration.property.locationId'),
        value: () => location.id.toString()
      },
      {
        key: 'LocationUUID',
        label: T('locationConfiguration.property.uuid'),
        value: () => location.uuid
      },
      {
        key: 'Timezone',
        label: T('locationConfiguration.property.timezone'),
        value: () => (
          <TimezoneEditor
            api={api}
            value={(location && location.timeZoneId) || 'UTC'}
            onSelected={timezone => handleSelectedTimezone(timezone)}
            readOnly={readOnly}
          />
        )
      },
      {
        key: 'organization',
        label: T('locationConfiguration.property.organization'),
        tab: Tab.GENERAL,
        value: () => (
          <OrganizationField
            value={activationCode}
            update={code => handleSetActivationCode(undefined, code, false)}
            readOnly={readOnly}
            displayedValue={(location.organization && getOrganizationName(location.organization)) || ''}
          />
        )
      },
      {
        key: 'LocationActivationCode',
        label: T('locationConfiguration.property.activationCode'),
        value: () => (
          <ActivationCodeField
            location={location}
            value={activationCode}
            update={(code, withDevice) => handleSetActivationCode(deviceActivationCode, code, withDevice)}
            isDevice={false}
            readOnly={readOnly}
          />
        )
      },
      {
        key: 'DeviceActivationCode',
        label: T('locationConfiguration.property.deviceActivationCode'),
        value: () => (
          <ActivationCodeField
            location={location}
            value={deviceActivationCode}
            update={code => handleSetDeviceActivationCode(deviceActivationCode, code)}
            isDevice={true}
            readOnly={readOnly}
          />
        )
      }
    );
  }
  result.push({
    key: 'Operator',
    label: T('chargingStationConfiguration.property.operator'),
    value: () =>
      isServiceDesk ? (
        <OperatorEditor value={chargingStation.data.operator} onSelected={handleOperatorChanged} readOnly={readOnly} />
      ) : (
        chargingStation.data.operator?.name
      )
  });
  result.push(
    {
      key: 'Address',
      label: T('chargingStationConfiguration.property.address'),
      value: () => {
        const address = chargingStationGroup.address;
        return (
          <AddressEditor
            value={
              address
                ? {
                    streetAndNumber: address.streetAndNumber,
                    city: address.city,
                    postalCode: address.postalCode,
                    countryCode: address.country
                  }
                : undefined
            }
            onChanged={handleAddressChanged}
            readOnly={readOnly}
          />
        );
      }
    },
    {
      key: 'Floor',
      label: T('chargingStationConfiguration.property.floor'),
      value: state => (
        <NameEditor
          value={state.floor}
          placeholder={T('chargingStationConfiguration.property.floor.placeholder')}
          update={handleFloorChanged}
          readOnly={readOnly}
        />
      )
    }
  );
  result.push({
    key: 'LocationGPS',
    label: T('locationConfiguration.property.geographicLocation'),
    value: () => {
      const latitude = location.latitude;
      const longitude = location.longitude;
      return (
        <GPSCoordinateEditor
          value={{latitude, longitude}}
          update={position => handleUpdateGps(position)}
          readOnly={readOnly}
        />
      );
    }
  });

  return result;
}

function getPropertiesForCharger(
  api: API,
  stationDetails: StationDetails,
  charger: ChargingStationModule,
  readOnly: boolean,
  modals: IPromiseModalTarget,
  isServiceDesk: boolean,
  callbacks: PropertiesCallbacks
) {
  const {updateState, updateConnectorState} = callbacks;
  const {chargingStation, location} = stationDetails;
  const isUltra = chargingStation.isUltra();
  const chargingStationGroup = stationDetails?.chargingHub;
  const parentHasGateway = chargingStationGroup && chargingStationGroup.serialNumber !== undefined;
  const parentId = chargingStationGroup?.id;
  const serialNumber = chargingStation.serialNumber;
  const loadConfiguration = (stationDetails?.loadManagementSettings || {})[charger.smartDevice!.id];
  const smartMode = loadConfiguration?.optimizationStrategy;
  const locationId = location.id;
  const smartDevice = charger.smartDevice!;
  const smartDeviceId = smartDevice.id;

  const handleMaxCurrentChanged = (value: number) => {
    if (value < 6) {
      NotificationManager.error(T('chargingStationConfiguration.maxCurrent.error.lessThanSix'));
      return Promise.resolve(false);
    }

    return api.smartDevices
      .updateConfigurationProperty(chargingStation.serviceLocationId!, smartDevice, maxCurrentPropertyName, {
        Quantity: {value, unit: 'A'}
      })
      .then(() => {
        updateConnectorState(smartDevice.id, {maxCurrent: value});
        NotificationManager.success(T('chargingStationConfiguration.property.maxCurrent.updated'));
        return true;
      })
      .catch(() => {
        NotificationManager.error(T('chargingStationConfiguration.propertyUpdateFailed'));
        return false;
      });
  };

  const handleMaxPowerChanged = (value: number) => {
    return api.smartDevices
      .updateConfigurationProperty(chargingStation.serviceLocationId!, smartDevice, maxPowerPropertyName, {
        Quantity: {value, unit: 'W'}
      })
      .then(() => {
        updateConnectorState(smartDevice.id, {maxPower: value});
        NotificationManager.success(T('chargingStationConfiguration.property.maxPower.updated'));
        return true;
      })
      .catch(e => {
        NotificationManager.error(translateError(e, T('chargingStationConfiguration.propertyUpdateFailed')));
        return false;
      });
  };

  const handleMinExcessPercentageChanged = (value: number) => {
    return api.smartDevices
      .updateConfigurationProperty(chargingStation.serviceLocationId!, smartDevice, minExcessPercentagePropertyName, {
        Integer: value
      })
      .then(() => {
        updateConnectorState(smartDevice.id, {minExcessPercentage: value});
        NotificationManager.success(T('chargingStationConfiguration.property.minExcessPercentage.updated'));
        return true;
      })
      .catch(e => {
        NotificationManager.error(translateError(e, T('chargingStationConfiguration.propertyUpdateFailed')));
        return false;
      });
  };

  const handlePhasesChanged = (state: PropertiesState, value: string) => {
    const serialNumber = stationDetails.chargingStation.serialNumber;
    const update = updateControllerSetting(stationDetails.controllers, state, charger.position || 0, {
      phases: getPhaseAssignmentForCode(value)
    });
    return api
      .updateChargingStationControllers(serialNumber, update)
      .then(() => {
        api.invalidateHighLevelConfiguration(locationId);
        NotificationManager.success(T('chargingStationConfiguration.property.phaseRotation.updated'));
        updateConnectorState(smartDeviceId, {phaseAssignment: value});
        return true;
      })
      .catch(() => {
        NotificationManager.error(T('chargingStationConfiguration.propertyUpdateFailed'));
        return false;
      });
  };

  const handleChargingSpeedChanged = (value: number) => {
    return updateStartCharging(api, chargingStation.serviceLocationId!, charger.smartDevice!, {Integer: value})
      .then(() => {
        NotificationManager.success(T('chargingStationConfiguration.property.current.updated'));
        return true;
      })
      .catch(() => {
        NotificationManager.error(T('chargingStationConfiguration.propertyUpdateFailed'));
        return false;
      });
  };

  const setChargingMode = (mode: ChargingMode) => {
    return api.smartDevices.runAction(locationId, smartDevice, getActionForChargingMode(mode), {}).then(() => {
      NotificationManager.success(T('chargingStationConfiguration.chargingModeUpdated'), undefined, 5000);
      callbacks.refreshChargingStation(true);
    });
  };

  const setSmartChargingStrategy = (strategy: OptimizationStrategy) => {
    return updateSmartChargingStrategy(api, stationDetails, smartDeviceId, strategy).then(() => {
      NotificationManager.success(T('chargingStationConfiguration.smartStrategyUpdated'), undefined, 5000);
      callbacks.refreshStationDetails(true);
    });
  };

  const setSmartChargingMode = (mode: SmartChargingMode) => {
    return api.smartDevices
      .runAction(locationId, charger.smartDevice!, 'setChargingMode', {mode: {String: mode}})
      .then(() => {
        NotificationManager.success(T('chargingStationConfiguration.smartStrategyUpdated'), undefined, 5000);
        callbacks.refreshChargingStation(true);
        callbacks.refreshStationDetails(true);
      });
  };

  const handleCableLockedChanged = (locked: boolean) => {
    return api.chargingStations
      .update(parentId, serialNumber, {
        cableLocked: locked
      })
      .then(() => updateState({cableLocked: locked}));
  };

  const result: PropertyEntry<PropertiesState>[] = [];
  const tab = Tab.CONNECTORX + (charger.position || 1);

  const carCharger = charger;
  const chargingMode = carCharger.smartDevice?.carCharger?.chargingMode;
  const chargingModeLabel = T('chargingStationConfiguration.property.chargingMode');
  const smartStrategyLabel = T('chargingStationConfiguration.property.useSurplusSchedule.title');
  const minExcessPercentageLabel = T('chargingStationConfiguration.property.minExcessPercentage');

  // SPLIT doesn't have phase assignment for now, but will be changed in the future
  const highLevelConfig = stationDetails?.highLevelConfiguration;
  const hasPhaseAssignment =
    highLevelConfig?.phaseType !== PhaseType.Split &&
    highLevelConfig?.phaseType !== PhaseType.Single &&
    chargingStation?.hasFeature(ChargingStationFeature.PhaseRotation);

  if (hasPhaseAssignment) {
    const sideLabel = carCharger?.position === 1 ? T('general.right') : T('general.left');
    result.push({
      key: `carCharger${carCharger.id}Phases`,
      label: T('chargingStationConfiguration.property.phaseRotation', {
        side: sideLabel
      }),
      tab,
      value: (
        state // eslint-disable-line no-loop-func
      ) => (
        <PhaseSelector
          value={state.connectors.get(smartDeviceId)?.phaseAssignment || '012'}
          update={value => handlePhasesChanged(state, value)}
          readOnly={readOnly}
        />
      )
    });
  }

  const hasChargingMode =
    chargingStation.hasFeature(ChargingStationFeature.StopCharging) ||
    chargingStation.hasFeature(ChargingStationFeature.PauseCharging) ||
    chargingStation.hasFeature(ChargingStationFeature.SmartCharging);

  if (hasChargingMode) {
    result.push({
      key: `carCharger${carCharger.id}ChargingMode`,
      label: chargingModeLabel,
      tab,
      value: state => {
        return (
          <ChargingModeField
            isCharging={state.connectors.get(smartDeviceId)?.charging || false}
            isReadOnly={readOnly}
            chargingMode={chargingMode}
            smartMode={smartMode}
            station={chargingStation}
            setSmartChargingMode={setSmartChargingMode}
            setChargingMode={setChargingMode}
          />
        );
      }
    });
    if (chargingMode === ChargingMode.Smart && !(smartMode === OptimizationStrategy.ExcessOnly)) {
      result.push({
        key: `carCharger${carCharger.id}ChargingStrategy`,
        label: smartStrategyLabel,
        tab,
        value: () => (
          <SmartStrategyField
            isReadOnly={readOnly}
            smartMode={smartMode}
            isUltra={isUltra}
            setSmartChargingStrategy={setSmartChargingStrategy}
          />
        )
      });
    }
  }
  if (
    chargingStation?.hasFeature(ChargingStationFeature.SmartCharging) &&
    chargingMode === ChargingMode.Smart &&
    (loadConfiguration?.optimizationStrategy === OptimizationStrategy.SchedulesOnly ||
      loadConfiguration?.optimizationStrategy === OptimizationStrategy.SchedulesThenExcess)
  ) {
    result.push({
      key: `carCharger${carCharger.id}Schedule`,
      label: T('smartCharging.schedule.title'),
      tab,
      value: () => <ScheduleField loadConfig={loadConfiguration} />
    });
  }
  if (
    chargingStation?.hasFeature(ChargingStationFeature.PauseCharging) &&
    (chargingMode === ChargingMode.Normal || chargingMode === ChargingMode.Stop)
  ) {
    result.push({
      key: `carCharger${carCharger.id}PauseResumeCharging`,
      label: T('chargingStationConfiguration.property.pauseResume'),
      tab,
      value: state => (
        <PauseResumeField
          isReadOnly={readOnly}
          isCharging={state.connectors.get(smartDeviceId)?.charging || false}
          chargingMode={chargingMode}
          smartMode={smartMode}
          setChargingMode={setChargingMode}
          isUltra={isUltra}
        />
      )
    });
  }
  if (
    chargingStation?.hasFeature(ChargingStationFeature.SmartCharging) &&
    parentHasGateway &&
    chargingMode === ChargingMode.Smart &&
    (smartMode === OptimizationStrategy.ExcessOnly || smartMode === OptimizationStrategy.SchedulesThenExcess)
  ) {
    result.push({
      key: `carCharger${carCharger.id}MinExcess`,
      label: minExcessPercentageLabel,
      tab,
      value: state => (
        <NumberEditor
          value={state.connectors.get(smartDeviceId)?.minExcessPercentage}
          update={value => handleMinExcessPercentageChanged(value)}
          suffix="%"
          readOnly={readOnly}
          min={0}
          max={100}
        />
      )
    });
  }
  if (chargingStation?.hasFeature(ChargingStationFeature.MaxCurrent) && chargingMode === ChargingMode.Normal) {
    const sideLabel = carCharger?.label === '1' ? T('general.right') : T('general.left');
    result.push({
      key: `carCharger${carCharger.id}Current`,
      label: T('chargingStationConfiguration.property.current', {
        side: sideLabel
      }),
      tab,
      value: (
        state // eslint-disable-line no-loop-func
      ) => {
        // Javascript's way to round to 2 decimals is iffy  (https://stackoverflow.com/a/11832950)
        const connector = state.connectors.get(smartDeviceId);
        return (
          <NumberEditor
            value={Math.round(((connector?.chargingSpeed || 0) + Number.EPSILON) * 100) / 100}
            extra={` (${connector ? Math.round((getCurrent(connector) + Number.EPSILON) * 100) / 100 : ''} A)`}
            update={handleChargingSpeedChanged}
            suffix="%"
            readOnly={readOnly}
            min={0}
            max={100}
          />
        );
      }
    });
  }
  if (chargingStation?.hasFeature(ChargingStationFeature.MaxPower)) {
    const chargerSide = carCharger?.label === '1' ? T('general.right') : T('general.left');
    result.push({
      key: `carCharger${carCharger.id}Max`,
      label: T('chargingStationConfiguration.property.maxPower', {
        side: chargerSide
      }),
      tab,
      value: (
        state // eslint-disable-line no-loop-func
      ) => (
        <NumberEditor
          value={state.connectors.get(smartDeviceId)?.maxPower}
          update={handleMaxPowerChanged}
          suffix="kW"
          readOnly={readOnly}
          min={0}
          max={state.connectors.get(smartDeviceId)?.maxPowerRange}
        />
      )
    });
  } else if (chargingStation?.hasFeature(ChargingStationFeature.MaxCurrent)) {
    const sideLabel = carCharger?.label === '1' ? T('general.right') : T('general.left');
    result.push({
      key: `carCharger${carCharger.id}Max`,
      label: T('chargingStationConfiguration.property.maxCurrent', {
        side: sideLabel
      }),
      tab,
      value: (
        state // eslint-disable-line no-loop-func
      ) => (
        <NumberEditor
          value={state.connectors.get(smartDeviceId)?.maxCurrent}
          update={handleMaxCurrentChanged}
          suffix="A"
          readOnly={readOnly}
          min={0}
          max={state.currentLimit}
        />
      )
    });
  }
  if (
    chargingStation?.hasFeature(ChargingStationFeature.LockCable) &&
    chargingStation?.data?.connectionType === 'CASEB'
  ) {
    result.push({
      key: `cableLocked${charger.position || 1}`,
      label: T('chargingStationConfiguration.property.cableLocked'),
      tab,
      value: state => (
        <CableLockedField
          isLocked={state.cableLocked}
          isReadOnly={readOnly}
          handleCableLockedChanged={handleCableLockedChanged}
        />
      )
    });
  }

  const sequenceNumberProperty = charger.smartDevice?.configurationProperties.find(
    p => p.spec.name === 'etc.smart.device.type.car.charger.smappee.charger.number'
  );
  if (sequenceNumberProperty && isServiceDesk) {
    const handleClickedEdit = () => {
      modals
        .show<boolean>(props => <SequenceNumberModal location={stationDetails.chargingHub} {...props} />)
        .then(updated => updated && callbacks.refreshChargingStation(true));
    };

    result.push({
      key: `sequenceNumber${charger.position || 1}`,
      label: T('chargingStationConfiguration.property.sequenceNumber'),
      tab,
      value: state => (
        <SequenceNumberField
          value={sequenceNumberProperty.values[0].Integer || 0}
          readOnly={readOnly}
          onClickedEdit={handleClickedEdit}
        />
      )
    });
  }
  return result;
}
