import {useCallback} from 'react';

import {Button as RsButton, RowActions} from '../../components/bootstrap';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import {AuthUser} from '../../models/AuthUser';
import {
  CalculatedStringField,
  ComponentField,
  FieldAlignment,
  IntegerField,
  ITableField,
  StringField
} from '../../models/Table';
import {getUserTypeDisplayName, IUser} from '../../models/User';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';

interface IListeners {
  onClickedEdit: (user: IUser) => void;
  onClickedResetPassword: (user: IUser) => void;
  onClickedResetActivations: (user: IUser) => void;
  onClickedUserOrganizations: (user: IUser) => void;
  onClickedContracts: (user: IUser) => void;
  onClickedRemove: (user: IUser) => void;
  onClickedUnarchive: (user: IUser) => void;
}

export function getTableColumns(me: AuthUser, listeners?: IListeners) {
  const columns: ITableField<IUser>[] = [];
  if (me.isServiceDesk()) {
    columns.push(new IntegerField('id', 'ID'));
  }

  if (!hasFeature(AppFeature.SocialLogin)) {
    columns.push(
      new StringField('userName', T('users.field.username'), {
        alwaysVisible: true,
        autoInsert: 'start'
      })
    );
  }

  columns.push(
    new StringField('emailAddress', T('users.field.email')),
    new StringField('firstName', T('users.field.firstName')),
    new CalculatedStringField('role', T('users.field.role'), row => getUserTypeDisplayName(row.type), {width: 125})
  );
  if (listeners) {
    columns.push(
      new ComponentField(
        'actions',
        T('users.field.actions'),
        user => (
          <UserActions
            me={me}
            user={user}
            onClickedEdit={listeners.onClickedEdit}
            onClickedResetPassword={listeners.onClickedResetPassword}
            onClickedResetActivations={listeners.onClickedResetActivations}
            onClickedUserOrganizations={listeners.onClickedUserOrganizations}
            onClickedContracts={listeners.onClickedContracts}
            onClickedRemove={listeners.onClickedRemove}
            onClickedUnarchive={listeners.onClickedUnarchive}
          />
        ),
        {align: FieldAlignment.Center, autoInsert: 'end', width: 100}
      )
    );
  }
  return columns;
}

interface UserActionsProps {
  me: AuthUser;
  user: IUser;
  onClickedEdit: (user: IUser) => void;
  onClickedResetPassword: (user: IUser) => void;
  onClickedResetActivations: (user: IUser) => void;
  onClickedUserOrganizations: (user: IUser) => void;
  onClickedContracts: (user: IUser) => void;
  onClickedRemove: (user: IUser) => void;
  onClickedUnarchive: (user: IUser) => void;
}
const UserActions = (props: UserActionsProps) => {
  const {
    me,
    user,
    onClickedEdit,
    onClickedResetPassword,
    onClickedUnarchive,
    //onClickedResetActivations,
    onClickedUserOrganizations,
    onClickedContracts,
    onClickedRemove
  } = props;
  const sameUsername = me.username === user.userName;
  const sameEmail = me.email === user.emailAddress;
  const sameUser = sameUsername && sameEmail;
  const isServiceDesk = me.isServiceDesk();
  const canEdit = !me.isReadOnly();

  const handleClickedEdit = useCallback(() => onClickedEdit(user), [onClickedEdit, user]);
  const handleClickedResetPassword = useCallback(() => onClickedResetPassword(user), [onClickedResetPassword, user]);
  //const handleClickedResetActivations = useCallback(() => onClickedResetActivations(user), [onClickedResetActivations, user]);
  const handleClickedRemove = useCallback(() => onClickedRemove(user), [onClickedRemove, user]);
  const handleClickedUnarchive = useCallback(() => onClickedUnarchive(user), [onClickedUnarchive, user]);
  const handleClickedUserOrganizations = useCallback(
    () => onClickedUserOrganizations(user),
    [onClickedUserOrganizations, user]
  );
  const handleClickedContracts = useCallback(() => onClickedContracts(user), [onClickedContracts, user]);

  if (user.archivedOn && isServiceDesk) {
    return (
      <RowActions>
        <RsButton title="Restore user" onClick={handleClickedUnarchive} className={testingClasses.remove}>
          {T('users.restore.action')}
        </RsButton>
      </RowActions>
    );
  }

  return (
    <RowActions>
      {canEdit && <RowActionButton title="Edit user" onClick={handleClickedEdit} icon="Pencil" />}
      {canEdit && <RowActionButton title="Reset password" onClick={handleClickedResetPassword} icon="LockAlt" />}
      {isServiceDesk && (
        <RowActionButton title="User organizations" icon="Users" onClick={handleClickedUserOrganizations} />
      )}
      {isServiceDesk && <RowActionButton title="Contracts" icon="Contract" onClick={handleClickedContracts} />}
      {canEdit && (
        <RowActionButton
          action="delete"
          title="Remove user"
          disabled={sameUser}
          onClick={handleClickedRemove}
          className={testingClasses.remove}
          data-testid={testingClasses.remove}
        />
      )}
    </RowActions>
  );
};
