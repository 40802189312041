import {LoggedFeature} from './Logging';

export enum AppFeature {
  Debug = 'Debug',
  SolarOptimizationChart = 'SolarOptimizationChart',
  CreateSmartDevices = 'CreateSmartDevices',
  SocialLogin = 'SocialLogin',
  DevTools = 'DevTools',
  DeleteUser = 'DeleteUser',
  PlannedMaintenance = 'PlannedMaintenance',
  SquareCPO = 'SquareCPO',
  ReplacementModules = 'ReplacementModules',
  VatExemptCPO = 'VatExemptCPO',
  PaymentTerminals = 'PaymentTerminals',
  NewStationFeatures = 'NewStationFeatures',
  SequenceNumbers = 'SequenceNumbers'
}

export interface AppParameters {
  apiUrl: string;
  features: AppFeature[];
  auth: {
    google: string;
    microsoft: string;
    apple: string;
    redirectUrl: string;
  };
  googleMapsApiKey: string;
  log: LoggedFeature[];
  evChargingTargetUrl?: string;
}

const parameters: AppParameters = (window as any).appParameters;

export function getMapsApiKey() {
  return parameters.googleMapsApiKey;
}

export function getAuthParameters() {
  return parameters.auth;
}

export function hasFeature(feature: AppFeature) {
  return parameters.features.includes(feature);
}

export function isLogged(feature: LoggedFeature) {
  return parameters.log.includes(feature);
}

export function getMQTTUrl() {
  return `${parameters.apiUrl.replace('https://', 'wss://')}/mqtt`;
}

export function getEVChargingTargetUrl() {
  return (
    parameters.evChargingTargetUrl || `${window.location.protocol}//${window.location.host}${window.location.pathname}`
  );
}
