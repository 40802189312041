import {IPowerMessage} from '../../livedata/LiveDataModels';
import {LocationDataStates} from '../../livedata/LiveDataMulti';
import {IChannelSensor} from '../../models/Channel';
import {CTType} from '../../models/CTType';
import {ILegacyChannel} from '../../models/LegacyChannel';
import {ILoad, LoadType, ILoadUpdateChannels, UpdateChannelAggregation} from '../../models/Load';
import {Phase} from '../../models/Phase';
import {IChannelPaths} from '../../models/TrackingPath';

export interface IChannelInfo extends IChannelPaths {
  id: number;
  serviceLocationId?: number;
  location: string;
  name: string;
  type: LoadType;
  phase: Phase;
  phaseLabel: string;
  maxAmpere?: number;
  ctType: CTType;
  reversed: boolean;
  publishIndex?: number;
  updateChannels: ILoadUpdateChannels | undefined;
  sensor?: IChannelSensor;
}

export interface ILiveProData {
  channel: ILegacyChannel;
  index: number;
  active?: number;
  reactive?: number;
  current?: number;
  voltages: number[];
}

export interface ILiveLowLevelData {
  channel: IChannelInfo;
  index: number;
  active?: number;
  reactive?: number;
  current?: number;
  phaseVoltage?: number;
  lineVoltage?: number;
}

export interface ILiveHighLevelData {
  load: ILoad;
  index: number;
  active?: number;
  reactive?: number;
  currents: {[key: string]: number};
  phaseVoltages: {[key: string]: number};
  lineVoltages: {[key: string]: number};
  numberOfPhasesUsed?: number;
}

const DATA_OFFSET = 3;
export function getLiveProData(proChannels: ILegacyChannel[], powerMessage: IPowerMessage | undefined) {
  return proChannels.map((channel, i) => {
    if (powerMessage === undefined || powerMessage.channelData === undefined) {
      return {channel, index: i, voltages: []};
    } else {
      const data = powerMessage.channelData as number[];
      const active = data[i * 2 + DATA_OFFSET];
      const reactive = data[i * 2 + DATA_OFFSET + 1];
      const voltages = [data[0] / 10, data[1] / 10, data[2] / 10];
      return {channel, index: i, active, reactive, voltages};
    }
  });
}

export function getLiveLowLevelData(
  channels: IChannelInfo[],
  powerMessage: IPowerMessage | undefined
): ILiveLowLevelData[] {
  const message = powerMessage;
  (window as any).lastPowerMessage = message;

  if (message === undefined) {
    return channels.map((channel, index) => ({channel, index}));
  }

  return channels.map((channel, i) => {
    const {activeConfig, reactiveConfig, currentConfig, phaseConfig, lineConfig} = channel;

    const active = activeConfig.extract(message);
    const reactive = reactiveConfig.extract(message);
    const current = currentConfig.extract(message);
    const phaseVoltage = phaseConfig.extract(message);
    const lineVoltage = lineConfig.extract(message);
    return {
      channel,
      index: i,
      active,
      reactive,
      current,
      phaseVoltage,
      lineVoltage
    };
  });
}

export function getLiveHighLevelData(
  loads: (ILoad & {channels: IChannelInfo[]})[],
  powerMessage: IPowerMessage | undefined
) {
  if (
    powerMessage === undefined ||
    ((!Array.isArray(powerMessage.activePowerData) || powerMessage.activePowerData.length === 0) &&
      powerMessage.childData === undefined)
  ) {
    return loads.map((load, index) => ({
      load,
      index,
      currents: {},
      phaseVoltages: {},
      lineVoltages: {}
    }));
  }

  const message = powerMessage;

  // Loads
  let liveData: ILiveHighLevelData[] = [];
  let index = 0;
  for (let load of loads) {
    const {updateChannels, channels} = load;
    if (!updateChannels) continue;

    const {activePower, reactivePower} = updateChannels;
    let active = 0;
    let reactive = 0;

    // Active power
    if (activePower) {
      const {aggregation} = activePower;

      // Use aspect paths to retrieve values
      if (aggregation === UpdateChannelAggregation.Sum) {
        for (let channel of channels) {
          try {
            const value = channel.activeConfig.extract(message);
            if (value !== undefined) active += value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }
    }

    // Reactive power
    if (reactivePower) {
      const {aggregation} = reactivePower;

      // Use aspect paths to retrieve values
      if (aggregation === 'SUM') {
        for (let channel of channels) {
          try {
            const value = channel.reactiveConfig.extract(message);
            if (value !== undefined) reactive += value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }
    }

    // Assign objects
    const currents: {[key: string]: number} = {};
    const phaseVoltages: {[key: string]: number} = {};
    const lineVoltages: {[key: string]: number} = {};

    // Loop over channels to assign phase voltages
    for (let channel of load.channels) {
      if (!channel.updateChannels) continue;

      const {phase} = channel;
      const {current, lineVoltage, phaseVoltage} = channel.updateChannels;

      if (current) {
        // Use aspect paths to retrieve values
        for (let channel of channels) {
          try {
            const value = channel.currentConfig.extract(message);
            if (value !== undefined) currents[phase] = value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }

      if (phaseVoltage) {
        // Use aspect paths to retrieve values
        for (let channel of channels) {
          try {
            const value = channel.phaseConfig.extract(message);
            if (value !== undefined) phaseVoltages[phase] = value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }

      if (lineVoltage) {
        // Use aspect paths to retrieve values
        for (let channel of channels) {
          try {
            const value = channel.lineConfig.extract(message);
            if (value !== undefined) lineVoltages[phase] = value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }
    }

    liveData.push({
      load,
      index,
      active,
      reactive,
      currents,
      phaseVoltages,
      lineVoltages
    });
    index++;
  }

  return liveData;
}

export function getLiveLowLevelDataMulti(
  locationId: number,
  channels: IChannelInfo[],
  data: LocationDataStates
): ILiveLowLevelData[] {
  return channels.map((channel, i) => {
    const message = data.getState(channel.serviceLocationId || locationId).power;
    if (message === undefined) return {channel, index: i};

    const {activeConfig, reactiveConfig, currentConfig, phaseConfig, lineConfig} = channel;

    const active = activeConfig.extract(message);
    const reactive = reactiveConfig.extract(message);
    const current = currentConfig.extract(message);
    const phaseVoltage = phaseConfig.extract(message);
    const lineVoltage = lineConfig.extract(message);
    return {
      channel,
      index: i,
      active,
      reactive,
      current,
      phaseVoltage,
      lineVoltage
    };
  });
}

export function getLiveHighLevelDataMulti(loads: (ILoad & {channels: IChannelInfo[]})[], data: LocationDataStates) {
  // Loads
  let liveData: ILiveHighLevelData[] = [];
  let index = 0;
  for (let load of loads) {
    const message = load.serviceLocationId ? data.getState(load.serviceLocationId).power : undefined;
    const {updateChannels, channels} = load;
    if (!updateChannels || !message) continue;

    const {activePower, reactivePower} = updateChannels;
    let active = 0;
    let reactive = 0;

    // Active power
    if (activePower) {
      const {aggregation} = activePower;

      // Use aspect paths to retrieve values
      if (aggregation === UpdateChannelAggregation.Sum) {
        for (let channel of channels) {
          try {
            const value = channel.activeConfig.extract(message);
            if (value !== undefined) active += value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }
    }

    // Reactive power
    if (reactivePower) {
      const {aggregation} = reactivePower;

      // Use aspect paths to retrieve values
      if (aggregation === 'SUM') {
        for (let channel of channels) {
          try {
            const value = channel.reactiveConfig.extract(message);
            if (value !== undefined) reactive += value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }
    }

    // Assign objects
    const currents: {[key: string]: number} = {};
    const phaseVoltages: {[key: string]: number} = {};
    const lineVoltages: {[key: string]: number} = {};

    // Loop over channels to assign phase voltages
    for (let channel of load.channels) {
      if (!channel.updateChannels) continue;

      const {phase} = channel;
      const {current, lineVoltage, phaseVoltage} = channel.updateChannels;

      if (current) {
        // Use aspect paths to retrieve values
        for (let channel of channels) {
          try {
            const value = channel.currentConfig.extract(message);
            if (value !== undefined) currents[phase] = value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }

      if (phaseVoltage) {
        // Use aspect paths to retrieve values
        for (let channel of channels) {
          try {
            const value = channel.phaseConfig.extract(message);
            if (value !== undefined) phaseVoltages[phase] = value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }

      if (lineVoltage) {
        // Use aspect paths to retrieve values
        for (let channel of channels) {
          try {
            const value = channel.lineConfig.extract(message);
            if (value !== undefined) lineVoltages[phase] = value;
          } catch (err) {
            // Ignore parsing errors
          }
        }
      }
    }

    liveData.push({
      load,
      index,
      active,
      reactive,
      currents,
      phaseVoltages,
      lineVoltages
    });
    index++;
  }

  return liveData;
}
