import React, {useState} from 'react';

import {useAppContext} from '../../app/context';
import {Form, SingleActionModal} from '../../components/bootstrap';
import {NumberInputGroup, NumberValue} from '../../components/inputs/NumberInput';
import {PricesExclVat} from '../../components/Notes';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {formatCurrency} from '../../utils/Currency';
import {translateError} from '../../utils/Errors';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';

interface OrderTokensModalProps extends IPromiseModalProps<boolean> {
  organizationId: number;
}

export default function OrderTokensModal(props: OrderTokensModalProps) {
  const {organizationId} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const form = useFormState();
  const {api} = useAppContext();
  const [cards, setCards] = useState<NumberValue>(NumberValue.create(10));
  const [error, setError] = useState<string>();

  const handleClickedOrder = () => {
    if (form.hasErrors()) {
      return Promise.resolve('');
    }

    return api.organizations
      .orderCards(organizationId, cards.numberValue || 0)
      .then(() => {
        resolve(true);
        return undefined;
      })
      .catch((err: unknown) => setError(translateError(err)));
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('publicChargingTokens.orderCards.title')}
      action={handleClickedOrder}
      actionText={T('publicChargingTokens.orderCards.order')}
      note={<PricesExclVat />}
      error={error}
    >
      <p style={{marginTop: '1rem'}}>
        {T('publicChargingTokens.orderCards.message', {
          price: formatCurrency('EUR', 5)
        })}
      </p>
      <FormContextProvider value={form}>
        <Form>
          <NumberInputGroup
            name="cards"
            label={T('publicChargingTokens.orderCards.amount')}
            value={cards}
            onChange={setCards}
          />
        </Form>
      </FormContextProvider>
    </SingleActionModal>
  );
}
