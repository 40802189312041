import cn from 'classnames';
import React, {useRef, useState} from 'react';

import {isAPIResponse} from '../../api/APIClient';

import {useAppContext} from '../../app/context';

import {SingleActionModal} from '../../components/bootstrap';
import {SelectInput} from '../../components/inputs/SelectInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ChargingStationModule} from '../../models/ChargingStation';
import {DeviceType, getDeviceTypeLabelFor} from '../../models/DeviceType';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {translateError} from '../../utils/Errors';
import {useFormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {useUser} from '../CardUtils';
import {validateChargingStationModuleSerial} from '../CreateChargingStations/ModuleInput';

import styles from './ReplaceComponentModal.module.scss';

interface ReplaceComponentModalProps extends IPromiseModalProps<boolean> {
  stationSerial: string;
  module: ChargingStationModule;
}

export default function ReplaceComponentModal(props: ReplaceComponentModalProps) {
  const {stationSerial, module} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(false);
  const {api} = useAppContext();
  const me = useUser();
  const modals = useModals();

  const form = useFormState();
  const validate = (value: string) =>
    module.type === DeviceType.CarChargerSticker ? undefined : validateChargingStationModuleSerial(module.type, value);
  const handleSaveRef = useRef<() => void>(() => {});
  const [error, setError] = useState<string>();

  const [manualSerialNumber, setManualSerialNumber] = useState('');
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(
    module.replacementCandidateSerialNumbers?.[0] || 'other'
  );
  const moduleReplacementSupport = hasFeature(AppFeature.ReplacementModules);
  const hasReplacementFeature = module.hasOwnProperty('replacementCandidateSerialNumbers');
  const hasMultiple = hasReplacementFeature && module.replacementCandidateSerialNumbers!.length > 1;
  const hasOne = hasReplacementFeature && module.replacementCandidateSerialNumbers!.length === 1;
  const hasNone = hasReplacementFeature && module.replacementCandidateSerialNumbers!.length === 0;
  const isNotInstalled = !hasMultiple && !hasOne && !hasNone;

  const handleSave = () => {
    form.clearServerErrors();
    setError(undefined);
    if (form.hasErrors()) return Promise.resolve(undefined);

    const serialNumber =
      !moduleReplacementSupport || selectedSerialNumber === 'other' ? manualSerialNumber : selectedSerialNumber;
    return api
      .replaceChargingStationModule(stationSerial, module.id, serialNumber)
      .then(() => resolve(true))
      .catch(err => {
        if (isAPIResponse(err) && err.statusCode === 409) {
          if (me.isServiceDesk() && (err as any).chargingStationSerial) {
            const otherStationSerial = (err as any).chargingStationSerial;
            modals
              .show<ConfirmationResult>(props => (
                <ConfirmationPromiseModal
                  title={T('moduleReplacement.swapComponent')}
                  message={T('moduleReplacement.swapComponentConfirmationMessage', {
                    otherStationSerial
                  })}
                  {...props}
                />
              ))
              .then(result => {
                if (result === ConfirmationResult.Accept) {
                  api
                    .replaceChargingStationModuleWithOther(stationSerial, module.id, serialNumber, otherStationSerial)
                    .then(() => resolve(true))
                    .catch(err => setError(translateError(err)));
                }
              });
          } else {
            form.setServerError('serialNumber', T('moduleReplacement.componentInUse'));
          }
        } else {
          setError(translateError(err));
        }
      });
  };
  handleSaveRef.current = handleSave;
  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      title={T('moduleReplacement.replaceComponent')}
      error={error}
      action={handleSave}
      actionText={T('moduleReplacement.replace')}
    >
      {moduleReplacementSupport ? (
        <div className={styles.content}>
          {(hasNone || isNotInstalled) && (
            <>
              <p>{T('moduleReplacement.modulesFound')}</p>
              <div className={styles.card__details}>
                <h6>{T('moduleReplacement.type')}</h6>
                <p>{getDeviceTypeLabelFor(props.module.type)}</p>
              </div>
              <div className={styles.row}>
                <div className={cn(styles.col, styles.list, 'text-danger')}>
                  <h6>{T('moduleReplacement.oldSerial')}</h6>
                  <p>{props.module.serialNumber}</p>
                </div>
              </div>
              <div className={styles.row}>
                <div className={cn(styles.col, styles.alignRight)}>
                  {me.isServiceDesk() && selectedSerialNumber === 'other' && (
                    <>
                      <h6>{T('moduleReplacement.otherSerial')}</h6>
                      <TextInputGroup
                        name="serialNumber"
                        // label={T('moduleReplacement.serialNumber')}
                        value={manualSerialNumber}
                        onChange={setManualSerialNumber}
                        validate={validate}
                        onEnter={() => handleSaveRef.current()}
                        error={form.getServerError('serialNumber')}
                        className={styles.colRight}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {!hasNone && !isNotInstalled && (
            <>
              <p className="mb-0">{T('moduleReplacement.modulesFound')}</p>
              <div className={styles.card__details}>
                <h6>{T('moduleReplacement.type')}</h6>
                <p>{getDeviceTypeLabelFor(props.module.type)}</p>
              </div>
              <div className={styles.row}>
                <div className={cn(styles.col, styles.list)}>
                  <h6>{T('moduleReplacement.oldSerial')}</h6>
                  <p className="text-danger">{props.module.serialNumber}</p>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col}>
                  {(hasMultiple || (hasOne && me.isServiceDesk()) || (isNotInstalled && me.isServiceDesk())) && (
                    <>
                      <h6>{hasMultiple ? T('moduleReplacement.newSerials') : T('moduleReplacement.newSerial')}</h6>
                      <SelectInput
                        onChange={setSelectedSerialNumber}
                        value={selectedSerialNumber}
                        className={styles.dropdown}
                      >
                        {module.replacementCandidateSerialNumbers?.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                        {me.isServiceDesk() && <option value="other">Other</option>}
                      </SelectInput>
                      {me.isServiceDesk() && (
                        <div className={cn(styles.col, styles.alignRight)}>
                          {selectedSerialNumber === 'other' && (
                            <>
                              <h6>{T('moduleReplacement.otherSerial')}</h6>
                              <TextInputGroup
                                name="serialNumber"
                                // label={T('moduleReplacement.serialNumber')}
                                value={manualSerialNumber}
                                onChange={setManualSerialNumber}
                                validate={validate}
                                onEnter={() => handleSaveRef.current()}
                                error={form.getServerError('serialNumber')}
                                className={styles.colRight}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {hasOne && !me.isServiceDesk() && (
                    <>
                      <h6>{T('moduleReplacement.newSerial')}</h6>
                      <span>{selectedSerialNumber}</span>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <TextInputGroup
          name="serialNumber"
          label={T('moduleReplacement.serialNumber')}
          value={manualSerialNumber}
          onChange={setManualSerialNumber}
          validate={validate}
          onEnter={() => handleSaveRef.current()}
          error={form.getServerError('serialNumber')}
        />
      )}
    </SingleActionModal>
  );
}
