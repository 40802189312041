import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSrc from 'highcharts/highcharts.src';
import HighchartsHighStock from 'highcharts/highstock';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import React, {useRef, useEffect, useMemo, useCallback, useState} from 'react';

import {useCardContext} from '../cards/CardContext';
import {useThrottledEffect} from '../utils/Hooks';
import {T} from '../utils/Internationalization';
import {classes} from '../utils/Styles';

import styles from './Chart.module.scss';
import CrashedChart from './CrashedChart';

interface ChartProps {
  config: ChartConfig;
  className?: string;
  from?: number;
  to?: number;
  cardDimensions?: string; // deprecated
  adjustRangeToActualData?: boolean;
}

const highstockConfig = {
  boost: {
    seriesThreshold: 50 // doesn't matter if the series are active or not...
  },
  credits: {enabled: false}, // Highstock license taken care of by @PieterWerbrouck
  animation: false, // Makes charts slower for larger data sets
  rangeSelector: {enabled: false},
  legend: {
    verticalAlign: 'bottom',
    enabled: true
  },
  title: {text: ''},
  time: {},
  plotOptions: {
    series: {
      animation: false,
      dataGrouping: {
        minPointLength: 1, // Show zeroes
        enabled: false
      },
      gapSize: null
    },
    line: {
      animation: false,
      dataGrouping: {
        minPointLength: 1, // Show zeroes
        enabled: false
      },
      gapSize: null
    },
    column: {
      animation: false,
      dataGrouping: {
        minPointLength: 1, // Show zeroes
        enabled: false
      },
      gapSize: null
    }
  },
  lang: {
    noData: T('generic.noData')
  },
  xAxis: {
    type: 'datetime',
    ordinal: false
  },
  tooltip: {
    shared: true,
    split: false,
    enabled: true
  }
};

function getExtremes(props: ChartProps): {min: number; max: number} | undefined {
  const {from, to, config} = props;
  if (from !== undefined && to !== undefined) return {min: from, max: to};

  if (!config.series || config.series.length === 0) return undefined;

  const firstSeries = config.series[0] as any;
  if (firstSeries.data.length === 0) return undefined;

  const min = firstSeries.data[0][0];
  const max = firstSeries.data[firstSeries.data.length - 1][0];
  return {min, max};
}

export type ChartConfig = HighchartsHighStock.Options;

HighchartsMore(HighchartsHighStock as unknown as typeof HighchartsSrc);
HighchartsNoData(HighchartsHighStock);
HighchartsSolidGauge(HighchartsHighStock);
HighchartsBoost(HighchartsHighStock);

function Chart(props: ChartProps) {
  const {config, className, adjustRangeToActualData} = props;
  const {cardDimensions} = useCardContext();

  const chartRef = useRef<HighchartsHighStock.Chart | null>(null);
  const chartConfig = useRef<ChartConfig>(props.config);
  const resizeTimeout = useRef<NodeJS.Timeout>();

  const resizeChart = useCallback(() => {
    if (!chartRef.current) return;

    const chart = Object.assign({}, {width: null, height: null}, chartConfig.current.chart);
    chartRef.current.setSize(chart.width, chart.height);
  }, []);
  useEffect(resizeChart, [resizeChart]);

  useEffect(() => {
    const handleWindowResized = () => {
      if (resizeTimeout.current) clearTimeout(resizeTimeout.current);

      resizeTimeout.current = setTimeout(() => resizeChart(), 200);
    };

    window.addEventListener('resize', handleWindowResized);
    return () => {
      chartRef.current = null;
      window.removeEventListener('resize', handleWindowResized);
    };
  }, [resizeChart]);

  const extremes = getExtremes(props);
  const min = extremes && extremes.min;
  const max = extremes && extremes.max;

  const actualConfig = useMemo(() => {
    const chart = {
      chart: {
        zoomType: 'x'
      }
    };

    const result = Object.assign({}, highstockConfig, chart, config);
    return result;
  }, [config]);

  useThrottledEffect(
    () => {
      if (chartRef.current) {
        // workaround for Highcharts bug: SW-8092
        const newNumberOfYAxis = Array.isArray(actualConfig.yAxis) ? actualConfig.yAxis.length : 1;
        const oldNumberOfYAxis = Array.isArray(chartConfig.current.yAxis) ? chartConfig.current.yAxis.length : 1;
        if (oldNumberOfYAxis === newNumberOfYAxis) {
          chartRef.current.update(actualConfig);
        } else {
          console.log('DONT UPDATE CHART - Y AXIS CHANGED');
        }
      }
      chartConfig.current = actualConfig;
    },
    [actualConfig],
    500
  );
  useEffect(() => {
    if (chartRef.current) {
      if (!adjustRangeToActualData) {
        chartRef.current.xAxis[0].setExtremes(min, max);
      }
      chartRef.current.zoomOut(); // only for SW-6554
    }
  }, [min, max, adjustRangeToActualData]);

  const key = useMemo(() => {
    // the chart isn't smart enough to see that series are added/removed
    return (config.series || []).map(series => series.name).join('|');
  }, [config]);

  const numberOfYAxis = Array.isArray(actualConfig.yAxis) ? actualConfig.yAxis.length : 1;
  return (
    <div className={classes(styles.chart, className)}>
      <HighchartsReact
        key={numberOfYAxis}
        highcharts={HighchartsHighStock}
        options={actualConfig}
        update={false}
        constructorType="stockChart"
        callback={(chart: HighchartsHighStock.Chart | null) => (chartRef.current = chart)}
      />
    </div>
  );
}

interface SafeChartState {
  retryKey: number;
  errored: boolean;
}

export default class SafeChart extends React.Component<ChartProps, SafeChartState> {
  static getDerivedStateFromError(error: unknown) {
    return {errored: true};
  }

  constructor(props: ChartProps) {
    super(props);

    this.state = {retryKey: 0, errored: false};
  }

  componentDidUpdate(oldProps: ChartProps, oldState: SafeChartState) {
    if (this.state.errored && this.state.retryKey < 1) {
      this.setState({retryKey: this.state.retryKey + 1, errored: false});
    }
  }

  handleClickedRefresh = () => {
    this.setState({retryKey: this.state.retryKey + 1, errored: false});
  };

  render() {
    const {errored, retryKey} = this.state;
    if (errored) {
      return <CrashedChart onClickedRefresh={this.handleClickedRefresh} />;
    } else {
      return <Chart key={retryKey} {...this.props} />;
    }
  }
}
