import dayjs from 'dayjs';
import React, {useCallback, useEffect} from 'react';
import {Input} from 'reactstrap';

import {useFormContext} from '../../utils/FormContext';
import {FormState} from '../../utils/FormState';
import {T} from '../../utils/Internationalization';
import {Hotkeys} from '../../utils/Keys';

import FormInputGroup from './FormInputGroup';
import 'dayjs/plugin/customParseFormat';

export class DateTimeValue {
  static create(value: number | null) {
    if (value == null) {
      return DateTimeValue.none();
    } else {
      var dt = dayjs(value);
      return new DateTimeValue(dt.format('YYYY-MM-DD HH:mm'), dt);
    }
  }

  static none() {
    return new DateTimeValue('', null);
  }

  inputValue: string;
  dtValue: dayjs.Dayjs | null;

  private constructor(inputValue: string, dtValue: dayjs.Dayjs | null) {
    this.inputValue = inputValue;
    this.dtValue = dtValue;
  }

  withInputValue(value: string): DateTimeValue {
    if (value === '') {
      return new DateTimeValue(value, null);
    } else {
      const dtValue = dayjs(value, 'YYYY-MM-DD HH:mm');
      console.log('numValue', value, dtValue);
      if (dtValue.isValid()) return new DateTimeValue(value, dtValue);
      else return new DateTimeValue(value, this.dtValue);
    }
  }
}

interface DateTimeTextInputGroupProps {
  form?: FormState;
  name: string;
  label: string;
  value: DateTimeValue;
  onChange: (value: DateTimeValue) => void;
  readOnly?: boolean;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  onBlur?: () => void;
  info?: string;
  invalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
  error?: string;
}

export function DateTimeTextInput(props: DateTimeTextInputGroupProps) {
  const {
    name,
    label,
    value,
    onChange,
    onBlur,
    readOnly,
    min,
    max,
    autoFocus,
    onKeyPress,
    onEnter,
    info,
    invalid,
    placeholder,
    disabled = false,
    optional = false,
    error: customError
  } = props;

  const form = useFormContext();
  const error = value.dtValue === null ? 'Invalid datetime value' : undefined;
  form.setError(name, error);
  const actualInfo = info || (optional ? T('validatedInput.optional') : undefined);

  const actualOnKeyPress = onEnter
    ? (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === Hotkeys.ENTER) {
          if (form.hasErrors()) {
            form.setErrorVisible(name, error !== undefined);
            return;
          }
          onEnter();
        } else {
          onKeyPress && onKeyPress(e);
        }
      }
    : onKeyPress;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(value.withInputValue(e.currentTarget.value));
    },
    [onChange, value]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => form.remove(name), [name]);

  const shownError = form.getShownError(name);

  return (
    <FormInputGroup name={name} label={label} error={shownError || customError} info={actualInfo}>
      <Input
        type="text"
        value={value.inputValue}
        name={name}
        onChange={handleChange}
        onBlur={useCallback(() => {
          form.setErrorVisible(name, error !== undefined);
          onBlur && onBlur();
        }, [form, name, onBlur, error])}
        invalid={shownError !== undefined || invalid === true}
        readOnly={readOnly}
        min={min}
        max={max}
        innerRef={useCallback(ref => form.setRef(name, ref), [form, name])}
        autoFocus={autoFocus}
        onKeyPress={actualOnKeyPress}
        placeholder={placeholder}
        disabled={disabled}
      />
    </FormInputGroup>
  );
}
