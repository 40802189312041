import React, {useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {TextInputGroup} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IRoleType} from '../../models/Location';
import {IOrganization} from '../../models/Organization';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {T} from '../../utils/Internationalization';
import {validateEmail, validateRequired} from '../../utils/Validation';

interface LinkUserProps extends IPromiseModalProps<boolean> {
  organization: IOrganization | undefined;
}

export const LinkUser = (props: LinkUserProps) => {
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const onToggle = () => resolve(false);
  const [roles, setRoles] = useState<IRoleType[]>([]);
  const [role, setRole] = React.useState<IRoleType['id']>('HELPDESK');

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState<IOrganization | undefined>(props.organization);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [addFailure, setAddFailure] = useState(false);

  const handleClickSave = async () => {
    if (!organization) return;

    const {id} = organization;

    // Merge organization object
    try {
      const addedUsers = await api.organizations.addOrganizationUser(
        id,
        hasFeature(AppFeature.SocialLogin) ? email : username,
        role
      );
      const success = addedUsers.length > 0;
      if (success) {
        setAddFailure(false);
        NotificationManager.success(T('organizationUsers.link.success'));
        resolve(true);
      } else {
        setAddFailure(true);
        NotificationManager.error(T('organizationUsers.link.failed'));
      }
    } catch {
      NotificationManager.error(T('organizationUsers.link.failed'));
    }
  };

  React.useEffect(() => {
    async function fetchRoleTypes() {
      const roles = await api.getRoleTypes();
      setRoles(roles);
    }
    fetchRoleTypes();
  }, [api]);

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={onToggle}
      title={T('organizationUsers.link.title')}
      action={handleClickSave}
      actionText={T('organizationUsers.link.confirm')}
      error={addFailure ? T('organizationUsers.link.couldNotAdd') : undefined}
    >
      <OrganizationInputGroup
        organizations={inputOrganizations.organizations}
        name="organization"
        label={T('organizationUsers.link.organization')}
        value={organization}
        onChange={setOrganization}
        onUpdateQuery={updateOrganizationInputQuery}
      />

      <SelectInputGroup
        label={T('organizationUsers.link.accessRights')}
        name="accessRights"
        value={role}
        onChange={setRole}
      >
        <option value="HELPDESK">{T('role.partnerAdmin')}</option>
        <option value="OBSERVER">{T('role.partnerReadOnly')}</option>
        <option value="MANAGED">{T('role.basic')}</option>
        {/*roles.map(role => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))*/}
      </SelectInputGroup>

      {hasFeature(AppFeature.SocialLogin) ? (
        <TextInputGroup
          name="email"
          label={T('organizationUsers.link.email')}
          value={email || ''}
          validate={validateEmail}
          onChange={setEmail}
          autoFocus={true}
        />
      ) : (
        <TextInputGroup
          name="username"
          label={T('organizationUsers.link.username')}
          value={username || ''}
          validate={validateRequired}
          onChange={setUsername}
          autoFocus={true}
        />
      )}
    </SingleActionModal>
  );
};
