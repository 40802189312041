import React, {useEffect, useMemo, useState} from 'react';

import {ICreateSmartDeviceDefinition} from '../../api/LocationAPI';
import {useAppContext} from '../../app/context';
import {Form, SingleActionModal} from '../../components/bootstrap';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {TextInput, TextInputGroup} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {
  getPropertyValueFromString,
  IConfigurationProperty,
  SmartDeviceTypeCategories,
  SmartDeviceTypeCategory,
  translateSmartDeviceTypeCategory
} from '../../models/SmartDevice';
import {None} from '../../utils/Arrays';
import {useFormState} from '../../utils/FormState';
import {useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {validateRequired} from '../../utils/Validation';

import {useCreateSmartDeviceState} from './CreateSmartDeviceState';
import SmartDeviceField from './SmartDeviceField';

interface CreateSmartDeviceModalProps extends IPromiseModalProps<void> {
  locationId: number;
}

export function CreateSmartDeviceModal(props: CreateSmartDeviceModalProps) {
  const {locationId} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const form = useFormState();

  const [types = None] = useLoader(api => api.locations.getSmartDeviceTypes(locationId), [locationId]);

  const [error, setError] = useState<string>();
  const [state, actor] = useCreateSmartDeviceState();

  const deviceType = types.find(x => x.name === state.type);
  const fields = useMemo(() => {
    if (deviceType === undefined) return [];

    return deviceType.configurationProperties.map(property => (
      <SmartDeviceField
        property={{values: [], spec: property}}
        updateValue={(name, value) => actor.setProperty(name, value)}
        value={state.properties[property.name]}
      />
    ));
  }, [deviceType, state.properties, actor, form]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickedSave = async () => {
    const categoryError = validateRequired(state.category, T('smartDevices.create.category'), false);
    const typeError = validateRequired(state.type, T('smartDevices.create.type'), false);
    actor.setErrors({categoryError, typeError});
    if (categoryError || typeError || form.hasErrors()) return;
    if (deviceType === undefined) return;

    const request: ICreateSmartDeviceDefinition = {
      category: state.category,
      typeName: state.type,
      name: state.name,
      configurationProperties: deviceType.configurationProperties.map(property => ({
        values: getPropertyValueFromString(property, state.properties[property.name] || '') || [],
        spec: {name: property.name}
      }))
    };
    return api.locations
      .createSmartDevice(locationId, request)
      .then(response => {
        if (response && response.failures && response.failures.length > 0) {
          setError(T('smartDevices.error.couldNotCreate'));
          return;
        }
        resolve();
      })
      .catch(() => setError(T('smartDevices.error.couldNotCreate')));
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve()}
      title={T('smartDevices.create.title')}
      action={handleClickedSave}
      actionText={T('smartDevices.create.save')}
      error={error}
    >
      <Form>
        <SelectInputGroup
          label={T('smartDevices.create.category')}
          name="category"
          value={state.category}
          onChange={value => actor.setCategory(value as SmartDeviceTypeCategory)}
          error={state.categoryError}
        >
          <option value="">{T('smartDevices.create.category.choose')}</option>
          {SmartDeviceTypeCategories.map(category => (
            <option key={category} value={category}>
              {translateSmartDeviceTypeCategory(category)}
            </option>
          ))}
        </SelectInputGroup>
        <SelectInputGroup
          label={T('smartDevices.create.type')}
          name="type"
          value={state.type}
          onChange={value => actor.setType(value)}
          error={state.typeError}
        >
          <option value="">{T('smartDevices.create.type.choose')}</option>
          {types
            .filter(x => x.category === state.category)
            .map(type => (
              <option key={type.name} value={type.name}>
                {type.displayName}
              </option>
            ))}
        </SelectInputGroup>
        <TextInputGroup
          label={T('smartDevices.field.name')}
          name="name"
          value={state.name}
          onChange={name => actor.setName(name)}
        />
        {fields}
      </Form>
    </SingleActionModal>
  );
}
