import Highcharts from 'highcharts';
import HighchartsStandard from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSrc from 'highcharts/highcharts.src';
import HighchartsHighStock, {
  XAxisLabelsOptions,
  YAxisOptions,
  SeriesArearangeOptions,
  SeriesAreaOptions,
  SeriesLineOptions,
  SeriesColumnOptions,
  SeriesAreasplineOptions
} from 'highcharts/highstock';

import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import React from 'react';

import {Interval, TimeValues} from '../models/UsageValue';
import {T} from '../utils/Internationalization';

import {isAMPMTime} from '../utils/Locale';
import {log} from '../utils/Logging';
import {
  formatWeek,
  getMonthIndex,
  formatDay,
  getTimezoneOffset,
  getYearIndex,
  getStartOfYearTimestamp,
  formatMonth,
  getStartOfMonthTimestamp
} from '../utils/TimestampUtils';

import styles from './Chart.module.scss';
import {DateFormatPreset, formatDate} from './DateFormatter';
import {ActivePeriod, getReferenceLengthOfInterval} from './PeriodSelector';

export type ChartConfig = HighchartsHighStock.Options;
export type ChartStandardConfig = HighchartsStandard.Options;
interface IChartProps {
  config: ChartConfig;
  isHighstock: boolean;
  className?: string;
  cardDimensions: string;
  pure?: boolean;
  from?: number;
  to?: number;
}

export interface IIntervalChartOptions {
  period: ActivePeriod;
  series: (SeriesLineOptions | SeriesColumnOptions | SeriesArearangeOptions | SeriesAreaOptions)[];
  yAxis: YAxisOptions[];
  noDataText?: string;
  onShowSeries?: Highcharts.SeriesShowCallbackFunction;
  onHideSeries?: Highcharts.SeriesHideCallbackFunction;
  adjustRangeToActualData?: boolean;
  navigatorSeries?: string[];
  navigatorSerie?: SeriesAreasplineOptions;
  reserveYAxis?: number;
}

export function processIntervalData(
  data: [number, number | null | undefined][],
  period: ActivePeriod
): [number, number | null | undefined][] {
  if (data.length === 1) {
    const timestamp = data[0][0];
    data = [data[0], [timestamp + getReferenceLengthOfInterval(period.interval), null]];
  }
  if (period.interval === Interval.MONTH) {
    const startMonthIndex = getMonthIndex(period.from, period.timezone);
    data = data.map(element => {
      const monthIndex = getMonthIndex(element[0], period.timezone);
      const offset = monthIndex - startMonthIndex;
      return [offset, element[1]];
    });
  } else if (period.interval === Interval.YEAR) {
    const startYearIndex = getYearIndex(period.from, period.timezone);
    data = data.map(element => {
      const yearIndex = getYearIndex(element[0], period.timezone);
      const offset = yearIndex - startYearIndex;
      return [offset, element[1]];
    });
  }
  return data;
}

export function customFormatPoints(points: Highcharts.TooltipFormatterContextObject[]) {
  let result = '';
  points.forEach((point: any) => {
    const {tooltipOptions} = point.series;
    const {pointFormatter, pointFormat, valueDecimals} = tooltipOptions;
    if (valueDecimals !== undefined) {
      const oldPoint = point.point;
      const newPoint = {...oldPoint};
      if (typeof oldPoint.y === 'number') {
        newPoint.y = oldPoint.y.toFixed(valueDecimals);
      }
      if (typeof oldPoint.low === 'number') {
        newPoint.low = oldPoint.low.toFixed(valueDecimals);
      }
      if (typeof oldPoint.high === 'number') {
        newPoint.high = oldPoint.high.toFixed(valueDecimals);
      }

      point = {
        ...point,
        point: newPoint
      };
    }

    const label = pointFormatter ? pointFormatter.apply(point) : Highcharts.format(pointFormat, point);
    result += label;
  });
  return result;
}

function customFormatForYear(point: Highcharts.TooltipFormatterContextObject, timezone: string) {
  let result = `<span style="font-size: 10px">${getYearIndex(point.x as number, timezone)}</span>`;
  if (point.points) result += customFormatPoints(point.points);
  return result;
}
function customFormatForMonth(point: Highcharts.TooltipFormatterContextObject, timezone: string) {
  let result = `<span style="font-size: 10px">${formatMonth(point.x as number, timezone)}</span>`;
  if (point.points) result += customFormatPoints(point.points);
  return result;
}

function customFormatForWeek(point: any, timezone: string) {
  const week = formatWeek(point.x, timezone);
  let result = `<span style="font-size: 10px">${week}</span>`;
  if (point.points) result += customFormatPoints(point.points);
  return result;
}

function customFormatForDay(point: any, timezone: string) {
  const day = formatDay(point.x, timezone);
  let result = `<span style="font-size: 10px">${day}</span>`;
  if (point.points) result += customFormatPoints(point.points);
  return result;
}

export function customFormatTimestamp(point: any, timezone: string) {
  const time = formatDate(point.x, {
    timezoneId: timezone,
    format: DateFormatPreset.LocaleDateWithMonthAndTimeWithDay
  });

  let result = `<span style="font-size: 10px">${time}</span><br />`;
  if (point.points) result += customFormatPoints(point.points);
  return result;
}

function getIntervalLength(interval: Interval): number | undefined {
  switch (interval) {
    case Interval.INTERVAL:
    case Interval.MINUTES_5:
      return 5 * 60 * 1000;
    case Interval.HOUR:
      return 60 * 60 * 1000;
    case Interval.DAY:
      return 24 * 60 * 60 * 1000;
    case Interval.MONTH:
      // is this accurate enough?
      return 30 * 24 * 3600 * 1000;
    case Interval.YEAR:
      return 366 * 24 * 60 * 60 * 1000;
    default:
      return undefined;
  }
}

export function getDateTimeLabelFormats() {
  return isAMPMTime()
    ? {
        minute: '%l :%P',
        hour: '%l %P',
        day: '%e. %b',
        week: '%e. %b',
        month: "%b '%y",
        year: '%Y'
      }
    : {};
}

function getMinMaxValues(from: number, to: number, interval: Interval, timezone: string) {
  let min = from;
  let max = to;

  switch (interval) {
    case Interval.MONTH: {
      min = getStartOfMonthTimestamp(from, timezone);
      max = getStartOfMonthTimestamp(to, timezone);
      break;
    }
    case Interval.YEAR: {
      min = getStartOfYearTimestamp(from, timezone);
      max = getStartOfYearTimestamp(to, timezone);
      break;
    }
    default:
      break;
  }
  return [min, max];
}

export function createIntervalChart(options: IIntervalChartOptions): [ChartConfig, number, number] {
  const {
    period,
    series,
    yAxis,
    noDataText,
    onShowSeries,
    onHideSeries,
    adjustRangeToActualData,
    navigatorSeries,
    navigatorSerie,
    reserveYAxis
  } = options;
  const {timezone, from, to, interval} = period;
  const [min, max] = getMinMaxValues(from, to, interval, timezone);
  const xAxisMin = adjustRangeToActualData ? undefined : min;
  const xAxisMax = adjustRangeToActualData ? undefined : max;

  let tooltipFormatter: Highcharts.TooltipFormatterCallbackFunction | undefined;
  if (interval === Interval.YEAR) {
    tooltipFormatter = function (this: any) {
      return customFormatForYear(this, timezone);
    };
  } else if (interval === Interval.MONTH) {
    tooltipFormatter = function (this: any) {
      return customFormatForMonth(this, timezone);
    };
  } else if (interval === Interval.WEEK) {
    tooltipFormatter = function (this: any) {
      return customFormatForWeek(this, timezone);
    };
  } else if (interval === Interval.DAY) {
    tooltipFormatter = function (this: any) {
      return customFormatForDay(this, timezone);
    };
  } else {
    tooltipFormatter = function (this: any) {
      return customFormatTimestamp(this, timezone);
    };
  }

  let xAxisFormatter: Highcharts.FormatterCallbackFunction<Highcharts.AxisLabelsFormatterContextObject> | undefined;

  if (TimeValues.includes(interval)) {
    xAxisFormatter = function (this: Highcharts.AxisLabelsFormatterContextObject) {
      // let highcharts to format the label
      return this.axis.defaultLabelFormatter.call(this);
    };
  } else if (interval === Interval.MONTH) {
    xAxisFormatter = function (this: Highcharts.AxisLabelsFormatterContextObject) {
      return formatMonth(this.value as number, timezone).toString();
    };
  } else if (interval === Interval.YEAR) {
    xAxisFormatter = function (this: Highcharts.AxisLabelsFormatterContextObject) {
      return getYearIndex(this.value as number, timezone).toString();
    };
  } /* else {
    xAxisFormatter = function(this: Highcharts.AxisLabelsFormatterContextObject) {
      const time = dayjs(this.pos).tz(timezone);
      if (time.hour() === 0) {
        return time.format('DD/MM');
      } else {
        return time.format('HH:mm');
      }
    }
  }*/

  if (reserveYAxis) {
    while (yAxis.length < reserveYAxis) yAxis.push({title: {text: ''}}); // workaround
  }

  series.forEach(serie => {
    serie.boostThreshold = 2000;
    serie.pointInterval = getIntervalLength(interval);
  });

  const config: ChartConfig = {
    time: {
      //      getTimezoneOffset: (timestamp: number) => -dayjs(timestamp).tz(timezone).utcOffset(),
      getTimezoneOffset: getTimezoneOffset.bind(undefined, timezone),
      timezone
    },
    plotOptions: {
      column: {
        //cropThreshold: 1000,
        //stacking: 'normal',
        //grouping: false,
        groupPadding: 0.2,
        pointPadding: 0,
        dataGrouping: {
          enabled: false
        }
      },
      series: {
        dataGrouping: {
          enabled: false
        },
        events: {
          show: onShowSeries,
          hide: onHideSeries
        },
        gapSize: 1.2
      }
    },
    series: series.filter(serie => (serie.data || []).length > 0),
    xAxis: {
      type: interval === Interval.YEAR ? 'linear' : ('datetime' as 'datetime' | 'category' | 'linear'),
      ordinal: false,
      min: xAxisMin,
      max: xAxisMax,
      tickInterval: interval === Interval.YEAR || interval === Interval.MONTH ? getIntervalLength(interval) : undefined,
      dateTimeLabelFormats: getDateTimeLabelFormats(),
      labels: {
        formatter: xAxisFormatter
      } as XAxisLabelsOptions
    },
    yAxis,
    lang: {
      noData: series.length === 0 ? T('chart.noSeriesSelected') : noDataText || ''
    },
    tooltip: {
      shared: true,
      split: false,
      enabled: true,
      formatter: tooltipFormatter
    },
    navigator: {
      // this option was false, then true, now back to false... what did I break this time?
      // When it was set to true, the navigator zoomed in to the available data instead of the
      // provided min/max data. Setting it to false works, and the setExtremes
      // in the componentDidUpdate does the job of updating the range to the new value
      // now back to true -> if on false, this section doesn't update when content of the chart changes
      // as of 9.2.2 it doesn't reset the x axis
      adaptToUpdatedData: true,
      xAxis: {
        min: xAxisMin,
        max: xAxisMax,
        labels: {
          formatter: xAxisFormatter
        }
      }
    }
  };

  if (navigatorSeries) {
    //    config.navigator!.yAxis = yAxis;
    config.navigator!.series = series
      .filter(serie => serie.id && navigatorSeries.includes(serie.id))
      .map(serie => ({
        type: 'areaspline',
        data: serie.data,
        color: serie.color
        //        yAxis: serie.yAxis
      }));
  }
  if (navigatorSerie) {
    config.navigator!.series = [navigatorSerie];
  }
  if (navigatorSerie || navigatorSeries) {
    series.forEach(serie => (serie.showInNavigator = false));
  }

  log('redraw', 'Created interval chart', config);
  return [config, min, max];
}

export class Chart extends React.Component<IChartProps, Record<string, unknown>> {
  static highstockConfig = {
    credits: {enabled: false}, // Highstock license taken care of by @PieterWerbrouck
    animation: false, // Makes charts slower for larger data sets
    rangeSelector: {enabled: false},
    legend: {
      verticalAlign: 'bottom',
      enabled: true
    },
    title: {text: ''},
    time: {},
    plotOptions: {
      series: {
        animation: false,
        dataGrouping: {
          minPointLength: 1, // Show zeroes
          enabled: false
        },
        gapSize: null
      },
      line: {
        animation: false,
        dataGrouping: {
          minPointLength: 1, // Show zeroes
          enabled: false
        },
        gapSize: null
      },
      column: {
        animation: false,
        dataGrouping: {
          minPointLength: 1, // Show zeroes
          enabled: false
        },
        gapSize: null
      }
    },
    lang: {
      noData: T('generic.noData')
    },
    xAxis: {
      type: 'datetime',
      ordinal: false,
      dateTimeLabelFormats: getDateTimeLabelFormats()
    },
    tooltip: {
      shared: true,
      split: false,
      enabled: true
    }
  };

  static highchartsConfig = {
    credits: {enabled: false} // Highcharts license taken care of by @PieterWerbrouck
  };

  static defaultProps = {
    config: Chart.highstockConfig,
    isHighstock: true
  };

  chartInstance: typeof HighchartsStandard;
  chart: HighchartsHighStock.Chart | null;
  defaultConfig: any;
  resizeTimeout?: NodeJS.Timeout;
  lastExtremes: {min: number; max: number} = {min: 0, max: 0};
  firstExtremes: boolean = true;

  constructor(props: IChartProps) {
    super(props);
    const {isHighstock} = props;

    this.chart = null;

    // Assign Highcharts variables
    this.chartInstance = isHighstock ? HighchartsHighStock : HighchartsStandard;
    this.defaultConfig = isHighstock ? {...Chart.highstockConfig} : {...Chart.highchartsConfig};

    // Initialize Highcharts modules
    HighchartsMore(this.chartInstance as unknown as typeof HighchartsSrc);
    HighchartsNoData(this.chartInstance);
    HighchartsSolidGauge(this.chartInstance);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResized);
  }

  componentDidUpdate(lastProps: IChartProps) {
    this.resizeChart();

    if (this.chart && lastProps.config !== this.props.config) {
      this.chart.update(this.props.config);
      const extremes = this.getExtremes();
      if (
        extremes &&
        (this.lastExtremes.min !== extremes.min || this.lastExtremes.max !== extremes.max || this.firstExtremes)
      ) {
        this.firstExtremes = false;
        this.chart.xAxis[0].setExtremes(extremes.min, extremes.max);
        this.lastExtremes = extremes;
      }
    }
  }

  componentWillUnmount() {
    this.chart = null;
    window.removeEventListener('resize', this.handleWindowResized);
  }

  handleWindowResized = () => {
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout);

    this.resizeTimeout = setTimeout(() => this.resizeChart(), 200);
  };

  resizeChart() {
    if (!this.chart) return;

    const chart = Object.assign({}, {width: null, height: null}, this.props.config.chart);
    this.chart.setSize(chart.width, chart.height);
  }

  getExtremes() {
    const {from, to, config} = this.props;
    if (from !== undefined && to !== undefined) return {min: from, max: to};

    if (!config.series || config.series.length === 0) return undefined;

    const firstSeries = config.series[0] as any;
    if (firstSeries.data.length === 0) return undefined;

    const min = firstSeries.data[0][0];
    const max = firstSeries.data[firstSeries.data.length - 1][0];
    return {min, max};
  }

  shouldComponentUpdate(nextProps: IChartProps) {
    if (this.props.pure) return true;

    const config = this.props.config;
    const nextConfig = nextProps.config;
    let propsChanged = true;

    if (config && Array.isArray(config.series) && nextConfig && Array.isArray(nextConfig.series)) {
      const series = config.series.map(a => (a as any).data);
      const nextSeries = nextConfig.series.map(a => (a as any).data);
      propsChanged = series !== nextSeries; // assumes that series are usually cached/memo'ed somewhere
    }

    const yAxis = config.yAxis;
    const nextyAxis = nextConfig.yAxis;
    if (yAxis && Array.isArray(yAxis) && nextyAxis && Array.isArray(nextyAxis)) {
      if (yAxis[0].min !== nextyAxis[0].min) propsChanged = true;
    }

    // Keep checking state as well in case dimensions change
    return propsChanged;
  }

  setChart = (chart: HighchartsHighStock.Chart | null) => {
    this.chart = chart;
    this.resizeChart();
  };

  render() {
    let {config, className, isHighstock} = this.props;
    const instance = isHighstock ? HighchartsHighStock : HighchartsStandard;
    const chart = isHighstock
      ? {
          chart: {
            zoomType: 'x'
          }
        }
      : {};

    const constructorType = isHighstock ? 'stockChart' : 'chart';
    // Merge configs in order of importance, least important first
    config = Object.assign(this.defaultConfig, chart, config);

    // Hook extra classes into the component
    className = [styles.chart, className ? className : ''].join(' ');

    if ((config.series || []).length === 0) return <div />;

    const key = (config.series || []).map(series => series.name).join('|'); // the chart isn't smart enough to see that series are added/removed
    return (
      <div className={className}>
        <HighchartsReact
          key={key}
          highcharts={instance}
          options={config}
          update={false}
          constructorType={constructorType}
          callback={this.setChart}
        />
      </div>
    );
  }
}
