import {useMemo, useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Alert} from '../../components/bootstrap';
import FormInputGroup from '../../components/inputs/FormInputGroup';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {useNumberInput} from '../../components/inputs/NumberInput';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {PrinterInput} from '../../components/inputs/PrinterInput';
import {SelectInputGroup, useSelectInputGroup} from '../../components/inputs/SelectInput';
import {TextInputGroup, useTextInput} from '../../components/inputs/TextInput';
import {Checkbox} from '../../components/ui/checkbox';
import {useModals} from '../../modals/ModalContext';
import {SupportedType} from '../../models/ActivationCode';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {IOrganization} from '../../models/Organization';
import {ValidationError, IValidationError} from '../../models/ValidationError';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {FormContextProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {Language, T} from '../../utils/Internationalization';
import {validateBIC, validateIBAN} from '../../utils/Validation';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {cardViewProps, CardView} from '../components/CardView';
import {PreconfigurationKits} from '../Preconfiguration/Kits';

import ConfirmPurgeOrganizationInfoModal from './childModals/ConfirmPurgeOrganizationInfoModal';
import styles from './index.module.scss';

type ISpecialFunctionsSettings = ICardSettings;

enum SpecialFunction {
  ResetPreconfiguration = 'RESET_PRECONFIGURATION',
  CrashCard = 'CRASH_CARD',
  LoadForever = 'LOAD_FOREVER',
  RemoveChargingStation = 'REMOVE_CHARGING_STATION',
  ReprintKitLabel = 'REPRINT_KIT_LABEL',
  CreateProLicenseKeys = 'CREATE_PRO_LICENSE_KEYS',
  SendTestMail = 'SEND_TEST_MAIL',
  CheckIBANUsed = 'CHECK_IBAN_USED',
  CheckBICUsed = 'CHECK_BIC_USED',
  PurgeOrganizationInfo = 'PURGE_ORGANIZATION_INFO'
}

function getErrorMessage(errorCode: ValidationError) {
  switch (errorCode) {
    case ValidationError.NotFound:
    case ValidationError.NotConfigured:
      return 'Could not find a device with this serial number';
    default:
      return errorCode;
  }
}

const SpecialFunctions = (props: ICardProps<ISpecialFunctionsSettings>) => {
  const {fetch} = props;
  const form = useFormState();
  const {api} = useAppContext();
  const modals = useModals();
  const [selectedFunction, setSelectedFunction] = useState(SpecialFunction.ResetPreconfiguration);
  const [simulateCrash, setSimulateCrash] = useState(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState<string | JSX.Element>();
  const [feedbackError, setFeedbackError] = useState<string>();

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [replacementOrganizations, updateReplacementOrganizationInputQuery] = useQueryableOrganizations();

  const [organization, setOrganization] = useState<IOrganization>();
  const [replacementOrganization, setReplacementOrganization] = useState<IOrganization>();
  const [deleteBillingHistory, setDeleteBillingHistory] = useState<boolean>(false);
  const [replacementRFIDTokenInput, replacementRFIDToken] = useTextInput(
    'replacementRFIDToken',
    T('specialFunctions.purge.replacementRFIDToken'),
    ''
  );
  const organizationInput = (
    <OrganizationInputGroup
      organizations={inputOrganizations.organizations}
      value={organization}
      onChange={setOrganization}
      placeholder={T('locations.filter.organization.select')}
      noneLabel={T('locations.filter.organization.none')}
      label={T('specialFunctions.purge.organization')}
      name="organization"
      onUpdateQuery={updateOrganizationInputQuery}
    />
  );

  const replacementOrganizationInput = (
    <OrganizationInputGroup
      organizations={replacementOrganizations.organizations}
      value={replacementOrganization}
      onChange={setReplacementOrganization}
      placeholder={T('locations.filter.organization.select')}
      noneLabel={T('locations.filter.organization.none')}
      label={T('specialFunctions.purge.replacementOrganization')}
      name="replacementOrganization"
      onUpdateQuery={updateReplacementOrganizationInputQuery}
    />
  );

  const deleteBillingHistorySwitch = (
    <Checkbox
      id="delete-billing-history"
      name="delete-billing-history"
      label={T('specialFunctions.purge.deleteHistory')}
      checked={deleteBillingHistory}
      onCheckedChange={() => setDeleteBillingHistory(!deleteBillingHistory)}
      testId="delete-billing-history"
    />
  );
  const [gatewaySerialNumberInput, gatewaySerialNumber] = useTextInput(
    'gatewaySerialNumber',
    'Gateway Serial number',
    '',
    value =>
      selectedFunction !== SpecialFunction.ResetPreconfiguration || /^[0-9]{10}$/.test(value)
        ? undefined
        : 'Not a valid serial number'
  );
  const [chargingStationInput, chargingStation] = useTextInput(
    'chargingStation',
    'Charging Station serial number',
    '',
    value =>
      selectedFunction !== SpecialFunction.RemoveChargingStation || /^6[0-9]{9}$/.test(value)
        ? undefined
        : 'Not a valid serial number'
  );
  const kitTypeOptions = useMemo(() => {
    return PreconfigurationKits.map(kit => (
      <option key={kit.id} value={kit.id}>
        {kit.name}
      </option>
    ));
  }, []);
  const licenseTypeOptions = useMemo(() => {
    return [
      <option key="PRO" value={SupportedType.Pro}>
        Pro
      </option>,
      <option key="PLUS" value={SupportedType.Plus}>
        Plus
      </option>
    ];
  }, []);

  const [kitTypeInput, kitType] = useSelectInputGroup('kitType', 'Kit type', '', kitTypeOptions);
  const [printer, setPrinter] = useState<string>('jan');
  const [numberOfKeysInput, numberOfKeys] = useNumberInput('numberOfKeys', 'Number of keys', 10, 1);
  const [numberOfYearsInput, numberOfYears] = useNumberInput('numberOfYears', 'Validity', 2, 1, 10, 'years');
  const [licenseTypeInput, licenseType] = useSelectInputGroup(
    'licenseType',
    'License type',
    SupportedType.Pro,
    licenseTypeOptions
  );
  const [iban, setIBAN] = useState('');
  const [bic, setBIC] = useState('');
  const [validBIC, setValidBIC] = useState<boolean>(false);

  const handleFunctionChanged = (value: string) => {
    setSelectedFunction(value as SpecialFunction);
  };

  const handleCheckBIC = (value: string) => {
    setBIC(value);
    const invalidBIC = validateBIC(value);
    if (invalidBIC !== undefined || value.length === 0) {
      setValidBIC(false);
    } else {
      setValidBIC(true);
    }
  };

  const setError = (error: IValidationError | undefined) => {
    form.clearServerErrors();
    if (error) {
      form.setServerError(error.fieldName, getErrorMessage(error.error));
    }
  };

  const loadForever = () => {
    fetch('test', () => new Promise(() => {}), 'test');
  };

  const handleClickedExecute = async () => {
    setFeedbackSuccess(undefined);
    setFeedbackError(undefined);

    switch (selectedFunction) {
      case SpecialFunction.ResetPreconfiguration:
        await api
          .resetPreconfiguration(gatewaySerialNumber)
          .then(() => NotificationManager.success('Preconfiguration has been reset'))
          .catch(response => setError(JSON.parse(response.error)));
        break;
      case SpecialFunction.CrashCard:
        setSimulateCrash(true);
        break;
      case SpecialFunction.LoadForever:
        loadForever();
        break;
      case SpecialFunction.RemoveChargingStation:
        await api
          .deleteChargingStation(chargingStation)
          .then(() => NotificationManager.success('Charging station has been removed'))
          .catch(() => NotificationManager.error('Could not remove charging station'));
        break;
      case SpecialFunction.ReprintKitLabel:
        await api
          .printKitLabel(kitType, gatewaySerialNumber, printer)
          .then(() => NotificationManager.success('label has been printed'));
        break;
      case SpecialFunction.CreateProLicenseKeys: {
        if (numberOfYears === null || numberOfKeys === null) return;

        const activationCodes = await api.activationCodes.create({
          contractYears: numberOfYears,
          count: numberOfKeys,
          supportedTypes: [licenseType as SupportedType],
          features: [],
          reusable: false
        });
        if (activationCodes !== undefined) {
          setFeedbackSuccess(`License keys: ${activationCodes.map(code => code.code).join(', ')}`);
        } else {
          setFeedbackError('Could not create codes');
        }
        break;
      }
      case SpecialFunction.SendTestMail:
        await api.user.sendTestMail().then(() => NotificationManager.success('Test mail sent'));
        break;
      case SpecialFunction.CheckBICUsed:
        validBIC ? setFeedbackSuccess('BIC is valid') : setFeedbackError('BIC is invalid');
        break;
      case SpecialFunction.CheckIBANUsed:
        await api.serviceUtils.checkIBANUsed(iban).then(usage => {
          if (!usage || !usage.odooId) {
            setFeedbackError('Not found');
          } else {
            setFeedbackSuccess(
              <>
                {usage.odooId && (
                  <>
                    Odoo partner [{usage.odooId}] {usage.odooName || '?'}
                  </>
                )}
                {usage.user && !hasFeature(AppFeature.SocialLogin) && (
                  <>
                    <br />
                    User [{usage.user.id}] {usage.user.username}
                  </>
                )}
                {usage.user && (
                  <>
                    <br />
                    User [{usage.user.id}] {usage.user.emailAddress}
                  </>
                )}
                {usage.organization && (
                  <>
                    <br />
                    Organization [{usage.organization.id}] {usage.organization.name}
                  </>
                )}
              </>
            );
          }
        });
        break;
      case SpecialFunction.PurgeOrganizationInfo: {
        if (!organization || !replacementOrganization || !replacementRFIDToken) return;
        const callPurge = async () => {
          await api
            .purgeOrganization(
              organization?.id,
              replacementOrganization?.id,
              replacementRFIDToken,
              deleteBillingHistory
            )
            .then(() => NotificationManager.success(T('specialFunctions.purge.success')))
            .catch(() => NotificationManager.error(T('specialFunctions.purge.error')));
        };
        await modals.show<boolean>(props => (
          <ConfirmPurgeOrganizationInfoModal organization={organization!} purgeOrganization={callPurge} {...props} />
        ));
        break;
      }
    }
  };

  if (simulateCrash) throw Error('Boom!');

  return (
    <CardView {...cardViewProps(props)}>
      <FormContextProvider value={form}>
        <div className={styles.formContent}>
          <SelectInputGroup name="function" label="Function" value={selectedFunction} onChange={handleFunctionChanged}>
            <option value={SpecialFunction.CheckIBANUsed}>Check IBAN in use</option>
            <option value={SpecialFunction.CheckBICUsed}>Check BIC in use</option>
            <option value={SpecialFunction.ResetPreconfiguration}>Reset Preconfiguration</option>
            <option value={SpecialFunction.CrashCard}>Crash this card</option>
            <option value={SpecialFunction.LoadForever}>Load forever</option>
            <option value={SpecialFunction.RemoveChargingStation}>Remove charging station</option>
            <option value={SpecialFunction.ReprintKitLabel}>Reprint kit label</option>
            <option value={SpecialFunction.CreateProLicenseKeys}>Create pro license keys</option>
            <option value={SpecialFunction.SendTestMail}>Send test mail</option>
            <option value={SpecialFunction.PurgeOrganizationInfo}>Purge organization info</option>
          </SelectInputGroup>
          {(selectedFunction === SpecialFunction.ResetPreconfiguration ||
            selectedFunction === SpecialFunction.ReprintKitLabel) &&
            gatewaySerialNumberInput}
          {selectedFunction === SpecialFunction.RemoveChargingStation && chargingStationInput}
          {selectedFunction === SpecialFunction.ReprintKitLabel && kitTypeInput}
          {selectedFunction === SpecialFunction.ReprintKitLabel && (
            <FormInputGroup name="printer" label="Printer">
              <PrinterInput value={printer} onChange={setPrinter} />
            </FormInputGroup>
          )}
          {selectedFunction === SpecialFunction.CreateProLicenseKeys && licenseTypeInput}
          {selectedFunction === SpecialFunction.CreateProLicenseKeys && numberOfKeysInput}
          {selectedFunction === SpecialFunction.CreateProLicenseKeys && numberOfYearsInput}
          <div className={styles.orgRow}>
            {selectedFunction === SpecialFunction.PurgeOrganizationInfo && organizationInput}
            {selectedFunction === SpecialFunction.PurgeOrganizationInfo && replacementOrganizationInput}
          </div>
          {selectedFunction === SpecialFunction.PurgeOrganizationInfo && replacementRFIDTokenInput}
          {selectedFunction === SpecialFunction.PurgeOrganizationInfo && deleteBillingHistorySwitch}
          {selectedFunction === SpecialFunction.CheckIBANUsed && (
            <TextInputGroup name="iban" label="IBAN" value={iban} onChange={setIBAN} validate={validateIBAN} />
          )}
          {selectedFunction === SpecialFunction.CheckBICUsed && (
            <TextInputGroup name="bic" label="BIC" value={bic} onChange={handleCheckBIC} validate={validateBIC} />
          )}
          <FormSaveButton onSave={handleClickedExecute}>Execute</FormSaveButton>
          {feedbackSuccess && (
            <Alert color="success" style={{marginTop: 10}}>
              {feedbackSuccess}
            </Alert>
          )}
          {feedbackError && (
            <Alert color="error" style={{marginTop: 10}}>
              {feedbackError}
            </Alert>
          )}
        </div>
      </FormContextProvider>
    </CardView>
  );
};

const CARD: ICardType<ISpecialFunctionsSettings> = {
  type: CardTypeKey.SpecialFunctions,
  title: 'specialFunctions.title',
  description: 'specialFunctions.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Unaware,
  cardClass: SpecialFunctions
};
export default CARD;
