import {BrandColors} from '../utils/BrandColors';
import {T} from '../utils/Internationalization';

import {UltraStatus} from './ChargingStation';

// A = no EV connected
// B = cable connected
// C = charging
// D = charging (deprecated status)
// E = error
// F = faulty (maintenance needed)
// x1 = EV supply equipment not ready to supply energy
// x2 = EV supply equipment ready to supply energy
export const enum CarChargerIECStatus {
  A0 = 'A0', // only used by Smappee, while booting, but should never occur as current state
  A1 = 'A1',
  A2 = 'A2',
  B0 = 'B0',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  B8 = 'B8',
  B9 = 'B9',
  B12 = 'B12',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5',
  C6 = 'C6',
  C7 = 'C7',
  C8 = 'C8',
  C9 = 'C9',
  C12 = 'C12',
  D1 = 'D1',
  D2 = 'D2',
  E = 'E',
  F = 'F',
  Invalid0 = '0',
  Invalid9 = '9'
}

export const enum CarChargingStationChargingState {
  Started = 'STARTED',
  SmartCharging = 'SMART',
  Stopped = 'STOPPED',
  Suspended = 'SUSPENDED',
  Unknown = 'UNKNOWN'
}

export interface EVChargingStationControllerState {
  previous?: CarChargerIECStatus;
  current?: CarChargerIECStatus;
  ultra?: UltraStatus;
}

export interface CarChargingStationChargingStateMqttMessage {
  percentageLimit?: number;
  chargingState?: CarChargingStationChargingState;
  iecStatus?: EVChargingStationControllerState;
  available?: boolean;
}

export function isCharging(
  state: EVChargingStationControllerState | undefined,
  ultra: boolean,
  includePrevState = true
) {
  if (state === undefined) return false;

  return ultra ? isChargingDC(state) : isChargingAC(state, includePrevState);
}

export function isChargingPaused(chargingState: CarChargingStationChargingState | undefined) {
  return chargingState === CarChargingStationChargingState.Suspended;
}

function isChargingAC(state: EVChargingStationControllerState, includePrevState = true) {
  const {current, previous} = state;
  switch (current) {
    case CarChargerIECStatus.C1:
    case CarChargerIECStatus.D1:
      if (previous === CarChargerIECStatus.C2 || previous === CarChargerIECStatus.D2) {
        return true;
      }

      return !includePrevState;
    case CarChargerIECStatus.C2:
    case CarChargerIECStatus.D2:
      return true;
    default:
      return false;
  }
}

function isChargingDC(state: EVChargingStationControllerState) {
  const {current} = state;
  switch (current) {
    case CarChargerIECStatus.C6:
    case CarChargerIECStatus.C7:
      return true;
    default:
      return false;
  }
}

export function translateCarChargerIECStatus(
  state: EVChargingStationControllerState | undefined,
  cstate: CarChargingStationChargingState | undefined,
  ultra: boolean
): string | undefined {
  if (state === undefined) return undefined;

  return ultra ? translateCarChargerIECStatusDC(state) : translateCarChargerIECStatusAC(state, cstate);
}

function translateCarChargerIECStatusDC(state: EVChargingStationControllerState): string {
  const {current} = state;

  switch (current) {
    case CarChargerIECStatus.A0:
      return T('chargingStation.status.available');
    case CarChargerIECStatus.B1:
    case CarChargerIECStatus.B2:
      return T('chargingStation.status.cableConnected');
    case CarChargerIECStatus.B3:
    case CarChargerIECStatus.B4:
    case CarChargerIECStatus.B5:
    case CarChargerIECStatus.C3:
    case CarChargerIECStatus.C4:
    case CarChargerIECStatus.C5:
      return T('chargingStation.status.awaitingCar');
    case CarChargerIECStatus.C6:
    case CarChargerIECStatus.C7:
      return T('chargingStation.status.charging');
    case CarChargerIECStatus.B12:
    case CarChargerIECStatus.C12:
      return T('chargingStation.status.suspended');
    case CarChargerIECStatus.B8:
    case CarChargerIECStatus.C8:
      return T('chargingStation.status.chargingFinished');
    case CarChargerIECStatus.B9:
    case CarChargerIECStatus.C9:
      return getUltraError(state.ultra) || T('chargingStation.status.chargingFinished');
    case CarChargerIECStatus.E:
      return getUltraError(state.ultra) || T('chargingStation.status.error');
    case CarChargerIECStatus.F:
      return getUltraError(state.ultra) || T('chargingStation.status.unavailable');
    case CarChargerIECStatus.Invalid0: // SW-9583
    case CarChargerIECStatus.Invalid9: // SW-9583
      return getUltraError(state.ultra) || T('chargingStation.status.error');
    default:
      if (current?.startsWith('E')) {
        return getUltraError(state.ultra) || T('chargingStation.status.error');
      } else if (current?.startsWith('F')) {
        return getUltraError(state.ultra) || T('chargingStation.status.unavailable');
      }
      return '?';
  }
}

function getUltraError(ultra?: UltraStatus): string | undefined {
  if (ultra) {
    if (ultra.emergency) {
      return T('chargingStation.status.emergencyButtonPressed');
    } else if (ultra.timeout) {
      return T('chargingStation.status.timeout');
    }
  }

  return undefined;
}

function translateCarChargerIECStatusAC(
  state: EVChargingStationControllerState,
  cstate: CarChargingStationChargingState | undefined
): string {
  const {current} = state;
  switch (current) {
    case CarChargerIECStatus.A0:
      return T('chargingStation.status.booting');
    case CarChargerIECStatus.A1:
    case CarChargerIECStatus.A2:
      return T('chargingStation.status.available');
    case CarChargerIECStatus.B0:
      return T('chargingStation.status.wakeUp');
    case CarChargerIECStatus.B1:
      if (cstate === CarChargingStationChargingState.Suspended) {
        return T('chargingStation.status.suspended');
      }

      return T('chargingStation.status.cableConnected');
    case CarChargerIECStatus.B2:
      //if (previous === CarChargerIECStatus.B1)
      //  return T('chargingStation.status.cableConnected');

      return T('chargingStation.status.chargingFinished');
    case CarChargerIECStatus.C1:
    case CarChargerIECStatus.D1:
      //if (previous === CarChargerIECStatus.C2 || previous === CarChargerIECStatus.D2)
      //  return T('chargingStation.status.charging');

      // charging stations normally don't stay in this state
      // if they state in this state, assume there is something wrong
      return T('chargingStation.status.error');
    case CarChargerIECStatus.C2:
    case CarChargerIECStatus.D2:
      return T('chargingStation.status.charging');
    case CarChargerIECStatus.E:
      return T('chargingStation.status.error');
    case CarChargerIECStatus.F:
      return T('chargingStation.status.unavailable');
    default:
      return '?';
  }
}

export const enum ChargingStatusColor {
  White = 'white',
  Blue = 'blue',
  Green = 'green',
  Red = 'red'
}

export interface IChargingStatusColors {
  background: string;
  backgroundAlt: string;
  foreground: string;
  border?: string;
}

export function getChargingStatusColors(color: ChargingStatusColor): IChargingStatusColors {
  switch (color) {
    case ChargingStatusColor.White:
    default:
      return {
        background: 'white',
        backgroundAlt: BrandColors.ChargerAvailable,
        foreground: 'black',
        border: BrandColors.Pumice
      };
    case ChargingStatusColor.Blue:
      return {
        background: BrandColors.ChargerUnavailable,
        backgroundAlt: BrandColors.ChargerUnavailable,
        foreground: 'white'
      };
    case ChargingStatusColor.Green:
      return {
        background: BrandColors.ChargerAvailable,
        backgroundAlt: BrandColors.ChargerAvailable,
        foreground: 'white'
      };
    case ChargingStatusColor.Red:
      return {
        background: BrandColors.ChargerError,
        backgroundAlt: BrandColors.ChargerError,
        foreground: 'white'
      };
  }
}

export function getChargingStatusColor(
  state: EVChargingStationControllerState | undefined,
  cstate: CarChargingStationChargingState | undefined,
  ultra: boolean
): ChargingStatusColor {
  if (state === undefined) return ChargingStatusColor.White;

  return ultra ? getChargingStatusColorDC(state) : getChargingStatusColorAC(state, cstate);
}

function getChargingStatusColorDC(state: EVChargingStationControllerState): ChargingStatusColor {
  const {current} = state;
  switch (current) {
    case CarChargerIECStatus.A0:
      return ChargingStatusColor.White;
    case CarChargerIECStatus.B1:
    case CarChargerIECStatus.B2:
      return ChargingStatusColor.Blue;
    case CarChargerIECStatus.B3:
    case CarChargerIECStatus.B4:
    case CarChargerIECStatus.B5:
    case CarChargerIECStatus.C5:
      return ChargingStatusColor.Blue;
    case CarChargerIECStatus.C6:
    case CarChargerIECStatus.C7:
      return ChargingStatusColor.Green;
    case CarChargerIECStatus.B12:
    case CarChargerIECStatus.C12:
      return ChargingStatusColor.Green;
    case CarChargerIECStatus.B8:
    case CarChargerIECStatus.C8:
      return ChargingStatusColor.Green;
    case CarChargerIECStatus.B9:
    case CarChargerIECStatus.C9:
      return getUltraErrorColor(state.ultra) || ChargingStatusColor.Green;
    default:
      return ChargingStatusColor.Red;
  }
}

function getUltraErrorColor(ultra?: UltraStatus): ChargingStatusColor | undefined {
  if (ultra && (ultra.emergency || ultra.timeout)) {
    return ChargingStatusColor.Red;
  }

  return undefined;
}

function getChargingStatusColorAC(
  state: EVChargingStationControllerState,
  cstate: CarChargingStationChargingState | undefined
): ChargingStatusColor {
  const {current} = state;
  switch (current) {
    case CarChargerIECStatus.A0:
    case CarChargerIECStatus.A1:
    case CarChargerIECStatus.A2:
      return ChargingStatusColor.White;
    case CarChargerIECStatus.B1:
      if (cstate === CarChargingStationChargingState.Suspended) {
        return ChargingStatusColor.Green;
      }

      return ChargingStatusColor.Blue;
    case CarChargerIECStatus.B2:
      //if (previous === CarChargerIECStatus.B1)
      //  return ChargingStatusColor.Blue;

      return ChargingStatusColor.Green;
    case CarChargerIECStatus.C1:
    case CarChargerIECStatus.D1:
      //if (previous === CarChargerIECStatus.C2 || previous === CarChargerIECStatus.D2)
      //  return ChargingStatusColor.Green;

      return ChargingStatusColor.Red;
    case CarChargerIECStatus.C2:
    case CarChargerIECStatus.D2:
      return ChargingStatusColor.Green;
    case CarChargerIECStatus.E:
    case CarChargerIECStatus.F:
      return ChargingStatusColor.Red;
    default:
      return ChargingStatusColor.Red;
  }
}
