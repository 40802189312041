import cn from 'classnames';
import React from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../../app/context';
import {SingleActionModal} from '../../../components/bootstrap';
import {Icon, Icons} from '../../../components/Icon';
import SmappeeSolidIcon from '../../../components/icons/Smappee';
import ImageInput from '../../../components/inputs/ImageInput';
import {Button} from '../../../components/ui/button';
import {UserServicedesk} from '../../../components/ui-lib/icons/medium';
import {Bin, Help, LockLocked} from '../../../components/ui-lib/icons/small';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../../modals/ConfirmationPromiseModal';
import {useModals} from '../../../modals/ModalContext';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {
  ChargingDisplayImage,
  ChargingDisplayImageStatus,
  ChargingDisplayImageType
} from '../../../models/ChargingDisplayImage';

import {ILocation, isChargingParent, LocationFunctionType} from '../../../models/Location';
import {None} from '../../../utils/Arrays';
import {reportError} from '../../../utils/Errors';
import {Fetcher} from '../../../utils/Fetcher';
import {useAvailableChargingDisplayImages} from '../../../utils/FunctionalData';
import {T} from '../../../utils/Internationalization';
import {useUser} from '../../CardUtils';

import {getDisplayImageTypeLabel, TYPE_MAP} from '../models/ChargingStationConfiguration.model';

import HelpImageUploadModal from './childModals/HelpImageUploadModal';
import ImageCropModal from './childModals/ImageCropModal';
import ServiceDeskInfoModal from './childModals/ServiceDeskInfoModal';
import downSizeAndSendImage from './childModals/utils/downSizeAndSendImage';
import {base64toFile, isValidAspectRatio} from './childModals/utils/imageFns';
import styles from './ManageDisplayImagesModal.module.scss';

export interface ChargingStationDisplayImagesSettings {
  visible?: boolean;
  restrictedAccess?: boolean;
  name: string;
  locationId: number | undefined;
  serialNumber: string | undefined;
  locationFunctionType: LocationFunctionType;
  timeZoneId: string;
}

interface ManageDisplayImagesModalProps extends IPromiseModalProps<ChargingStationDisplayImagesSettings | undefined> {
  settings: ChargingStationDisplayImagesSettings;
  location: ILocation;
  fetch: Fetcher;
}

const REQUIRED_WIDTH = 320;
const REQUIRED_HEIGHT = 240;
const IMAGE_QUALITY = 100;
const IMAGE_TYPE = 'PNG';
const MIME_TYPE = 'image/png';
const IMAGE_ROTATION = 0;
const ASPECT_WIDTH = 4;
const ASPECT_HEIGHT = 3;

export const ManageDisplayImagesModal = (props: ManageDisplayImagesModalProps) => {
  const {settings, location, fetch} = props;
  const isChargingPark = settings.locationFunctionType === 'CHARGINGPARK';
  // eslint-disable-next-line no-debugger
  // debugger;
  const [images, refreshImages, isLoading] = useAvailableChargingDisplayImages(
    fetch,
    settings.serialNumber ?? undefined,
    settings.locationId ?? undefined,
    settings.locationFunctionType
  );
  const [selectedImage, setSelectedImage] = React.useState<ChargingDisplayImage>();
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const me = useUser();
  const modals = useModals();
  const fileReader = new FileReader();

  const getEditableImages = (images: ChargingDisplayImage[]): ChargingDisplayImage[] => {
    if (images?.length > 0) {
      return images.filter(
        image => image.type === ChargingDisplayImageType.Welcome || image.type === ChargingDisplayImageType.Support
      );
    } else {
      return None;
    }
  };
  const getEditableImagesForHelpdesk = (images: ChargingDisplayImage[]): ChargingDisplayImage[] => {
    if (images?.length > 0) {
      return images;
    } else {
      return None;
    }
  };
  const filteredImages = me.isServiceDesk() ? getEditableImagesForHelpdesk(images) : getEditableImages(images);
  const handleClose = React.useCallback(() => resolve(undefined), [resolve]);
  const handleCroppedImage = (dataUrl: string, fileName: string) => {
    let fileNameResizedFile = fileName;
    let newFileName = `${fileNameResizedFile}-cropped-resized.png`;
    const uploadFile = base64toFile(dataUrl, newFileName, MIME_TYPE);
    // resize the image and make api call
    downSizeAndSendImage({
      file: uploadFile,
      width: REQUIRED_WIDTH,
      height: REQUIRED_HEIGHT,
      imageType: IMAGE_TYPE,
      quality: IMAGE_QUALITY,
      rotation: IMAGE_ROTATION,
      api,
      serialNumber: settings.serialNumber!,
      locationId: settings.locationId,
      isChargingPark,
      CSDImageType: selectedImage ? (selectedImage.type as ChargingDisplayImageType) : ChargingDisplayImageType.Welcome,
      setImage: setSelectedImage,
      refresh: refreshImages,
      errorMsg: T('chargingStationConfiguration.manageDisplayImages.imageTransferFailed')
    });
  };

  const handleOnChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const files = e.currentTarget.files;
    const changedFile = files && files[0];
    if (!changedFile) return;
    if (isChargingPark && settings.locationId === undefined) return;
    if (!isChargingPark && settings.serialNumber === undefined) return;
    fileReader.readAsDataURL(changedFile);
    fileReader.onload = uploadedImg => {
      const imageUrl = (uploadedImg.target?.result as string) || ''; // use the base64 jpg string as preview
      const image = new Image();
      image.src = imageUrl;
      image.onload = async () => {
        const {height, width} = image;
        if (height == REQUIRED_HEIGHT && width == REQUIRED_WIDTH) {
          try {
            if (!isChargingPark) {
              const response = await api.uploadChargingStationImage(
                settings.serialNumber!,
                changedFile,
                selectedImage ? (selectedImage.type as ChargingDisplayImageType) : ChargingDisplayImageType.Welcome
              );
              if (response) {
                // Show preview
                setSelectedImage(response as unknown as ChargingDisplayImage);
                refreshImages();
              }
              NotificationManager.success(
                T('chargingStationConfiguration.manageDisplayImages.imageTransferSuccessful')
              );
            } else {
              const response = await api.uploadChargingParkImage(
                settings.locationId!,
                changedFile,
                selectedImage ? (selectedImage.type as ChargingDisplayImageType) : ChargingDisplayImageType.Welcome
              );
              if (response) {
                // Show preview
                setSelectedImage(response as unknown as ChargingDisplayImage);
                refreshImages();
              }
              NotificationManager.success(
                T('chargingStationConfiguration.manageDisplayImages.imageTransferSuccessful')
              );
            }
          } catch {
            NotificationManager.error(T('chargingStationConfiguration.manageDisplayImages.imageTransferFailed'));
          }
        } else {
          const validRatio = isValidAspectRatio(ASPECT_WIDTH, ASPECT_HEIGHT, width, height);
          if (validRatio) {
            // We have a valid dimensions ratio, just downsize this image
            downSizeAndSendImage({
              file: changedFile,
              width: REQUIRED_WIDTH,
              height: REQUIRED_HEIGHT,
              imageType: IMAGE_TYPE,
              quality: IMAGE_QUALITY,
              rotation: IMAGE_ROTATION,
              api,
              serialNumber: settings.serialNumber!,
              locationId: settings.locationId,
              isChargingPark,
              CSDImageType: selectedImage
                ? (selectedImage.type as ChargingDisplayImageType)
                : ChargingDisplayImageType.Welcome,
              setImage: setSelectedImage,
              refresh: refreshImages,
              errorMsg: T('chargingStationConfiguration.manageDisplayImages.imageTransferFailed')
            });
          } else {
            // let the user make the crop in the correct aspect ratio and resize the image
            modals.show<void>(modalProps => (
              <ImageCropModal
                image={image}
                cropAndResize={handleCroppedImage}
                fileName={changedFile?.name}
                {...modalProps}
              />
            ));
          }
        }
      };
    };
  };
  const handleOnClickFigure = async (e: ChargingDisplayImage) => {
    setSelectedImage(e);
  };

  const handleRemoveImage = (e: ChargingDisplayImage) => {
    modals
      .show(props => (
        <ConfirmationPromiseModal
          title={T('chargingStationConfiguration.manageDisplayImages.removeImage')}
          message={T('chargingStationConfiguration.manageDisplayImages.restore', {
            type: getDisplayImageTypeLabel(e.type)
          })}
          {...props}
        />
      ))
      .then(result => {
        if (result === ConfirmationResult.Accept) {
          if (isChargingPark) {
            // call chargingPark api
            api
              .deleteChargingParkImage(settings.locationId!, e.type)
              .then(() => {
                refreshImages();
                setSelectedImage(undefined);
                NotificationManager.success(T('chargingStationConfiguration.manageDisplayImages.imageRemoved'));
              })
              .catch(reportError);
          } else {
            api
              .deleteChargingStationImage(settings.serialNumber!, e.type)
              .then(() => {
                refreshImages();
                setSelectedImage(undefined);
                NotificationManager.success(T('chargingStationConfiguration.manageDisplayImages.imageRemoved'));
              })
              .catch(reportError);
          }
        }
      });
  };

  const closeModal = async () => {
    handleClose();
  };

  const handleServiceDeskInfo = async (selectedImage: ChargingDisplayImage): Promise<void> => {
    modals.show<void>(modalProps => <ServiceDeskInfoModal {...modalProps} image={selectedImage} />);
  };

  const handleShowHelpInfo = async (): Promise<void> => {
    modals.show<void>(modalProps => <HelpImageUploadModal {...modalProps} />);
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      size="xl"
      title={T('chargingStationConfiguration.manageDisplayImages.configureImagesDisplay', {name: settings.name!})}
      action={closeModal}
      actionText={T('modal.close')}
    >
      <div className={styles.row}>
        <div className={styles.column}>
          {isLoading && <div>{Icons.Loading}</div>}
          {isChargingParent(settings.locationFunctionType) ? (
            <p>{T('chargingStationConfiguration.manageDisplayImages.currentlyLoadedImages.chargingSquare')}</p>
          ) : (
            <p>{T('chargingStationConfiguration.manageDisplayImages.currentlyLoadedImages.chargingStation')}</p>
          )}
          {filteredImages?.length > 0 && !isLoading && (
            <div className={cn(styles.gallery, me.isServiceDesk() ? styles.helpDeskContainer : '')}>
              {filteredImages.map(img => (
                <figure
                  key={img.id}
                  className={cn(
                    styles.galleryItem,
                    img.id === selectedImage?.id ? styles.figureSelected : '',
                    me.isServiceDesk() ? styles.helpDeskGallery : ''
                  )}
                  onClick={() => handleOnClickFigure(img)}
                >
                  {img.statusFlags?.length > 0 && (
                    <div className={styles.statusContainer}>
                      {img.statusFlags?.includes(ChargingDisplayImageStatus.Busy) && (
                        <div className={cn(styles.statusIcon)}>
                          <i className={cn(Icon.HourGlass, styles.statusBusy)} />
                        </div>
                      )}
                      {img.statusFlags?.includes(ChargingDisplayImageStatus.Confirmed) && (
                        <div className={cn(styles.statusIcon)}>
                          <i className={cn(Icon.FileCheck)} />
                        </div>
                      )}
                      {img.isDefault && (img.type === 'ULTRA_V2_WELCOME' || img.type === 'ULTRA_V2_SUPPORT') && (
                        <div className={cn(styles.statusIcon)}>
                          <SmappeeSolidIcon className={styles.SmappeeIcon} width={11.75} height={16} />
                        </div>
                      )}
                    </div>
                  )}
                  {img.statusFlags?.length == 0 &&
                    img.isDefault &&
                    (img.type === 'ULTRA_V2_WELCOME' || img.type === 'ULTRA_V2_SUPPORT') && (
                      <div className={styles.statusContainer}>
                        <div className={cn(styles.statusIcon)}>
                          <SmappeeSolidIcon className={styles.SmappeeIcon} width={11.75} height={16} />
                        </div>
                      </div>
                    )}
                  {img.statusFlags?.length == 0 &&
                    !(img.type === 'ULTRA_V2_WELCOME') &&
                    !me.isServiceDesk() &&
                    !(img.type === 'ULTRA_V2_SUPPORT') && (
                      <div className={styles.statusContainer}>
                        <div className={cn(styles.notAllowed)}>
                          <LockLocked />
                        </div>
                      </div>
                    )}
                  <img
                    src={img.url.value}
                    alt={`${img.id.toString()}`}
                    className={cn(styles.galleryItemImage, img.isDefault ? styles.overlay : '')}
                  />
                  <p className={cn(styles.caption, me.isServiceDesk() ? styles.helpDeskCaption : '')}>
                    {TYPE_MAP[img.type]}
                  </p>
                </figure>
              ))}
            </div>
          )}
        </div>
        <div className={styles.column}>
          <p className="tw-pl-6">{T('chargingStationConfiguration.manageDisplayImages.preview')}:</p>
          <div className={cn(styles.previewSection, styles.rightCol)}>
            <ImageInput
              image={selectedImage ? selectedImage.url?.value : ''}
              onChange={handleOnChangeFile}
              emptyViewChildren={
                <div className={styles.emptyViewChildren}>
                  <div className={styles.editBadge}>
                    <i className={Icon.Upload} />
                  </div>
                </div>
              }
              className={styles.previewArea}
              showButton={
                selectedImage?.type === ChargingDisplayImageType.Welcome ||
                selectedImage?.type === ChargingDisplayImageType.Support ||
                me.isServiceDesk()
              }
            />
            <div className={styles.previewHelpArea}>
              <div className={styles.toolBar}>
                {!selectedImage && (
                  <Button variant="secondary_icon_btn" size="default" onClick={() => handleShowHelpInfo()}>
                    <Help className="!tw-w-4 !tw-h-4" />
                  </Button>
                )}
                {selectedImage && (
                  <>
                    <Button variant="secondary_icon_btn" size="default" onClick={() => handleShowHelpInfo()}>
                      <Help className="!tw-w-4 !tw-h-4" />
                    </Button>
                    {(selectedImage?.type === ChargingDisplayImageType.Welcome ||
                      selectedImage?.type === ChargingDisplayImageType.Support ||
                      me.isServiceDesk()) &&
                      !selectedImage?.isDefault && (
                        <Button
                          variant="secondary_icon_btn"
                          size="default"
                          title={T('chargingStationConfiguration.manageDisplayImages.removeImage')}
                          onClick={() => handleRemoveImage(selectedImage)}
                          data-testid="removeUltraImage"
                        >
                          <Bin />
                        </Button>
                      )}
                    {me.isServiceDesk() && (
                      <Button
                        variant="secondary_icon_btn"
                        size="default"
                        onClick={() => handleServiceDeskInfo(selectedImage)}
                        data-testid="getUltraImageInfo"
                      >
                        <UserServicedesk className="!tw-w-4 !tw-h-4" />
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SingleActionModal>
  );
};
