import {useMemo, useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button as RsButton, RowActions} from '../../components/bootstrap';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import Table, {SortOrder} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ComponentField, ITableField, StringField} from '../../models/Table';
import {IThirdPartyCharger} from '../../models/ThirdPartyCharger';
import {useAllThirdPartyChargers} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardChargingStationGroup, useUser} from '../CardUtils';
import ChargingStationParentButtonLink from '../ChargingStations/ChargingStationParentButtonLink';
import {CardActions} from '../components';
import {Spring} from '../components/CardActions';
import {CardView, CustomActions, cardViewProps} from '../components/CardView';

type ThirdPartyStationSettings = ICardSettingsWithTable;
const rowKey = (row: IThirdPartyCharger) => `${row.id}`;

const ThirdPartyStation = (props: ICardProps<ThirdPartyStationSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const chargingSquare = useCardChargingStationGroup(settings);
  const [errorMessage, setErrorMessage] = useState('');
  const {api} = useAppContext();
  const [thirdPartyChargers, refreshThirdPartyChargers] = useAllThirdPartyChargers(fetch, chargingSquare?.id);
  const me = useUser();
  const isServiceDesk = me.isServiceDesk();

  const chargingSquareId = chargingSquare && chargingSquare.id;
  const titleAddendum = <ChargingStationParentButtonLink location={chargingSquare} />;

  const columns = useMemo<ITableField<IThirdPartyCharger>[]>(() => {
    const handleClickDelete = async (item: IThirdPartyCharger) => {
      if (!chargingSquareId) return;
      try {
        await api.deleteProxyUrl(chargingSquareId, item.id);
        refreshThirdPartyChargers();
        NotificationManager.success(T('thirdPartyChargers.error.deleteProxyUrlSuccessful'));
        setErrorMessage('');
      } catch {
        setErrorMessage(T('thirdPartyChargers.error.deleteProxyUrlFailed'));
        NotificationManager.error(T('thirdPartyChargers.error.deleteProxyUrlFailed'));
      }
    };

    const handleClickCopy = async (item: IThirdPartyCharger) => {
      try {
        await navigator.clipboard.writeText(item.csmsUrl);
        NotificationManager.success(T('thirdPartyChargers.message.copyToClipboard'));
      } catch (error) {
        NotificationManager.error(T('thirdPartyChargers.error.copyToClipboard'));
      }
    };

    return [
      new StringField('csmsUrl', T('thirdPartyChargers.field.cmsUrl')),
      new StringField('vendor', T('thirdPartyChargers.field.vendor')),
      new StringField('smappeeSerialNumber', T('thirdPartyChargers.field.smappeeSerial')),
      new StringField('originalSerialNumber', T('thirdPartyChargers.field.originalSerial')),

      new ComponentField('actions', T('generic.actions'), item => (
        <RowActions className="leftAligned">
          {item.id && (
            <RowActionButton
              icon="Copy"
              title={T('thirdPartyChargers.copyURL')}
              onClick={() => handleClickCopy(item)}
            />
          )}
          {chargingSquareId && item.id && !item.smappeeSerialNumber && (
            <RowActionButton icon="Trash" title="Delete URL" onClick={() => handleClickDelete(item)} />
          )}
        </RowActions>
      ))
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingSquareId]);

  const handleClickGenerateURLs = async () => {
    if (!chargingSquare?.id) {
      return;
    }
    try {
      const result = await api.createProxyUrl(chargingSquare.id);
      if (result) {
        setErrorMessage('');
        refreshThirdPartyChargers();
        NotificationManager.success(T('thirdPartyChargers.error.createProxyUrlSuccessful'));
      } else {
        setErrorMessage(T('thirdPartyChargers.error.createProxyUrlFailed'));
      }
    } catch {
      setErrorMessage(T('thirdPartyChargers.error.createProxyUrlFailed'));
    }
  };

  const actions: CustomActions = actionState => (
    <CardActions>
      <Spring />
      <RsButton color="primary" onClick={handleClickGenerateURLs}>
        {T('thirdPartyChargingStations.actions.generateURLs')}
      </RsButton>
    </CardActions>
  );

  return (
    <CardView actions={actions} titleAddendum={titleAddendum} {...cardViewProps(props)}>
      <Table
        items={thirdPartyChargers}
        fields={columns}
        hasPaging={true}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noDefaultSort={true}
        rowKey={rowKey}
        noun="thirdPartyCharger"
      />
    </CardView>
  );
};

const DEFAULT_CARD_SETTINGS: ThirdPartyStationSettings = {
  table: {
    pageSize: 10,
    sortColumn: 'id',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'id', visible: false},
      {name: 'csmsUrl', visible: true},
      {name: 'vendor', visible: true},
      {name: 'smappeeSerialNumber', visible: true},
      {name: 'originalSerialNumber', visible: true}
    ]
  }
};

const CARD: ICardType<ThirdPartyStationSettings> = {
  type: CardTypeKey.ThirdPartyChargingStation,
  title: 'thirdPartyChargingStationConfiguration.title',
  description: 'thirdPartyChargingStationConfiguration.description',
  locationAware: CardLocationAwareness.Required,
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  cardClass: ThirdPartyStation
};

export default CARD;
