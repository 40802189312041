import {ChargingStation} from '../../models/ChargingStation';
import {IChargingStationDiagnosticEvent} from '../../models/ChargingStationDiagnostics';
import {CalculatedStringField, ITableField, StringField, TimestampField} from '../../models/Table';

export function getColumns(station: ChargingStation | undefined): ITableField<IChargingStationDiagnosticEvent>[] {
  return [
    new TimestampField('registrationTimestamp', 'Timestamp'),
    new StringField('code', 'Code'),
    new StringField('description', 'Description'),
    new CalculatedStringField('controller', 'Controller', item => {
      if (station === undefined) return item.serialNumber;

      const controllers = station.getControllers();
      const controller = controllers.find(module => module.serialNumber === item.serialNumber);
      return item.serialNumber + (controller && controller.label ? ` (${controller.label})` : '');
    })
  ];
}
