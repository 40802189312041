import React, {ReactNode} from 'react';

import {Spring} from '../../cards/components/CardActions';
import {FormProvider} from '../../utils/FormContext';
import {T} from '../../utils/Internationalization';

import {testingClasses} from '../../utils/TestingClasses';
import FormSaveButton from '../inputs/FormSaveButton';
import {ModalErrorMessage} from '../ModalErrorMessage';

import {Button as RsButton, Modal, ModalHeader, ModalBody, ModalFooter} from '.';

interface SingleActionModalProps {
  isOpen: boolean;
  onToggle: () => void;
  title: string;
  children: ReactNode;
  action: () => Promise<unknown>;
  actionText?: string;
  cancelText?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  error?: string;
  loading?: boolean;
  note?: ReactNode;
}

export const SingleActionModal = (props: SingleActionModalProps) => {
  const {
    isOpen,
    onToggle,
    title,
    children,
    action,
    actionText,
    cancelText,
    disabled,
    size = 'md',
    error,
    loading,
    note
  } = props;

  return (
    <FormProvider>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        autoFocus={false}
        size={size}
        className={loading ? testingClasses.loading : ''}
        data-testid={testingClasses.loading}
      >
        <ModalHeader toggle={onToggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter error={error}>
          {cancelText && (
            <RsButton color="secondary" onClick={onToggle} style={{flexShrink: 0}}>
              {cancelText}
            </RsButton>
          )}
          <Spring />
          <FormSaveButton onSave={action} disabled={disabled}>
            {actionText || T('modal.save')}
          </FormSaveButton>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
