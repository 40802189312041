import {ChargingStationModule, IChargingStation, IChargingStationUpdate} from '../models/ChargingStation';
import {SplitBillingAgreement} from '../models/SplitBillingAgreement';
import {IThirdPartyCharger} from '../models/ThirdPartyCharger';
import {AppFeature, hasFeature} from '../utils/AppParameters';
import {DataCache} from '../utils/DataCache';

import {APIClient} from './APIClient';

export class ChargingStationCache {
  chargingStationsByLocation: DataCache<number, IChargingStation[]>;
  chargingStationBySerial: DataCache<string, IChargingStation>;

  constructor(client: APIClient) {
    const api = new InternalChargingStationAPI(client);

    this.chargingStationsByLocation = new DataCache<number, IChargingStation[]>('chargingStations', id =>
      api.getByLocation(id)
    );
    this.chargingStationBySerial = new DataCache<string, IChargingStation>('chargingStation', (serial: string) =>
      api.getBySerial(serial)
    );
  }

  invalidateSerial(serial: string) {
    this.chargingStationBySerial.invalidate(serial);
  }
}

export class ChargingStationAPI {
  private client: APIClient;
  private cache: ChargingStationCache;

  constructor(client: APIClient, cache: ChargingStationCache) {
    this.client = client;
    this.cache = cache;
  }

  getByLocation(serviceLocationId: number, force?: boolean): Promise<IChargingStation[]> {
    return this.cache.chargingStationsByLocation.fetch(serviceLocationId, force);
  }

  getBySerial(serialNumber: string, force?: boolean): Promise<IChargingStation> {
    return this.cache.chargingStationBySerial.fetch(serialNumber, force);
  }

  invalidateForLocation(serviceLocationId: number) {
    this.cache.chargingStationsByLocation.invalidate(serviceLocationId);
  }

  getModules(serialNumber: string): Promise<ChargingStationModule[]> {
    const url = `/api/v10/chargingstations/${serialNumber}/modules`;
    return this.client.doGet(url);
  }

  getSplitBillingAgreements(serialNumber: string): Promise<SplitBillingAgreement[]> {
    const url = `/api/v10/chargingstations/${serialNumber}/splitbilling/agreements`;
    return this.client.doGet(url);
  }

  update(locationId: number | undefined, chargingStationSerial: string, updates: Partial<IChargingStationUpdate>) {
    const url = `/api/v10/chargingstations/${chargingStationSerial}`;
    this.cache.chargingStationBySerial.invalidate(chargingStationSerial);
    if (locationId) {
      this.cache.chargingStationsByLocation.invalidate(locationId);
    }
    return this.client.doPatch(url, updates);
  }
  swapChargingStationModule(chargingStationSerial: string, moduleId: number, serialNumber: string): Promise<unknown> {
    const url = `/api/v10/chargingstations/${chargingStationSerial}/modules/${moduleId}/swap`;
    this.cache.invalidateSerial(chargingStationSerial);
    return this.client.doPost(url, {
      otherModuleSerialNumber: serialNumber
    });
  }

  // getThirdPartyChargers(serviceLocationId: number): Promise<IThirdPartyCharger[]> {
  //   const url = `/api/v10/chargingparks/${serviceLocationId}/thirdpartychargers`;
  //   return this.client.doGet(url);
  // }
}

class InternalChargingStationAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  getByLocation(serviceLocationId: number): Promise<IChargingStation[]> {
    let url = `/api/v10/servicelocation/${serviceLocationId}/chargingstations`;

    return this.client.doGet(url);
  }

  getBySerial(serialNumber: string): Promise<IChargingStation> {
    let url: string;
    if (hasFeature(AppFeature.ReplacementModules)) {
      url = `/api/v10/chargingstations/${serialNumber}?includeReplacements=true`;
    } else {
      url = `/api/v10/chargingstations/${serialNumber}`;
    }
    return this.client.doGet(url);
  }
}
