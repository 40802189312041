import React, {ReactNode, useState} from 'react';

import {Spring} from '../../cards/components/CardActions';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ModalErrorMessage} from '../ModalErrorMessage';

import {Button as RsButton, Modal, ModalHeader, ModalBody, ModalFooter} from '.';

interface SingleActionModalProps {
  title: string;
  children: ReactNode;
  action?: () => Promise<string | undefined>;
  actionText?: string;
  cancelText?: string;
  disabled?: boolean;
  loading?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  isOpen: boolean;
  resolve: (result: boolean) => void;
  error?: string;
}

export const SingleActionPromiseModal = (props: SingleActionModalProps) => {
  const {title, children, action, actionText, cancelText, disabled, loading, size = 'md', isOpen, resolve} = props;

  const [error, setError] = useState<string>();
  const handleToggle = () => resolve(false);
  const handleAction = async () => {
    if (action === undefined) return;

    const result = await action();
    setError(result);
    if (result === undefined) resolve(true);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} loading={loading} autoFocus={false} size={size}>
      <ModalHeader toggle={handleToggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter error={error || props.error}>
        {cancelText && (
          <RsButton color="secondary" onClick={handleToggle}>
            {cancelText}
          </RsButton>
        )}
        {action && (
          <RsButton
            color="primary"
            onClick={handleAction}
            disabled={disabled === true}
            className={testingClasses.confirmButton}
            data-testid={testingClasses.confirmButton}
          >
            {actionText || T('modal.save')}
          </RsButton>
        )}
      </ModalFooter>
    </Modal>
  );
};
