import {Button as RsButton} from '../../../components/bootstrap';
import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {T} from '../../../utils/Internationalization';
import EditIcon from '../../LocationConfiguration/fields/EditIcon';

interface DisplayImagesFieldProps {
  status?: string;
  name?: string;
  readOnly: boolean;
  handleClickEditDisplayImages: () => void;
}
export const DisplayImagesField = (props: DisplayImagesFieldProps) => {
  const {status, name, readOnly = true, handleClickEditDisplayImages} = props;
  return (
    <span>
      {T('chargingStationConfiguration.property.displayImages.configureImages')}
      &nbsp;
      {!readOnly && (
        <Button variant="ghost_action_btn" size="icon" onClick={handleClickEditDisplayImages}>
          <Edit />
        </Button>
      )}
    </span>
  );
};
