import {CSSProperties, useEffect, useState} from 'react';
import {Table} from 'reactstrap';

import {useAppContext} from '../../../app/context';
import {SingleActionModal} from '../../../components/bootstrap';
import {IconButton} from '../../../components/IconButton';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {ChargingStation, ChargingStationModule} from '../../../models/ChargingStation';
import {ILocation} from '../../../models/Location';
import {None} from '../../../utils/Arrays';
import {useLoader} from '../../../utils/Hooks';
import {T} from '../../../utils/Internationalization';

interface SequenceNumberModalProps extends IPromiseModalProps<boolean> {
  location: ILocation;
}

function getSequenceNumber(charger: ChargingStationModule): number {
  const sequenceNumberProperty = charger.smartDevice?.configurationProperties.find(
    p => p.spec.name === 'etc.smart.device.type.car.charger.smappee.charger.number'
  );
  if (!sequenceNumberProperty) return 0;
  return sequenceNumberProperty.values[0].Integer || 0;
}

interface Connector {
  id: number;
  serialNumber: string;
  stationSerialNumber: string;
  name: string;
  sequenceNumber: number;
  first: boolean;
  multiple: boolean;
}

function getAllConnectors(stations: ChargingStation[]): Connector[] {
  const connectors = stations.flatMap(station => {
    const controllers = station.getControllers();
    const lowestSequenceNumber = Math.min(...controllers.map(c => getSequenceNumber(c)));
    return controllers.map((controller, index) => ({
      id: controller.id,
      serialNumber: controllers.length === 1 ? station.serialNumber : `${station.serialNumber}-${controller.position}`,
      stationSerialNumber: station.serialNumber,
      name: controllers.length === 1 ? station.name : `${station.name} ${controller.position}`,
      sequenceNumber: getSequenceNumber(controller),
      first: getSequenceNumber(controller) === lowestSequenceNumber,
      multiple: controllers.length > 1
    }));
  });
  connectors.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
  return connectors;
}

function fillGaps(connectors: Connector[]): (Connector | null)[] {
  if (connectors.length === 0) return None;
  shiftDuplicates(connectors);
  const maxSequenceNumber = connectors[connectors.length - 1].sequenceNumber;
  const allSequenceNumbers = new Array(maxSequenceNumber - 1).fill(null);
  connectors.forEach(connector => {
    allSequenceNumbers[connector.sequenceNumber - 1] = connector;
  });
  return allSequenceNumbers;
}

function shiftDuplicates(connectors: Connector[]) {
  const usedSlots = new Set<number>();
  connectors.forEach(connector => {
    while (usedSlots.has(connector.sequenceNumber)) {
      connector.sequenceNumber++;
    }
    usedSlots.add(connector.sequenceNumber);
  });
}

function visible(visible: boolean): CSSProperties {
  return visible ? {} : {visibility: 'hidden'};
}

export function SequenceNumberModal(props: SequenceNumberModalProps) {
  const [isOpen, resolve] = usePromiseModal(props);
  const {location} = props;
  const {api} = useAppContext();

  const [chargingStations = None] = useLoader(
    api =>
      api.chargingStations
        .getByLocation(location.id)
        .then(stations => stations.map(station => new ChargingStation(station))),
    [location.id]
  );
  const [editingConnectors, setEditingConnectors] = useState<(Connector | null)[]>(None);
  useEffect(() => setEditingConnectors(fillGaps(getAllConnectors(chargingStations))), [chargingStations]);

  const handleClose = () => resolve(false);

  const handleClickedSave = () => {
    const updates: Promise<unknown>[] = [];
    chargingStations.forEach(station =>
      station.getControllers().forEach(controller => {
        const oldSequenceNumber = getSequenceNumber(controller);
        const newSequenceNumber = editingConnectors.findIndex(c => c?.id === controller.id) + 1;
        if (oldSequenceNumber !== newSequenceNumber) {
          updates.push(
            api.smartDevices.updateConfigurationProperty(
              location.id,
              controller.smartDevice!,
              'etc.smart.device.type.car.charger.smappee.charger.number',
              {Integer: newSequenceNumber}
            )
          );
        }
      })
    );

    return Promise.all(updates).then(() => {
      api.chargingStations.invalidateForLocation(location.id);
      resolve(true);
    });
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      size="lg"
      action={handleClickedSave}
      title={T('chargingStationConfiguration.sequenceNumbers.title', {name: location.name || ''})}
    >
      <p>{T('chargingStationConfiguration.sequenceNumbers.info')}</p>
      <Table>
        <thead>
          <tr>
            <th>{T('chargingStationConfiguration.sequenceNumbers.sequenceNumber')}</th>
            <th>{T('chargingStationConfiguration.sequenceNumbers.connector')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {editingConnectors.map((connector, index) => (
            <ConnectorRow
              key={index}
              connectors={editingConnectors}
              connector={connector}
              index={index}
              updateConnectors={setEditingConnectors}
            />
          ))}
        </tbody>
      </Table>
    </SingleActionModal>
  );
}

interface ConnectorRowProps {
  connectors: (Connector | null)[];
  connector: Connector | null;
  index: number;
  updateConnectors: (connectors: (Connector | null)[]) => void;
}

function separateStationConnectors(
  connectors: (Connector | null)[],
  index: number
): [(Connector | null)[], (Connector | null)[]] {
  const connector = connectors[index];
  if (connector === null) {
    return [[null], connectors.filter((_, i) => i !== index)];
  } else {
    return [
      connectors.filter(c => c?.stationSerialNumber === connector.stationSerialNumber),
      connectors.filter(c => c === null || c.stationSerialNumber !== connector.stationSerialNumber)
    ];
  }
}

function isLastStation(connectors: (Connector | null)[], index: number): boolean {
  const stationSerialNumber = connectors[index]?.stationSerialNumber;
  return connectors.slice(index + 1).every(c => c?.stationSerialNumber === stationSerialNumber);
}

function ConnectorRow(props: ConnectorRowProps) {
  const {connectors, connector, index, updateConnectors} = props;

  const handleClickedMoveUp = () => {
    const [firstConnectors, remainingConnectors] = separateStationConnectors(connectors, index);
    const [nextConnectors, remainingConnectors2] = separateStationConnectors(remainingConnectors, index - 1);
    const newConnectors = remainingConnectors2
      .slice(0, index - nextConnectors.length)
      .concat(firstConnectors)
      .concat(nextConnectors)
      .concat(remainingConnectors2.slice(index - nextConnectors.length));
    updateConnectors(newConnectors);
  };

  const handleClickedMoveDown = () => {
    const [firstConnectors, remainingConnectors] = separateStationConnectors(connectors, index);
    const [nextConnectors, remainingConnectors2] = separateStationConnectors(remainingConnectors, index);
    const newConnectors = remainingConnectors2
      .slice(0, index)
      .concat(nextConnectors)
      .concat(firstConnectors)
      .concat(remainingConnectors2.slice(index));
    updateConnectors(newConnectors);
  };

  const handleClickedSwap = () => {
    if (connector === null) return;

    const [stationConnectors, otherConnectors] = separateStationConnectors(connectors, index);
    stationConnectors.reverse();
    stationConnectors.forEach((c, i) => c && (c.first = i === 0));
    const newConnectors = otherConnectors
      .slice(0, index)
      .concat(stationConnectors)
      .concat(otherConnectors.slice(index));
    updateConnectors(newConnectors);
  };

  const handleClickedRemoveGap = () => {
    const newConnectors = [...connectors];
    newConnectors.splice(index, 1);
    updateConnectors(newConnectors);
  };

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        {connector ? (
          connector.name
        ) : (
          <span style={{color: '#888'}}>{T('chargingStationConfiguration.sequenceNumbers.unassigned')}</span>
        )}
        <br />
        <span style={{color: '#888'}}>{connector?.serialNumber}</span>
      </td>
      <td style={{textAlign: 'center'}}>
        {connector?.first && (
          <>
            <IconButton
              icon="ArrowUp"
              size="sm"
              color="link"
              title={T('chargingStationConfiguration.sequenceNumbers.up.info')}
              style={visible(index > 0)}
              onClick={handleClickedMoveUp}
            />
            <IconButton
              icon="ArrowDown"
              size="sm"
              color="link"
              title={T('chargingStationConfiguration.sequenceNumbers.down.info')}
              style={visible(!isLastStation(connectors, index))}
              onClick={handleClickedMoveDown}
            />
            <IconButton
              icon="Shuffle"
              size="sm"
              color="link"
              title={T('chargingStationConfiguration.sequenceNumbers.swap.info')}
              style={visible(connector.multiple)}
              onClick={handleClickedSwap}
            />
          </>
        )}
        {connector === null && (
          <IconButton
            icon="Ban"
            size="sm"
            color="link"
            title={T('chargingStationConfiguration.sequenceNumbers.removeGap.info')}
            onClick={handleClickedRemoveGap}
          />
        )}
      </td>
    </tr>
  );
}
