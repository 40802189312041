import ReactSortableTree from '@nosferatu500/react-sortable-tree';
import {isValid} from 'date-fns';
import React, {useEffect, useMemo, useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button as RsButton} from '../../components/bootstrap';
import {Button} from '../../components/ui/button';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {ChargingStation} from '../../models/ChargingStation';
import {ILoad, LoadType} from '../../models/Load';
import {IChildLocation, isReadOnly, LocationFeature} from '../../models/Location';
import {IOverloadProtectionConfiguration} from '../../models/OverloadProtection';
import {setContextLocationId} from '../../redux/actions/location';
import {None} from '../../utils/Arrays';
import {reportError} from '../../utils/Errors';
import {useCardLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardLocation, useCardLocationDetails, useCardLocationId, useCardLocationParent, useUser} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {AddLoadsModal} from './AddLoadsModal';
import {LoadWithStation} from './LoadWithStation';
import {buildTree, decomposeTree, LoadTreeItem} from './OverloadProtectionTree';

interface OverloadProtectionSettings extends ICardSettings {
  collapsed: number[];
}

function updateLoads(target: ILoad, selection: Set<number>, allLoads: ILoad[], loads: ILoad[]) {
  const newLoads = allLoads.map(currentLoad => {
    const load = loads.find(l => l.id === currentLoad.id) || currentLoad;
    if (load.id === target.id || load.type === LoadType.Grid || load.type === LoadType.VirtualGrid) return load;

    if (selection.has(load.id)) {
      if (load.parentId === undefined || !selection.has(load.parentId)) {
        return {...load, parentId: target.id};
      } else {
        return load;
      }
    } else if (load.parentId !== undefined) {
      return {...load, parentId: undefined};
    } else {
      return load;
    }
  });
  removeDisconnectedChilds(newLoads);
  return newLoads;
}

function isValidParent(parent: ILoad, child: ILoad): boolean {
  if (parent.type === LoadType.VirtualGrid) {
    return child.type === LoadType.Grid;
  }

  return true;
}

function isDraggable(load: ILoad): boolean {
  return load.type !== LoadType.Grid && load.type !== LoadType.VirtualGrid;
}

interface LoadedData {
  overloadProtection: IOverloadProtectionConfiguration;
  loads: LoadWithStation[];
  childs: IChildLocation[];
}

function OverloadProtectionCard(props: ICardProps<OverloadProtectionSettings>) {
  const {fetch, settings, updateSettings} = props;
  const modals = useModals();
  const {api, store} = useAppContext();

  const locationId = useCardLocationId(settings);
  const location = useCardLocation(settings);
  const locationDetails = useCardLocationDetails(settings);
  const isFeatureAvailable = locationDetails && locationDetails.features.includes(LocationFeature.SmappeeMonitors);
  const parent = useCardLocationParent(settings);
  const [data, refresh] = useCardLoader<LoadedData | undefined>(
    (api, force) => {
      if (!locationId) return Promise.resolve(undefined);

      const highLevelConfig = api.getHighLevelConfigurationWithStations(locationId);
      const highLevelConfig2 = api.getHighLevelConfiguration(locationId, force);
      const childsPromise = api.locations.getChildren(locationId);
      const chargingStations = api.chargingStations.getByLocation(locationId, force);
      const overloadProtectionPromise = api.getOverloadProtection(locationId);

      return Promise.all([
        highLevelConfig,
        highLevelConfig2,
        childsPromise,
        chargingStations,
        overloadProtectionPromise
      ]).then(([highLevelConfig, highLevelConfig2, childs, chargingStations, overloadProtection]) => {
        const combinedLoads: LoadWithStation[] = [
          ...highLevelConfig.measurements,
          ...highLevelConfig2.measurements.filter(
            x => highLevelConfig.measurements.find(y => y.id === x.id) === undefined
          )
        ];
        // console.log(highLevelConfig, highLevelConfig2);
        chargingStations.forEach(station => {
          for (let module of station.modules) {
            if (module.smartDevice && module.smartDevice.carCharger && module.smartDevice.carCharger.channelIndices) {
              const channelIndices = module.smartDevice.carCharger.channelIndices;
              const load = combinedLoads.find(
                x =>
                  x.serviceLocationId === station.serviceLocation?.id &&
                  x.actuals.some(a => channelIndices.includes(a.publishIndex))
              );
              if (load) {
                load.chargingStation = new ChargingStation(station);
              }
            }
          }
        });
        combinedLoads.forEach(load => {
          if (load.type === LoadType.Grid) {
            const loadServiceLocationId = load.serviceLocationId ?? undefined;
            if (loadServiceLocationId) {
              const childRecord = overloadProtection?.childs?.find(x => x.serviceLocationId === loadServiceLocationId);
              load.overloadEnabled = true;
              load.overloadMaxAmpere = childRecord ? childRecord.maximumLoad : overloadProtection.maximumLoad;
            }
          }
          if (load.type === LoadType.VirtualGrid) {
            load.overloadEnabled = true;
            load.overloadMaxAmpere = overloadProtection.maximumLoad;
          }
        });
        return {overloadProtection, loads: combinedLoads, childs};
      });
    },
    [fetch, locationId],
    T('phasorDisplay.loading.configuration'),
    undefined
  );

  const me = useUser();
  const readOnly = isReadOnly(me, location);

  const currentLoads = data?.loads || None;
  const collapsed = settings.collapsed;
  const [loads, setLoads] = useState<ILoad[]>(None);
  useEffect(() => setLoads(currentLoads), [currentLoads]);

  const tree = useMemo(() => {
    const handleCurrentChanged = (load: ILoad, current: number | null) => {
      setLoads(
        loads.map(l => (l.id === load.id ? {...load, overloadMaxAmpere: current == null ? undefined : current} : l))
      );
    };

    const handleClickedAddToLoad = async (load: ILoad) => {
      const selection = await modals.show<Set<number> | false>(props => (
        <AddLoadsModal
          target={load}
          parent={location}
          childs={data?.childs || None}
          loads={loads}
          all={false}
          {...props}
        />
      ));

      if (selection === false) return;

      setLoads(updateLoads(load, selection, currentLoads, loads));
    };

    const handleClickedRemove = (load: ILoad) => {
      setLoads(
        loads.map(x => {
          if (x.id !== load.id && !isAncestor(loads, load, x)) {
            return x;
          } else {
            return {...x, overloadMaxAmpere: undefined, overloadEnabled: false, parentId: undefined};
          }
        })
      );
    };

    return buildTree(loads, collapsed, handleCurrentChanged, handleClickedAddToLoad, handleClickedRemove);
  }, [loads, modals, location, currentLoads, data?.childs, collapsed]);

  const hasChanges = useMemo(() => {
    return currentLoads.some(currentLoad => {
      const updatedLoad = loads.find(x => x.id === currentLoad.id);
      if (updatedLoad === undefined) return true;

      return (
        updatedLoad.parentId !== currentLoad.parentId ||
        updatedLoad.overloadMaxAmpere !== currentLoad.overloadMaxAmpere ||
        updatedLoad.overloadEnabled !== currentLoad.overloadEnabled
      );
    });
  }, [currentLoads, loads]);

  const handleClickedAdd = async () => {
    const grid = currentLoads.find((load: {type: LoadType}) => load.type === LoadType.Grid);
    if (grid === undefined) return;
    const selection = await modals.show<Set<number> | false>(props => (
      <AddLoadsModal target={grid} loads={loads} childs={data?.childs || None} all={true} {...props} />
    ));
    if (selection === false) return;

    setLoads(updateLoads(grid, selection, currentLoads, loads));
  };

  const handleChange = (items: LoadTreeItem[]) => {
    if (items.length !== 1) return; // multiple roots not allowed
    for (let item of items) {
      if (item.load.parentId !== undefined) {
        const parent = items.find(x => x.load.id === item.load.parentId);
        if (parent && !isValidParent(parent.load, item.load)) {
          return;
        }
      }
    }

    setLoads(decomposeTree(items as LoadTreeItem[], loads));
  };

  const handleClickedSave = () => {
    if (locationId === undefined) return;

    const updates: Promise<unknown>[] = [];
    for (let currentLoad of currentLoads) {
      const updatedLoad = loads.find(x => x.id === currentLoad.id);
      if (updatedLoad === undefined) {
        updates.push(
          api.updateHighLevelMeasurement(currentLoad.serviceLocationId || locationId, currentLoad.id, {
            overloadMaxAmpere: undefined,
            overloadEnabled: false,
            parentId: null
          })
        );
        continue;
      }

      if (
        updatedLoad.parentId === currentLoad.parentId &&
        updatedLoad.overloadMaxAmpere === currentLoad.overloadMaxAmpere &&
        updatedLoad.overloadEnabled === currentLoad.overloadEnabled
      ) {
        continue;
      }

      if (updatedLoad.type === LoadType.Grid || updatedLoad.type === LoadType.VirtualGrid) {
        updates.push(
          api.updateOverloadProtection(updatedLoad.serviceLocationId || locationId, {
            active: updatedLoad.overloadMaxAmpere !== undefined,
            maximumLoad: updatedLoad.overloadMaxAmpere
          })
        );
      } else {
        updates.push(
          api.updateHighLevelMeasurement(updatedLoad.serviceLocationId || locationId, updatedLoad.id, {
            overloadMaxAmpere: updatedLoad.overloadMaxAmpere,
            overloadEnabled: updatedLoad.overloadMaxAmpere !== undefined || updatedLoad.parentId !== undefined,
            parentId: updatedLoad.parentId || null
          })
        );
      }
    }

    Promise.all(updates)
      .then(() => {
        NotificationManager.success(T('overloadProtection.save.success'));
        refresh();
      })
      .catch(e => reportError(e, T('overloadProtection.save.failed')));
  };

  let error: JSX.Element | string | undefined;

  const actions: CustomActions = () => (
    <CardActions>
      {isFeatureAvailable && <Reload onReload={refresh} />}
      {/* <Spring /> */}
      {!readOnly && isFeatureAvailable && (
        <>
          <Button
            variant="primary_default"
            title={T('overloadProtection.save')}
            onClick={handleClickedSave}
            size="lg"
            disabled={!hasChanges}
          >
            {T('overloadProtection.save')}
          </Button>
          <Button
            variant="secondary_default"
            title={T('overloadProtection.addLoad')}
            onClick={handleClickedAdd}
            size="lg"
          >
            {T('overloadProtection.addLoad')}
          </Button>
        </>
      )}
    </CardActions>
  );

  if (location?.parentId) {
    const onClickedParent = () => {
      if (location.parentId !== undefined) {
        setContextLocationId(store, api, location.parentId);
      }
    };
    error = (
      <>
        {T('overloadProtection.error.notForChildLocations')}
        <br />
        {location.parentId !== undefined && (
          <RsButton
            color="link"
            withoutPadding
            onClick={onClickedParent}
            style={{fontSize: 'inherit', position: 'relative', bottom: 3}}
          >
            {parent?.name || T('overloadProtection.error.notForChildLocations.goToParent')}
          </RsButton>
        )}
      </>
    );
  } else if (tree.length === 0) {
    error = T('overloadProtection.error.noGrid');
  } else if (data && !data.overloadProtection.active) {
    error = T('overloadProtection.error.disabled');
  }

  const onToggleVisibility = (node: LoadTreeItem) => {
    const wasCollapsed = collapsed.includes(node.load.id);
    updateSettings({
      collapsed: wasCollapsed ? collapsed.filter(x => x !== node.load.id) : [...collapsed, node.load.id]
    });
  };

  if (!isFeatureAvailable) {
    error = T('overloadProtection.error.noOverloadProtection');
  }

  return (
    <CardView error={error} actions={actions} {...cardViewProps(props)}>
      {tree.length > 0 && (
        <ReactSortableTree
          treeData={tree}
          onChange={items => handleChange(items as LoadTreeItem[])}
          onVisibilityToggle={item => onToggleVisibility(item.node as LoadTreeItem)}
          getNodeKey={item => (item.node as LoadTreeItem).load.id.toString()}
          canDrag={item => !readOnly && isDraggable((item.node as LoadTreeItem).load)}
          canDrop={canDrop => canDrop.nextParent !== undefined && isValidParent(canDrop.nextParent.load, canDrop.node)}
          canNodeHaveChildren={item => (item ? (item as LoadTreeItem).load.chargingStation === undefined : false)}
        />
      )}
      <Spring />
      <p style={{marginBottom: 0, textAlign: 'right', fontStyle: 'italic'}}>{T('overloadProtection.hint')}</p>
    </CardView>
  );
}

function isAncestor(loads: ILoad[], ancestor: ILoad, child: ILoad): boolean {
  if (child.parentId === undefined) {
    return false;
  } else if (child.parentId === ancestor.id) {
    return true;
  }

  const parent = loads.find(load => load.id === child.parentId);
  if (parent === undefined) {
    return false;
  } else {
    return isAncestor(loads, ancestor, parent);
  }
}

function removeDisconnectedChilds(loads: ILoad[]) {
  const connectedLoadIds = new Set<number>();
  for (let load of loads) {
    if (load.type === LoadType.Grid) {
      connectedLoadIds.add(load.id);
    }
  }

  let changed: boolean;
  do {
    changed = false;

    for (let load of loads) {
      if (load.parentId && connectedLoadIds.has(load.parentId) && !connectedLoadIds.has(load.id)) {
        connectedLoadIds.add(load.id);
        changed = true;
      }
    }
  } while (changed);

  for (let load of loads) {
    if (!connectedLoadIds.has(load.id)) {
      load.overloadEnabled = false;
      load.overloadMaxAmpere = undefined;
      load.parentId = undefined;
    }
  }
}

const DEFAULT_SETTINGS: OverloadProtectionSettings = {
  collapsed: None
};
const CARD: ICardType<OverloadProtectionSettings> = {
  type: CardTypeKey.OverloadProtection,
  title: 'overloadProtection.title',
  description: 'overloadProtection.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 3,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: OverloadProtectionCard
};
export default CARD;
