import React from 'react';

import {Subscript} from '../../components/Styling';
import Table, {IPersistedTableSettings} from '../../components/Table';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {ILoad, getLoadTypeLabel, getLoadName} from '../../models/Load';
import {ILocationSummary, isBigConsumer} from '../../models/Location';
import {PHASES} from '../../models/Phase';
import {
  CalculatedStringField,
  ITableField,
  NumberField,
  CalculatedNumberField,
  ITableCellProps,
  CalculatedIntegerField
} from '../../models/Table';

import {getPowerFactor} from '../../utils/Calculations';

import {T} from '../../utils/Internationalization';

import {ILiveHighLevelData} from './Data';

import {LoadNameField} from './LoadNameField';

interface InfinityHighLevelProps {
  location: ILocationSummary;
  loads: ILoad[];
  items: ILiveHighLevelData[];
  tableSettings: IPersistedTableSettings;
  phaseType: PhaseType;
  onUpdatedTableSettings: (settings: IPersistedTableSettings) => void;
}
class InfinityHighLevel extends React.Component<InfinityHighLevelProps, {}> {
  static getTableColumns(
    loads: ILoad[],
    phaseType: PhaseType,
    location: ILocationSummary
  ): ITableField<ILiveHighLevelData>[] {
    const bigConsumer = isBigConsumer(location);
    const powerMultiplier = bigConsumer ? 0.001 : 1;
    const columns: ITableField<ILiveHighLevelData>[] = [
      new CalculatedIntegerField('index', '#', row => row.index + 1, {
        alwaysVisible: true,
        autoInsert: 'start'
      }),
      new CalculatedStringField('type', T('liveElectricityValues.table.loadType'), row =>
        getLoadTypeLabel(row.load.type)
      ),
      new LoadNameField('name', T('liveElectricityValues.table.load'), row => getLoadName(row.load), {width: 250}),
      new NumberField('numberOfPhasesUsed', T('liveElectricityValues.table.numberOfPhases'), {unit: '#'}),
      new CalculatedNumberField(
        'active',
        'P',
        row => (row.active === undefined ? undefined : row.active * powerMultiplier),
        {
          title: T('liveElectricityValues.table.activePower'),
          unit: bigConsumer ? 'kW' : 'W',
          digitsAfterComma: bigConsumer ? 2 : 0
        }
      ),
      new CalculatedNumberField(
        'reactive',
        'Q',
        row => (row.reactive === undefined ? undefined : row.reactive * powerMultiplier),
        {
          title: T('liveElectricityValues.table.reactivePower'),
          unit: bigConsumer ? 'kvar' : 'var',
          digitsAfterComma: bigConsumer ? 2 : 0
        }
      ),
      new CalculatedNumberField('powerFactor', 'PF', row => getPowerFactor(row.active, row.reactive), {
        title: T('liveElectricityValues.table.powerFactor'),
        digitsAfterComma: 2
      }),
      new CalculatedNumberField(
        'apparent',
        'S',
        row => {
          if (row.active === undefined || row.reactive === undefined) {
            return undefined;
          }

          return Math.sqrt(row.active * row.active + row.reactive * row.reactive) * powerMultiplier;
        },
        {
          title: T('liveElectricityValues.table.apparentPower'),
          unit: bigConsumer ? 'kVA' : 'VA',
          digitsAfterComma: 0
        }
      )
    ];

    if (loads.length > 0) {
      columns.push(
        new CalculatedNumberField(
          'current',
          'I',
          row => {
            const {currents} = row;
            let total = 0;
            let count = 0;

            for (let phase of PHASES) {
              if (currents[phase]) {
                total += currents[phase];
                count++;
              }
            }
            return count > 0 ? total / count : undefined;
          },
          {
            title: T('liveElectricityValues.table.current'),
            digitsAfterComma: 2
          }
        )
      );

      columns.push(
        new CalculatedNumberField(
          'phase_voltage',
          'ULN',
          row => {
            const {phaseVoltages} = row;
            let total = 0;
            let count = 0;

            for (let phase of PHASES) {
              if (phaseVoltages[phase]) {
                total += phaseVoltages[phase];
                count++;
              }
            }
            return count > 0 ? total / count : undefined;
          },
          {
            header: (props: ITableCellProps) => (
              <th {...props}>
                U<Subscript>LN</Subscript>
              </th>
            ),
            title: (
              <span>
                U<Subscript>LN</Subscript> - {T('liveElectricityValues.table.phaseVoltage')}
              </span>
            ),
            unit: 'V',
            digitsAfterComma: 1
          }
        )
      );

      if (phaseType !== PhaseType.Single) {
        columns.push(
          new CalculatedNumberField(
            'line_voltage',
            'ULL',
            row => {
              const {lineVoltages} = row;
              let total = 0;
              let count = 0;
              for (let phase of PHASES) {
                if (lineVoltages[phase]) {
                  total += lineVoltages[phase];
                  count++;
                }
              }
              return count > 0 ? total / count : undefined;
            },
            {
              header: (props: ITableCellProps) => (
                <th {...props}>
                  U<Subscript>LL</Subscript>
                </th>
              ),
              title: (
                <span>
                  U<Subscript>LL</Subscript> - {T('liveElectricityValues.table.lineVoltage')}
                </span>
              ),
              unit: 'V',
              digitsAfterComma: 1
            }
          )
        );
      }
    }

    return columns;
  }

  rowKey = (row: ILiveHighLevelData) => row.load.id;
  render() {
    const {loads, items, location, phaseType, tableSettings, onUpdatedTableSettings} = this.props;

    return (
      <Table
        fields={InfinityHighLevel.getTableColumns(loads, phaseType, location)}
        items={items}
        rowKey={this.rowKey}
        hasPaging={true}
        noun="load"
        settings={tableSettings}
        updateSettings={onUpdatedTableSettings}
      />
    );
  }
}

export default InfinityHighLevel;
