import {MarkerClusterer, MarkerProps} from '@react-google-maps/api';
import dayjs from 'dayjs';
import * as React from 'react';

import {IMapProps, Map} from '../../components/Map';

import {ChargingHubSummary} from './ChargingHub';
import {ChargingHubInfoWindow} from './ChargingHubInfoWindow';
import {ChargingHubMarker} from './ChargingHubMarker';

interface ChargingHubMapProps extends IMapProps {
  chargingHubs: ChargingHubSummary[];
  loadedAt: dayjs.Dayjs;
  onClickedHub: (hub: ChargingHubSummary) => void;
  onClickLocation: (locationId: number) => void;
}

const MapStyle: google.maps.MapTypeStyle[] = [
  {
    featureType: 'poi',
    stylers: [{visibility: 'off'}]
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{visibility: 'off'}]
  }
];

function clusterCalculator(markers: google.maps.Marker[], clusterIconStylesCount: number) {
  const total = markers.reduce(
    (total, marker) => {
      const count = parseInt(marker.getLabel()?.text || '0');
      total.count += count;
      const icon = marker.getIcon();
      const iconUrl = typeof icon === 'object' ? (icon as google.maps.Icon).url : '';
      let style = 0;
      if (iconUrl.endsWith('-available.png')) {
        style = 3;
      } else if (iconUrl.endsWith('-error.png')) {
        style = 1;
      } else if (iconUrl.endsWith('-charging.png')) {
        style = 2;
      } else if (iconUrl.endsWith('-unavailable.png')) {
        style = 3;
      }
      total.style = Math.min(total.style, style);
      return total;
    },
    {count: 0, style: 3}
  );
  const large = total.count > 99;
  return {
    text: total.count.toString(),
    index: total.style + (large ? 4 : 0),
    title: ''
  };
}

const clusterIconStyles: ClusterIconStyle[] = [
  {
    url: '/assets/markers/cluster-error.png',
    width: 32,
    height: 32,
    textColor: '#ffffff',
    textSize: 14,
    fontWeight: 'normal',
    fontFamily: '"InterVariable", sans-serif',
    anchorText: [-1, 0]
  },
  {
    url: '/assets/markers/cluster-charging.png',
    width: 32,
    height: 32,
    textColor: '#ffffff',
    textSize: 14,
    fontWeight: 'normal',
    fontFamily: '"InterVariable", sans-serif',
    anchorText: [-1, 0]
  },
  {
    url: '/assets/markers/cluster-available.png',
    width: 32,
    height: 32,
    textColor: '#ffffff',
    textSize: 14,
    fontWeight: 'normal',
    fontFamily: '"InterVariable", sans-serif',
    anchorText: [-1, 0]
  },
  {
    url: '/assets/markers/cluster-unavailable.png',
    width: 32,
    height: 32,
    textColor: '#ffffff',
    textSize: 14,
    fontWeight: 'normal',
    fontFamily: '"InterVariable", sans-serif',
    anchorText: [-1, 0]
  },
  {
    url: '/assets/markers/cluster-red-large.png',
    width: 41,
    height: 41,
    textColor: '#ffffff',
    textSize: 14,
    fontWeight: 'normal',
    fontFamily: '"InterVariable", sans-serif'
  },
  {
    url: '/assets/markers/cluster-green-large.png',
    width: 41,
    height: 41,
    textColor: '#ffffff',
    textSize: 14,
    fontWeight: 'normal',
    fontFamily: '"InterVariable", sans-serif'
  },
  {
    url: '/assets/markers/cluster-yellow-large.png',
    width: 41,
    height: 41,
    textColor: '#000000',
    textSize: 14,
    fontWeight: 'normal',
    fontFamily: '"InterVariable", sans-serif'
  },
  {
    url: '/assets/markers/cluster-gray-large.png',
    width: 41,
    height: 41,
    textColor: '#ffffff',
    textSize: 14,
    fontWeight: 'normal',
    fontFamily: '"InterVariable", sans-serif'
  }
];

export const ChargingHubMap = (props: ChargingHubMapProps) => {
  const {chargingHubs, loadedAt, onClickedHub, onClickLocation} = props;

  const [selectedHub, setSelectedHub] = React.useState<ChargingHubSummary>();

  const handleClickedHub = (hub: ChargingHubSummary) => {
    setSelectedHub(hub);
    onClickedHub(hub);
  };

  const handleMarkerClustererClick = () => {
    setSelectedHub(undefined);
  };

  return (
    <Map
      {...props}
      options={{
        streetViewControl: true,
        fullscreenControl: true,
        styles: MapStyle
      }}
    >
      <MarkerClusterer
        onClick={handleMarkerClustererClick}
        gridSize={20}
        calculator={clusterCalculator}
        styles={clusterIconStyles as any}
      >
        {clusterer => {
          return (
            <>
              {chargingHubs.map(hub => (
                <ChargingHubMarker
                  key={hub.location.id}
                  hub={hub}
                  loadedAt={loadedAt}
                  onClick={() => handleClickedHub(hub)}
                  isCurrent={false}
                  clusterer={clusterer}
                />
              ))}
              {selectedHub && (
                <ChargingHubInfoWindow
                  hub={selectedHub}
                  loadedAt={loadedAt}
                  onClickLocation={onClickLocation}
                  onClose={() => setSelectedHub(undefined)}
                />
              )}
            </>
          );
        }}
      </MarkerClusterer>
    </Map>
  );
};
