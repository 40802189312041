import 'react-image-crop/dist/ReactCrop.css';
import cn from 'classnames';

import {useCallback, useRef, useState} from 'react';

import ReactCrop, {PercentCrop, PixelCrop, centerCrop, convertToPixelCrop, makeAspectCrop} from 'react-image-crop';
import Resizer from 'react-image-file-resizer';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

import {IPromiseModalProps, usePromiseModal} from '../../../../modals/PromiseModal';
import {T} from '../../../../utils/Internationalization';

import styles from './ImageCropModal.module.scss';
import setCanvasPreview from './utils/setCanvasPreview';

interface ImageCropModalProps extends IPromiseModalProps<void> {
  image?: HTMLImageElement;
  fileName: string;
  cropAndResize: (dataUrl: string, fileName: string) => void;
}

const WIDTH_DIMENSION = 320;
const HEIGHT_DIMENSION = 240;
const ASPECT_RATIO = 4 / 3;

const ImageCropModal = (props: ImageCropModalProps) => {
  const {image, fileName, cropAndResize} = props;
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = useCallback(() => resolve(), [resolve]);
  const [crop, setCrop] = useState<PercentCrop>();

  const onImageLoad = (e: {
    currentTarget: {width: number; height: number; naturalWidth?: number; naturalHeight?: number};
  }) => {
    const {width, height} = e.currentTarget;
    const cropWidthInPercent = (WIDTH_DIMENSION / width) * 100;
    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalHeader toggle={handleClose}>{T('chargingStationConfiguration.manageDisplayImages.cropImage')}</ModalHeader>
      <ModalBody>
        {image && (
          <div className={cn(styles.flex, styles.flexRow, styles.itemsStart, styles.justifyBetween)}>
            <ReactCrop
              crop={crop}
              circularCrop={false}
              keepSelection
              aspect={ASPECT_RATIO}
              minWidth={WIDTH_DIMENSION}
              minHeight={HEIGHT_DIMENSION}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            >
              <img
                ref={imgRef}
                src={image.src}
                alt="Upload"
                style={{maxHeight: '80vh', maxWidth: '960px'}}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            <button
              className={styles.cropButton}
              onClick={() => {
                setCanvasPreview(
                  imgRef.current!,
                  previewCanvasRef.current!,
                  convertToPixelCrop(crop!, imgRef.current!.width, imgRef.current!.height)
                );
                const dataUrl = previewCanvasRef.current?.toDataURL();
                if (dataUrl) {
                  cropAndResize(dataUrl, fileName);
                }
                handleClose();
              }}
            >
              {T('chargingStationConfiguration.manageDisplayImages.cropImage')}
            </button>
          </div>
        )}
        {crop && (
          <canvas
            ref={previewCanvasRef}
            className={styles.canvasPreview}
            style={{display: 'none', border: '1px solid black', objectFit: 'contain', width: 320, height: 240}}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ImageCropModal;
