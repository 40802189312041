import {useEffect, useState} from 'react';

import {useAppContext} from '../../app/context';
import {Alert, SingleActionModal} from '../../components/bootstrap';
import LocationInputGroup from '../../components/inputs/LocationInputGroup';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ILocation, ILocationSearchResult, isChargingParent} from '../../models/Location';
import {translateError} from '../../utils/Errors';
import {useLocationUsingAPI} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';

interface MoveStationModalProps extends IPromiseModalProps<boolean> {
  parentId: number | undefined;
  location: ILocation;
  chargingStation: boolean;
  filter?: (location: ILocationSearchResult) => boolean;
}

export default function MoveStationModal(props: MoveStationModalProps) {
  const {parentId, location, filter, chargingStation} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleToggle = () => resolve(false);
  const {api} = useAppContext();
  const [loadedLocation] = useLocationUsingAPI(api, parentId);
  const [parentLocation, setParentLocation] = useState<ILocation | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => setParentLocation(loadedLocation), [loadedLocation]);

  const handleLocationSelected = async (id: number) => {
    const fetchedLocation = await api.locations.get(id);
    setParentLocation(fetchedLocation);
  };

  const handleClickedSave = () => {
    if (!parentLocation) return Promise.resolve();

    return api
      .updateLocation(location?.id, {
        parentId: parentLocation?.id
      })
      .then(() => {
        if (parentId) {
          api.chargingStations.invalidateForLocation(parentId);
        }
        resolve(true);
      })
      .catch(error => setErrorMessage(translateError(error)));
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleToggle}
      title={
        chargingStation ? T('chargingStationConfiguration.setParent.title') : T('locationConfiguration.setParent.title')
      }
      action={handleClickedSave}
      actionText={T('chargingStationConfiguration.setParent.save')}
      error={errorMessage}
    >
      {location && isChargingParent(location.functionType) && (
        <Alert color="warning">
          <p>{T('locationConfiguration.property.parent.promote.message')}</p>
          {location.serialNumber ? <p>{T('locationConfiguration.property.parent.promote.message.connect')}</p> : null}
        </Alert>
      )}
      <LocationInputGroup
        label={T('chargingStationConfiguration.setParent.label')}
        location={parentLocation}
        onSelected={handleLocationSelected}
        filter={filter}
      />
    </SingleActionModal>
  );
}
