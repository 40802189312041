import {useCallback} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../../app/context';
import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import {useModals} from '../../../modals/ModalContext';
import {DynamicTariff, TariffType} from '../../../models/Tariff';
import {None} from '../../../utils/Arrays';
import {translateError} from '../../../utils/Errors';
import {T} from '../../../utils/Internationalization';

import {EditTariffModal, TariffSettings} from './TariffModal';

interface TariffFieldProps {
  readOnly: boolean;
  locationId: number;
  type: TariffType;
  dynamic?: DynamicTariff;
  onChanged: () => void;
}

export function TariffField(props: TariffFieldProps) {
  const {readOnly, locationId, type, dynamic, onChanged} = props;
  const modals = useModals();
  const {api} = useAppContext();

  const handleClickedEdit = useCallback(async () => {
    const settings: TariffSettings = type === TariffType.Dynamic ? {type, dynamic: dynamic!} : {type, flat: None};

    const saveSettings = (settings: TariffSettings | undefined) => {
      if (!settings) return Promise.resolve(undefined);

      console.log(`Updating settings from ${type} to ${settings.type}`, settings);
      let result: Promise<unknown>;
      if (settings.type === TariffType.Dynamic) {
        if (type === TariffType.Dynamic) {
          result = api.tariffs.updateDynamicTariff(locationId, settings.dynamic);
        } else {
          result = api.tariffs.setDynamicTariff(locationId, settings.dynamic);
        }
      } else if (settings.type === TariffType.Flat && type === TariffType.Dynamic) {
        result = api.tariffs.switchTariff(locationId);
      } else {
        result = Promise.resolve();
      }

      return result
        .then(() => undefined)
        .catch(err => translateError(err, T('locationConfiguration.tariff.updateFailed')));
    };

    modals
      .show<TariffSettings | undefined>(props => <EditTariffModal settings={settings} save={saveSettings} {...props} />)
      .then(updatedSettings => {
        if (updatedSettings !== undefined) {
          NotificationManager.success(T('locationConfiguration.tariff.updated'));
          onChanged();
        }
      });
  }, [type, dynamic, modals, api.tariffs, locationId, onChanged]);

  return (
    <>
      {type === TariffType.Dynamic ? T('locationConfiguration.tariff.dynamic') : T('locationConfiguration.tariff.flat')}{' '}
      {!readOnly && (
        <Button variant="ghost_action_btn" size="icon" onClick={handleClickedEdit}>
          <Edit />
        </Button>
      )}
    </>
  );
}
