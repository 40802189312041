import React from 'react';

import {Button as RsButton} from '../../../components/bootstrap';

import {TimezoneInput} from '../../../components/TimezoneInput';
import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';
import API from '../../../core/api';
import {ITimezone} from '../../../models/Timezone';
import {T} from '../../../utils/Internationalization';

import EditIcon from './EditIcon';

interface TimezoneEditorProps {
  api: API;
  value: string;
  onSelected: (value: ITimezone) => Promise<any>;
  readOnly: boolean;
}
interface TimezoneEditorState {
  editing: boolean;
  editingValue: string;
  loading: boolean;
  selectedValue: ITimezone | undefined;
}
class TimezoneEditor extends React.Component<TimezoneEditorProps, TimezoneEditorState> {
  fetching: Promise<any> | null;

  constructor(props: TimezoneEditorProps) {
    super(props);

    this.state = {
      editing: false,
      editingValue: props.value,
      loading: false,
      selectedValue: undefined
    };

    this.fetching = null;
  }

  componentDidUpdate(oldProps: TimezoneEditorProps) {
    if (oldProps.value !== this.props.value) {
      this.setState({editingValue: this.props.value, selectedValue: undefined});
    }
  }

  handleClickedEdit = () => {
    this.setState({editing: true});
  };

  handleClickedCancel = () => {
    this.setState({
      editing: false,
      editingValue: this.props.value,
      selectedValue: undefined
    });
  };

  handleClickedSave = () => {
    const {onSelected} = this.props;
    const {selectedValue} = this.state;
    if (selectedValue === undefined) return;

    this.setState({loading: true});
    onSelected(selectedValue)
      .then(() => this.setState({loading: false, selectedValue: undefined}))
      .catch(() => this.setState({loading: false}));
  };

  handleTimezoneSelected = (timezone: ITimezone) => {
    this.setState({
      selectedValue: timezone,
      editingValue: timezone.name,
      editing: false
    });
  };

  render() {
    const {api, value, readOnly} = this.props;
    const {editing, loading, editingValue, selectedValue} = this.state;

    if (editing) {
      return (
        <div style={{display: 'flex'}}>
          <TimezoneInput api={api} value={editingValue} onSelected={this.handleTimezoneSelected} autoFocus={true} />
          <RsButton
            color="primary"
            onClick={this.handleClickedSave}
            disabled={selectedValue === undefined}
            style={{marginLeft: '0.3em'}}
          >
            {T('locationConfiguration.field.save')}
            {loading && <i style={{marginLeft: '0.3em'}} className="fas fa-circle-notch fa-spin" />}
          </RsButton>
          <RsButton onClick={this.handleClickedCancel} style={{marginLeft: '0.3em'}}>
            {T('locationConfiguration.field.cancelEdit')}
          </RsButton>
        </div>
      );
    } else {
      return (
        <span>
          {value}
          &nbsp;
          {!readOnly && (
            <Button variant="ghost_action_btn" size="icon" onClick={this.handleClickedEdit}>
              <Edit />
            </Button>
          )}
        </span>
      );
    }
  }
}

export default TimezoneEditor;
