import {APIClient} from './APIClient';

export class ServiceUtilsAPI {
  private client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  checkIBANUsed(iban: string) {
    return this.client.doPost<IBANUsage>('/api/v10/serviceutils/checkiban', {
      iban
    });
  }
}

export interface IBANUsage {
  iban: string;
  odooId?: number;
  odooName?: string;
  user?: {
    id: number;
    username: string;
    emailAddress: string;
  };
  organization?: {
    id: number;
    name: string;
  };
}
