import React from 'react';
import {NavbarBrand as RsNavbarBrand, NavbarBrandProps} from 'reactstrap';

import './NavbarBrand.scss';

import SmappeeLogoLarge from '../../logo/smappee-logo-large';
import {useAppSelector} from '../../utils/Hooks';
import {classes} from '../../utils/Styles';

export function NavbarBrand(props: NavbarBrandProps) {
  const {className, dispatch, ...otherProps} = props;
  const collapsed = useAppSelector(state => state.uiState.menuCollapsed);

  // Hook extra classes into the component
  const fullClassName = classes('dash-navbar-brand', collapsed ? 'collapsed' : '', className);

  // cheated here -> not clear why TypeScript is complaining here
  /*  const style = dashboardStyle.menuLogo ? {
    background: `url(${dashboardStyle.menuLogo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: dashboardStyle.menuLogoWidth
  } : undefined;*/
  return (
    <RsNavbarBrand href="/" className={fullClassName} {...(otherProps as any)}>
      Smappee
    </RsNavbarBrand>
  );
}
