import classnames from 'classnames';
import React, {useCallback, useMemo} from 'react';

import {useAppContext} from '../../app/context';
import {Button as RsButton, Input, RowActions} from '../../components/bootstrap';
import Table, {migrateTableSettings, SortOrder} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Checkbox} from '../../components/ui/checkbox';
import {Plus} from '../../components/ui-lib/icons/small';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import RemoveModal from '../../modals/RemoveModal';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ITableField} from '../../models/Table';
import {User, IUser, hasPartnerFunctionality} from '../../models/User';
import {useAutoRefresh, useDelayedEffect} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useUser} from '../CardUtils';
import {Reload} from '../components/actions';
import {CardActions, Spring} from '../components/CardActions';
import {CustomSettings, CardView, CustomActions} from '../components/CardView';
import ColumnChooser from '../components/ColumnChooser';

import {getTableColumns} from './Columns';
import EditUser from './EditUser';
import {EditUserContracts} from './EditUserContracts';
import {ResetPassword} from './ResetPassword';

import {useUsersState} from './State';
import {UserOrganizationsModal} from './UserOrganizationsModal';

const rowKey = (item: IUser) => item.id;

type IUsersSettings = ICardSettingsWithTable;

function UsersCard(props: ICardProps<IUsersSettings>) {
  const {settings, updateSettings} = props;

  const {api} = useAppContext();
  const me = useUser();
  const modals = useModals();
  const isServiceDesk = me.isServiceDesk();
  const [includeArchived, setIncludeArchived] = React.useState<boolean>(false);

  const [state, actor] = useUsersState();
  const {query, items: itemsState, notYetLoaded} = state;

  const items = React.useMemo(() => {
    return itemsState.filter(item => includeArchived || !item.archivedOn);
  }, [includeArchived, itemsState]);

  const refresh = useCallback(() => {
    if (isServiceDesk && query === '') {
      actor.reset();
      return;
    }

    actor.startLoading();
    api
      .findUsers(query)
      .then(items => actor.finishLoading(items))
      .catch(() => actor.errorLoading('users'));
  }, [api, actor, query, isServiceDesk]);
  useDelayedEffect(refresh, [refresh], 500);
  useAutoRefresh(refresh);

  const handleClickedCreate = () => {
    const user = User.empty();
    modals.show<User | undefined>(props => <EditUser user={user} {...props} />).then(() => refresh());
  };

  const tableColumns: ITableField<IUser>[] = useMemo(() => {
    const handleClickedEdit = async (userJson: IUser) => {
      const user = User.fromJSON(userJson);
      const newUser = await modals.show<User | undefined>(props => <EditUser user={user} {...props} />);

      if (newUser) {
        refresh();
        actor.update(newUser.toJSON());
      }
    };

    const handleResetPassword = (userJson: IUser) => {
      const user = User.fromJSON(userJson);
      modals.show<void>(props => <ResetPassword user={user} {...props} />);
    };

    const handleResetActivations = (user: IUser) => {
      return modals
        .show<ConfirmationResult>(props => (
          <ConfirmationPromiseModal
            title="Confirm app activation records reset"
            message={`Are you sure you want to remove all app activation records for ${user.userName || 'this user'} ?`}
            acceptLabel="Reset"
            rejectLabel="Cancel"
            {...props}
          />
        ))
        .then(confirmed => {
          if (confirmed === ConfirmationResult.Accept) {
            return api.clearAppActivationsForUser(user.id);
          }
        });
    };

    const handleClickedUserOrganizations = (user: IUser) => {
      modals.show<void>(props => <UserOrganizationsModal user={user} {...props} />);
    };

    const handleClickedRemove = (user: IUser) => {
      return modals
        .show<boolean>(props => {
          const label = user.userName || user.emailAddress || 'this user';
          return (
            <RemoveModal
              title={T('users.remove.title')}
              message={T('users.remove.message', {label})}
              execute={api =>
                api.removeUser(user.id).then((value?: {error?: string}) => {
                  if (value?.error) {
                    throw {
                      statusCode: 400,
                      success: false,
                      localizedError: value?.error
                    };
                  }
                })
              }
              successMessage={T('users.deleted')}
              failureMessage={T('users.remove.error')}
              {...props}
            />
          );
        })
        .then(success => success && refresh());
      //return modals.show<void>(props => <RemoveUser user={user} {...props} />).then(() => refresh());
    };

    const handleClickedUnarchive = (user: IUser) => {
      return modals
        .show<boolean>(props => {
          const label = user.userName || user.emailAddress || 'this user';
          return (
            <RemoveModal
              buttonLabel={T('users.restore.action')}
              title={T('users.restore.title')}
              message={T('users.restore.message')}
              execute={api =>
                api.restoreUser(user.id).then((value?: {error?: string}) => {
                  if (value?.error) {
                    throw {
                      statusCode: 400,
                      success: false,
                      localizedError: value?.error
                    };
                  }
                })
              }
              successMessage={T('users.restore.successMessage')}
              failureMessage={T('users.restore.failureMessage')}
              {...props}
            />
          );
        })
        .then(success => success && refresh());
      //return modals.show<void>(props => <RemoveUser user={user} {...props} />).then(() => refresh());
    };

    const handleClickedContracts = (user: IUser) => {
      return modals.show<void>(props => <EditUserContracts user={user} {...props} />);
    };

    return getTableColumns(me, {
      onClickedEdit: handleClickedEdit,
      onClickedResetPassword: handleResetPassword,
      onClickedResetActivations: handleResetActivations,
      onClickedUserOrganizations: handleClickedUserOrganizations,
      onClickedContracts: handleClickedContracts,
      onClickedRemove: handleClickedRemove,
      onClickedUnarchive: handleClickedUnarchive
    });
  }, [modals, refresh, api, me, actor]);

  const customSettings: CustomSettings<IUsersSettings> = (editingSettings, updateEditingSettings) => {
    return (
      <ColumnChooser
        fields={tableColumns}
        settings={editingSettings.table}
        updateSettings={table => updateEditingSettings({table})}
      />
    );
  };

  const handleChangedSearch = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      actor.updateQuery(e.currentTarget.value);
    },
    [actor]
  );

  const canEdit = !me.isReadOnly();

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      <Input
        name="query"
        type="text"
        autoWidth={true}
        placeholder={T('users.search')}
        onChange={handleChangedSearch}
        value={query}
        className={classnames(testingClasses.searchUsers, '!tw-my-0 tw-leading-[22px] tw-py-2 tw-h-10 tw-max-h-10')}
        data-testid={testingClasses.searchUsers}
      />
      {isServiceDesk && (
        <Checkbox
          id="includeArchived"
          name="includeArchived"
          label={T('users.filter.includeArchived')}
          checked={includeArchived}
          onCheckedChange={setIncludeArchived}
          wrapperClassName="tw-mb-[0rem]"
          testId="includeArchived"
        />
      )}
      {/* <Spring /> */}
      {canEdit && (
        <Button
          variant="secondary_default"
          onClick={handleClickedCreate}
          title={T('users.newUser')}
          className={testingClasses.createUser}
          data-testid={testingClasses.createUser}
        >
          <span className="tw-mr-2">
            <Plus />
          </span>
          {T('users.newUser')}
        </Button>
      )}
    </CardActions>
  );

  const error = notYetLoaded ? T('userChargingSessions.error.notYetLoaded') : undefined;

  return (
    <CardView
      ready={!state.waiting}
      error={error}
      actions={actions}
      customSettings={customSettings}
      settings={settings}
      loading={props.loading}
      fetching={[...props.fetching, {name: 'users', loading: state.loading, error: state.error}]}
    >
      <Table
        fields={tableColumns}
        items={items}
        rowKey={rowKey}
        hasPaging={true}
        noun="user"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
}

const DEFAULT_SETTINGS: IUsersSettings = {
  table: {
    sortColumn: 'userName',
    sortOrder: SortOrder.ASCENDING,
    pageSize: 10,
    columns: [
      {name: 'firstName', visible: true},
      {name: 'emailAddress', visible: true},
      {name: 'role', visible: true}
    ]
  }
};
const CARD: ICardType<IUsersSettings> = {
  type: CardTypeKey.Users,
  title: 'users.title',
  description: 'users.description',
  categories: [CardCategory.USERS],
  rights: UserRights.User,
  isAvailable: hasPartnerFunctionality,
  width: 3,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: UsersCard
};
export default CARD;
