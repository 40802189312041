import {getCurrentLanguage} from '../utils/Internationalization';

import {UserType} from './User';

export interface IAuthUser {
  userId: number;
  userName: string;
  firstName: string;
  lastName?: string;
  emailAddress: string;
  emailAddressVerified?: boolean;
  refreshToken: string;
  token: string;
  userType: APIUserType | UserType;
  hasAdvancedLicense: boolean;
  language?: string;
  isServiceDesk: boolean;
  signup?: boolean;
  termsOfUseAccepted?: boolean;
}

export const enum APIUserType {
  User = '0',
  Helpdesk = '1',
  PartnerViewer = '2',
  PartnerAdmin = '3',
  ReadOnly = '4',
  PartnerAPI = '5',

  // used internally
  Anonymous = ''
}

export const enum UserRights {
  User = 1,
  Partner = 2,
  ServiceDesk = 4,
  PartnerAdmin = 8
}

const ROLES = {
  [UserType.Anonymous]: 0,
  [UserType.BasicAdmin]: UserRights.User,
  [UserType.BasicReadOnly]: UserRights.User,
  [UserType.PartnerViewer]: UserRights.User | UserRights.Partner,
  [UserType.PartnerAdmin]: UserRights.User | UserRights.Partner | UserRights.PartnerAdmin,
  [UserType.ServiceDesk]: UserRights.User | UserRights.ServiceDesk,
  [UserType.PartnerAPI]: 0, // no rights on the portal
  [UserType.PartnerReadOnly]: UserRights.User | UserRights.Partner,
  [UserType.M2MAPI]: 0 // no rights on the portal
};

export function getUserRole(type: APIUserType | UserType): UserType {
  switch (type) {
    // old format returned by API
    case APIUserType.Helpdesk:
      return UserType.ServiceDesk;
    case APIUserType.PartnerViewer:
      return UserType.PartnerViewer;
    case APIUserType.PartnerAdmin:
      return UserType.PartnerAdmin;
    case APIUserType.User:
      return UserType.BasicAdmin;
    case APIUserType.PartnerAPI:
      return UserType.PartnerAPI;
    case APIUserType.Anonymous:
      return UserType.Anonymous;
    case APIUserType.ReadOnly:
      return UserType.BasicReadOnly;
    default:
      return type;
  }
}

export class AuthUser {
  static anonymous() {
    return new AuthUser({
      userId: 0,
      userName: '',
      firstName: '',
      emailAddress: '',
      userType: APIUserType.Anonymous,
      refreshToken: '',
      token: '',
      hasAdvancedLicense: false,
      language: getCurrentLanguage(),
      isServiceDesk: false,
      // has signed up through SSO and is logged in for the first time
      signup: false,
      // We don't want a popup flickering on the screen if the user has already accepted the terms of use
      termsOfUseAccepted: true
    });
  }

  static get(user?: IAuthUser) {
    return user ? new AuthUser(user) : AuthUser.anonymous();
  }

  userId: number;
  refreshToken: string;
  token: string;
  username: string;

  role: UserType;
  hasLicense: boolean;
  firstName: string;
  lastName?: string;
  email: string;
  emailAddressVerified?: boolean;
  language: string;

  serviceDesk: boolean;

  signup?: boolean;
  termsOfUseAccepted?: boolean;

  constructor(user: IAuthUser) {
    this.userId = user.userId;
    this.username = user.userName;
    this.firstName = user.firstName;
    this.lastName = user.lastName || '';
    this.email = user.emailAddress;
    this.role = getUserRole(user.userType);
    this.language = user.language || getCurrentLanguage();
    this.emailAddressVerified = user.emailAddressVerified;

    this.refreshToken = user.refreshToken;
    this.token = user.token;
    this.hasLicense = user.hasAdvancedLicense;
    this.serviceDesk = user.isServiceDesk;
    this.signup = user.signup;
    this.termsOfUseAccepted = user.termsOfUseAccepted;
  }

  isLoggedIn() {
    return this.role !== UserType.Anonymous;
  }

  isServiceDesk() {
    return this.role === UserType.ServiceDesk;
  }

  isRegularUser() {
    return this.role === UserType.BasicAdmin || this.role === UserType.BasicReadOnly;
  }

  isPartnerUser() {
    return (
      this.role === UserType.PartnerAdmin ||
      this.role === UserType.PartnerViewer ||
      this.role === UserType.PartnerReadOnly
    );
  }

  isPartnerAdmin() {
    return this.role == UserType.PartnerAdmin;
  }

  isReadOnly() {
    return (
      this.role === UserType.PartnerViewer ||
      this.role === UserType.PartnerReadOnly ||
      this.role === UserType.BasicReadOnly
    );
  }

  hasAnyRight(right: UserRights) {
    return (ROLES[this.role] & right) > 0;
  }

  isAnonymous() {
    return this.role === UserType.Anonymous;
  }

  canLogin() {
    return this.role !== UserType.M2MAPI && this.role !== UserType.PartnerAPI;
  }

  serialize(): IAuthUser {
    return {
      userId: this.userId,
      userName: this.username,
      firstName: this.firstName,
      emailAddress: this.email,
      userType: this.role,
      refreshToken: this.refreshToken,
      token: this.token,
      hasAdvancedLicense: this.hasLicense,
      language: this.language,
      isServiceDesk: this.serviceDesk,
      termsOfUseAccepted: this.termsOfUseAccepted
    };
  }
}
