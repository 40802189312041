import {Button} from '../../../components/ui/button';
import {Edit} from '../../../components/ui-lib/icons/small';

interface SequenceNumberFieldProps {
  value: number;
  readOnly: boolean;
  onClickedEdit: () => void;
}

export function SequenceNumberField(props: SequenceNumberFieldProps) {
  const {value, readOnly, onClickedEdit} = props;
  console.log(props);
  return (
    <span>
      {value}{' '}
      {!readOnly && (
        <Button variant="ghost_action_btn" size="icon" onClick={onClickedEdit}>
          <Edit />
        </Button>
      )}
    </span>
  );
}
