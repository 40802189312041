import React, {useState, useEffect, useCallback, memo} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Row, Col, Alert} from '../../components/bootstrap';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {SelectInputGroup, useSelectInputGroup} from '../../components/inputs/SelectInput';
import {useTextInput} from '../../components/inputs/TextInput';
import {ScrollPane} from '../../components/ScrollPane';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {DeviceType, getSerialPrefix} from '../../models/DeviceType';
import {None} from '../../utils/Arrays';
import {FormProvider, useFormContext} from '../../utils/FormContext';
import {T} from '../../utils/Internationalization';
import {ICardType, CardTypeKey, CardCategory, CardLocationAwareness, ICardProps} from '../CardType';
import {CardView, cardViewProps} from '../components/CardView';

import {ModuleSerial, ModuleInput, postProcessModuleSerial} from './ModuleInput';

const enum Version {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
  DCV1 = 'DCV1',
  DCV2 = 'DCV2',
  DCV24 = 'DCV24'
}

function translateVersion(version: Version): string {
  switch (version) {
    case Version.V1:
      return 'V1';
    case Version.V2:
      return 'V2';
    case Version.V3:
      return 'V3';
    case Version.DCV1:
      return 'V1';
    case Version.DCV2:
      return 'V2';
    case Version.DCV24:
      return 'V2 PLC Rocket';
  }
}

const enum ModelFeature {
  LEM = 'L'
}

class StationModel {
  id: string;
  version: Version;
  articleNumber: string;
  name: string;
  features: ModelFeature[];

  constructor(version: Version, articleNumber: string, name: string, features?: ModelFeature[]) {
    this.id = `${articleNumber}/${version}`;
    if (features && features.length > 0) {
      this.id += `+${features.join('+')}`;
    }
    this.version = version;
    this.articleNumber = articleNumber;
    this.name = name;
    this.features = features || None;
  }

  isDC(): boolean {
    return this.version === Version.DCV1 || this.version === Version.DCV2 || this.version === Version.DCV24;
  }

  isACWithRFIDSeparate(): boolean {
    return this.version === Version.V1 || this.version === Version.V2;
  }

  isACWithRFIDTogether(): boolean {
    return this.version === Version.V3;
  }

  hasLEMMeter(): boolean {
    return this.features.includes(ModelFeature.LEM);
  }

  hasEichrechtMeter(): boolean {
    return (
      this.articleNumber.startsWith('EVBE') ||
      this.articleNumber.startsWith('EVUE') ||
      this.articleNumber.startsWith('EVOE')
    );
  }

  getNumberOfChargeControllers(): number {
    if (this.articleNumber.startsWith('EVB-2') || this.articleNumber.startsWith('EVB-AC2-')) {
      return 2;
    } else if (this.isDC() && this.articleNumber.includes('D-')) {
      return 2;
    } else {
      return 1;
    }
  }

  getChargeControllerDeviceType(): DeviceType {
    if (this.isDC()) {
      return DeviceType.DCChargingController;
    } else if (this.isACWithRFIDSeparate()) {
      return DeviceType.ACChargingController;
    } else if (this.isACWithRFIDTogether()) {
      return DeviceType.ACChargingControllerWithRFID;
    } else {
      throw new Error('Invalid model');
    }
  }
}

const models = [
  //  new StationModel(Version.V1, 'EVB-1332-C5R-E', 'EV Base 32A right 5m cable'),
  //  new StationModel(Version.V1, 'EVB-1332-C5L-E', 'EV Base 32A left 5m cable'),
  new StationModel(Version.V1, 'EVB-2332-C5-E', 'EV Base 32A 2 x 5m cable'),
  new StationModel(Version.V1, 'EVB-2332-B-E', 'EV Base 32A 2 x socket'),
  new StationModel(Version.V1, 'EVB-2332-BS-E', 'EV Base 32A 2 x socket with shutter'),
  new StationModel(Version.V1, 'EVWB-332-C8R-E-W', 'EV Wall Business 3 phase 32A white, right 8m cable'),
  new StationModel(Version.V1, 'EVWB-332-C8R-E-B', 'EV Wall Business 3 phase 32A black, right 8m cable'),
  new StationModel(Version.V1, 'EVWB-332-BR-E-W', 'EV Wall Business 3 phase 32A white, right socket'),
  new StationModel(Version.V1, 'EVWB-332-BR-E-B', 'EV Wall Business 3 phase 32A black, right socket'),
  new StationModel(Version.V1, 'EVWB-332-BSR-E-W', 'EV Wall Business 3 phase 32A white, right socket with shutter'),
  new StationModel(Version.V1, 'EVWB-332-BSR-E-B', 'EV Wall Business 3 phase 32A black, right socket with shutter'),
  new StationModel(Version.V1, 'EVW-132-C8R-E-W', 'EV Wall Home 1 phase 32A white, right 8m cable'),
  new StationModel(Version.V1, 'EVW-132-C8R-E-B', 'EV Wall Home 1 phase 32A black, right 8m cable'),
  new StationModel(Version.V1, 'EVW-332-C8R-E-W', 'EV Wall Home 3 phase 32A white, right 8m cable'),
  new StationModel(Version.V1, 'EVW-332-C8R-E-B', 'EV Wall Home 3 phase 32A black, right 8m cable'),
  new StationModel(Version.V1, 'EVW-132-BR-E-W', 'EV Wall Home 1 phase 32A white, right socket'),
  new StationModel(Version.V1, 'EVW-132-BR-E-B', 'EV Wall Home 1 phase 32A black, right socket'),
  new StationModel(Version.V1, 'EVW-132-BSR-E-W', 'EV Wall Home 1 phase 32A white, right socket with shutter'),
  new StationModel(Version.V1, 'EVW-132-BSR-E-B', 'EV Wall Home 1 phase 32A black, right socket with shutter'),
  new StationModel(Version.V1, 'EVW-332-BR-E-W', 'EV Wall Home 3 phase 32A white, right socket'),
  new StationModel(Version.V1, 'EVW-332-BR-E-B', 'EV Wall Home 3 phase 32A black, right socket'),
  new StationModel(Version.V1, 'EVW-332-BSR-E-W', 'EV Wall Home 3 phase 32A white, right socket with shutter'),
  new StationModel(Version.V1, 'EVW-332-BSR-E-B', 'EV Wall Home 3 phase 32A black, right socket with shutter'),
  new StationModel(Version.V1, 'EVWL-332-BR-E-W', 'EV Wall Lite 3 phase 32A white, right socket'),
  new StationModel(Version.V1, 'EVWL-332-BR-E-B', 'EV Wall Lite 3 phase 32A black, right socket'),
  new StationModel(Version.V1, 'EVWL-332-BSR-E-W', 'EV Wall Lite 3 phase 32A white, right socket with shutter'),
  new StationModel(Version.V1, 'EVWL-332-BSR-E-B', 'EV Wall Lite 3 phase 32A black, right socket with shutter'),

  new StationModel(Version.V2, 'EVB-2332-C5-E', 'EV Base 32A 2 x 5m cable'),
  new StationModel(Version.V2, 'EVB-2332-B-E', 'EV Base 32A 2 x socket'),
  new StationModel(Version.V2, 'EVB-2332-BS-E', 'EV Base 32A 2 x socket with shutter'),

  new StationModel(Version.V3, 'EVB-2332-C5-E', 'EV Base 32A 2 x 5m cable'),
  new StationModel(Version.V3, 'EVB-2332-B-E', 'EV Base 32A 2 x socket'),
  new StationModel(Version.V3, 'EVB-2332-BS-E', 'EV Base 32A 2 x socket with shutter'),
  new StationModel(Version.V3, 'EVBE-2332-B-E', 'EV Base Eichrecht 32A 2 x socket'),
  new StationModel(Version.V3, 'EVWB-332-C8R-E-W', 'EV Wall Business 3 phase 32A white, right 8m cable'),
  new StationModel(Version.V3, 'EVWB-332-C8R-E-B', 'EV Wall Business 3 phase 32A black, right 8m cable'),
  new StationModel(Version.V3, 'EVWB-332-BR-E-W', 'EV Wall Business 3 phase 32A white, right socket'),
  new StationModel(Version.V3, 'EVWB-332-BR-E-B', 'EV Wall Business 3 phase 32A black, right socket'),
  new StationModel(Version.V3, 'EVWB-332-BSR-E-W', 'EV Wall Business 3 phase 32A white, right socket with shutter'),
  new StationModel(Version.V3, 'EVWB-332-BSR-E-B', 'EV Wall Business 3 phase 32A black, right socket with shutter'),
  new StationModel(Version.V3, 'EVWE-332-C8R-E-W', 'EV Wall Business 3 phase 32A white, right 8m cable'),
  new StationModel(Version.V3, 'EVWE-332-BR-E-W', 'EV Wall Business 3 phase 32A white, right 8m cable'),
  new StationModel(Version.V3, 'EVW-132-C8R-E-W', 'EV Wall Home 1 phase 32A white, right 8m cable'),
  new StationModel(Version.V3, 'EVW-132-C8R-E-B', 'EV Wall Home 1 phase 32A black, right 8m cable'),
  new StationModel(Version.V3, 'EVW-332-C8R-E-W', 'EV Wall Home 3 phase 32A white, right 8m cable'),
  new StationModel(Version.V3, 'EVW-332-C8R-E-B', 'EV Wall Home 3 phase 32A black, right 8m cable'),
  new StationModel(Version.V3, 'EVW-132-BR-E-W', 'EV Wall Home 1 phase 32A white, right socket'),
  new StationModel(Version.V3, 'EVW-132-BR-E-B', 'EV Wall Home 1 phase 32A black, right socket'),
  new StationModel(Version.V3, 'EVW-132-BSR-E-W', 'EV Wall Home 1 phase 32A white, right socket with shutter'),
  new StationModel(Version.V3, 'EVW-132-BSR-E-B', 'EV Wall Home 1 phase 32A black, right socket with shutter'),
  new StationModel(Version.V3, 'EVW-332-BR-E-W', 'EV Wall Home 3 phase 32A white, right socket'),
  new StationModel(Version.V3, 'EVW-332-BR-E-B', 'EV Wall Home 3 phase 32A black, right socket'),
  new StationModel(Version.V3, 'EVW-332-BSR-E-W', 'EV Wall Home 3 phase 32A white, right socket with shutter'),
  new StationModel(Version.V3, 'EVW-332-BSR-E-B', 'EV Wall Home 3 phase 32A black, right socket with shutter'),
  new StationModel(Version.V3, 'EVWL-332-BR-E-W', 'EV Wall Lite 3 phase 32A white, right socket'),
  new StationModel(Version.V3, 'EVWL-332-BSR-E-W', 'EV Wall Lite 3 phase 32A white, right socket with shutter'),
  new StationModel(Version.V3, 'EVWNA-248-C18-E-W', 'EV Wall North America split phase 48A white with cable'),

  new StationModel(Version.V3, 'EVOL-332-B-E-B', 'EV One Lite 3 phase 32A black'),
  new StationModel(Version.V3, 'EVO-332-B-E-B', 'EV One 3 phase 32A black'),
  new StationModel(Version.V3, 'EVOB-332-B-E-B', 'EV One Business 3 phase 32A black'),
  new StationModel(Version.V3, 'EVOE-332-B-E-B', 'EV One Eichrecht 3 phase 32A black'),

  new StationModel(Version.DCV1, 'EVBU-200-CCS3R', 'EV Ultra 200kW - 5 inverters - 1 connector CCS 3m cable'),
  new StationModel(Version.DCV1, 'EVBU-80-CCS3R', 'EV Ultra 80kW - 3 inverters - 1 connector CCS 3m cable'),
  new StationModel(Version.DCV2, 'EVU-240S-C3-B', 'EV Ultra 240 kW - 6 inverters - 1 connector CCS 3m cable'),
  new StationModel(Version.DCV2, 'EVU-240S-C3-B', 'EV Ultra 240 kW with LEM - 6 inverters - 1 connector CCS 3m cable', [
    ModelFeature.LEM
  ]),
  new StationModel(
    Version.DCV2,
    'EVUE-240S-C3-B',
    'EV Ultra 240 kW with LEM (Eichrecht) - 6 inverters - 1 connector CCS 3m cable',
    [ModelFeature.LEM]
  ),
  new StationModel(Version.DCV24, 'EVU-240S-C3-B', 'EV Ultra 240 kW - 6 inverters - 1 connector CCS 3m cable'),
  new StationModel(
    Version.DCV24,
    'EVU-240S-C3-B',
    'EV Ultra 240 kW - 6 inverters - 1 connector CCS 3m cable with LEM meter',
    [ModelFeature.LEM]
  ),
  new StationModel(
    Version.DCV24,
    'EVUE-240S-C3-B',
    'EV Ultra 240 kW (Eichrecht) - 6 inverters - 1 connector CCS 3m cable'
  ),
  new StationModel(
    Version.DCV24,
    'EVUE-240S-C3-B',
    'EV Ultra 240 kW (Eichrecht) - 6 inverters - 1 connector CCS 3m cable with LEM meter',
    [ModelFeature.LEM]
  ),
  new StationModel(Version.DCV24, 'EVU-80D-C3-B', 'EV Ultra 80 kW - 2 inverters - 2 connectors CCS 3m cable'),
  new StationModel(
    Version.DCV24,
    'EVU-80D-C3-B',
    'EV Ultra 80 kW - 2 inverters - 2 connectors CCS 3m cable with LEM meter',
    [ModelFeature.LEM]
  ),
  new StationModel(
    Version.DCV24,
    'EVUE-80D-C3-B',
    'EV Ultra 80 kW (Eichrecht) - 2 inverters - 2 connectors CCS 3m cable'
  ),
  new StationModel(
    Version.DCV24,
    'EVUE-80D-C3-B',
    'EV Ultra 80 kW (Eichrecht) - 2 inverters - 2 connectors CCS 3m cable with LEM meter',
    [ModelFeature.LEM]
  ),
  new StationModel(Version.DCV24, 'EVU-240D-C3-B', 'EV Ultra 80 kW - 6 inverters - 2 connectors CCS 3m cable'),
  new StationModel(
    Version.DCV24,
    'EVU-240D-C3-B',
    'EV Ultra 80 kW - 6 inverters - 2 connectors CCS 3m cable with LEM meter',
    [ModelFeature.LEM]
  ),
  new StationModel(
    Version.DCV24,
    'EVUE-240D-C3-B',
    'EV Ultra 240 kW (Eichrecht) - 6 inverters - 2 connectors CCS 3m cable'
  ),
  new StationModel(
    Version.DCV24,
    'EVUE-240D-C3-B',
    'EV Ultra 240 kW (Eichrecht) - 6 inverters - 2 connectors CCS 3m cable with LEM meter',
    [ModelFeature.LEM]
  ),
  new StationModel(Version.DCV24, 'EVU-480D-C3-B', 'EV Ultra 480 kW - 12 inverters - 2 connectors CCS 3m cable'),
  new StationModel(
    Version.DCV24,
    'EVU-480D-C3-B',
    'EV Ultra 480 kW - 12 inverters - 2 connectors CCS 3m cable with LEM meter',
    [ModelFeature.LEM]
  ),
  new StationModel(
    Version.DCV24,
    'EVUE-480D-C3-B',
    'EV Ultra 480 kW (Eichrecht) - 12 inverters - 2 connectors CCS 3m cable'
  ),
  new StationModel(
    Version.DCV24,
    'EVUE-480D-C3-B',
    'EV Ultra 240 kW (Eichrecht) - 12 inverters - 2 connectors CCS 3m cable with LEM meter',
    [ModelFeature.LEM]
  )

  //...
];

const articleNumberOptions = models.map(option => (
  <option key={option.id} value={option.id}>
    [{option.articleNumber}][{translateVersion(option.version)}] {option.name}
  </option>
));

const sideLabels = [
  '',
  'etc.smart.device.type.car.charger.smappee.controller.right',
  'etc.smart.device.type.car.charger.smappee.controller.left'
];

const URL_PATTERN = /https:\/\/ev.smappee.net\/(A[0-9]{7}[12])/;

function getModule(
  currentModules: ModuleSerial[],
  type: DeviceType,
  width: number,
  offset: number,
  position?: number
): ModuleSerial {
  return (
    currentModules.find(module => module.type === type && module.position === position) || {
      inputName: type + (position ? position.toString() : ''),
      type,
      position,
      serialNumber: '',
      width,
      offset
    }
  );
}

function getModules(model: StationModel, current: ModuleSerial[]): ModuleSerial[] {
  let numberOfChargeControllers = model.getNumberOfChargeControllers();
  let singleSide: number | undefined;
  const articleNumber = model.articleNumber;

  if (
    articleNumber.includes('-C2L-') ||
    articleNumber.includes('-C5L-') ||
    articleNumber.includes('-C8L-') ||
    articleNumber.includes('-BL-')
  ) {
    singleSide = 2;
  } else if (
    articleNumber.includes('-C2R-') ||
    articleNumber.includes('-C5R-') ||
    articleNumber.includes('-C8R-') ||
    articleNumber.includes('-BR-')
  ) {
    singleSide = 1;
  }

  const result: ModuleSerial[] = [];

  for (let i = 0; i < numberOfChargeControllers; i++) {
    result.push(
      getModule(current, model.getChargeControllerDeviceType(), 6, 0, numberOfChargeControllers > 1 ? i + 1 : undefined)
    );
  }

  if (model.isACWithRFIDSeparate()) {
    if (model.version === Version.V1) {
      result.push(getModule(current, DeviceType.RFID, 6, 0));
    } else {
      for (let i = 0; i < numberOfChargeControllers; i++) {
        result.push(getModule(current, DeviceType.RFID, 6, 0, numberOfChargeControllers > 1 ? i + 1 : undefined));
      }
    }
  }
  if (!model.articleNumber.startsWith('EVW') && !model.isACWithRFIDTogether()) {
    result.push(getModule(current, DeviceType.LED, 6, 0));
  }

  if (
    articleNumber.endsWith('-ETH') ||
    articleNumber.includes('-E') ||
    articleNumber.startsWith('EVBU') ||
    articleNumber.startsWith('EVU')
  ) {
    result.push(getModule(current, DeviceType.EthernetConnect, 6, 0));
  } else if (articleNumber.endsWith('-4G') || articleNumber.includes('-G')) {
    result.push(getModule(current, DeviceType.FourGConnect, 6, 0));
  }

  if (articleNumber.startsWith('EVW-') || articleNumber.startsWith('EVBU') || articleNumber.startsWith('EVU')) {
    result.push(getModule(current, DeviceType.PowerBox, 6, 0));
    result.push(getModule(current, DeviceType.CTHub, 6, 0));
  }
  if (articleNumber.startsWith('EVW-3')) {
    result.push(getModule(current, DeviceType.ClosedCTHub, 6, 0));
  }

  return result;
}

function checkModuleTypeSwitch(modules: ModuleSerial[], index: number, type: DeviceType) {
  const module = modules[index];
  const {serialNumber} = module;

  const prefix = getSerialPrefix(type);
  if (prefix && serialNumber.startsWith(prefix) && module.type !== type) {
    const targetIndex = modules.findIndex(m => m.type === type);
    if (targetIndex < 0) return;

    modules[index].serialNumber = '';
    modules[targetIndex] = {...modules[targetIndex], serialNumber};
  }
}

function checkQRCode(modules: ModuleSerial[], index: number) {
  const serial = modules[index].serialNumber;
  const match = URL_PATTERN.exec(serial);
  if (!match) return;

  const serialForBoth = serial.substring(0, serial.length - 1);
  modules[index].serialNumber = '';
  for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    if (module.type === DeviceType.CarChargerSticker) {
      modules[i] = {
        ...module,
        serialNumber: serialForBoth + (module.position || 1)
      };
    }
  }
}

function checkModuleSwitch(modules: ModuleSerial[], index: number) {
  const module = modules[index];
  const {serialNumber} = module;

  checkQRCode(modules, index);
  if (serialNumber.length !== 10) return;

  checkModuleTypeSwitch(modules, index, DeviceType.LED);
  checkModuleTypeSwitch(modules, index, DeviceType.RFID);
  checkModuleTypeSwitch(modules, index, DeviceType.EthernetConnect);
  checkModuleTypeSwitch(modules, index, DeviceType.FourGConnect);
}

function isSerialComplete(module: ModuleSerial): boolean {
  if (module.type === DeviceType.RCM) return module.serialNumber.length === 17;
  else if (module.type === DeviceType.CarChargerSticker) {
    return URL_PATTERN.test(module.serialNumber);
  } else return module.serialNumber.length === 10;
}

interface ModuleInputsProps {
  modules: ModuleSerial[];
  setModules: (modules: ModuleSerial[]) => void;
}

const ModuleInputs = memo((props: ModuleInputsProps) => {
  const {modules, setModules} = props;
  const form = useFormContext();
  const updateModule = (index: number, module: ModuleSerial) => {
    const newModules = modules.map((m, i) => (i === index ? module : m));
    checkModuleSwitch(newModules, index);
    setModules(newModules);

    // automatically focus on the next field
    if (isSerialComplete(newModules[index]) && index + 1 < newModules.length) {
      // skip already filled fields
      let nextIndex = index + 1;
      while (nextIndex < newModules.length && isSerialComplete(newModules[nextIndex])) {
        nextIndex++;
      }

      if (nextIndex < newModules.length) {
        form.focus(newModules[nextIndex].inputName);
      }
    }
  };

  return (
    <>
      {modules.map((module, index) => (
        <Col key={module.inputName} sm={{size: module.width, offset: module.offset}}>
          <ModuleInput module={module} setModule={module => updateModule(index, module)} />
        </Col>
      ))}
    </>
  );
});

const moduleTypesWithLabel: DeviceType[] = [
  DeviceType.ACChargingController,
  DeviceType.CarChargerSticker,
  DeviceType.MIDPoly,
  DeviceType.MIDSingle
];

const CreateChargingStation = (props: ICardProps<ICardSettings>) => {
  const {api} = useAppContext();
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<boolean>(false);

  const [modelInput, modelId] = useSelectInputGroup('model', 'Model', models[0].id, articleNumberOptions);
  const model = models.find(model => model.id === modelId);
  const articleNumber = model?.articleNumber;
  const [modules, setModules] = useState<ModuleSerial[]>(model ? getModules(model, []) : []);

  const firstModuleInputName = modules[0].inputName;
  const [serialNumberInput, serialNumber, clearSerialNumber] = useTextInput(
    'serialNumber',
    'Serial number',
    '',
    value => {
      if (!/^([A-Z0-9-]+_)?6[0-9]{9}$/.test(value)) {
        return 'Invalid serial number';
      }

      const [articleCodeFromSerial, serial] = serialNumber.split('_');
      if (serial && articleNumber !== articleCodeFromSerial) {
        return 'Does not match serial';
      }
    },
    useCallback(
      (form, value) => {
        const isComplete =
          (value.startsWith('6') && value.length === 10) || (value.includes('_6') && value.split('_')[1].length === 10);

        if (isComplete) form.focus(firstModuleInputName);
      },
      [firstModuleInputName]
    )
  );

  useEffect(() => {
    if (model) {
      setModules(modules => getModules(model, modules));
    }
  }, [model]);

  const handleClickedSave = async () => {
    let [articleCode, serial] = serialNumber.split('_');
    if (!articleNumber) {
      return;
    }
    if (!serial) {
      serial = articleCode;
      articleCode = articleNumber;
    }

    const allModules = modules;
    const controllers = model.getNumberOfChargeControllers();
    if (model.hasEichrechtMeter()) {
      for (let i = 0; i < controllers; i++) {
        allModules.push(getModule(modules, DeviceType.MIDPolyISKRA, 6, 0, controllers > 1 ? i + 1 : undefined));
      }
    }
    if (model.hasLEMMeter()) {
      for (let i = 0; i < controllers; i++) {
        allModules.push(getModule(modules, DeviceType.LEMDCMeter, 6, 0, controllers > 1 ? i + 1 : undefined));
      }
    }

    try {
      await api.createChargingStation({
        serialNumber: serial,
        articleCode,
        modules: modules
          .filter(module => module.serialNumber !== '')
          .map(module => ({
            type: module.type,
            serialNumber: postProcessModuleSerial(module.serialNumber, module.type),
            position: module.position,
            label:
              module.position === undefined && !moduleTypesWithLabel.includes(module.type)
                ? undefined
                : sideLabels[module.position || 1]
          }))
      });
      setError(undefined);
      setSuccess(true);
      clearSerialNumber();
      setModules(getModules(model, []));
      NotificationManager.success(T('createChargingStation.created'));
    } catch (ex) {
      console.log(ex);
      setError('Charging station creation failed (server error)');
      setSuccess(false);
    }
  };

  return (
    <CardView {...cardViewProps(props)}>
      <FormProvider>
        <ScrollPane>
          <Row>
            <Col sm={12}>{modelInput}</Col>
            <Col sm={6}>{serialNumberInput}</Col>
            <ModuleInputs modules={modules} setModules={setModules} />
            <Col sm={12}>
              <FormSaveButton onSave={handleClickedSave}>Save</FormSaveButton>
            </Col>
          </Row>
          {error && <Alert color="danger">{error}</Alert>}
          {success && <Alert color="success">Charging station created successfully</Alert>}
        </ScrollPane>
      </FormProvider>
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  title: 'createChargingStation.title',
  description: 'createChargingStation.description',
  type: CardTypeKey.CreateChargingStation,
  locationAware: CardLocationAwareness.Unaware,
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 3,
  cardClass: CreateChargingStation,
  defaultSettings: {}
};
export default CARD;
