import React, {useState, useMemo, useCallback} from 'react';

import {Input, Label, FormGroup, FormText} from '../../components/bootstrap';
import {ReactSortable as Sortable} from '../../components/sortable/Sortable';
import {
  ISelectedColumn,
  IPersistedTableSettings,
  collapseGroups,
  isSelectedGroup,
  expandGroups,
  normalizeTableColumns
} from '../../components/Table';
import {Checkbox} from '../../components/ui/checkbox';
import {GripVertical} from '../../components/ui-lib/icons/medium';
import {Columns} from '../../components/ui-lib/icons/small';
import {cn} from '../../lib/utils';
import {ITableField, getGroup, ColumnGroupKey} from '../../models/Table';
import {replace} from '../../utils/Arrays';
import {T} from '../../utils/Internationalization';
import {classes} from '../../utils/Styles';

import styles from './ColumnChooser.module.scss';

interface IColumnChooserProps<T> {
  settings: IPersistedTableSettings;
  fields: ITableField<T>[];
  updateSettings: (settings: IPersistedTableSettings) => void;
  title?: string;
}

interface SortableColumn {
  id: string;
  column: ISelectedColumn;
}

function getColumnLabel<T>(field: ITableField<T>): [string, string | JSX.Element] {
  const title = field.options.title;
  const result = field.options.byline ? `${field.label} [${field.options.byline}]` : field.label;
  return [result, title || result];
}

export default function ColumnChooser<T>(props: IColumnChooserProps<T>) {
  const {settings, fields, updateSettings, title = T('columnChooser.title')} = props;
  const {columns, grouped} = settings;

  const [filter, setFilter] = useState('');
  const normalizedColumns = useMemo(() => normalizeTableColumns(columns, grouped, fields), [columns, grouped, fields]);
  const indexedFields = useMemo(() => new Map(fields.map(field => [field.name, field])), [fields]);
  const groupable = useMemo(() => fields.some(field => field.options.group !== undefined), [fields]);

  const updateTableSettings = useCallback(
    (updates: Partial<IPersistedTableSettings>) => {
      updateSettings({...settings, ...updates});
    },
    [updateSettings, settings]
  );

  const handleColumnOrderChanged = (items: SortableColumn[]) => {
    let columns = items.map(x => x.column);
    updateTableSettings({columns});
  };

  const handleFilterChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  };

  const handleGroupChanged = (checked: boolean) => {
    const grouped = checked;
    const updates: Partial<IPersistedTableSettings> = {grouped};
    if (grouped) updates.columns = collapseGroups(settings, fields);
    else updates.columns = expandGroups(settings, fields);

    updateTableSettings(updates);
  };

  const [renderedColumns, sortableColumns] = useMemo(() => {
    const updateVisibility = (name: string, visible: boolean): ISelectedColumn[] | undefined => {
      if (name.startsWith('group::')) {
        const groupName = name.substr(7);
        return replace(normalizedColumns, column => isSelectedGroup(column) && column.group === groupName, {
          group: groupName as ColumnGroupKey,
          visible
        });
      } else {
        return replace(normalizedColumns, column => !isSelectedGroup(column) && column.name === name, {
          name,
          visible
        });
      }
    };

    const handleVisibilityChanged = (checked: boolean, id: string) => {
      const updatedColumns = updateVisibility(id, !!checked);
      if (updatedColumns !== undefined) {
        updateTableSettings({columns: updatedColumns});
      }
    };

    const items = [];
    const sortableItems: SortableColumn[] = [];

    // Render each column as a separate checkbox
    for (let column of normalizedColumns) {
      const isGroup = isSelectedGroup(column);
      let title: string | JSX.Element = '';
      let label: string;
      let id: string = '';
      if (isSelectedGroup(column)) {
        const group = getGroup(column.group);
        label = title = group === undefined ? column.group : T(group.translationKey);
        id = `group::${column.group}`;
      } else {
        const field = indexedFields.get(column.name);
        if (!field) continue;

        const {alwaysVisible, follows} = field.options;
        if (alwaysVisible || follows) continue;

        id = column.name;
        [label, title] = getColumnLabel(field);
      }

      // Filter
      const hasFilter = filter.trim() !== '';
      const showColumn = hasFilter ? label.toLowerCase().includes(filter.toLowerCase()) : true;

      items.push(
        <div key={id} data-id={id} className={classes(styles.column, !showColumn && styles.hidden)}>
          <div className={styles.wrapper}>
            <Checkbox
              id={id}
              name={id}
              label={typeof title === 'string' ? title : undefined}
              checked={column.visible}
              onCheckedChange={checked => handleVisibilityChanged(checked, id)}
              testId={id}
            />
            {isGroup && (
              <label title={T('columnChooser.grouped')} className="tw-p-0 tw-ml-2.5 !tw-mb-0" htmlFor={id}>
                <Columns className="tw-h-4 tw-w-4" />
              </label>
            )}
            <span className={cn(styles.arrows, '!tw-flex !tw-justify-end')}>
              <GripVertical width={16} height={16} />
            </span>
          </div>
        </div>
      );
      sortableItems.push({id, column});
    }

    return [items, sortableItems];
  }, [normalizedColumns, indexedFields, filter, updateTableSettings]);

  return (
    <FormGroup>
      <div className={styles.header}>
        <Label>{title}</Label>

        <Input
          type="text"
          name="filter"
          autoWidth={true}
          placeholder={T('columnChooser.filterHint')}
          value={filter}
          onChange={handleFilterChanged}
          bsSize="sm"
        />
      </div>

      <Sortable className={styles.chooser} list={sortableColumns} setList={handleColumnOrderChanged}>
        {renderedColumns}
      </Sortable>

      <div className={styles.footer}>
        {groupable && (
          <Checkbox
            id="grouped"
            name="grouped"
            label={T('columnChooser.group')}
            defaultChecked={grouped}
            onCheckedChange={handleGroupChanged}
            style={{display: 'flex', transform: 'scale(0.9)'}}
            testId="grouped"
          />
        )}

        {!groupable && <div style={{flex: 1}} />}

        <FormText color="muted" className="tw-mt-2">
          {T('columnChooser.reorderHint')}
        </FormText>
      </div>
    </FormGroup>
  );
}
