import React from 'react';
import {NavLink as RsNavLink, NavLinkProps} from 'reactstrap';

import './NavLink.scss';

export class NavLink extends React.Component<NavLinkProps> {
  render() {
    let {active, className, ...otherProps} = this.props;

    // Hook extra classes into the component
    className = ['dash-nav-Link', active ? 'active' : '', className || ''].join(' ');

    // cheated here -> not clear why TypeScript is complaining here
    return (
      <RsNavLink active={active} className={className} {...(otherProps as any)}>
        {this.props.children}
      </RsNavLink>
    );
  }
}
