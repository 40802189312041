import dayjs from 'dayjs';
import React, {useCallback, useMemo, useRef, useState} from 'react';

import {isAPIResponse} from '../../api/APIClient';
import {LatLonArea} from '../../api/UserAPI';
import {useAppContext} from '../../app/context';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import API from '../../core/api';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {ChargingStationSummary} from '../../models/ChargingStation';
import {setContextLocationId} from '../../redux/actions/location';
import {None} from '../../utils/Arrays';
import {BrandColors} from '../../utils/BrandColors';
import {ServerErrorCode} from '../../utils/Errors';
import {useOrganization} from '../../utils/FunctionalData';
import {useDelayedEffect, useLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';

import {useUser} from '../CardUtils';
import {ColoredDot} from '../ChargingStations/ChargingStatusField';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {CardView, cardViewProps, ICardState} from '../components/CardView';

import {ChargingHubSummary} from './ChargingHub';

import {ChargingHubMap} from './ChargingHubMap';

import styles from './index.module.scss';

interface ChargingStationOverviewSettings extends ICardSettings {
  zoomLevel: number;
  center: {
    lat: number;
    lng: number;
  };
  organization?: number;
}

interface StationsResult {
  stations: ChargingStationSummary[];
  tooManyResults: boolean;
  loadedAt: dayjs.Dayjs;
}

interface StationsQuery {
  area?: LatLonArea;
  organizationId?: number;
}

const NoStations: StationsResult = {
  stations: None,
  tooManyResults: false,
  loadedAt: dayjs()
};

function queryStations(api: API, query: StationsQuery): Promise<StationsResult> {
  if (!query) return Promise.resolve(NoStations);
  let request: Promise<ChargingStationSummary[]>;
  if (query.area === undefined) {
    return Promise.resolve(NoStations);
  } else if (query.organizationId !== undefined) {
    request = api.organizations.getChargingStations(query.organizationId, query.area);
  } else {
    request = api.user.getChargingStations(query.area);
  }
  return request
    .then(stations => ({
      tooManyResults: false,
      stations,
      loadedAt: dayjs.utc()
    }))
    .catch(err => {
      console.log('Error:', err);
      if (isAPIResponse(err)) {
        if (err.code === ServerErrorCode.TooManyResults) {
          return {tooManyResults: true, stations: None, loadedAt: dayjs.utc()};
        }
      }
      throw err;
    });
}

function getAreaFromBounds(bounds: google.maps.LatLngBounds): LatLonArea {
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();
  return {
    maxLat: northEast.lat(),
    maxLon: northEast.lng(),
    minLat: southWest.lat(),
    minLon: southWest.lng()
  };
}

function areEqual(a?: LatLonArea, b?: LatLonArea) {
  if (a === b) return true;
  if (a === undefined || b === undefined) return false;

  return a.maxLat === b.maxLat && a.maxLon === b.maxLon && a.minLat === b.minLat && a.minLon === b.minLon;
}

function useQuery(area?: LatLonArea, organizationId?: number) {
  const [value, setValue] = useState<StationsQuery>({
    area,
    organizationId
  });
  const lastValue = useRef(value);
  lastValue.current = value;

  useDelayedEffect(
    () => {
      if (!areEqual(area, lastValue.current.area) || organizationId !== lastValue.current.organizationId) {
        setValue({
          area,
          organizationId
        });
      }
    },
    [area, organizationId],
    500
  );
  return value;
}

function ChargingStationOverviewCard(props: ICardProps<ChargingStationOverviewSettings>) {
  const {fetch, settings, updateSettings} = props;

  const {store, api} = useAppContext();
  const isServiceDesk = useUser().isServiceDesk();

  const [bounds, setBounds] = useState<{
    bounds: google.maps.LatLngBounds;
    zoomLevel: number;
  }>();

  const area = bounds && getAreaFromBounds(bounds.bounds);
  const query = useQuery(area, settings.organization);

  const [chargingStations = NoStations, reload] = useLoader<StationsResult>(api => queryStations(api, query), [query]);

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organization || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const chargingHubs = useMemo(() => {
    const filteredStations = chargingStations.stations.filter(x =>
      x.chargers.some(c => c.serialNumber !== undefined || c.typeName !== 'acchargingcontroller')
    );

    const hubs: ChargingHubSummary[] = [];
    const hubsByParent = new Map<number, ChargingHubSummary>();
    for (let station of filteredStations) {
      const parent = station.parent || station.serviceLocation;
      let hub = hubsByParent.get(parent.id);
      if (hub === undefined) {
        hub = {
          location: parent,
          chargingStations: [],
          count: 0
        };
        hubsByParent.set(parent.id, hub);
        hubs.push(hub);
      }
      hub.chargingStations.push(station);
      hub.count += station.chargers.length;
    }
    hubs.forEach(hub =>
      hub.chargingStations.sort((a, b) => a.serviceLocation.name.localeCompare(b.serviceLocation.name))
    );
    return hubs;
  }, [chargingStations]);

  const handleClickedChargingHub = (hub: ChargingHubSummary) => {};

  const actions = (state: ICardState) => {
    return (
      <CardActions>
        <Reload onReload={reload} />
        {isServiceDesk && (
          <div style={{width: 250}}>
            <OrganizationInput
              organizations={inputOrganizations.organizations}
              value={organization}
              onChange={org => updateSettings({organization: org && org.id})}
              onUpdateQuery={updateOrganizationInputQuery}
              clearable={true}
            />
          </div>
        )}
      </CardActions>
    );
  };

  let error: string | undefined;
  if (chargingStations.tooManyResults) {
    error = T(
      isServiceDesk ? 'chargingStationsOverview.tooManyResults.serviceDesk' : 'chargingStationsOverview.tooManyResults'
    );
  }

  const handleZoomChanged = useCallback((zoomLevel: number) => updateSettings({zoomLevel}), [updateSettings]);
  const handleCenterChanged = useCallback(
    center => {
      updateSettings({center});
    },
    [updateSettings]
  );

  const handleBoundsChanged = useCallback((bounds?: google.maps.LatLngBounds, zoomLevel?: number) => {
    if (bounds === undefined || zoomLevel === undefined) return;

    return setBounds({bounds, zoomLevel});
  }, []);

  const handleClickLocation = useCallback(
    (locationId: number) => {
      setContextLocationId(store, api, locationId);
    },
    [store, api]
  );

  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      <div className={styles.main}>
        <ChargingHubMap
          mapContainerClassName={styles.main}
          mapContainerStyle={{flexGrow: 1}}
          zoom={settings.zoomLevel}
          center={settings.center}
          chargingHubs={chargingHubs}
          loadedAt={chargingStations.loadedAt}
          onClickedHub={handleClickedChargingHub}
          onClickLocation={handleClickLocation}
          onZoomChanged={handleZoomChanged}
          onCenterChanged={handleCenterChanged}
          onBoundsChanged={handleBoundsChanged}
        />
        <div className={styles.legenda}>
          <div className={styles.legendaItem}>
            <ColoredDot color={BrandColors.ChargerError} />
            &nbsp;
            {T('chargingStationsOverview.status.error')}
          </div>
          <div className={styles.legendaItem}>
            <ColoredDot color={BrandColors.ChargerUnavailable} />
            &nbsp;
            {T('chargingStationsOverview.status.unavailable')}
          </div>
          <div className={styles.legendaItem}>
            <ColoredDot color={BrandColors.ChargerAvailable} />
            &nbsp;
            {T('chargingStationsOverview.status.available')}
          </div>
          <div className={styles.legendaItem}>
            <ColoredDot color={BrandColors.ChargerInUse} />
            &nbsp;
            {T('chargingStationsOverview.status.used')}
          </div>
          {error}
        </div>
      </div>
    </CardView>
  );
}

const defaultSettings: ChargingStationOverviewSettings = {
  zoomLevel: 8,
  center: {
    lat: 50.822937,
    lng: 3.311227
  }
};

const CARD: ICardType<ChargingStationOverviewSettings> = {
  type: CardTypeKey.ChargingStationOverview,
  title: 'chargingStationsOverview.title',
  description: 'chargingStationsOverview.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  width: 4,
  height: 2,
  defaultSettings,
  locationAware: CardLocationAwareness.Aware,
  cardClass: ChargingStationOverviewCard
};

export default CARD;
