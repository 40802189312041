import cn from 'classnames';
import {useCallback} from 'react';

import {Modal, ModalBody, ModalHeader} from '../../../../components/bootstrap';
import {Icon} from '../../../../components/Icon';
import SmappeeSolidIcon from '../../../../components/icons/Smappee';
import {LockLocked} from '../../../../components/ui-lib/icons/small';
import {IPromiseModalProps, usePromiseModal} from '../../../../modals/PromiseModal';
import {T} from '../../../../utils/Internationalization';

import styles from './HelpImageUploadModal.module.scss';

interface HelpImageUploadModalProps extends IPromiseModalProps<void> {
  help?: string;
}

const HelpImageUploadModal = (props: HelpImageUploadModalProps) => {
  const {help} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = useCallback(() => resolve(), [resolve]);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={handleClose}>{T('chargingStationConfiguration.manageDisplayImages.help.title')}</ModalHeader>
      <ModalBody>
        <div className={cn(styles.modalBody)}>
          <p>{T('chargingStationConfiguration.manageDisplayImages.uploadInstruction')}</p>
          <p>{T('chargingStationConfiguration.manageDisplayImages.onlyJPGandPNG')}</p>
          <h4>{T('chargingStation.status')}</h4>
          <table className={cn(styles.helpTable, 'table table-bordered')}>
            <thead>
              <tr>
                <td className={cn(styles.headerCell)}>{T('general.symbol')}</td>
                <td className={cn(styles.headerCell)}>
                  {T('chargingStationConfiguration.manageDisplayImages.status.description')}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={cn(styles.statusIcon)}>
                  <span className={styles.locked}>
                    <LockLocked />
                  </span>
                </td>
                <td className={cn(styles.description)}>
                  {T('chargingStationConfiguration.manageDisplayImages.imageLocked')}
                </td>
              </tr>
              <tr>
                <td className={cn(styles.statusIcon)}>
                  <i className={cn(Icon.HourGlass, styles.busy)} />
                </td>
                <td className={cn(styles.description)}>
                  {T('chargingStationConfiguration.manageDisplayImages.imageBusy')}
                </td>
              </tr>
              <tr>
                <td className={cn(styles.statusIcon)}>
                  <span>
                    <SmappeeSolidIcon className={styles.SmappeeIcon} width={11.75} height={16} />
                  </span>
                </td>
                <td className={cn(styles.description)}>
                  {T('chargingStationConfiguration.manageDisplayImages.defaultImage')}
                </td>
              </tr>
              <tr>
                <td className={cn(styles.statusIcon)}>
                  <i className={cn(Icon.FileCheck, styles.confirmed)} />
                </td>
                <td className={cn(styles.description)}>
                  {T('chargingStationConfiguration.manageDisplayImages.uploadSuccesfulOnCS')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default HelpImageUploadModal;
