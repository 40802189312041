import {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {CanDeleteResponse} from '../../api/ContractsAPI';
import {useAppContext} from '../../app/context';
import {Modal} from '../../components/bootstrap';
import {Icons} from '../../components/Icon';
import FormSaveButton from '../../components/inputs/FormSaveButton';
import {ModalErrorMessage} from '../../components/ModalErrorMessage';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {Contract, ContractDetails} from '../../models/Contract';
import {translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';
import {TranslationKey} from '../../utils/TranslationTerms';
import {Spring} from '../components/CardActions';

interface DeleteContractModalProps extends IPromiseModalProps<boolean> {
  contract: ContractDetails;
  closeOnly: boolean;
}

function getDeleteInfo(contract: Contract) {
  switch (contract) {
    case Contract.SEPA:
      return T('contracts.delete.delete.info.sepa');
    case Contract.SplitBilling:
      return T('contracts.delete.delete.info.splitBilling');
    case Contract.ChargingHost:
      return T('contracts.delete.delete.info.paymentPackage');
    case Contract.eMSPBusiness:
    case Contract.eMSPPersonal:
      return T('contracts.delete.delete.info.emsp');
    default:
      return '?';
  }
}

function getCloseInfo(contract: Contract) {
  switch (contract) {
    case Contract.SplitBilling:
      return T('contracts.delete.close.info.splitBilling');
    case Contract.ChargingHost:
      return T('contracts.delete.close.info.paymentPackage');
    case Contract.eMSPBusiness:
    case Contract.eMSPPersonal:
      return T('contracts.delete.close.info.emsp');
    default:
      return '?';
  }
}

export function DeleteContractModal(props: DeleteContractModalProps) {
  const {contract, closeOnly} = props;
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const [loading, setLoading] = useState(false);
  const [deletable, setDeletable] = useState<CanDeleteResponse>();
  const [error, setError] = useState<string>();

  const onToggle = () => resolve(false);

  useEffect(() => {
    setLoading(true);
    api.contracts
      .canDelete(contract.id)
      .then(setDeletable)
      .finally(() => setLoading(false));
  }, [api, contract.id]);

  const canDelete = deletable && deletable.cannotDeleteCause === undefined;
  const canClose = deletable && deletable.cannotCloseCause === undefined;
  const closeableContract = contract.contract !== Contract.SEPA && contract.closed === undefined;

  const handleClickedDelete = () =>
    api.contracts
      .delete(contract.id)
      .catch(e => setError(translateError(e, T('contracts.delete.delete.error'))))
      .then(() => {
        NotificationManager.success(T('contracts.delete.delete.success'));
        resolve(true);
      });
  const handleClickedClose = () =>
    api.contracts
      .close(contract.id)
      .catch(e => setError(translateError(e, T('contracts.delete.close.error'))))
      .then(() => {
        NotificationManager.success(T('contracts.delete.close.success'));
        resolve(true);
      });

  return (
    <Modal isOpen={isOpen} toggle={onToggle} autoFocus={false}>
      <ModalHeader toggle={onToggle}>
        {closeOnly ? T('contracts.delete.titleClose') : T('contracts.delete.title')}
      </ModalHeader>
      <ModalBody>
        {loading && (
          <p style={{textAlign: 'center'}}>
            {T('contracts.delete.checking')}
            <br />
            {Icons.Loading}
          </p>
        )}
        {!closeOnly && canDelete && canClose && closeableContract && <p>{T('contracts.delete.chooseAction')}</p>}
        {!closeOnly && deletable?.cannotCloseCause && deletable.cannotCloseCause === deletable.cannotDeleteCause ? (
          <p>
            {T('contracts.delete.error.cannotDeleteOrClose')}{' '}
            {T(`contracts.delete.error.${deletable.cannotDeleteCause}` as TranslationKey)}
          </p>
        ) : (
          <>
            {!closeOnly && (
              <p>
                {deletable?.cannotDeleteCause
                  ? `${T('contracts.delete.error.cannotDelete')} ${T(
                      `contracts.delete.error.${deletable.cannotDeleteCause}` as TranslationKey
                    )}`
                  : getDeleteInfo(contract.contract)}
              </p>
            )}
            {closeableContract && (
              <p>
                {deletable?.cannotCloseCause
                  ? `${T('contracts.delete.error.cannotClose')} ${T(
                      `contracts.delete.error.${deletable.cannotCloseCause}` as TranslationKey
                    )}`
                  : getCloseInfo(contract.contract)}
              </p>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter error={error}>
        {!closeOnly && (
          <FormSaveButton onSave={handleClickedDelete} disabled={!canDelete}>
            {T('contracts.delete.delete')}
          </FormSaveButton>
        )}
        {closeableContract && (
          <FormSaveButton onSave={handleClickedClose} disabled={!canClose}>
            {T('contracts.delete.close')}
          </FormSaveButton>
        )}
      </ModalFooter>
    </Modal>
  );
}
