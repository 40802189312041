import React, {useState, useEffect, useMemo, useCallback} from 'react';

import {NotificationManager} from 'react-notifications';

import {Table} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {
  Button as RsButton,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from '../../components/bootstrap';
import {Checkbox} from '../../components/ui/checkbox';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {CTType, CT_TYPES, getCTTypeLabel} from '../../models/CTType';
import {
  ILegacyChannel,
  LegacyConnectionType,
  LEGACY_CONNECTION_TYPES,
  getLegacyConnectionTypeName,
  LEGACY_CONSUMPTION_TYPES,
  getLegacyConsumptionTypeName,
  LegacyConsumptionType
} from '../../models/LegacyChannel';
import {ILocationSummary} from '../../models/Location';
import {getPhaseLabel, Phase} from '../../models/Phase';
import {T} from '../../utils/Internationalization';

import styles from './EditConfig.module.scss';

const PHASES = [Phase.L1, Phase.L2, Phase.L3];

const CTTypeOptions = CT_TYPES.map(type => (
  <option key={type} value={type}>
    {getCTTypeLabel(type)}
  </option>
));

interface ChannelRowProps {
  readOnly: boolean;
  channel: ILegacyChannel;
  updateChannel: (channelId: number, updates: Partial<ILegacyChannel>) => void;
  error?: {field: string; text: string};
}
const ChannelRow = (props: ChannelRowProps) => {
  const {readOnly, channel, updateChannel, error} = props;

  const connectionOptions = LEGACY_CONNECTION_TYPES.map(type => (
    <option key={type} value={type}>
      {getLegacyConnectionTypeName(type)}
    </option>
  ));

  const phaseOptions = PHASES.map((phase, index) => (
    <option key={phase} value={index}>
      {getPhaseLabel(phase)}
    </option>
  ));

  const consumptionTypeOptions = LEGACY_CONSUMPTION_TYPES.map(type => (
    <option key={type} value={type}>
      {getLegacyConsumptionTypeName(type)}
    </option>
  ));

  const handleNameChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    updateChannel(channel.channel, {name: event.currentTarget.value});
  };

  const handleCTTypeChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    updateChannel(channel.channel, {
      cttype: parseInt(event.currentTarget.value) as CTType
    });
  };

  const handleConsumptionChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    updateChannel(channel.channel, {
      consumption: parseInt(event.currentTarget.value) as LegacyConsumptionType
    });
  };

  const handleConnectionChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    updateChannel(channel.channel, {
      connection: parseInt(event.currentTarget.value) as LegacyConnectionType
    });
  };

  const handlePhaseChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    updateChannel(channel.channel, {
      phase: parseInt(event.currentTarget.value)
    });
  };

  const handleReversedChanged = (checked: boolean) => {
    updateChannel(channel.channel, {reversed: checked});
  };

  const handleBalancedChanged = (checked: boolean) => {
    updateChannel(channel.channel, {balanced: checked});
  };

  const handleNilmChanged = (checked: boolean) => {
    updateChannel(channel.channel, {nilm: checked});
  };

  const renderName = (channel: ILegacyChannel, invalid: boolean) => {
    return (
      <Input
        key={`name-${channel.channel}`}
        type="text"
        name="name"
        value={channel.name}
        disabled={readOnly}
        onChange={handleNameChanged}
        invalid={invalid}
      />
    );
  };

  const renderPhases = (channel: ILegacyChannel, invalid: boolean) => {
    return (
      <Input
        key={`phase-${channel.channel}`}
        type="select"
        name="phase"
        value={channel.phase}
        onChange={handlePhaseChanged}
        invalid={invalid}
        disabled={readOnly}
      >
        {phaseOptions}
      </Input>
    );
  };

  const renderCTs = (channel: ILegacyChannel) => {
    return (
      <Input
        key={`cttype-${channel.channel}`}
        type="select"
        name="cttype"
        value={channel.cttype}
        onChange={handleCTTypeChanged}
        autoWidth={false}
        disabled={readOnly}
      >
        {CTTypeOptions}
      </Input>
    );
  };

  const renderConnection = (channel: ILegacyChannel) => {
    return (
      <Input
        key={`connection-${channel.channel}`}
        type="select"
        name="connection"
        value={channel.connection}
        onChange={handleConnectionChanged}
        disabled={readOnly}
      >
        {connectionOptions}
      </Input>
    );
  };

  const renderCategory = (channel: ILegacyChannel) => {
    return (
      <Input
        key={`consumption-${channel.channel}`}
        type="select"
        name="consumption"
        value={channel.consumption}
        onChange={handleConsumptionChanged}
        disabled={readOnly}
      >
        {consumptionTypeOptions}
      </Input>
    );
  };

  const renderCheckbox = (
    channel: ILegacyChannel,
    property: string,
    value: boolean,
    // onChanged: (e: React.SyntheticEvent<HTMLInputElement>) => void
    onChanged: (checked: boolean) => void
  ) => {
    return (
      <Checkbox
        id={`${property}-${channel.channel}`}
        name={`${property}-${channel.channel}`}
        key={`${property}-${channel.channel}`}
        defaultChecked={value}
        onCheckedChange={onChanged}
        disabled={readOnly}
        wrapperClassName="tw-mb-0"
        testId={`${property}-${channel.channel}`}
      />
    );
  };

  const {channel: id} = channel;
  const isValid = error === undefined;
  const {field: eField = '', text: eText = ''} = error || {};
  const tooltipId = `error-${id}`;

  return (
    <tr key={`channel-${id}`} className={isValid ? '' : styles.invalidRow}>
      <td className={styles.groupColumn}>
        <span>{id}</span>

        {eText && [
          <span id={tooltipId} key={tooltipId} className="fal fa-exclamation" />,
          <UncontrolledTooltip key={`${tooltipId}-tooltip`} placement="left" target={tooltipId}>
            {eText}
          </UncontrolledTooltip>
        ]}
      </td>
      <td>{renderName(channel, eField === 'name')}</td>
      <td>{renderPhases(channel, eField === 'phase')}</td>
      <td>{renderConnection(channel)}</td>
      <td>{renderCategory(channel)}</td>
      <td>{renderCTs(channel)}</td>
      <td className={styles.sensorReversed}>
        {renderCheckbox(channel, 'reversed', channel.reversed, handleReversedChanged)}
      </td>
      <td className={styles.sensorReversed}>
        {renderCheckbox(channel, 'balanced', channel.balanced, handleBalancedChanged)}
      </td>
      <td className={styles.sensorReversed}>{renderCheckbox(channel, 'nilm', channel.nilm, handleNilmChanged)}</td>
    </tr>
  );
};

interface EditConfigProps extends IPromiseModalProps<void> {
  location: ILocationSummary;
  readOnly: boolean;
}

export default (props: EditConfigProps) => {
  const {location, readOnly} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState<ILegacyChannel[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (!location || !location.deviceType) return;

    const {id} = location;
    api.getLegacyChannels(id).then(channels => {
      setChannels(channels);
      setErrorMessage(undefined);
    });
  }, [api, location]);

  const [errorMap, hasErrors] = useMemo(() => {
    let hasErrors = false;
    const errors = new Map<number, {field: string; text: string}>();

    for (let channel of channels) {
      let {channel: id, name} = channel;
      name = name ? name.trim() : '';

      // Name cannot be empty
      if (name === '') {
        errorMap.set(id, {
          field: 'name',
          text: T('liveElectricityValues.configuration.channelNameRequired')
        });
        hasErrors = true;
      }
    }

    return [errors, hasErrors];
  }, [channels]);

  const updateChannel = useCallback((channelId: number, updates: Partial<ILegacyChannel>) => {
    setChannels(channels =>
      channels.map(channel => {
        if (channel.channel !== channelId) return channel;
        else return {...channel, ...updates};
      })
    );
  }, []);

  const handleClickSave = () => {
    saveChannels().then(() => resolve());
  };

  const saveChannels = () => {
    const {id} = location;
    setLoading(true);

    // Wait until everything is updated
    return api
      .updateLegacyChannels(id, channels)
      .then(() => {
        setLoading(false);
        NotificationManager.success(T('liveElectricityValues.configuration.save.success'));
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage(T('liveElectricityValues.configuration.save.failed'));
      });
  };

  const rows = useMemo(
    () =>
      channels.map(channel => (
        <ChannelRow
          readOnly={readOnly}
          channel={channel}
          updateChannel={updateChannel}
          error={errorMap.get(channel.channel)}
        />
      )),
    [channels, updateChannel, errorMap, readOnly]
  );

  const table = (
    <div>
      <Table>
        <thead>
          <tr>
            <th className={styles.groupColumn}>#</th>
            <th>{T('liveElectricityValues.configuration.channel')}</th>
            <th>{T('liveElectricityValues.configuration.phase')}</th>
            <th>{T('liveElectricityValues.configuration.connection')}</th>
            <th>{T('liveElectricityValues.configuration.category')}</th>
            <th>{T('liveElectricityValues.configuration.ctType')}</th>
            <th style={{textAlign: 'center'}}>{T('liveElectricityValues.configuration.reversed')}</th>
            <th style={{textAlign: 'center'}}>{T('liveElectricityValues.configuration.balanced')}</th>
            <th style={{textAlign: 'center'}}>{T('liveElectricityValues.configuration.nilm')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );

  const onToggle = () => resolve();
  return (
    <Modal isOpen={isOpen} toggle={onToggle} size="xl" autoFocus={false} loading={loading}>
      <ModalHeader toggle={onToggle}>
        {readOnly ? T('liveElectricityValues.viewConfiguration') : T('liveElectricityValues.editConfiguration')}
      </ModalHeader>
      <ModalBody>{table}</ModalBody>
      <ModalFooter error={errorMessage}>
        {readOnly && (
          <ModalFooter>
            <RsButton onClick={onToggle}>{T('liveElectricityValues.configuration.close')}</RsButton>
          </ModalFooter>
        )}

        {!readOnly && (
          <RsButton color="primary" onClick={handleClickSave} disabled={hasErrors}>
            {T('liveElectricityValues.configuration.saveAndRestart')}
          </RsButton>
        )}
      </ModalFooter>
    </Modal>
  );
};
