import qs from 'qs';
import UniversalRouter from 'universal-router';

import {AuthUser, UserRights} from '../models/AuthUser';
import routes from '../routes';
import {Path} from '../routes/Path';
import {IContext, IRoute, IResolvedRoute} from '../routes/Route';

export const router = new UniversalRouter(routes, {
  async resolveRoute(context, params) {
    let {pathname, query} = context;
    const {api, store} = context as unknown as IContext;

    const urlParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlParams) {
      params[`?${key}`] = value;
    }

    const route = context.route as IRoute;
    let {ensureLoggedOut, ensureRights} = route;
    const {location: serialNumber} = query;

    const me: AuthUser = store.getState().me.me;

    // Save selected location as preference if possible
    if (me.isLoggedIn() && serialNumber !== undefined) {
      const locations = await api.searchLocations(serialNumber, 1);
      if (locations.length > 0) {
        await api.setUserPreference('locationId', locations[0].serviceLocationId.toString());
      }
    }

    // Append query string params
    const suffix = qs.stringify(query);
    if (suffix !== '') {
      // Force redirect to board view as logged in user
      if (pathname === '/') {
        pathname = '/board';
        ensureRights = UserRights.User;
      }

      pathname += `?${suffix}`;
    }

    // Ensure logged out or in
    if (me.isLoggedIn() && ensureLoggedOut !== undefined) {
      return {redirect: ensureLoggedOut, title: '', description: ''};
    } else if (ensureRights !== undefined && !me.hasAnyRight(ensureRights)) {
      return {
        title: '',
        description: '',
        redirect: Path.LOGIN,
        returnTo: pathname,
        ensureRights
      };
    }

    if (route.load !== undefined) {
      return route.load().then(action => action.default({api, store}, params));
    }

    if (route.action !== undefined) {
      return route.action({api, store, ...context}, params) as unknown as IResolvedRoute;
    }

    return undefined;
  }
});
