import {IDevice, IFirmwareVersion} from '../models/Device';
import {IChildLocation, ILocation, LocationResponsibilities} from '../models/Location';
import {LocationUpgradeStatus} from '../models/LocationUpgradeStatus';
import {IConfigurationPropertyValue, ISmartDeviceType, SmartDeviceTypeCategory} from '../models/SmartDevice';
import {DataCache} from '../utils/DataCache';

import {APIClient} from './APIClient';

export class LocationsCache {
  private client: APIClient;
  locations: DataCache<number, ILocation>;
  locationsWithHistory: DataCache<number, ILocation>;

  constructor(client: APIClient) {
    this.client = client;
    this.locations = new DataCache<number, ILocation>('locations', id => this.getLocationInternal(id));
    this.locationsWithHistory = new DataCache<number, ILocation>('locationsWithHistory', id =>
      this.getLocationWithHistoryInternal(id)
    );
  }

  invalidate(locationId: number) {
    this.locations.invalidate(locationId);
    this.locationsWithHistory.invalidate(locationId);
  }

  getLocation(locationId: number, refresh: boolean = false) {
    return this.locations.fetch(locationId, refresh);
  }

  getLocationWithHistory(locationId: number, refresh: boolean = false) {
    return this.locationsWithHistory.fetch(locationId, refresh);
  }

  private getLocationInternal(locationId: number): Promise<ILocation> {
    const url = `/api/v10/servicelocation/${locationId}?includeDetails=true`;
    return this.client.doGet(url);
  }

  private getLocationWithHistoryInternal(locationId: number): Promise<ILocation> {
    const url = `/api/v10/servicelocation/${locationId}?includeHistory=true`;
    return this.client.doGet(url);
  }
}

export class LocationAPI {
  private client: APIClient;
  private cache: LocationsCache;

  constructor(client: APIClient, cache: LocationsCache) {
    this.client = client;
    this.cache = cache;
  }

  get(locationId: number, refresh: boolean = false) {
    return this.cache.getLocation(locationId, refresh);
  }

  getWithHistory(locationId: number, refresh: boolean = false) {
    return this.cache.getLocationWithHistory(locationId, refresh);
  }

  getChildren(parentId: number): Promise<IChildLocation[]> {
    const url = `/api/v10/servicelocation/${parentId}/childs`;
    return this.client.doGet(url);
  }

  getUpgradeStatus(locationId: number): Promise<LocationUpgradeStatus> {
    const url = `/api/v10/servicelocation/${locationId}/upgrading`;
    return this.client.doGet(url);
  }

  getModules(locationId: number, includeInternal: boolean = false): Promise<IDevice[]> {
    let url = `/api/v10/servicelocation/${locationId}/devices`;
    if (includeInternal) url += '?showInternal=true';

    return this.client.doGet<IDevice[]>(url);
  }

  getFirmwareVersions(locationId: number): Promise<{[key: string]: IFirmwareVersion[]}> {
    const url = `/api/v10/servicelocation/${locationId}/devices/firmwareversions`;
    return this.client.doGet(url);
  }

  restart(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/restart`;
    return this.client.doPut(url);
  }

  upgrade(locationId: number): Promise<unknown> {
    const url = `/api/v10/servicelocation/${locationId}/upgrade`;
    return this.client.doPut(url);
  }

  getSmartDeviceTypes(locationId: number): Promise<ISmartDeviceType[]> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devicetypes`;
    return this.client.doGet(url);
  }

  createSmartDevice(
    locationId: number,
    definition: ICreateSmartDeviceDefinition
  ): Promise<ICreateSmartDeviceResponse | undefined> {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices`;
    return this.client.doPost(url, definition);
  }

  deleteSmartDevice(locationId: number, deviceId: string) {
    const url = `/api/v10/servicelocation/${locationId}/homecontrol/smart/devices/${deviceId}`;
    return this.client.doDelete(url);
  }

  getResponsibilities(locationId: number): Promise<LocationResponsibilities> {
    const url = `/api/v10/servicelocation/${locationId}/responsibilities`;
    return this.client.doGet(url);
  }

  setCPO(locationId: number, cpo: number) {
    const url = `/api/v10/servicelocation/${locationId}/responsibilities/cpo`;
    return this.client.doPut(url, {organization: {id: cpo}});
  }

  deleteCPO(locationId: number | string) {
    const url = `/api/v10/servicelocation/${locationId}/responsibilities/cpo`;
    return this.client.doDelete(url);
  }
}

export interface ICreateSmartDeviceDefinition {
  category: SmartDeviceTypeCategory;
  typeName: string;
  name: string;
  configurationProperties: {
    values: IConfigurationPropertyValue[];
    spec: {name: string};
  }[];
}

export interface ICreateSmartDeviceResponse {
  failures?: ISmartDeviceFailure[];
}

export interface ISmartDeviceFailure {
  type: string;
  messageId: string;
  formattedMessage: string;
}
