import classnames from 'classnames';
import dayjs from 'dayjs';
import React, {useEffect, useMemo, useState} from 'react';
import 'dayjs/plugin/isoWeek';
import DatePicker from 'react-datepicker';
import {PopoverBody} from 'reactstrap';
import {v1 as uuid_v1} from 'uuid';

import {FORMAT_DATE_S, FORMAT_DATE_S_PICKER} from '../core/constants';
import {cn} from '../lib/utils';
import {getLimitsFromRetention, IRetentionPolicy} from '../models/RetentionPolicy';
import {Interval} from '../models/UsageValue';

import {useAppSelector} from '../utils/Hooks';
import {T} from '../utils/Internationalization';
import {classes} from '../utils/Styles';
import {testingClasses} from '../utils/TestingClasses';
import {TranslationKey} from '../utils/TranslationTerms';

import {Input, Label, Popover, FormGroup} from './bootstrap';

import styles from './PeriodSelector.module.scss';
import {Button} from './ui/button';
// import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from './ui/select';
import {InfoCircle} from './ui-lib/icons/medium';
import {Calendar} from './ui-lib/icons/small';

export enum Period {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_WEEK = 'lastWeek',
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
  LAST_MONTH = 'lastMonth',
  THIS_YEAR = 'thisYear',
  LAST_YEAR = 'lastYear',
  DAYS_14 = 'days14',
  DAYS_7 = 'days7',
  DAYS_1 = 'days1',
  CUSTOM = 'custom'
}

export interface PeriodSettings {
  interval: Interval;
  period: Period;
  from?: number;
  to?: number;
}

export interface ActivePeriod {
  interval: Interval;
  from: number;
  to: number;
  timezone: string;
}

export function arePeriodsEqual(a: PeriodSettings, b: PeriodSettings) {
  if (a === b) return true;
  if (a.interval !== b.interval || a.period !== b.period) return false;
  if (a.period === Period.CUSTOM && (a.from !== b.from || a.to !== b.to)) {
    return false;
  }

  return true;
}

export function areActivePeriodsEqual(a: ActivePeriod, b: ActivePeriod) {
  if (a === b) return true;
  if (a.interval !== b.interval) return false;
  if (a.from !== b.from || a.to !== b.to) return false;
  if (a.timezone !== b.timezone) return false;

  return true;
}
// Period labels
export const PERIOD_LABELS: {[key: string]: TranslationKey} = {
  [Period.TODAY]: 'period.today',
  [Period.YESTERDAY]: 'period.yesterday',
  [Period.THIS_WEEK]: 'period.thisWeek',
  [Period.LAST_WEEK]: 'period.lastWeek',
  [Period.DAYS_1]: 'period.days1',
  [Period.DAYS_7]: 'period.days7',
  [Period.DAYS_14]: 'period.days14',
  [Period.THIS_MONTH]: 'period.thisMonth',
  [Period.LAST_MONTH]: 'period.lastMonth',
  [Period.THIS_YEAR]: 'period.thisYear',
  [Period.LAST_YEAR]: 'period.lastYear',
  [Period.CUSTOM]: 'period.custom'
};

// Interval labels
const INTERVAL_LABELS: {[key: string]: TranslationKey} = {
  [Interval.MINUTES_5]: 'intervalValues.5minutes',
  [Interval.X_MINUTES_10]: 'intervalValues.10minutes',
  [Interval.X_MINUTES_15]: 'intervalValues.15minutes',
  [Interval.X_MINUTES_20]: 'intervalValues.20minutes',
  [Interval.X_MINUTES_30]: 'intervalValues.30minutes',
  [Interval.INTERVAL]: 'intervalValues.5minutes',
  [Interval.HOUR]: 'intervalValues.hourly',
  [Interval.DAY]: 'intervalValues.daily',
  [Interval.WEEK]: 'intervalValues.weekly',
  [Interval.MONTH]: 'intervalValues.monthly',
  [Interval.YEAR]: 'intervalValues.yearly',
  [Interval.X_TWO_YEARS]: 'intervalValues.2yearly'
};

export enum PeriodRoundingMode {
  INCLUSIVE,
  EXCLUSIVE,
  ROUND_DOWN,
  ROUND_DOWN_GRAPH
}

interface IDayjsRange {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
}

export function getRangeForPeriod(period: Period, timezone?: string): IDayjsRange {
  const now = dayjs.tz(undefined, timezone);
  let from = now.startOf('day');
  let to = now.endOf('day');

  // Apply from and to dates for periods
  switch (period) {
    case Period.TODAY:
      from = now.startOf('day');
      to = now.endOf('day');
      break;
    case Period.YESTERDAY:
      const yesterday = now.subtract(1, 'days');
      from = yesterday.startOf('day').clone();
      to = yesterday.endOf('day');
      break;
    case Period.THIS_WEEK:
      from = now.startOf('isoWeek');
      to = now.endOf('isoWeek');
      break;
    case Period.LAST_WEEK:
      const lastWeekM = now.subtract(1, 'weeks');
      from = lastWeekM.startOf('isoWeek').clone();
      to = lastWeekM.endOf('isoWeek');
      break;
    case Period.DAYS_14: {
      const startOfTomorrow = now.endOf('day').add(1, 'milliseconds');
      from = startOfTomorrow.subtract(14, 'days');
      to = startOfTomorrow.subtract(1, 'milliseconds');
      break;
    }
    case Period.DAYS_7: {
      const startOfTomorrow = now.endOf('day').add(1, 'milliseconds');
      from = startOfTomorrow.subtract(7, 'days');
      to = startOfTomorrow.subtract(1, 'milliseconds');
      break;
    }
    case Period.DAYS_1: {
      from = now.subtract(1, 'days');
      to = now;
      break;
    }
    case Period.THIS_MONTH:
      from = now.startOf('month');
      to = now.endOf('month');
      break;
    case Period.LAST_MONTH:
      from = now.subtract(1, 'months').startOf('month');
      to = from.endOf('month');
      break;
    case Period.THIS_YEAR:
      from = now.startOf('year');
      to = now.endOf('year');

      if (timezone === 'Europe/London') {
        // SW-8632 viewing data of last year with timezone = London starts at the wrong date
        // only observed with Europe/London, so far... odd
        from = from.add(1, 'hour');
      }
      break;
    case Period.LAST_YEAR:
      // SW-8632 viewing data of last year with timezone = London shows nothing
      const reference = now.subtract(1, 'years');
      from = reference.startOf('year');
      to = reference.endOf('year');

      if (timezone === 'Europe/London') {
        // SW-8632 viewing data of last year with timezone = London starts at the wrong date
        // only observed with Europe/London, so far... odd
        from = from.add(1, 'hour');
      }
      break;
  }

  return {from, to};
}

export function getReferenceLengthOfInterval(interval: Interval) {
  switch (interval) {
    default:
    case Interval.INTERVAL:
    case Interval.MINUTES_5:
      return 5 * 60 * 1000;
    case Interval.HOUR:
      return 60 * 60 * 1000;
    case Interval.DAY:
      return 24 * 60 * 60 * 1000;
    case Interval.MONTH:
      return 31 * 24 * 60 * 60 * 1000;
    case Interval.YEAR:
      return 365 * 24 * 60 * 60 * 1000;
  }
}

export function roundDown(to: number, interval: Interval): number {
  switch (interval) {
    case Interval.MINUTES_5:
      return to + 1 - 5 * 60 * 1000;
    case Interval.X_MINUTES_10:
      return to + 1 - 10 * 60 * 1000;
    case Interval.X_MINUTES_15:
      return to + 1 - 15 * 60 * 1000;
    case Interval.X_MINUTES_20:
      return to + 1 - 20 * 60 * 1000;
    case Interval.X_MINUTES_30:
      return to + 1 - 30 * 60 * 1000;
    case Interval.HOUR:
      return to + 1 - 60 * 60 * 1000;
    case Interval.DAY:
      return dayjs(to).startOf('day').valueOf();
    case Interval.WEEK:
      return dayjs(to).startOf('week').valueOf();
    case Interval.MONTH:
      return dayjs(to).startOf('month').valueOf();
    case Interval.YEAR:
      return dayjs(to).startOf('year').valueOf();
    default:
      return to;
  }
}

export function roundDownDayjs(to: dayjs.Dayjs, interval: Interval): dayjs.Dayjs {
  switch (interval) {
    case Interval.MINUTES_5:
      return to.add(1, 'milliseconds').subtract(5, 'minutes');
    case Interval.X_MINUTES_10:
      return to.add(1, 'milliseconds').subtract(10, 'minutes');
    case Interval.X_MINUTES_15:
      return to.add(1, 'milliseconds').subtract(15, 'minutes');
    case Interval.X_MINUTES_20:
      return to.add(1, 'milliseconds').subtract(20, 'minutes');
    case Interval.X_MINUTES_30:
      return to.add(1, 'milliseconds').subtract(30, 'minutes');
    case Interval.HOUR:
      return to.add(1, 'milliseconds').subtract(60, 'minutes');
    case Interval.DAY:
      return to.startOf('day');
    case Interval.WEEK:
      return to.startOf('week');
    case Interval.MONTH:
      return to.startOf('month');
    case Interval.YEAR:
      return to.startOf('year');
    default:
      return to;
  }
}

function getBasePeriod(
  from: number | undefined,
  to: number | undefined,
  period: Period,
  timezone?: string
): IDayjsRange {
  if (period !== Period.CUSTOM || from === undefined || to === undefined) {
    return getRangeForPeriod(period, timezone);
  } else {
    return {
      from: dayjs.tz(from, timezone),
      to: dayjs.tz(to, timezone)
    };
  }
}

export function getPeriodRangeForTimezone(
  settings: PeriodSettings,
  locationTimezone: string,
  retention: IRetentionPolicy | undefined,
  rounding: PeriodRoundingMode
): ActivePeriod {
  const {interval} = settings;
  let {from, to} = getBasePeriod(settings.from, settings.to, settings.period, locationTimezone);
  if (retention) {
    const {minTime, maxTime} = getLimitsFromRetention(interval, retention);
    if (minTime && from.isBefore(minTime)) from = minTime;
    if (maxTime && to.isAfter(maxTime)) to = maxTime;
    if (to < from) to = from;
  }
  if (interval === Interval.MONTH || interval === Interval.YEAR || interval === Interval.WEEK) {
    const newFrom = roundDownDayjs(from, interval);
    from = newFrom;
  } else if (interval === Interval.DAY && rounding === PeriodRoundingMode.ROUND_DOWN_GRAPH) {
    to = roundDownDayjs(to, interval);
  }
  if (interval === Interval.YEAR && rounding === PeriodRoundingMode.ROUND_DOWN_GRAPH) {
    to = roundDownDayjs(to, interval);
  }
  //  if (rounding === PeriodRoundingMode.INCLUSIVE)
  //    to++;
  if (rounding === PeriodRoundingMode.ROUND_DOWN) {
    const newTo = roundDownDayjs(to, interval);
    to = newTo;
  }
  return {
    from: from.valueOf(),
    to: to.valueOf(),
    interval,
    timezone: locationTimezone
  };
}

export function usePeriodRangeForTimezone(
  settings: PeriodSettings,
  locationTimezone: string | undefined,
  rounding: PeriodRoundingMode
): ActivePeriod | undefined {
  return useMemo(() => {
    if (locationTimezone === undefined) return undefined;

    return getPeriodRangeForTimezone(settings, locationTimezone, undefined, rounding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.from, settings.to, settings.interval, settings.period, locationTimezone, rounding]);
}

export function useCappedPeriodRangeForTimezone(
  settings: PeriodSettings,
  locationTimezone: string | undefined,
  retention: IRetentionPolicy | undefined,
  rounding: PeriodRoundingMode
): ActivePeriod | undefined {
  return useMemo(() => {
    if (locationTimezone === undefined || retention === undefined) {
      return undefined;
    }

    return getPeriodRangeForTimezone(settings, locationTimezone, retention, rounding);
  }, [settings, locationTimezone, retention, rounding]);
}

interface PeriodSelectorProps {
  onChanged: (settings: PeriodSettings) => void;
  settings: PeriodSettings;
  activeInterval?: Interval;
  allowedPeriods?: Period[];
  allowedIntervals?: Interval[];
  retention?: IRetentionPolicy;
  withoutInterval?: boolean;
}

function sanitizeDate(date: number | undefined) {
  if (date === undefined) return undefined;

  return dayjs(date);
}

const defaultAllowedPeriods = [
  Period.TODAY,
  Period.YESTERDAY,
  Period.THIS_WEEK,
  Period.LAST_WEEK,
  Period.DAYS_14,
  Period.DAYS_7,
  //Period.DAYS_1,
  Period.THIS_MONTH,
  Period.LAST_MONTH,
  Period.THIS_YEAR,
  Period.LAST_YEAR,
  Period.CUSTOM
];

const defaultAllowedIntervals = [
  Interval.MINUTES_5,
  Interval.HOUR,
  Interval.DAY,
  Interval.WEEK,
  Interval.MONTH,
  Interval.YEAR
];

export default function PeriodSelector(props: PeriodSelectorProps) {
  const {
    onChanged,
    settings,
    activeInterval,
    allowedPeriods = defaultAllowedPeriods,
    allowedIntervals = defaultAllowedIntervals,
    retention,
    withoutInterval
  } = props;

  const presenting = useAppSelector(state => state.uiState.presenting);

  const uuid = useMemo(() => uuid_v1().replace(/-/g, ''), []);
  const popoverId = `p${uuid}`;
  const popoverContentId = `c${uuid}`;
  const fromPickerId = `f${uuid}`;
  const toPickerId = `t${uuid}`;
  const warningId = `w${uuid}`;

  // Transform dates to Dayjs format format
  const from = sanitizeDate(settings.from);
  const to = sanitizeDate(settings.to);

  // Choose defaults
  const period = (allowedPeriods.includes(settings.period) && settings.period) || allowedPeriods[0];
  const interval = (allowedIntervals.includes(settings.interval) && settings.interval) || allowedIntervals[0];

  const [popoverRendered, setPopoverRendered] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState(period);
  const [selectedInterval, setSelectedInterval] = useState(interval);
  const [selectedFrom, setSelectedFrom] = useState(from);
  const [selectedTo, setSelectedTo] = useState(to);

  useEffect(() => setSelectedPeriod(period), [period]);
  useEffect(() => setSelectedInterval(interval), [interval]);

  const range = selectedPeriod === Period.CUSTOM ? undefined : getRangeForPeriod(period);
  const activeFrom = range ? range.from : selectedFrom;
  const activeTo = range ? range.to : selectedTo;

  const periodOptions = useMemo(
    () =>
      allowedPeriods.map(period => (
        <option key={period} value={period}>
          {T(PERIOD_LABELS[period])}
        </option>
      )),
    [allowedPeriods]
  );

  // const periodSelectOptions = useMemo(
  //   () =>
  //     allowedPeriods.map(period => (
  //       <SelectItem key={period} value={period}>
  //         {T(PERIOD_LABELS[period])}
  //       </SelectItem>
  //     )),
  //   [allowedPeriods]
  // );

  const intervalOptions = useMemo(
    () =>
      allowedIntervals.map(interval => (
        <option key={interval} value={interval}>
          {T(INTERVAL_LABELS[interval])}
        </option>
      )),
    [allowedIntervals]
  );

  const isPopover = (target: HTMLElement | null): boolean => {
    if (!target) return false;

    const popoverId = `p${uuid}`;
    const popoverContentId = `c${uuid}`;
    const fromPickerId = `f${uuid}`;
    const toPickerId = `t${uuid}`;

    const targetElement = target as HTMLElement;
    if (
      targetElement.id === popoverContentId ||
      targetElement.id === popoverId ||
      targetElement.id === fromPickerId ||
      targetElement.id === toPickerId ||
      targetElement.classList.contains('react-datepicker') ||
      (targetElement.nodeName === 'BUTTON' && targetElement.parentElement === null)
    ) {
      // happens with datepicker arrows which have been removed
      return true;
    }

    return isPopover(targetElement.parentElement);
  };

  useEffect(() => {
    if (popoverOpen) {
      const windowClickListener = (ev: MouseEvent) => {
        if (isPopover(ev.target as HTMLElement | null)) return;

        setPopoverOpen(false);
      };

      window.document.addEventListener('click', windowClickListener);
      return () => window.document.removeEventListener('click', windowClickListener);
    }
  });

  const haveValuesChanged =
    selectedPeriod !== period || selectedInterval !== interval || selectedFrom !== from || selectedTo !== to;

  const togglePopover = () => {
    const newPopoverOpen = !popoverOpen;
    setPopoverOpen(state => !state);
    if (!newPopoverOpen && haveValuesChanged) saveChanges();

    setPopoverRendered(true);
    setPopoverOpen(newPopoverOpen);
  };

  const handlePeriodChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSelectedPeriod(event.currentTarget.value as Period);
  };

  // const handleSelectedPeriod = (period: string) => {
  //   setSelectedPeriod(period as Period);
  // };

  const handleIntervalChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSelectedInterval(event.currentTarget.value as Interval);
  };

  const handleFromChanged = (date: Date | null) => {
    const from = date === null ? undefined : dayjs(date);
    setSelectedPeriod(Period.CUSTOM);
    setSelectedFrom(from);
  };

  const handleToChanged = (date: Date | null) => {
    const to = date === null ? undefined : dayjs(date).endOf('day');
    setSelectedPeriod(Period.CUSTOM);
    setSelectedTo(to);
  };

  const saveChanges = () => {
    let from = selectedFrom;
    let to = selectedTo;
    if (to && from && to.isBefore(from)) {
      const tempMoment = from;
      from = to.startOf('day');
      to = tempMoment.endOf('day');
    }

    onChanged({
      period: selectedPeriod,
      interval: selectedInterval,
      from: from && from.valueOf(),
      to: to && to.valueOf()
    });
  };

  const renderWarning = () => {
    const currentInterval = props.activeInterval;

    // Format tooltip with fallback labels
    const currentLabel = T(INTERVAL_LABELS[currentInterval || ''] || 'periodSelector.interval');
    const activeLabel = T(INTERVAL_LABELS[interval] || 'intervalValues.requested');
    const tooltipBody = T('periodSelector.otherIntervalShown', {
      actualInterval: currentLabel.toLocaleLowerCase(),
      requestedInterval: activeLabel.toLocaleLowerCase()
    });

    return (
      // <div
      //   id={warningId}
      //   key="warning-icon"
      //   className={`fal fa-info-circle ${styles.warningIcon}`}
      //   title={tooltipBody ? tooltipBody : undefined}
      // />
      <span key="warning-icon" title={tooltipBody ? tooltipBody : undefined} className="tw-ml-2">
        <InfoCircle />
      </span>
    );
  };

  const renderTooltipText = () => {
    const intervalLabel = T(INTERVAL_LABELS[activeInterval || interval]);
    let tooltipBody = null;

    if (intervalLabel && activeFrom && activeTo) {
      tooltipBody = T('periodSelector.tooltip', {
        interval: intervalLabel,
        from: activeFrom.format(FORMAT_DATE_S),
        to: activeTo.format(FORMAT_DATE_S)
      });
    }

    return tooltipBody;
  };

  const hasCustomPeriod = allowedPeriods.includes(Period.CUSTOM);
  const tooltipBody = renderTooltipText();
  const showWarning = activeInterval !== undefined && activeInterval !== interval;

  if (presenting) {
    return (
      <div className={styles.action}>
        <div>
          <span className="fal fa-calendar-alt" style={{marginRight: '4px'}} />
          &nbsp;
          {T(PERIOD_LABELS[period])}
        </div>
        <span className={styles.presentationMode}>{tooltipBody}</span>
      </div>
    );
  }

  const {minTime, maxTime} = getLimitsFromRetention(selectedInterval, retention);
  const minDate = minTime === undefined ? undefined : minTime.toDate();
  const maxDate = maxTime === undefined ? undefined : maxTime.toDate();
  return (
    <div className={classes(styles.action, testingClasses.rangeSelector)} data-testid={testingClasses.rangeSelector}>
      <Button
        id={popoverId}
        title={tooltipBody ? tooltipBody : ''}
        variant="secondary_default"
        onClick={togglePopover}
        className={cn('tw-whitespace-nowrap', testingClasses.rangeSelectorButton)}
        data-testid={testingClasses.rangeSelectorButton}
      >
        <span className="tw-mr-2">
          <Calendar />
        </span>
        {T(PERIOD_LABELS[period])}
      </Button>

      {/* Show warning that the active intervals are not the same  */}
      {showWarning && renderWarning()}

      {popoverRendered && (
        <Popover
          id={popoverContentId}
          fade={true}
          delay={100}
          className={styles.periodSelector}
          placement="bottom"
          target={popoverId}
          isOpen={popoverOpen}
        >
          <PopoverBody className={styles.periodSelectorBody}>
            <FormGroup>
              <Label>{T('periodSelector.field.period')}</Label>
              <Input
                type="select"
                value={selectedPeriod}
                name="period"
                className={testingClasses.rangeSelectorPeriod}
                onChange={handlePeriodChanged}
                data-testid={testingClasses.rangeSelectorPeriod}
              >
                {periodOptions}
              </Input>
              {/* @todo: Wait with the new Select within a parent popup due to focus and z-index issues */}
              {/* <Select name="period" value={selectedPeriod} onValueChange={period => handleSelectedPeriod(period)}>
                <SelectTrigger
                  className={classnames(
                    testingClasses.rangeSelectorPeriod,
                    'tw-pl-1 !tw-ml-6 !tw-max-w-60 !tw-text-base}'
                  )}
                  data-testid={testingClasses.rangeSelectorPeriod}
                >
                  <SelectValue />
                </SelectTrigger>
                <SelectContent className="!tw-z-[10000]">
                  <SelectGroup>{periodSelectOptions}</SelectGroup>
                </SelectContent>
              </Select> */}
            </FormGroup>
            {withoutInterval ? (
              <FormGroup />
            ) : (
              <FormGroup>
                <Label>{T('periodSelector.field.interval')}</Label>
                <Input
                  type="select"
                  value={selectedInterval}
                  name="interval"
                  className={testingClasses.rangeSelectorInterval}
                  onChange={handleIntervalChanged}
                  data-testid={testingClasses.rangeSelectorInterval}
                >
                  {intervalOptions}
                </Input>
              </FormGroup>
            )}

            {hasCustomPeriod && (
              <FormGroup>
                <Label>{T('periodSelector.field.from')}</Label>
                <DatePicker
                  id={fromPickerId}
                  name="from"
                  minDate={minDate}
                  maxDate={maxDate}
                  selected={activeFrom ? activeFrom.toDate() : undefined}
                  dateFormat={FORMAT_DATE_S_PICKER}
                  className={classes('form-control', testingClasses.rangeSelectorFrom)}
                  data-testid={testingClasses.rangeSelectorFrom}
                  onChange={handleFromChanged}
                />
              </FormGroup>
            )}
            {hasCustomPeriod && (
              <FormGroup>
                <Label>{T('periodSelector.field.to')}</Label>
                <DatePicker
                  id={toPickerId}
                  name="to"
                  minDate={minDate}
                  maxDate={maxDate}
                  selected={activeTo ? activeTo.toDate() : undefined}
                  dateFormat={FORMAT_DATE_S_PICKER}
                  className={classes('form-control', testingClasses.rangeSelectorTo)}
                  onChange={handleToChanged}
                  data-testid={testingClasses.rangeSelectorTo}
                />
              </FormGroup>
            )}

            <div className={styles.applyPeriod}>
              <Button
                variant="primary_default"
                size="lg"
                onClick={togglePopover}
                className={testingClasses.rangeSelectorApply}
                data-testid={testingClasses.rangeSelectorApply}
              >
                {T('periodSelector.apply')}
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
}
