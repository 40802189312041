import {useEffect, useReducer} from 'react';

import {useAppContext} from '../app/context';
import {useUser} from '../cards/CardUtils';

import {getOnlineStatus} from '../models/OnlineStatus';
import {None} from '../utils/Arrays';

import {TrackingType, IPowerMessage} from './LiveDataModels';
import {MqttConnector, MqttSubscription, MqttTopicType} from './MqttConnector';

interface LiveDataLocation {
  trackingSerialNumber: string;
  uuid: string;
  id: number;
}

enum CarChargerStatesActionType {
  Reset,
  SetState,
  SetPower,
  SetOffline
}

interface BaseCarChargerStatesAction {
  type: CarChargerStatesActionType;
}

interface CarChargerStatesResetAction extends BaseCarChargerStatesAction {
  type: CarChargerStatesActionType.Reset;
}

interface CarChargerStatesSetPowerAction extends BaseCarChargerStatesAction {
  type: CarChargerStatesActionType.SetPower;
  serviceLocationId: number;
  power: IPowerMessage;
}

interface CarChargerStatesSetOfflineAction extends BaseCarChargerStatesAction {
  type: CarChargerStatesActionType.SetOffline;
  serviceLocationId: number;
}

type LiveDataStatesAction =
  | CarChargerStatesResetAction
  | CarChargerStatesSetPowerAction
  | CarChargerStatesSetOfflineAction;

export class LocationDataState {
  id: number;
  power?: IPowerMessage;
  offline: boolean;

  constructor(id: number, power?: IPowerMessage, offline: boolean = false) {
    this.id = id;
    this.power = power;
    this.offline = offline;
  }

  withPower(message: IPowerMessage) {
    return new LocationDataState(this.id, message, false);
  }

  withOffline() {
    return new LocationDataState(this.id, undefined, true);
  }

  getOnlineStatus() {
    return getOnlineStatus(this.power, this.offline, false);
  }
}

export class LocationDataStates {
  states: Map<number, LocationDataState>;

  constructor(states?: Map<number, LocationDataState>) {
    this.states = states || new Map();
  }

  getState(id: number) {
    return this.states.get(id) || new LocationDataState(id);
  }

  withState(id: number, state: LocationDataState) {
    const result = new Map(this.states);
    result.set(id, state);
    return new LocationDataStates(result);
  }
}

function locationDataReducer(state: LocationDataStates, action: LiveDataStatesAction): LocationDataStates {
  switch (action.type) {
    default:
    case CarChargerStatesActionType.Reset:
      return new LocationDataStates();
    case CarChargerStatesActionType.SetOffline:
      return state.withState(action.serviceLocationId, state.getState(action.serviceLocationId).withOffline());
    case CarChargerStatesActionType.SetPower:
      return state.withState(
        action.serviceLocationId,
        state.getState(action.serviceLocationId).withPower(action.power)
      );
  }
}

class LocationInternalState {
  serviceLocationId: number;
  onOffline: (serviceLocationId: number) => void;

  powerSubscription?: MqttSubscription;
  offlineTimeout?: NodeJS.Timeout;
  resendTrackingTimeout?: NodeJS.Timeout;

  constructor(serviceLocationId: number, onOffline: (serviceLocationId: number) => void) {
    this.serviceLocationId = serviceLocationId;
    this.onOffline = onOffline;

    this.handleOfflineTimeout = this.handleOfflineTimeout.bind(this);
    this.handleResendTrackingTimeout = this.handleResendTrackingTimeout.bind(this);

    this.offlineTimeout = setTimeout(this.handleOfflineTimeout, 65000);
    this.resendTrackingTimeout = setInterval(this.handleResendTrackingTimeout, 20000);
  }

  onMessageReceived() {
    if (this.offlineTimeout) clearTimeout(this.offlineTimeout);
    if (this.resendTrackingTimeout) clearInterval(this.resendTrackingTimeout);

    this.offlineTimeout = setTimeout(this.handleOfflineTimeout, 65000);
    this.resendTrackingTimeout = setInterval(this.handleResendTrackingTimeout, 20000);
  }

  close() {
    if (this.powerSubscription) this.powerSubscription.close();

    if (this.offlineTimeout) clearTimeout(this.offlineTimeout);
    if (this.resendTrackingTimeout) clearInterval(this.resendTrackingTimeout);
  }

  private handleOfflineTimeout() {
    this.onOffline(this.serviceLocationId);
  }

  private handleResendTrackingTimeout() {
    this.powerSubscription && this.powerSubscription.resendTrackingMessage();
  }
}

class LiveDataInternalState {
  stations: Map<number, LocationInternalState>;
  onOffline: (id: number) => void;

  constructor(onOffline: (id: number) => void) {
    this.stations = new Map<number, LocationInternalState>();
    this.onOffline = onOffline;
  }

  close() {
    this.stations.forEach(value => value.close());
    this.stations.clear();
  }

  register(id: number) {
    if (!this.stations.has(id)) {
      this.stations.set(id, new LocationInternalState(id, this.onOffline));
    }
  }

  setPowerSubscription(id: number, subscription: MqttSubscription) {
    const charger = this.stations.get(id);
    if (!charger) return;

    const current = charger.powerSubscription;
    if (current) current.close();

    charger.powerSubscription = subscription;
  }

  onMessageReceived(id: number) {
    const charger = this.stations.get(id);
    if (!charger) return;

    charger.onMessageReceived();
  }
}

export function useMultiLocationLiveData(locations: LiveDataLocation[]): LocationDataStates {
  const {mqtt} = useAppContext();
  const me = useUser();

  const [states, dispatcher] = useReducer<typeof locationDataReducer>(locationDataReducer, new LocationDataStates());

  useEffect(() => {
    const handleTimeout = (serviceLocationId: number) => {
      dispatcher({type: CarChargerStatesActionType.SetOffline, serviceLocationId});
    };

    const internalState = new LiveDataInternalState(handleTimeout);

    const setLastPowerMessage = (serviceLocationId: number, message: IPowerMessage) => {
      internalState.onMessageReceived(serviceLocationId);
      dispatcher({
        type: CarChargerStatesActionType.SetPower,
        serviceLocationId,
        power: message
      });
    };

    const handlePowerConnected = (subscription: MqttSubscription, id: number) => {
      internalState.setPowerSubscription(id, subscription);
    };

    for (var request = 0; request < locations.length; request++) {
      const {trackingSerialNumber, uuid, id} = locations[request];

      internalState.register(id);
      mqtt.subscribe(
        me.userId.toString(),
        uuid, // username
        uuid, // password
        MqttConnector.getTopic(uuid, MqttTopicType.Power),
        {
          type: TrackingType.Realtime,
          serialNumber: trackingSerialNumber,
          trackingLocationUUID: uuid
        },
        false,
        subscription => handlePowerConnected(subscription, id),
        message => setLastPowerMessage(id, message)
      );
    }

    return () => {
      internalState.close();
      dispatcher({type: CarChargerStatesActionType.Reset});
    };
  }, [mqtt, locations, dispatcher, me.userId]);

  return states;
}
