import {AuthUser, IAuthUser} from '../models/AuthUser';
import {IUploadedFile} from '../models/UploadedFile';
import {AppStore} from '../redux';
import {RepeatableAbortController} from '../utils/RepeatableAbortController';

import {APISharedLoginState} from './APISharedState';

interface StringToStringMap {
  [key: string]: string;
}

// HTTP methods
export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export interface APIResponse<T> {
  data?: T;
  statusCode: number;
  success: boolean;
  code?: string;
  error?: string;
  errors?: APISpecificError[];
  localizedError?: string; // set by dashboard itself
}

export function isAPIResponse(error: unknown): error is APIResponse<unknown> {
  return typeof (error as any).statusCode === 'number';
}

export interface APISpecificError {
  code: string;
  detail: string;
  entity?: string;
  instance?: string;
  status?: number;
  statusCode?: number;
  success?: boolean;
  title?: string;
  type?: string;
}

export class APIClient {
  static create(
    store: AppStore | undefined,
    backend: (url: string, options: any) => Promise<Response>,
    onAuthenticationFailure: () => void,
    onMaintenance: () => void
  ) {
    const loginState = new APISharedLoginState(store, backend);
    return new APIClient(loginState, undefined, onAuthenticationFailure, onMaintenance);
  }

  static stringifyBody(body: string | object | null): string {
    if (body === null) return '';

    switch (typeof body) {
      case 'string':
        return body;
      case 'object':
        return JSON.stringify(body);
      default:
        return '';
    }
  }

  loginState: APISharedLoginState;
  abortController?: RepeatableAbortController;
  onAuthenticationFailure: () => void;
  onMaintenance: () => void;
  authenticationFailed: boolean = false;
  nonCancellable?: APIClient;

  constructor(
    state: APISharedLoginState,
    abortController: RepeatableAbortController | undefined,
    onAuthenticationFailure: () => void,
    onMaintenance: () => void,
    nonCancellable?: APIClient
  ) {
    this.loginState = state;
    this.abortController = abortController;
    this.onAuthenticationFailure = onAuthenticationFailure;
    this.onMaintenance = onMaintenance;
    this.nonCancellable = nonCancellable;
  }

  getNonCancellable() {
    return this.nonCancellable || this;
  }

  withAbort(controller?: RepeatableAbortController) {
    return controller
      ? new APIClient(
          this.loginState,
          controller,
          this.onAuthenticationFailure,
          this.onMaintenance,
          this.getNonCancellable()
        )
      : this.getNonCancellable();
  }

  abort() {
    if (this.abortController) this.abortController.abort();
  }

  createHeaders(): StringToStringMap {
    const {token} = this.loginState.me;

    // Return security token or empty headers
    if (token !== '') {
      return {token};
    } else {
      return {};
    }
  }

  getUserId() {
    return this.loginState.me.userId;
  }

  getToken() {
    return this.loginState.me.token;
  }

  //
  // HTTP methods
  //

  async doCall<T>(
    method: Method,
    url: string,
    body?: string | object,
    retries = 0,
    contentType: string = '',
    isText: boolean = false,
    empty?: T,
    accept?: string,
    isBinary: boolean = false
  ): Promise<APIResponse<T>> {
    if (this.loginState.refreshingLogin) {
      return this.loginState.refreshingLogin.then(() => this.doCall(method, url, body, retries + 1));
    }

    const {backend} = this.loginState;
    const headers = this.createHeaders();
    let serializedBody: string | undefined;
    let autoContentType = '';
    if (typeof body === 'string') {
      serializedBody = body;
      autoContentType = 'text/plain';
    } else if (typeof body === 'object') {
      serializedBody = APIClient.stringifyBody(body);
      autoContentType = 'application/json';
    }

    if (serializedBody) {
      headers['Content-Type'] = contentType || autoContentType;
    }
    if (isText) headers.Accept = 'text/plain';
    else if (accept) headers.Accept = accept;

    // Do response
    const signal = this.abortController === undefined ? undefined : this.abortController.signal;
    const response = await backend(url, {
      method,
      headers,
      body: serializedBody,
      signal
    });

    const {status, statusText} = response;
    let success = status >= 200 && status < 300,
      data;

    if (status === 200) {
      try {
        if (isText) {
          data = await response.text();
        } else if (isBinary) {
          data = await response.arrayBuffer();
        } else {
          data = await response.json();
        }
      } catch (err) {
        // Nothing else to return since there is no response
        return {statusCode: status, success, data: '' as any};
      }
    } else if (status === 204 && empty !== undefined) {
      return {statusCode: 200, success, data: empty};
    } else if (status === 401 && retries < 3) {
      // Recursively retry max 3 times after refreshing login
      await this.doRefreshLogin();
      return this.doCall(method, url, body, retries + 1);
    } else if (status >= 400 && status < 599) {
      const responseText = (await response.text()) as string;
      if (responseText.startsWith('{')) {
        // new structured error message
        let structuredError = JSON.parse(responseText);
        if (status === 503) {
          if (structuredError.code === 'api.under.maintenance') {
            this.doInvokeMaintenanceInfo();
          }
        }
        return {
          statusCode: status,
          success,
          ...structuredError
        };
      } else if (responseText.startsWith('"')) {
        // old structured error message
        const responseSplit = responseText.substring(1, responseText.length - 1).split(':', 2);
        return {
          statusCode: status,
          success,
          code: responseSplit[0],
          error: responseSplit[1]
        };
      } else if (responseText.includes(':')) {
        // old structured error message
        const responseSplit = responseText.split(':', 2);
        return {
          statusCode: status,
          success,
          code: responseSplit[0],
          error: responseSplit[1]
        };
      } else if (responseText === '') {
        return {
          statusCode: status,
          success,
          error: statusText
        };
      } else {
        return {
          statusCode: status,
          success,
          error: responseText
        };
      }
    }

    return {
      data: data || '',
      statusCode: status,
      success
    };
  }

  async doCheckedCall<T>(method: Method, url: string, body?: string | object, contentType: string = ''): Promise<T> {
    const result = await this.doCall<T>(method, url, body, 0, contentType);
    if ((result.statusCode >= 400 && result.statusCode <= 599) || result.data === undefined) {
      throw result;
    } else {
      return result.data;
    }
  }

  async doGet<T>(url: string, empty?: T): Promise<T> {
    const result = await this.doCall<T>(Method.GET, url, undefined, undefined, undefined, undefined, empty);
    if (result.data === undefined) throw result;

    return result.data;
  }

  async doGetText(url: string): Promise<string> {
    const result = await this.doCall<string>(Method.GET, url, undefined, undefined, undefined, true);
    if (result.data === undefined) throw result;

    return result.data;
  }

  async doGetWithNoContentAllowed<T>(url: string): Promise<T | undefined> {
    const result = await this.doCall<T>(Method.GET, url);
    return result.data;
  }

  async doGetWithStatus<T>(url: string): Promise<APIResponse<T>> {
    return this.doCall(Method.GET, url);
  }

  async doPost<T>(url: string, body: object | string): Promise<T> {
    return this.doCheckedCall(Method.POST, url, body);
  }

  async doPostWithStatus<T>(url: string, body: object | string): Promise<APIResponse<T>> {
    return this.doCall(Method.POST, url, body);
  }

  async doPut<T>(url: string, body: object | string = '', contentType: string = ''): Promise<T> {
    return this.doCheckedCall(Method.PUT, url, body, contentType);
  }

  async doPutWithStatus<T>(url: string, body: object | string): Promise<APIResponse<T>> {
    return this.doCall(Method.PUT, url, body);
  }

  async doPatch<T>(url: string, body: object | string): Promise<T> {
    return this.doCheckedCall(Method.PATCH, url, body);
  }

  async doPatchWithStatus<T>(url: string, body: object | string): Promise<APIResponse<T>> {
    return this.doCall(Method.PATCH, url, body);
  }

  async doDelete<T>(url: string, body: object | string = ''): Promise<T> {
    return this.doCheckedCall(Method.DELETE, url, body);
  }

  async doDeleteWithStatus<T>(url: string, body: object | string = ''): Promise<APIResponse<T>> {
    return this.doCall(Method.DELETE, url, body);
  }

  async doUpload(url: string, file: File, name: string = 'file'): Promise<unknown> {
    const headers = this.createHeaders();

    // Create new form and attach file
    const body = new FormData();
    body.append(name, file);

    // Append token to authorization header
    headers.Authorization = headers.token;

    // Disable CORS to automatically append boundary to content type
    const response: Response & {data?: any} = await this.loginState.backend(url, {
      method: Method.POST,
      headers,
      body,
      mode: 'same-origin'
    });

    // Append data to response object
    try {
      response.data = await response.json();
    } catch (err) {
      // Nothing to do here since there is no response
    }

    return response;
  }

  doRefreshLogin(newLanguage?: string, newRefreshToken?: string): Promise<unknown> {
    if (this.loginState.refreshingLogin) return this.loginState.refreshingLogin;

    const {refreshToken, language} = this.loginState.me;

    return (this.loginState.refreshingLogin = fetch('/dashapi/refreshToken', {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        refreshToken: newRefreshToken || refreshToken,
        language: newLanguage || language
      })
    }).then(response => {
      if (response.status === 200) {
        return response.json().then((data: IAuthUser) => {
          const currentUser = this.loginState.me.serialize();
          const me = {...currentUser, ...data};
          this.loginState.refreshingLogin = null;
          this.loginState.setUser(new AuthUser(me));
        });
      } else if (response.status === 503) {
        this.doInvokeMaintenanceInfo();
        throw Error('Maintenance');
      } else {
        this.onAuthenticationFailure();
        throw Error('Authentication failed');
      }
    }));
  }

  doInvokeMaintenanceInfo() {
    this.onMaintenance();
  }

  uploadFile(url: string, file: File, fileParameterName = 'upfile'): Promise<IUploadedFile[]> {
    const data = new FormData();
    data.append(fileParameterName, file, file.name);
    return fetch(url, {
      method: 'POST',
      body: data,
      headers: {token: this.loginState.me.token}
    }).then(response => response.json());
  }

  uploadBlob(url: string, content: Blob, name: string): Promise<IUploadedFile[]> {
    const data = new FormData();
    data.append('upfile', content, name);
    return fetch(url, {
      method: 'POST',
      body: data,
      headers: {token: this.loginState.me.token}
    }).then(response => response.json());
  }
}
