import React from 'react';

import {Col, Form, Row} from '../../../components/bootstrap';
import {AddressInput} from '../../../components/inputs/AddressInput';
import SelectLanguageInputGroup from '../../../components/inputs/SelectLanguageInputGroup';
import {TextInputGroup} from '../../../components/inputs/TextInput';
import {Checkbox} from '../../../components/ui/checkbox';
import {Contract} from '../../../models/Contract';
import {FormState} from '../../../utils/FormState';
import {T} from '../../../utils/Internationalization';
import {validateEmail, validateRequired} from '../../../utils/Validation';

import {useUser} from '../../CardUtils';

import {EditOrganizationFormState} from './EditOrganizationModels';

interface EditOrganizationAccountingTabProps {
  formState: FormState;
  state: EditOrganizationFormState;
  updateState: (updates: Partial<EditOrganizationFormState>) => void;
  contracts: Contract[];
}

export default function EditOrganizationAccountingTab(props: EditOrganizationAccountingTabProps) {
  const {formState, state, updateState, contracts} = props;
  const me = useUser();
  const {linkAccounting} = state;
  const hasContracts = contracts.length > 0;
  const hasSelfBilling = contracts.includes(Contract.ChargingHost);

  return (
    <Form style={{marginTop: '0.5rem'}}>
      {!hasContracts && (
        <Row>
          <Col md={12}>
            <Checkbox
              id="link_accounting"
              name="link_accounting"
              label={T('organizations.linkAccounting')}
              info={T('organizations.linkAccounting.info')}
              checked={linkAccounting}
              onCheckedChange={linkAccounting => updateState({linkAccounting})}
              testId="link_accounting"
            />
          </Col>
        </Row>
      )}
      {linkAccounting && (
        <Row>
          <Col xl={4}>
            <h3>{T('organizations.column.companyInfo')}</h3>
            <TextInputGroup
              form={formState}
              name="vat"
              label={T('organizations.field.vat')}
              value={state.vat}
              onChange={vat => updateState({vat})}
              disabled={!me.isServiceDesk()}
              validate={validateRequired}
              error={formState.getServerError('vat')}
            />
            <AddressInput
              name="company_address"
              value={state.address}
              onChange={changes => updateState({address: {...state.address, ...changes}})}
              streetLabel={T('organizationRegistration.company.address.street')}
              cityLabel={T('organizationRegistration.company.address.city')}
              countryLabel={T('organizationRegistration.company.country')}
            />
          </Col>
          <Col md={4}>
            <h3>{T('organizations.column.contactInfo')}</h3>
            <SelectLanguageInputGroup
              name="contact_language"
              label={T('organizationRegistration.contact.language')}
              value={state.contactLanguage}
              onChange={contactLanguage => updateState({contactLanguage})}
            />
            <TextInputGroup
              name="contact_firstname"
              label={T('organizationRegistration.contact.firstName')}
              value={state.contactFirstName}
              onChange={contactFirstName => updateState({contactFirstName})}
              validate={validateRequired}
            />
            <TextInputGroup
              name="contact_lastname"
              label={T('organizationRegistration.contact.lastName')}
              value={state.contactLastName}
              onChange={contactLastName => updateState({contactLastName})}
              validate={validateRequired}
            />
            <TextInputGroup
              name="contact_email"
              label={T('organizationRegistration.contact.email')}
              value={state.contactEmail}
              onChange={contactEmail => updateState({contactEmail})}
              validate={validateEmail}
            />
            <TextInputGroup
              name="contact_phone"
              label={T('organizationRegistration.contact.phone')}
              value={state.contactPhone}
              onChange={contactPhone => updateState({contactPhone})}
              optional
            />
          </Col>
          <Col md={4}>
            <h3>{T('organizations.column.billingInfo')}</h3>
            <Checkbox
              id="billing_same"
              name="billing_same"
              label={T('organizations.billingSameAsCompany')}
              info={T('organizations.billingSameAsCompany.info')}
              checked={state.billingSameAsCompany}
              onCheckedChange={billingSameAsCompany => updateState({billingSameAsCompany})}
              testId="billing_same"
              wrapperClassName="tw-mb-4"
              labelClassName="tw-leading-[136%]"
            />
            {!state.billingSameAsCompany && (
              <>
                <SelectLanguageInputGroup
                  name="billing_language"
                  label={T('organizationRegistration.billing.language')}
                  value={state.billingLanguage}
                  onChange={billingLanguage => updateState({billingLanguage})}
                />
                <TextInputGroup
                  name="billing_firstname"
                  label={T('organizationRegistration.billing.firstName')}
                  value={state.billingFirstName}
                  onChange={billingFirstName => updateState({billingFirstName})}
                />
                <TextInputGroup
                  name="billing_lastname"
                  label={T('organizationRegistration.billing.lastName')}
                  value={state.billingLastName}
                  onChange={billingLastName => updateState({billingLastName})}
                />
                <TextInputGroup
                  name="billing_email"
                  label={T('organizationRegistration.billing.email')}
                  value={state.billingEmail}
                  onChange={billingEmail => updateState({billingEmail})}
                />
                <TextInputGroup
                  name="billing_phone"
                  label={T('organizationRegistration.billing.phone')}
                  value={state.billingPhone}
                  onChange={billingPhone => updateState({billingPhone})}
                  optional
                />
                <AddressInput
                  name="billing_address"
                  value={state.billingAddress}
                  onChange={changes =>
                    updateState({
                      billingAddress: {...state.billingAddress, ...changes}
                    })
                  }
                  streetLabel={T('organizationRegistration.billing.address.street')}
                  cityLabel={T('organizationRegistration.billing.address.city')}
                  countryLabel={T('organizationRegistration.billing.country')}
                />
              </>
            )}
          </Col>
        </Row>
      )}
    </Form>
  );
}
