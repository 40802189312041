import React from 'react';

import {Button as RsButton} from '../../../components/bootstrap';
import {Button} from '../../../components/ui/button';
import {Bin, EyeVisible, FilePdf, Play} from '../../../components/ui-lib/icons/small';
import {
  FluviusMeasurementCampaignType,
  IFluviusCampaign,
  FluviusCampaignStatus
} from '../../../models/FluviusMeasurementCampaign';
import {IMeasuringCase, IOrganizationRegion} from '../../../models/Organization';
import {ITableField, CalculatedStringField, StringField, ComponentField, TimestampField} from '../../../models/Table';

interface Callbacks {
  onClickedView: (item: IFluviusCampaign) => void;
  onClickedViewPDF: (item: IFluviusCampaign) => void;
  onClickedStart: (item: IFluviusCampaign) => void;
  onClickedRemove: (item: IFluviusCampaign) => void;
}

export const rowKey = (item: IFluviusCampaign) => item.id;

export function getTableColumns(
  regions: IOrganizationRegion[],
  measuringCases: IMeasuringCase[],
  callbacks: Callbacks
): ITableField<IFluviusCampaign>[] {
  const {onClickedView, onClickedViewPDF, onClickedStart, onClickedRemove} = callbacks;
  return [
    new TimestampField('creationTimestamp', 'Datum aanmaak'),
    new CalculatedStringField('type', 'Type', item => {
      if (item.configuration.type === FluviusMeasurementCampaignType.Cabine) {
        return 'Cabine';
      } else return 'Voetpadkast';
    }),
    new CalculatedStringField('status', 'Status', item =>
      item.status === FluviusCampaignStatus.Active
        ? item.confirmActivation
          ? 'Actief'
          : 'Wacht op activatie'
        : 'Inactief'
    ),
    new CalculatedStringField('region', 'Regio', item => {
      const region = regions.find(r => r.id === item.configuration.region);
      if (!region) return undefined;
      return region.name;
    }),
    new CalculatedStringField('set', 'Meetset', item => {
      const measurementCase = measuringCases.find(c => c.id === item.configuration.measuringCase);
      if (!measurementCase) return undefined;
      return measurementCase.name;
    }),
    new StringField('name', 'Naam'),
    new CalculatedStringField(
      'vpkOrCabinId',
      'Cabine/VPK ID',
      item => item.configuration.vpkId || item.configuration.cabinId
    ),
    new CalculatedStringField('city', 'Gemeente', item => item.configuration.city),
    new CalculatedStringField('address', 'Adres', item => item.configuration.address),
    new CalculatedStringField('reason', 'Reden', item => item.configuration.reason),
    new CalculatedStringField('notes', 'Opmerkingen', item => item.configuration.notes),
    new ComponentField(
      'actions',
      'Acties',
      item => {
        return (
          <>
            <Button variant="ghost_action_btn" title="Bekijken" onClick={() => onClickedView(item)} className="tw-mr-2">
              <EyeVisible width={16} height={16} />
            </Button>
            <Button
              variant="ghost_action_btn"
              title="PDF openen"
              onClick={() => onClickedViewPDF(item)}
              className="tw-mr-2"
            >
              <FilePdf width={16} height={16} />
            </Button>
            {item.status === FluviusCampaignStatus.Inactive && (
              <Button
                variant="ghost_action_btn"
                title="Starten"
                onClick={() => onClickedStart(item)}
                className="tw-mr-2"
              >
                <Play width={16} height={16} />
              </Button>
            )}
            {item.status === FluviusCampaignStatus.Inactive && (
              <Button variant="ghost_action_btn" title="Verwijderen" onClick={() => onClickedRemove(item)}>
                <Bin width={16} height={16} />
              </Button>
            )}
          </>
        );
      },
      {autoInsert: 'end'}
    )
  ];
}
