import React from 'react';

import {getSidePower} from '../../livedata/LiveChargingUtils';
import {
  translateCarChargerIECStatus,
  isCharging,
  getChargingStatusColors,
  getChargingStatusColor
} from '../../models/ChargingStatus';
import {SmartDeviceConnectionStatus, getSmartDeviceConnectionStatusLabel} from '../../models/SmartDevice';
import {ITableField, IFieldOptions, DEFAULT_OPTIONS} from '../../models/Table';

import {T} from '../../utils/Internationalization';

import {IChargingStationWithLiveData} from './Columns';

function getSideStatus(item: IChargingStationWithLiveData, position: number) {
  if (item.station.forcedState) {
    return translateCarChargerIECStatus(
      item.station.forcedState.state[position - 1],
      undefined,
      item.station.isUltra()
    );
  }
  if (item.station.getCarCharger(position) === undefined) {
    return undefined;
  }

  const status = item.status[position - 1];
  if ((status === undefined || status.available === undefined) && !item.station.data.available) {
    return T('chargingStation.status.unavailable');
  }
  if (status && status.available === false) {
    return T('chargingStation.status.unavailable');
  }

  const connectionStatus = item.connectionStatus[position - 1];
  if (connectionStatus === undefined) return undefined;

  if (connectionStatus !== SmartDeviceConnectionStatus.Connected) {
    return getSmartDeviceConnectionStatusLabel(connectionStatus, false);
  } else {
    return translateCarChargerIECStatus(
      status && status.iecStatus,
      status && status.chargingState,
      item.station.isUltra()
    );
  }
}

function getCombinedSideStatus(item: IChargingStationWithLiveData, position: number) {
  const status = item.status[position - 1];
  const iecStatus = item.station.forcedState
    ? item.station.forcedState.state[position - 1]
    : status && status.iecStatus;
  const chargingState = status && status.chargingState;
  const charging = isCharging(iecStatus, item.station.isUltra());
  let result = getSideStatus(item, position);
  if (result && charging && item.station.data.trackingSerialNumber !== undefined) {
    result += ` ${getSidePower(item.power, item.perSidePower, item.station, position)}`;
  }
  return result;
}

interface ColoredDotProps {
  color: string;
}
export function ColoredDot(props: ColoredDotProps) {
  const {color} = props;
  return <span style={{color}}>&#x2B24;</span>;
}

export default class ChargingStatusField implements ITableField<IChargingStationWithLiveData> {
  name: string;
  label: string;
  connector: number;
  options: IFieldOptions;

  constructor(name: string, label: string, connector: number, options: Partial<IFieldOptions> = {}) {
    this.name = name;
    this.label = label;
    this.connector = connector;
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  renderCellContent(item: IChargingStationWithLiveData) {
    const value = this.getValue(item);
    if (value === undefined) return undefined;

    let color;
    const status = item.status[this.connector - 1];
    const iecStatus = item.station.forcedState
      ? item.station.forcedState.state[this.connector - 1]
      : status && status.iecStatus;
    const chargingState = status && status.chargingState;

    if (iecStatus) {
      const colors = getChargingStatusColors(getChargingStatusColor(iecStatus, chargingState, item.station.isUltra()));
      color = colors.background;
    }
    return (
      <>
        {value} {color && color !== 'white' && <ColoredDot color={color} />}
      </>
    );
  }

  getExportCSVValue(item: IChargingStationWithLiveData): string {
    return this.getValue(item) || '';
  }

  getExportExcelValue(item: IChargingStationWithLiveData): string {
    return this.getValue(item) || '';
  }

  getValue(item: IChargingStationWithLiveData): string | undefined {
    return getCombinedSideStatus(item, this.connector);
  }

  sort = (a: IChargingStationWithLiveData, b: IChargingStationWithLiveData): number => {
    const aValue = this.getValue(a);
    const bValue = this.getValue(b);
    if (aValue === bValue) return 0;
    if (aValue === undefined) return -1;
    if (bValue === undefined) return 1;
    return aValue.localeCompare(bValue);
  };
}
