import {useMemo, useState} from 'react';

import {UserRights} from '../../models/AuthUser';
import {IChargingSession} from '../../models/ChargingStation';
import {isClosed, SplitBillingAgreement} from '../../models/SplitBillingAgreement';
import {None} from '../../utils/Arrays';
import {useCardLoader} from '../../utils/Hooks';
import {plural} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardChargingStation, useUser} from '../CardUtils';
import {Reload} from '../components/actions';
import {cardViewProps} from '../components/CardView';
import {ISplitBillingBaseCardSettings} from '../SplitBilling/SplitBillingCard';
import {SplitBillingCard} from '../SplitBilling/SplitBillingCard';

function SplitBilling(props: ICardProps<ISplitBillingBaseCardSettings>) {
  const {settings, updateSettings} = props;
  const me = useUser();

  const chargingStation = useCardChargingStation(settings);
  const serialNumber = chargingStation?.chargingStation?.serialNumber;

  const [agreements, refreshAgreements] = useCardLoader<SplitBillingAgreement[]>(
    async api => (serialNumber ? api.chargingStations.getSplitBillingAgreements(serialNumber) : Promise.resolve(None)),
    [serialNumber],
    plural('splitBillingAgreement'),
    None
  );
  const [selectedAgreement, setSelectedAgreement] = useState<SplitBillingAgreement>();
  const [sessions, refreshSessions] = useCardLoader<IChargingSession[]>(
    async api => (selectedAgreement ? api.getSplitBillingChargingSessions(selectedAgreement.id) : None),
    [selectedAgreement],
    plural('chargingSession'),
    None
  );

  const [showPending, setShowPending] = useState(true);
  const [showActive, setShowActive] = useState(true);
  const [showClosed, setShowClosed] = useState(true);
  const [addLoading, setAddLoading] = useState(false);

  const filteredAgreements = useMemo(() => {
    if (showPending && showActive && showClosed) return agreements;

    return agreements.filter(agreement => {
      if (agreement.confirmed) {
        const closed = isClosed(agreement);
        return closed ? showClosed : showActive;
      } else {
        return showPending;
      }
    });
  }, [agreements, showPending, showActive, showClosed]);

  const refresh = () => {
    refreshAgreements();
    refreshSessions();
  };

  const actions = <Reload onReload={refresh} />;

  return (
    <SplitBillingCard
      readOnly={me.isRegularUser()}
      agreements={filteredAgreements}
      actions={actions}
      addLoading={addLoading}
      isEmployer={me.isServiceDesk()}
      refreshAgreements={refreshAgreements}
      selectedAgreement={selectedAgreement}
      setSelectedAgreement={setSelectedAgreement}
      sessions={sessions}
      updateSettings={updateSettings}
      {...cardViewProps(props)}
    />
  );
}

const CARD: ICardType<ISplitBillingBaseCardSettings> = {
  type: CardTypeKey.SplitBillingOnStation,
  title: 'splitBillingOnStation.title',
  description: 'splitBillingOnStation.description',
  categories: [CardCategory.EV],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: {
    agreementsTable: {
      pageSize: 10,
      columns: [
        {name: 'status', visible: true},
        {name: 'rfid', visible: true},
        {name: 'chargingStationSerial', visible: true},
        {name: 'from', visible: true},
        {name: 'to', visible: true},
        {name: 'organization', visible: true},
        {name: 'firstName', visible: true},
        {name: 'lastName', visible: true}
      ]
    },
    sessionsTable: {
      pageSize: 10,
      columns: [
        {name: 'from', visible: true},
        {name: 'to', visible: true},
        {name: 'duration', visible: true},
        {name: 'energy', visible: true},
        {name: 'reinbursement', visible: true}
      ]
    }
  },
  locationAware: CardLocationAwareness.RequiresChargingStation,
  cardClass: SplitBilling
};
export default CARD;
