import React, {useEffect, useState} from 'react';

import {useAppContext} from '../../app/context';
import {Alert, SingleActionModal} from '../../components/bootstrap';
import {OrganizationInputGroup, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {SelectInputGroup} from '../../components/inputs/SelectInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IChargingSession} from '../../models/ChargingStation';
import {SplitBillingAgreement} from '../../models/SplitBillingAgreement';
import {None} from '../../utils/Arrays';
import {translateError} from '../../utils/Errors';
import {T} from '../../utils/Internationalization';
import {getStatus} from '../SplitBilling/Columns';

interface FixSessionsModalProps extends IPromiseModalProps<boolean> {
  sessions: IChargingSession[];
}

export function FixSessionsModal(props: FixSessionsModalProps) {
  const {sessions} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState(inputOrganizations.defaultOrganization);
  const [splitBillingAgreements, setSplitBillingAgreements] = useState<SplitBillingAgreement[]>(None);
  const [splitBillingAgreementId, setSplitBillingAgreementId] = useState<number>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (organization?.id) {
      api.organizations
        .getSplitBillingAgreements(organization.id)
        .then(agreements => {
          setSplitBillingAgreements(agreements);
          setSplitBillingAgreementId(agreements[0]?.id);
        })
        .catch(() => setSplitBillingAgreements(None));
    }
  }, [api, organization?.id]);

  const handleClickedSave = () => {
    console.log('Saving to', splitBillingAgreementId);
    if (!splitBillingAgreementId) return Promise.resolve('No split billing agreement selected');

    const promise = api.chargingSessions.updatePaymentSpecificationInBulk(
      sessions.map(session => session.id),
      {
        newPaymentType: 'SPLITBILLING',
        splitBillingAgreementId
      }
    );

    return promise
      .then(() => {
        resolve(true);
        return undefined;
      })
      .catch(e => {
        const err = translateError(e);
        setError(err);
        return err;
      });
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('chargingSessions.fix.title')}
      action={handleClickedSave}
      error={error}
    >
      <div style={{marginTop: '0.5rem'}}>
        <OrganizationInputGroup
          name="organization"
          value={organization}
          onChange={setOrganization}
          label="Organization"
          organizations={inputOrganizations.organizations}
          onUpdateQuery={updateOrganizationInputQuery}
        />
        <SelectInputGroup
          name="splitBillingAgreement"
          value={splitBillingAgreementId?.toString() || ''}
          onChange={v => setSplitBillingAgreementId(parseInt(v))}
          label="Split billing agreement"
        >
          {splitBillingAgreements.map(agreement => (
            <option key={agreement.id} value={agreement.id}>
              {`${agreement.refund.invoice.firstName} ${agreement.refund.invoice.lastName} (${getStatus(agreement)})`}
            </option>
          ))}
        </SelectInputGroup>
        <Alert color="info">
          Updating the payment specification of charging sessions will change the payment type to split billing and link
          it to a split billing agreement. The session rate will follow the current rate in the split billing agreement.
        </Alert>
      </div>
    </SingleActionModal>
  );
}
