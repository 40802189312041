import React, {useEffect, useReducer} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {Icons} from '../../components/Icon';
import {NumberInputGroup, NumberValue} from '../../components/inputs/NumberInput';
import {Button} from '../../components/ui/button';
import {Checkbox} from '../../components/ui/checkbox';
import {Edit} from '../../components/ui-lib/icons/small';
import {useModals} from '../../modals/ModalContext';

import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ILocationSummary, isReadOnly} from '../../models/Location';
import {IOverloadProtectionConfiguration} from '../../models/OverloadProtection';
import {FormProvider} from '../../utils/FormContext';
import {useFormState} from '../../utils/FormState';
import {useLocationIdWithGrid} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {useCardContext} from '../CardContext';
import {useUser} from '../CardUtils';

import styles from './EditConfig.module.scss';

const overloadConfigurationReducer = (
  state: IOverloadProtectionConfiguration,
  newState: Partial<IOverloadProtectionConfiguration>
) => {
  return {...state, ...newState};
};

interface MaximumLoadModalProps extends IPromiseModalProps<boolean> {
  readOnly: boolean;
  configuration: IOverloadProtectionConfiguration;
  locationId: number;
  locationIdWithGrid?: number;
  onSave?: (value: IOverloadProtectionConfiguration) => void;
}

function MaximumLoadModal(props: MaximumLoadModalProps) {
  const [isOpen, resolve] = usePromiseModal(props);
  const form = useFormState();
  const {fetch} = useCardContext();
  const [error, setError] = React.useState<string | undefined>();
  const [loadingState, setLoadingState] = React.useState<'saving' | 'idle'>('idle');
  const [config, setMaximumLoadConfiguration] = useReducer(overloadConfigurationReducer, props.configuration);

  const {readOnly, locationIdWithGrid} = props;

  async function handleSave() {
    if (!locationIdWithGrid) return;

    if (form.hasErrors()) {
      return form.showErrors();
    }

    setLoadingState('saving');

    try {
      await fetch(
        'save',
        api => api.updateOverloadProtection(locationIdWithGrid, config),
        T('smartDevices.loading.maximumLoad')
      );
      setLoadingState('idle');

      NotificationManager.success('Maximum load settings saved', 'Success', 5000);
    } catch (error: any) {
      setLoadingState('idle');
      setError(error.message || error.error);

      throw error.error;
    }

    setLoadingState('idle');
    props.onSave?.(config);

    resolve(true);
  }

  const {maximumLoad, active} = config;

  return (
    <FormProvider>
      <SingleActionModal
        error={error}
        title={T('smartDevices.maximumLoad.label')}
        action={handleSave}
        isOpen={isOpen}
        onToggle={() => resolve(false)}
        size="md"
        loading={loadingState === 'saving'}
        actionText={T('smartDevices.maximumLoad.save')}
        disabled={form.hasErrors() || readOnly}
      >
        <Checkbox
          name="maxload-active"
          id="maxload-active"
          checked={active ?? false}
          label={T('smartDevices.maximumLoad.enable')}
          disabled={readOnly || locationIdWithGrid === undefined}
          onChange={() => setMaximumLoadConfiguration({active: !active})}
          style={{marginBottom: 0}}
          testId="maxload-active"
        />
        {active && (
          <NumberInputGroup
            name="max-load-power"
            label={T('smartDevices.maximumLoad.label')}
            suffix="A"
            disabled={readOnly || locationIdWithGrid === undefined}
            value={maximumLoad ? NumberValue.create(maximumLoad) : NumberValue.create(0)}
            onChange={value => setMaximumLoadConfiguration({maximumLoad: value.numberValue ?? undefined})}
          />
        )}
      </SingleActionModal>
    </FormProvider>
  );
}

const initialMaximumLoadConfiguration: IOverloadProtectionConfiguration = {
  active: false,
  maximumLoad: undefined
};

function MaximumLoadSettings({location}: {location: ILocationSummary}) {
  const modals = useModals();
  const {api} = useAppContext();
  const {fetch} = useCardContext();
  const [loadingState, setLoadingState] = React.useState<'loading' | 'loaded'>('loading');
  const me = useUser();
  const readOnly = isReadOnly(me, location);

  const [maximumLoadConfiguration, setMaximumLoadConfiguration] = useReducer(
    overloadConfigurationReducer,
    initialMaximumLoadConfiguration
  );
  const {active, maximumLoad} = maximumLoadConfiguration;

  const locationIdWithGrid = useLocationIdWithGrid(fetch, location);

  useEffect(() => {
    async function fetchMaximumLoadSettings() {
      if (!locationIdWithGrid) {
        return;
      }

      try {
        const configuration = await fetch(
          'overloadProtection',
          api => api.getOverloadProtection(locationIdWithGrid),
          T('smartDevices.loading.overloadProtection')
        );

        setMaximumLoadConfiguration(configuration);
      } catch (error) {
        console.error(error);
      }

      setLoadingState('loaded');
    }

    fetchMaximumLoadSettings();
  }, [api, fetch, location, locationIdWithGrid]);

  function onUpdateMaximumLoad(configuration: IOverloadProtectionConfiguration) {
    setMaximumLoadConfiguration(configuration);
  }

  async function onEdit() {
    await modals.show<boolean>(props => {
      return (
        <MaximumLoadModal
          readOnly={readOnly}
          locationIdWithGrid={locationIdWithGrid}
          locationId={location.id}
          configuration={maximumLoadConfiguration}
          onSave={onUpdateMaximumLoad}
          {...props}
        />
      );
    });
  }

  return locationIdWithGrid ? (
    <div className={styles.maxCapacity}>
      {loadingState === 'loading' && <div className={styles.center}>{Icons.Loading}</div>}
      {loadingState === 'loaded' && (
        <>
          {!maximumLoad || !active ? (
            <span>{T('ctConfiguration.maxLoadNotSet')}</span>
          ) : (
            <div className={styles.capacityQuickView}>
              <span>
                {T('ctConfiguration.maxLoad')}: {maximumLoad} A
              </span>
            </div>
          )}
          <Button variant="ghost" size="icon" onClick={onEdit}>
            <Edit className="tw-h-4 tw-w-4" />
          </Button>
        </>
      )}
    </div>
  ) : (
    <span>{''}</span>
  );
}

export {MaximumLoadSettings};
