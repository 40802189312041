import React, {useCallback} from 'react';

import {NotificationManager} from 'react-notifications';

import {APISpecificError} from '../../api/APIClient';
import {useAppContext} from '../../app/context';
import {RowActionButton} from '../../components/bootstrap/RowActions';
import Table, {migrateTableSettings} from '../../components/Table';
import {Button} from '../../components/ui/button';
import {Link} from '../../components/ui-lib/icons/small';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights, AuthUser} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {ILocationUser} from '../../models/Location';
import {ITableField, StringField, ComponentField, FieldAlignment, CalculatedStringField} from '../../models/Table';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {useLocationUsers} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useUser, useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {Spring} from '../components/CardActions';
import {cardViewProps, CardView, CustomActions} from '../components/CardView';

import {AddUserModal} from './AddUserModal';

interface IUserActionsProps {
  me: AuthUser;
  user: ILocationUser;
  onRemove: (user: ILocationUser) => void;
}
const UserActions = (props: IUserActionsProps) => {
  const {me, user, onRemove} = props;
  const canManageUsers = !me.isReadOnly();
  const handleRemove = useCallback(() => onRemove(user), [onRemove, user]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {canManageUsers && (
        <RowActionButton
          action="delete"
          title={T('sharedUsers.remove.tooltip')}
          onClick={handleRemove}
          className={testingClasses.remove}
          data-testid={testingClasses.remove}
        />
      )}
    </>
  );
};

function renderActionColumn(me: AuthUser, onClickedRemove: (user: ILocationUser) => void, user: ILocationUser) {
  return <UserActions me={me} user={user} onRemove={onClickedRemove} />;
}

function getTableColumns(me: AuthUser, onClickedRemove: (user: ILocationUser) => void): ITableField<ILocationUser>[] {
  const result: ITableField<ILocationUser>[] = [];

  if (!hasFeature(AppFeature.SocialLogin)) {
    result.push(new StringField('userName', T('sharedUsers.field.username')));
  }

  result.push(new StringField('firstName', T('sharedUsers.field.firstName')));

  if (me.isServiceDesk()) {
    result.push(new StringField('emailAddress', T('sharedUsers.field.email')));
  }

  result.push(
    new CalculatedStringField('role', T('users.field.role'), (row: {role: any}) => {
      return row.role !== 'OBSERVER' ? T('sharedUsers.field.fullAccess') : T('sharedUsers.field.readOnlyAccess');
    })
  );

  result.push(
    new ComponentField(
      'actions',
      T('sharedUsers.field.actions'),
      user => renderActionColumn(me, onClickedRemove, user),
      {autoInsert: 'end', align: FieldAlignment.Center}
    )
  );
  return result;
}

const rowKey = (item: ILocationUser) => item.id;

type ISharedUsersSettings = ICardSettingsWithTable;

const SharedUsers = (props: ICardProps<ISharedUsersSettings>) => {
  const {fetch, settings, updateSettings, loading} = props;
  const {api} = useAppContext();
  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const modals = useModals();
  const me = useUser();

  const [users, refresh] = useLocationUsers(fetch, locationId);

  const handleClickedAddUser = () => {
    if (!locationId) return;

    modals
      .show(props => <AddUserModal api={api} locationId={locationId} {...props} />)
      .then(success => {
        if (success) refresh();
      });
  };

  const handleClickedRemove = (user: ILocationUser) => {
    const name = hasFeature(AppFeature.SocialLogin) ? user.firstName : user.userName;

    modals
      .show(props => (
        <ConfirmationPromiseModal
          title={T('sharedUsers.remove.confirmTitle', {name})}
          message={T('sharedUsers.remove.confirmMessage', {name})}
          acceptLabel={T('sharedUsers.remove.confirm')}
          rejectLabel={T('sharedUsers.remove.cancel')}
          {...props}
        />
      ))
      .then(status => {
        if (status === ConfirmationResult.Accept) removeUser(user);
      });
  };

  const removeUser = async (user: ILocationUser) => {
    if (hasFeature(AppFeature.SocialLogin)) {
      if (!locationId || !user.emailAddress) return;
    } else if (!locationId || !user.userName) return;

    try {
      let message;
      if (hasFeature(AppFeature.SocialLogin)) {
        await api.removeUserFromLocationById(locationId, user.id);
        message = T('sharedUsers.removed', {name: user.firstName});
      } else {
        await api.removeUserFromLocation(locationId, user.userName);
        message = T('sharedUsers.removed', {name: user.userName});
      }

      refresh();
      NotificationManager.success(message);
    } catch (err: unknown) {
      const error = err as APISpecificError;
      let message: string;
      if (error && error.code === 'no.owner.remains') {
        message = T(error?.code);
      } else {
        message = T('sharedUsers.error.couldNotRemove', {
          name: user.firstName
        });
      }
      NotificationManager.error(message);
    }
  };

  const canManageUsers = !me.isReadOnly();
  const fields = getTableColumns(me, handleClickedRemove);

  const error = location && (location.parentId === undefined ? undefined : T('sharedUsers.error.notForChildLocations'));
  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      {state.ready && <ExportCsv name={state.title} fields={fields} items={users} settings={settings.table} />}
      {/* <Spring /> */}
      {canManageUsers && state.ready && (
        <Button
          variant="secondary_default"
          title={T('sharedUsers.share')}
          size="lg"
          onClick={handleClickedAddUser}
          className={testingClasses.addButton}
          data-testid={testingClasses.addButton}
        >
          <span className="tw-mr-2">
            <Link />
          </span>
          {T('sharedUsers.share')}
        </Button>
      )}
    </CardActions>
  );

  return (
    <CardView error={error} actions={actions} {...cardViewProps(props)}>
      {!loading && (
        <Table
          fields={fields}
          items={users}
          rowKey={rowKey}
          noun="user"
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
        />
      )}
    </CardView>
  );
};

const DEFAULT_SETTINGS: ISharedUsersSettings = {
  table: {
    pageSize: 10,
    columns: [
      ...(hasFeature(AppFeature.SocialLogin) ? [] : [{name: 'userName', visible: true}]),
      {name: 'firstName', visible: true},
      {name: 'emailAddress', visible: true},
      {name: 'role', visible: true}
    ]
  }
};
const CARD: ICardType<ISharedUsersSettings> = {
  type: CardTypeKey.SharedUsers,
  title: 'sharedUsers.title',
  description: 'sharedUsers.description',
  categories: [CardCategory.USERS],
  rights: UserRights.User,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: SharedUsers
};
export default CARD;
