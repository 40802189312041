import React, {useEffect, useState, useCallback, useMemo} from 'react';

import {Button as RsButton, FormGroup, FormText} from '../../components/bootstrap';

import {IPersistedTableSettings, SortOrder, getEffectiveColumns} from '../../components/Table';

import {Checkbox} from '../../components/ui/checkbox';
import {useMultiLocationLiveData} from '../../livedata/LiveDataMulti';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {HighOrLowLevel, ICardSettingsWithTable} from '../../models/CardSettings';
import {Device} from '../../models/Device';
import {DeviceType} from '../../models/DeviceType';
import {PhaseType, getPhaseTypeLabel} from '../../models/HighLevelConfiguration';
import {ILegacyChannel} from '../../models/LegacyChannel';
import {ILoad, ILoadUpdateChannels, getLoadName} from '../../models/Load';
import {ILocationSummary, isLocalityChild, isReadOnly, LocationFunctionType} from '../../models/Location';
import {OnlineStatus} from '../../models/OnlineStatus';
import {getPhaseLabel} from '../../models/Phase';
import {ITableField} from '../../models/Table';
import {IChannelPaths, createPathObject} from '../../models/TrackingPath';
import {getDualUltraHighLevelConfiguration, isDualUltraAssembly} from '../../utils/DualUltra';
import {useChargingStationForLocation, useHistoricalDeviceInfo} from '../../utils/FunctionalData';
import {T, plural} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useUser} from '../CardUtils';
import {CardActions, Spring} from '../components/CardActions';
import {CardView, CustomSettings, cardViewProps, CustomActions} from '../components/CardView';
import ColumnChooser from '../components/ColumnChooser';

import {
  ILiveHighLevelData,
  ILiveLowLevelData,
  ILiveProData,
  IChannelInfo,
  getLiveProData,
  getLiveHighLevelDataMulti,
  getLiveLowLevelDataMulti
} from './Data';

import EditConfigLegacy from './EditConfigLegacy';
import EditConfigMovedModal from './EditConfigMovedModal';
import InfinityHighLevel from './InfinityHighLevel';
import InfinityLowLevel from './InfinityLowLevel';
import ProLowLevel from './ProLowLevel';

export interface ILiveElectricityValuesSettings extends ICardSettingsWithTable {
  cardType: HighOrLowLevel;
}

function createUpdateChannels(updateChannels: ILoadUpdateChannels): IChannelPaths {
  const {activePower, reactivePower, current, lineVoltage, phaseVoltage} = updateChannels;
  const activeConfig = createPathObject((activePower.aspectPaths || [])[0], undefined, 'active');
  const reactiveConfig = createPathObject((reactivePower.aspectPaths || [])[0], undefined, 'reactive');
  const currentConfig = createPathObject((current.aspectPaths || [])[0], 0.001, 'current');
  const phaseConfig = createPathObject((phaseVoltage.aspectPaths || [])[0], 0.1, 'phase');
  const lineConfig = createPathObject((lineVoltage.aspectPaths || [])[0], 0.1, 'line voltage');

  return {activeConfig, reactiveConfig, currentConfig, phaseConfig, lineConfig};
}

function translateColumns(settings: IPersistedTableSettings, fields: ITableField<any>[]) {
  const newColumns = getEffectiveColumns(settings, fields);
  return {...settings, columns: newColumns};
}

function getTableColumns(
  location: ILocationSummary,
  cardType: HighOrLowLevel,
  deviceType: DeviceType,
  phaseType: PhaseType | undefined,
  channels: IChannelInfo[],
  loads: (ILoad & {channels: IChannelInfo[]})[],
  serviceDesk: boolean
): ITableField<any>[] {
  const isInfinity = deviceType && Device.isInfinity(deviceType);
  if (!isInfinity) {
    cardType = HighOrLowLevel.Low;
  }

  const isLowLevel = cardType === HighOrLowLevel.Low;
  if (!isInfinity) {
    return ProLowLevel.getTableColumns(location);
  } else if (phaseType === undefined) {
    return [];
  } else if (isLowLevel) {
    return InfinityLowLevel.getTableColumns(channels, phaseType, location, serviceDesk);
  } else {
    return InfinityHighLevel.getTableColumns(loads, phaseType, location);
  }
}

const LiveElectricityValues = (props: ICardProps<ILiveElectricityValuesSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {cardType} = settings;
  const modals = useModals();

  const location = useCardLocation(settings);

  const [loads, setLoads] = useState<(ILoad & {channels: IChannelInfo[]})[]>([]);
  const [channels, setChannels] = useState<IChannelInfo[]>([]);
  const [proChannels, setProChannels] = useState<ILegacyChannel[]>([]);
  const [phaseType, setPhaseType] = useState<PhaseType>();
  const [underConstruction, setUnderConstruction] = useState(false);
  const historicalInfo = useHistoricalDeviceInfo(fetch, location);

  const locationId = location && location.id;
  const deviceType = historicalInfo && historicalInfo.type;
  const serialNumber = historicalInfo && historicalInfo.serialNumber;
  const isUltraDualAssembly = location !== undefined && isDualUltraAssembly(location);
  const isInfinity = (deviceType && Device.isInfinity(deviceType)) || isUltraDualAssembly;
  const isLegacy = !deviceType || !Device.isInfinity(deviceType);
  const stationSerialNumber = location?.chargingStation?.serialNumber;
  const [station] = useChargingStationForLocation(fetch, isUltraDualAssembly ? location : undefined);

  const locations = useMemo(() => {
    if (isUltraDualAssembly) {
      const parts = station?.data?.parts || [];
      return parts.map((part, index) => ({
        id: part.serviceLocation.id,
        uuid: part.serviceLocation.uuid,
        trackingSerialNumber: part.trackingSerialNumber || station?.data.trackingSerialNumbers?.[index] || ''
      }));
    } else if (location?.id && location?.serialNumber) {
      return [
        {
          id: location.id,
          uuid: location.uuid,
          trackingSerialNumber: location.serialNumber
        }
      ];
    } else {
      return [];
    }
  }, [isUltraDualAssembly, station, location?.id, location?.uuid, location?.serialNumber]);
  const liveData = useMultiLocationLiveData(locations);
  const powerMessage = locationId === undefined ? undefined : liveData.getState(locationId).power;
  const status = locationId === undefined ? OnlineStatus.Unavailable : liveData.getState(locationId).getOnlineStatus();

  let error: string | JSX.Element | undefined;
  if ((!deviceType || !serialNumber || status == OnlineStatus.Unavailable) && !isUltraDualAssembly) {
    error = T('liveElectricityValues.noDeviceInstalled');
  } else if (!isUltraDualAssembly && deviceType && !Device.hasLiveValues(deviceType)) {
    error = T('liveElectricityValues.notSupportedForDevice');
  } else if (status === OnlineStatus.Offline) {
    error = T('card.error.noLiveValues');
  }
  //else if (isChild && !isServiceDesk)
  //  error = <>{T('liveElectricityValues.notSupportedForLocalityChild')}<br /><SelectLocationIdButton fetch={fetch} locationId={location!.parentId!} /></>;

  const refresh = useCallback(() => {
    if (locationId === undefined || ((!deviceType || !serialNumber) && !isDualUltraAssembly)) return;
    //if (isChild && !isServiceDesk)
    //  return;

    // Forcefully change card type if incompatible with device type
    if (!isInfinity && cardType !== HighOrLowLevel.Low) {
      updateSettings({cardType: HighOrLowLevel.Low});
      return;
    }

    const fetchInfinityData = () => {
      fetch(
        'configuration',
        api =>
          isUltraDualAssembly && stationSerialNumber
            ? api.chargingStations
                .getBySerial(stationSerialNumber)
                .then(station => getDualUltraHighLevelConfiguration(api, locationId, station))
            : api.getHighLevelConfiguration(locationId),
        T('liveElectricityValues.loading.configuration')
      ).then(config => {
        const channels: IChannelInfo[] = [];
        const loads: (ILoad & {channels: IChannelInfo[]})[] = [];

        // Sanitize data
        const phaseType = config ? config.phaseType : undefined;
        const underConstruction = config ? config.underConstruction : false;
        const items = config ? config.measurements || [] : [];

        for (let load of items) {
          let {actuals, type, maximumAmpere: maxAmpere} = load;
          const loadChannels = [];

          // Sanitize name
          const name = getLoadName(load);

          let valid = false;
          for (let i = 0; i < actuals.length; i++) {
            const actual = actuals[i];
            const {updateChannels, sensor} = actual;
            if (!updateChannels) continue;

            let {id, phase, ctType} = actual;
            const {location, reversed} = sensor || {
              location: 'N/A',
              reversed: false
            };

            valid = true;
            const channelPaths = createUpdateChannels(updateChannels);

            // Sanitize phase
            const phaseLabel = getPhaseLabel(phase);

            const channel = {
              id,
              serviceLocationId: isUltraDualAssembly ? load.serviceLocationId : undefined,
              location,
              name,
              type,
              phase,
              phaseLabel,
              maxAmpere,
              ctType,
              reversed,
              activeConfig: channelPaths.activeConfig,
              reactiveConfig: channelPaths.reactiveConfig,
              currentConfig: channelPaths.currentConfig,
              phaseConfig: channelPaths.phaseConfig,
              lineConfig: channelPaths.lineConfig,
              updateChannels,
              publishIndex: actual.publishIndex,
              sensor
            };

            channels.push(channel);
            loadChannels.push(channel);
          }

          if (valid) {
            loads.push({...load, channels: loadChannels});
          }
        }

        setPhaseType(phaseType);
        setLoads(loads);
        setChannels(channels);
        setUnderConstruction(underConstruction);
      });
    };

    const fetchOtherLowLevelData = () => {
      if (!locationId) return;

      fetch('configuration', api => api.getLegacyChannels(locationId), plural('channel')).then(channels => {
        setLoads([]);
        setChannels([]);
        setProChannels(channels);
        setUnderConstruction(false);
      });
    };

    // Determine where to retrieve channels configuration
    if (isInfinity) {
      fetchInfinityData();
    } else {
      fetchOtherLowLevelData();
    }
  }, [
    locationId,
    cardType,
    isInfinity,
    deviceType,
    fetch,
    serialNumber,
    updateSettings,
    isUltraDualAssembly,
    stationSerialNumber
  ]);
  useEffect(refresh, [refresh]);

  const proData: ILiveProData[] = useMemo(
    () => (isInfinity ? [] : getLiveProData(proChannels, powerMessage)),
    [isInfinity, powerMessage, proChannels]
  );

  const highLevelData: ILiveHighLevelData[] = useMemo(
    () => (!isInfinity || cardType !== HighOrLowLevel.High ? [] : getLiveHighLevelDataMulti(loads, liveData)),
    [isInfinity, cardType, loads, liveData]
  );

  const lowLevelData: ILiveLowLevelData[] = useMemo(
    () =>
      !isInfinity || cardType !== HighOrLowLevel.Low || locationId === undefined
        ? []
        : getLiveLowLevelDataMulti(locationId, channels, liveData),
    [isInfinity, cardType, channels, liveData, locationId]
  );

  const me = useUser();
  const handleToggleEditConfig = async () => {
    if (!location) return <div />;

    const readOnly = isReadOnly(me, location);

    if (isLegacy) {
      await modals.show<void>(props => <EditConfigLegacy location={location} readOnly={readOnly} {...props} />);
    } else {
      await modals.show<void>(props => <EditConfigMovedModal {...props} />);
    }
    refresh();
  };

  const canEdit = !me.isReadOnly();
  const hasConfig =
    location &&
    location.deviceType !== DeviceType.P1S1 &&
    location.deviceType !== DeviceType.Energy &&
    location.deviceType !== DeviceType.Solar &&
    location.functionType !== LocationFunctionType.ChargingStation;

  const actions: CustomActions = () => (
    <CardActions>
      <Spring />
      {hasConfig && (
        <RsButton disabled={!location} onClick={handleToggleEditConfig}>
          {canEdit ? T('liveElectricityValues.editConfiguration') : T('liveElectricityValues.viewConfiguration')}
        </RsButton>
      )}
    </CardActions>
  );

  const renderSettings: CustomSettings<ILiveElectricityValuesSettings> = (settings, updateSettings) => {
    if (!location) return <div />;

    let {cardType, table} = settings;
    const columns =
      (location &&
        deviceType &&
        getTableColumns(location, cardType, deviceType, phaseType, channels, loads, me.isServiceDesk())) ||
      [];

    const handleChangeLevel = (checked: boolean) => {
      const cardType = checked ? HighOrLowLevel.Low : HighOrLowLevel.High;
      const tableSettings = translateColumns(settings.table, columns);
      updateSettings({cardType, table: tableSettings});
    };

    // Determine relevant columns
    if (!isInfinity) {
      cardType = HighOrLowLevel.Low;
    }

    const isLowLevel = cardType === HighOrLowLevel.Low;

    return (
      <div>
        <FormGroup>
          <Checkbox
            id="cardType"
            name="cardType"
            label={T('liveElectricityValues.settings.splitLoads')}
            defaultChecked={isLowLevel}
            disabled={!isInfinity}
            onCheckedChange={handleChangeLevel}
            testId="cardType"
          />

          {!isInfinity && <FormText>{T('liveElectricityValues.settings.channelsOnly')}</FormText>}
        </FormGroup>
        <ColumnChooser fields={columns} settings={table} updateSettings={table => updateSettings({table})} />
      </div>
    );
  };

  const renderHighLevelTable = () => {
    if (!location || !phaseType) return <div />;

    return (
      <InfinityHighLevel
        location={location}
        loads={loads}
        items={highLevelData}
        phaseType={phaseType}
        tableSettings={settings.table}
        onUpdatedTableSettings={table => updateSettings({table})}
      />
    );
  };

  const renderLowLevelTable = () => {
    if (!location) return <div />;

    if (isInfinity) {
      if (!phaseType) return <div />;

      return (
        <InfinityLowLevel
          location={location}
          channels={channels}
          items={lowLevelData}
          phaseType={phaseType}
          tableSettings={settings.table}
          serviceDesk={me.isServiceDesk()}
          updateTableSettings={table => updateSettings({table})}
        />
      );
    } else {
      return (
        <ProLowLevel
          location={location}
          data={proData}
          tableSettings={settings.table}
          updateTableSettings={table => updateSettings({table})}
        />
      );
    }
  };

  const titleAddendum = (
    <>
      {phaseType && <span style={{paddingLeft: '1em'}}>{getPhaseTypeLabel(phaseType)}</span>}
      {underConstruction && <span>&nbsp;&middot;&nbsp;{T('liveElectricityValues.underConstruction')}</span>}
    </>
  );

  const hasData = loads || channels || proChannels;
  return (
    <CardView
      error={error}
      titleAddendum={titleAddendum}
      actions={actions}
      customSettings={renderSettings}
      ready={powerMessage !== undefined}
      {...cardViewProps(props)}
    >
      {hasData && (cardType === HighOrLowLevel.High ? renderHighLevelTable() : renderLowLevelTable())}
    </CardView>
  );
};

const DEFAULT_SETTINGS: ILiveElectricityValuesSettings = {
  cardType: HighOrLowLevel.Low,
  table: {
    pageSize: 10,
    sortColumn: 'index',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'type', visible: true},
      {name: 'name', visible: true},
      {name: 'phase', visible: true},
      {name: 'location', visible: true},
      {name: 'ctType', visible: true},
      {name: 'numberOfPhasesUsed', visible: true},
      {name: 'active', visible: true},
      {name: 'reactive', visible: true},
      {name: 'powerFactor', visible: true}
    ]
  }
};
const CARD: ICardType<ILiveElectricityValuesSettings> = {
  type: CardTypeKey.LiveElectricityValues,
  title: 'liveElectricityValues.title',
  description: 'liveElectricityValues.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Required,
  cardClass: LiveElectricityValues
};
export default CARD;
