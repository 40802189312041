import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {NotificationManager} from 'react-notifications';
import {Table} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {Button as RsButton} from '../../components/bootstrap';
import SelectLocationButton from '../../components/SelectLocationButton';
import {Button} from '../../components/ui/button';
import {Plus} from '../../components/ui-lib/icons/small';
import {ConfirmationResult, ConfirmationPromiseModal} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {IChannelUpdates} from '../../models/Channel';
import {Device} from '../../models/Device';
import {isCTHub} from '../../models/DeviceType';
import {PhaseType, getPhaseTypeLabel, IHighLevelConfiguration} from '../../models/HighLevelConfiguration';
import {Load, ILoadUpdates, ILoad, LoadSource} from '../../models/Load';
import {isReadOnly, LocationFeature, LocationFunctionType} from '../../models/Location';
import {UserType} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useApplianceTypes, useChildLocations, useLocation, useLocationModules} from '../../utils/FunctionalData';
import {useLoader} from '../../utils/Hooks';
import {T, singular} from '../../utils/Internationalization';

import {testingClasses} from '../../utils/TestingClasses';
import {ICardProps, CardTypeKey, ICardType, CardCategory, CardLocationAwareness} from '../CardType';

import {useCardLocation, useUser} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';

import {CardView, CustomActions} from '../components/CardView';

import AddLoadModal from './AddLoadModal';

import {MaxCapacitySettings} from './CapacitySettings';
import CompleteConfigView from './CompleteConfigView';

import styles from './EditConfig.module.scss';
import {LoadConfigurator} from './LoadConfigurator';
import {MaximumLoadSettings} from './MaximumLoadSettings';

function validateLoads(loads: Load[]): {
  [key: number]: {[key: string]: string};
} {
  const result: {[key: number]: {[key: string]: string}} = {};
  for (var load of loads) {
    const errors: {[key: string]: string} = {};
    let {id} = load;
    const isGrid = load.isGrid();
    const name = (load.name || '').trim();
    let hasErrors = false;

    // Name cannot be empty
    if (!isGrid && name === '') {
      errors.name = T('liveElectricityValues.configuration.loadNameRequired');
      hasErrors = true;
    }

    // Compare to other highlevels
    for (let item of loads) {
      if (item.id === id) continue;

      if (!isGrid && !item.isGrid() && item.name.trim() === name) {
        errors.name = T('liveElectricityValues.configuration.loadNameNotUnique');
        hasErrors = true;
      }

      // There can only be one grid type
      if (isGrid && item.isGrid()) {
        errors.type = T('liveElectricityValues.configuration.onlyOneGridAllowed');
        hasErrors = true;
      }
    }

    if (hasErrors) result[load.id] = errors;
  }
  return result;
}

const MyCardView = (props: ICardProps<ICardSettings>) => {
  const {fetch, settings} = props;
  const locationSummary = useCardLocation(settings);
  const [location] = useLocation(fetch, locationSummary?.id);

  const {api} = useAppContext();
  const me = useUser();
  const modals = useModals();
  const isServiceDesk = me.isServiceDesk();

  const locationId = location && location.id;
  const serialNumber = location && location.serialNumber;
  const deviceType = location && location.deviceType;
  const functionType = location && location.functionType;
  const isInfinity = deviceType && Device.isInfinity(deviceType);
  const readOnly = isReadOnly(me, location) || !location?.features?.includes(LocationFeature.LoadConfig);

  const [deviceInfo] = useLoader(
    async api => (serialNumber ? api.getDeviceInfo(serialNumber) : undefined),
    [serialNumber]
  );

  const [highLevelConfig, setHighLevelConfig] = useState<IHighLevelConfiguration>();
  const [loads, setLoads] = useState<Load[]>([]);
  const [changedLoadIds, setChangedLoadIds] = useState<Set<number>>(new Set());
  const [changedChannelIds, setChangedChannelIds] = useState<Set<number>>(new Set());
  const [phaseType, setPhaseType] = useState<PhaseType>();
  const [childLocations] = useChildLocations(fetch, location);

  const [loading, setLoading] = useState(false);
  const applianceTypes = useApplianceTypes(fetch);
  const [underConstruction, setUnderConstruction] = useState(false);
  const isVirtualGrid = loads?.some(el => el?.type === 'VIRTUAL_GRID');

  const hasChanges = changedLoadIds.size > 0 || changedChannelIds.size > 0;
  const supportsMID = deviceInfo === undefined ? false : deviceInfo.supportsMID;

  const [devices] = useLocationModules(fetch, locationId);
  const ctHubs = useMemo(() => (devices || None).filter(device => isCTHub(device.type)), [devices]);

  const refresh = useCallback(
    (force: boolean) => {
      if (!locationId) return;
      if (!isInfinity) return;

      // Retrieve configuration
      fetch(
        'data',
        api => Promise.all([api.getHighLevelConfiguration(locationId, force)]),
        singular('configuration')
      ).then(([highLevelConfig]) => {
        const {underConstruction, phaseType, measurements: highLevelMeasurements} = highLevelConfig;
        const loads = highLevelMeasurements.map(highLevel => Load.fromJSON(highLevel));
        const virtualGridLoad = loads?.filter(load => load.type === 'VIRTUAL_GRID');
        setLoads(virtualGridLoad?.length > 0 ? virtualGridLoad : loads);
        setPhaseType(phaseType);
        setUnderConstruction(underConstruction);
        setHighLevelConfig(highLevelConfig);
        setChangedChannelIds(new Set());
        setChangedLoadIds(new Set());
      });
    },
    [fetch, locationId, isInfinity]
  );
  useEffect(() => refresh(false), [refresh]);

  const handleClickSave = async () => {
    try {
      await saveLoads();
      refresh(true);
      NotificationManager.success(T('liveElectricityValues.configuration.save.success'));
    } catch {
      NotificationManager.error(T('liveElectricityValues.configuration.save.failed'));
    }
  };

  const handleCompleteConfig = async () => {
    if (!locationId) return;

    // Save and complete measurements and close modal window
    try {
      await saveLoads();
      const data = await api.completeLoads(locationId);
      if (data.success) {
        NotificationManager.success(T('liveElectricityValues.configuration.save.success'));
        refresh(true);
      } else {
        NotificationManager.error(T('liveElectricityValues.configuration.save.failed'));
      }
    } catch {
      NotificationManager.error(T('liveElectricityValues.configuration.save.failed'));
    }
  };

  const saveLoads = () => {
    if (!locationId) return;

    setLoading(true);

    const promises = [];
    for (let load of loads) {
      if (changedLoadIds.has(load.id)) {
        promises.push(api.updateHighLevelMeasurement(locationId, load.id, load.getUpdates()));
      }
      for (let channel of load.channels) {
        if (changedChannelIds.has(channel.id)) {
          promises.push(api.updateActualMeasurement(locationId, channel.id, channel.getUpdates()));
        }
      }
    }

    return Promise.all(promises).then(values => {
      setLoading(false);
      const success = !values.some(value => !value.success);
      if (success) {
        setChangedLoadIds(new Set());
        setChangedChannelIds(new Set());
      } else {
        NotificationManager.error(T('liveElectricityValues.configuration.save.failed'));
      }

      return success;
    });
  };

  const errors = useMemo(() => validateLoads(loads), [loads]);
  const hasErrors = Object.keys(errors).length > 0;

  const loadViews = useMemo(() => {
    const rows = [];
    let groupCount = 1;

    const updateLoad = (loadId: number, updates: ILoadUpdates) => {
      const load = loads.find(load => load.id === loadId);
      if (!load) return;

      setLoads(loads => loads.map(load => (load.id === loadId ? load.updateWith(updates) : load)));
      setChangedLoadIds(ids => {
        const updatedIds = new Set(ids);
        updatedIds.add(loadId);
        return updatedIds;
      });
    };

    const updateChannel = (loadId: number, channelId: number, updates: IChannelUpdates) => {
      setLoads(loads => {
        const load = loads.find(load => load.id === loadId);
        if (!load) return loads;

        const updatedLoad = load.updateChannelWith(channelId, updates); // TODO: stop storing channels in the load here; they'll get desynced
        return loads.map(load => (load.id === loadId ? updatedLoad : load));
      });

      setChangedChannelIds(ids => {
        const updatedIds = new Set(ids);
        updatedIds.add(channelId);
        return updatedIds;
      });
    };

    const handleClickedDeleteLoad = async (load: Load) => {
      if (!locationId) return;

      const loadId = load.id;
      const confirmation = await modals.show<ConfirmationResult>(props => (
        <ConfirmationPromiseModal
          title={T('ctConfiguration.removeLoad.remove.title')}
          message={T('ctConfiguration.removeLoad.remove.message')}
          {...props}
        />
      ));
      if (confirmation !== ConfirmationResult.Accept) return;

      try {
        const result = await api.deleteHighLevelMeasurement(locationId, loadId);
        setLoads(loads => (loads || []).filter(load => load.id !== loadId));
      } catch (error: any) {
        if (error.statusCode === 422) {
          if (error.error === 'highlevelmeasurementspecification.in.use.by.automation') {
            NotificationManager.error(T('ctConfiguration.removeLoad.usedInAutomation'));
          } else if (error.error === 'highlevelmeasurementspecification.in.use.by.car.charger') {
            NotificationManager.error(T('ctConfiguration.removeLoad.usedInCarCharger'));
          } else {
            NotificationManager.error(T('ctConfiguration.removeLoad.failed'));
          }
        } else {
          NotificationManager.error(T('ctConfiguration.removeLoad.failed'));
        }
      }
    };

    for (let load of loads) {
      const measurement = highLevelConfig && highLevelConfig.measurements.find(m => m.id === load.id);
      const midBusAddress =
        measurement && (measurement.actuals.length > 0 ? measurement.actuals[0].midBusAddress : undefined);

      // there can be no load without a location
      if (!locationId) return;

      rows.push(
        <LoadConfigurator
          key={load.id}
          load={load}
          groupIndex={groupCount++}
          readOnly={readOnly}
          phaseType={phaseType || PhaseType.Single}
          applianceTypes={applianceTypes}
          midAddress={midBusAddress}
          errors={errors[load.id] || {}}
          updateLoad={updateLoad}
          updateChannel={updateChannel}
          ctHubs={ctHubs}
          onClickedDelete={handleClickedDeleteLoad}
          location={location}
        />
      );
    }

    return (
      <>
        <Table>
          {!isVirtualGrid && (
            <thead>
              <tr>
                <th className={styles.groupColumn}>#</th>
                <th>{T('ctConfiguration.source')}</th>
                <th>{T('liveElectricityValues.configuration.name')}</th>
                <th>{T('ctConfiguration.loadType')}</th>
                <th>{T('liveElectricityValues.configuration.options')}</th>
                <th className={styles.sensorReversed}>{T('liveElectricityValues.configuration.measuredByGrid')}</th>
                <th />
              </tr>
            </thead>
          )}
          <tbody>{rows}</tbody>
        </Table>
        {isVirtualGrid && (
          <div className={styles.virtualGridChildsContainer}>
            {childLocations
              .filter(loc => loc.functionType === 'DEFAULT')
              .map(location => (
                <div
                  key={location.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: 50
                  }}
                >
                  <SelectLocationButton location={location} className={styles.virtualChildBtn} />
                  <MaxCapacitySettings location={location} />
                  <MaximumLoadSettings location={location} />
                </div>
              ))}
          </div>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loads,
    locationId,
    modals,
    api,
    highLevelConfig,
    readOnly,
    phaseType,
    applianceTypes,
    errors,
    ctHubs,
    childLocations,
    isVirtualGrid
  ]);

  const isValid = useMemo(() => {
    if (hasErrors) return false;

    const usedInputs = new Set();
    for (let load of loads) {
      for (let channel of load.channels) {
        if (channel.sensor) {
          const name = `${channel.sensor.serialNumber}-${channel.sensor.location}`;
          if (usedInputs.has(name)) return false;

          usedInputs.add(name);
        }
      }
    }
    return true;
  }, [loads, hasErrors]);

  const isUnderConstruction = underConstruction || hasChanges;

  const handleClickedAdd = async () => {
    if (locationId === undefined || phaseType === undefined) return;

    const load = await modals.show<ILoad | undefined>(props => (
      <AddLoadModal
        locationId={locationId}
        supportsMID={supportsMID}
        phaseType={phaseType}
        existingLoads={loads}
        {...props}
      />
    ));
    if (load !== undefined) {
      setLoads(loads => [...(loads || []), Load.fromJSON(load)]);
    }
  };

  const isComplete = useMemo(
    () =>
      !loads.some(load =>
        load.channels.some(
          channel =>
            channel.phase === undefined ||
            (load.source === LoadSource.CT &&
              (channel.sensor === undefined ||
                channel.sensor.location === undefined ||
                channel.sensor.serialNumber === undefined))
        )
      ),
    [loads]
  );

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={() => refresh(true)} />
      <Spring />
      {state.ready && !readOnly && (
        <RsButton
          onClick={handleClickSave}
          disabled={!isValid}
          className={testingClasses.saveButton}
          data-testid={testingClasses.saveButton}
        >
          {T('liveElectricityValues.configuration.save')}
        </RsButton>
      )}
      {state.ready && (isServiceDesk || me.role === UserType.PartnerAdmin) && !isUnderConstruction && (
        <RsButton
          color="primary"
          onClick={handleCompleteConfig}
          disabled={
            !(isServiceDesk || me.role === UserType.PartnerAdmin) && (!isUnderConstruction || !isValid || !isComplete)
          }
          className={testingClasses.commitButton}
          data-testid={testingClasses.commitButton}
        >
          {T('liveElectricityValues.configuration.forceCommit')}
        </RsButton>
      )}
      {state.ready && !readOnly && !isVirtualGrid && (
        <Button
          variant="secondary_default"
          onClick={handleClickedAdd}
          className={testingClasses.addButton}
          data-testid={testingClasses.addButton}
        >
          <span className="tw-mr-2">
            <Plus />
          </span>
          {T('ctConfiguration.addLoad')}
        </Button>
      )}
    </CardActions>
  );

  let error: string | JSX.Element | undefined;
  if (!isInfinity) {
    error = T('ctConfiguration.notSupported');
  } else if (
    (functionType === LocationFunctionType.LocalityParent && !isVirtualGrid) ||
    (functionType === LocationFunctionType.LocalityParentChargingPark && !isVirtualGrid)
  ) {
    error = (
      <span>
        {T('ctConfiguration.notSupportedByLocalityParents')}
        <br />
        {childLocations.map((location, index) => (
          <span key={location.id}>
            {index > 0 && <>&middot;</>}
            <SelectLocationButton location={location} />
          </span>
        ))}
      </span>
    );
  }

  return (
    <CardView
      actions={actions}
      loading={loading || props.loading}
      ready={devices !== undefined}
      error={error}
      titleAddendum={<span>&nbsp;- {phaseType && getPhaseTypeLabel(phaseType)}</span>}
      fetching={props.fetching}
      settings={props.settings}
    >
      {isUnderConstruction && (
        <CompleteConfigView
          readOnly={readOnly}
          disabled={!isServiceDesk && (!isUnderConstruction || !isValid || !isComplete)}
          onClickedComplete={handleCompleteConfig}
        />
      )}
      <div style={{overflowY: 'auto'}}>{loadViews}</div>
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.CTConfiguration,
  title: 'ctConfiguration.title',
  description: 'ctConfiguration.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 4,
  height: 3,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Required,
  cardClass: MyCardView
};
export default CARD;
