import {useState} from 'react';

import {useAppContext} from '../../app/context';
import {SingleActionModal} from '../../components/bootstrap';
import {useNumberInput} from '../../components/inputs/NumberInput';
import {useTextInput} from '../../components/inputs/TextInput';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {IChargingPriority} from '../../models/ChargingPriorities';
import {T} from '../../utils/Internationalization';
import {validateRFID, validateUsername} from '../../utils/Validation';

interface AddTokenProps extends IPromiseModalProps<IChargingPriority[] | null> {
  serviceLocation: number;
  priority: number;
}

export function AddToken(props: AddTokenProps) {
  const {serviceLocation} = props;
  const {api} = useAppContext();
  const [isOpen, resolve] = usePromiseModal(props);
  const onToggle = () => resolve(null);

  const [addFailure, setAddFailure] = useState(false);

  const [priorityInput, priority] = useNumberInput(
    'priority',
    T('chargingStationPriorities.priority'),
    props.priority,
    0,
    100,
    undefined,
    {autoFocus: true}
  );
  const [tokenInput, token] = useTextInput('token', T('chargingStationPriorities.token'), '', validateRFID);
  const [commentInput, comment] = useTextInput('comment', T('chargingStationPrivileges.column.comment'), '');

  const handleClickSave = async () => {
    if (priority === null) return Promise.resolve(undefined);

    setAddFailure(false);
    return api.chargingPriorities
      .updateChargingPriority(serviceLocation, {
        id: 0,
        token,
        serviceLocationId: serviceLocation,
        priority,
        comment
      })
      .then(entries => {
        resolve(entries);
      })
      .catch(() => setAddFailure(true));
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={onToggle}
      title={T('chargingStationPriorities.add.title')}
      action={handleClickSave}
      actionText={T('chargingStationPriorities.add.save')}
      error={addFailure ? T('chargingStationPriorities.add.failed') : undefined}
    >
      <div>
        {priorityInput}
        {tokenInput}
        {commentInput}
      </div>
    </SingleActionModal>
  );
}
