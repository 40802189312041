import React, {useState, useEffect, useMemo, useCallback} from 'react';

import {useAppContext} from '../../app/context';
import {
  Button as RsButton,
  Input,
  Label,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  RowActions
} from '../../components/bootstrap';

import {Icons} from '../../components/Icon';
import Table, {migrateTableSettings} from '../../components/Table';

import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IDevice, IDeviceOutputControl} from '../../models/Device';
import {DeviceType, getDeviceTypeLabelFor} from '../../models/DeviceType';
import {isReadOnly, LocationFeature} from '../../models/Location';
import {ISmartDevice, getSmartDeviceConnectionStatusLabel} from '../../models/SmartDevice';
import {ISwitch} from '../../models/Switch';
import {StringField, ComponentField, ITableField} from '../../models/Table';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {None} from '../../utils/Arrays';
import {
  useSmartDevices,
  useLocationModules,
  useLocationSwitches,
  useLocationIdWithGrid
} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {CardCategory, ICardType, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation, useCardLocationDetails, useUser} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {
  OutputModuleSmartDevice,
  AnySmartDevice,
  SmartDeviceWrapper,
  SwitchDeviceWrapper,
  SmartDeviceAction
} from './AnySmartDevice';
import {CreateSmartDeviceModal} from './CreateSmartDeviceModal';

import {EditSmartDeviceModal} from './EditSmartDeviceModal';

type ISmartDevicesSettings = ICardSettingsWithTable;

function getTableColumns(
  readOnly: boolean,
  getDeviceStatus: (item: AnySmartDevice) => React.ReactNode
): ITableField<AnySmartDevice>[] {
  return [
    new StringField('name', T('smartDevices.field.name')),
    new ComponentField('connectionStatus', T('smartDevices.field.connectionStatus'), getDeviceStatus, {
      autoInsert: 'end',
      alwaysVisible: true,
      sortable: false
    }),
    new ComponentField(
      'actions',
      T('generic.actions'),
      item => <RowActions>{item.renderRowActions(readOnly)}</RowActions>,
      {autoInsert: 'end', width: 150}
    )
  ];
}

const rowKey = (item: AnySmartDevice) => item.key;

const SmartDevices = (props: ICardProps<ISmartDevicesSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const {api} = useAppContext();
  const modals = useModals();
  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationSerial = location && location.serialNumber;
  const deviceType = (location && location.deviceType) || DeviceType.UNKNOWN;
  const locationIdWithGrid = useLocationIdWithGrid(fetch, location);
  const locationDetails = useCardLocationDetails(settings);
  const isFeatureAvailable = locationDetails && locationDetails.features.includes(LocationFeature.HomeControl);
  const me = useUser();
  const readOnly = isReadOnly(me, location);

  const [maximumLoad, setMaximumLoad] = useState('');
  const [smartDevices, refreshSmartDevices] = useSmartDevices(fetch, locationId);
  const [devices = None, refreshDevices] = useLocationModules(fetch, locationId);
  const [outputModuleUpdatedStates, setOutputModuleUpdatedStates] = useState<{
    [key: string]: string;
  }>({});
  const [switches, refreshSwitches] = useLocationSwitches(fetch, locationId);

  const outputModuleControls = useMemo(() => {
    const handleChangedControl = (device: IDevice, control: IDeviceOutputControl, option: string) => {
      if (!locationId) return;

      const configuration = control.configurations.find(c => c.id === option);
      if (!configuration) return;

      api.setOutputModuleState(locationId, device.serialNumber, control.id, configuration);
      setOutputModuleUpdatedStates(states => ({
        ...states,
        [`${device.serialNumber}::${control.id}`]: option
      }));
    };

    const controls: OutputModuleSmartDevice[] = [];
    devices.forEach(module => {
      if (module.output === undefined) return;

      module.output.controls.forEach(control => {
        controls.push(
          new OutputModuleSmartDevice(
            module,
            control,
            handleChangedControl,
            outputModuleUpdatedStates[`${module.serialNumber}::${control.id}`]
          )
        );
      });
    });
    return controls;
  }, [api, locationId, devices, outputModuleUpdatedStates]);

  const allDevices = useMemo(() => {
    const handleEditSmartDevice = async (device: ISmartDevice) => {
      if (!locationId) return;

      await modals.show<void>(props => <EditSmartDeviceModal device={device} locationId={locationId} {...props} />);
      refreshSmartDevices();
    };

    const handleDeleteSmartDevice = (device: ISmartDevice) => {
      if (!locationId) return;

      modals
        .show<ConfirmationResult>(props => (
          <ConfirmationPromiseModal
            title={T('smartDevices.delete.title')}
            message={T('smartDevices.delete.message', {name: device.name})}
            {...props}
          />
        ))
        .then(result => {
          if (result === ConfirmationResult.Accept) {
            api.locations.deleteSmartDevice(locationId, device.id).then(() => refreshSmartDevices());
          }
        });
    };

    const handleEditSwitch = (device: ISwitch, action: SmartDeviceAction) => {
      if (!locationId) return;

      api.executeSwitchAction(locationId, device.id, action);
    };

    const filteredSmartDevices = smartDevices; //.filter(device => !isEVLineDevice(device));

    return [
      ...outputModuleControls,
      ...filteredSmartDevices.map(
        device => new SmartDeviceWrapper(device, handleEditSmartDevice, handleDeleteSmartDevice)
      ),
      ...switches.map(device => new SwitchDeviceWrapper(locationSerial, device, handleEditSwitch))
    ];
  }, [smartDevices, outputModuleControls, switches, locationId, modals, refreshSmartDevices, api, locationSerial]);

  useEffect(() => {
    if (!locationIdWithGrid) return;

    fetch(
      'overloadProtection',
      api => api.getOverloadProtection(locationIdWithGrid),
      T('smartDevices.loading.overloadProtection')
    ).then(result => {
      if (result.maximumLoad !== undefined) {
        setMaximumLoad(result.maximumLoad.toString());
      } else {
        setMaximumLoad('');
      }
    });
  }, [fetch, locationIdWithGrid]);

  const handleChangedMaximumLoad = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setMaximumLoad(e.currentTarget.value);
  };

  const handleClickedSaveMaximumLoad = useCallback(() => {
    if (!locationIdWithGrid) return;

    fetch(
      'save',
      api =>
        api.updateOverloadProtection(locationIdWithGrid, {
          maximumLoad: parseInt(maximumLoad)
        }),
      T('smartDevices.loading.maximumLoad')
    );
  }, [fetch, locationIdWithGrid, maximumLoad]);

  const isMaximumLoadValid = /^[0-9]+$/.test(maximumLoad);

  const tableColumns = useMemo(() => {
    const getDeviceStatus = (item: AnySmartDevice) => {
      if (!item.hasConnectionStatus()) return undefined;

      if (!!item?.connectionStatus) {
        return getSmartDeviceConnectionStatusLabel(item.connectionStatus, item.canBeInitializing);
      }

      const title = T('smartDevices.noSmartDeviceStatus', {
        deviceType: getDeviceTypeLabelFor(deviceType)
      });
      return (
        <>
          {T('smartDevices.statusUnavailable')}
          &nbsp;&nbsp;
          <Icons.Info title={title} />
        </>
      );
    };

    return getTableColumns(readOnly, getDeviceStatus);
  }, [deviceType, readOnly]);

  const handleReload = () => {
    refreshSmartDevices();
    refreshDevices();
    refreshSwitches(true);
  };

  const handleClickedCreate = () => {
    if (locationId === undefined) return;

    modals.show(props => <CreateSmartDeviceModal locationId={locationId} {...props} />).then(refreshSmartDevices);
  };

  const actions: CustomActions = () => (
    <CardActions>
      {isFeatureAvailable && <Reload onReload={handleReload} />}
      <Spring />
      {hasFeature(AppFeature.CreateSmartDevices) && isFeatureAvailable && (
        <RsButton onClick={handleClickedCreate}>
          {Icons.Add}
          &nbsp;
          {T('smartDevices.create')}
        </RsButton>
      )}
    </CardActions>
  );

  let error: string | undefined;
  if (!isFeatureAvailable) {
    error = T('smartDevices.error.noSmartDevices');
  }

  return (
    <CardView error={error} actions={actions} {...cardViewProps(props)}>
      <Table
        fields={tableColumns}
        items={allDevices}
        rowKey={rowKey}
        noun="smartDevice"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS: ISmartDevicesSettings = {
  table: {
    pageSize: 10,
    columns: [{name: 'name', visible: true}]
  }
};
const CARD: ICardType<ISmartDevicesSettings> = {
  type: CardTypeKey.SmartDevices,
  title: 'smartDevices.title',
  description: 'smartDevices.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table),
  cardClass: SmartDevices
};
export default CARD;
