import {SeriesColumnOptions, SeriesLineOptions, YAxisOptions} from 'highcharts';
import React from 'react';

import {createIntervalChart} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import {IPersistedTableSettings, ISelectedRow} from '../../components/Table';
import {IDeviceInputChannel} from '../../models/Device';
import {ILocationSummary, SensorChannelType} from '../../models/Location';
import {Interval, getUnitLabel, showAsLineGraph} from '../../models/UsageValue';

import {BrandColors} from '../../utils/BrandColors';
import {createPointFormatter} from '../../utils/GraphUtils';

import styles from './index.module.scss';
import {IInputModuleChartData, InputModuleValue} from './Models';

interface InputModuleReadingsChartProps {
  data: IInputModuleChartData;
  tableSettings: IPersistedTableSettings;
}

const COLOR_PALETTE: string[] = [
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a'
];
function getChannelColor(channel: IDeviceInputChannel) {
  switch (channel.type) {
    case SensorChannelType.Electricity:
      return BrandColors.OrangeSunglow;
    case SensorChannelType.Gas:
      return BrandColors.Blue;
    case SensorChannelType.Water:
      return BrandColors.Blue;
    default:
      return COLOR_PALETTE[channel.id % COLOR_PALETTE.length];
  }
}

function getOrCreateYAxis(existing: YAxisOptions[], text: string) {
  const found = existing.findIndex(axis => axis.title !== undefined && axis.title.text === text);
  if (found >= 0) return found;

  const index = existing.length;
  existing.push({
    title: {
      text
    }
  });
  return index;
}

function getChannelUnitLabel(channel: IDeviceInputChannel) {
  if (channel.customUnitName !== undefined) return channel.customUnitName;

  return getUnitLabel(channel.unit);
}

function createInputModuleChannel(
  channel: IDeviceInputChannel,
  index: number,
  interval: Interval,
  items: InputModuleValue[],
  yAxis: YAxisOptions[]
) {
  const unit = getChannelUnitLabel(channel);
  return {
    name: channel.name,
    type: showAsLineGraph(interval) ? 'line' : 'column',
    data: items.map(item => [item.timestamp, item.values[index]]),
    color: getChannelColor(channel),
    marker: {enabled: false},
    tooltip: {
      pointFormatter: createPointFormatter(channel.name, unit, 2)
    },
    yAxis: getOrCreateYAxis(yAxis, unit)
  } as SeriesColumnOptions | SeriesLineOptions;
}

export const InputModuleReadingsChart = React.memo((props: InputModuleReadingsChartProps) => {
  const {data, tableSettings} = props;
  const {channels, readings, period, module} = data;

  if (module.input === undefined) return null;

  const selectedColumns = new Set(
    tableSettings.columns.filter(column => column.visible).map(column => (column as ISelectedRow).name)
  );
  const yAxis: YAxisOptions[] = [];
  const series = channels
    .map((channel, index) => ({channel, index}))
    .filter(channel => selectedColumns.has(`channel${channel.channel.id}`))
    .map(channel => createInputModuleChannel(channel.channel, channel.index, period.interval, readings, yAxis));

  const [config, actualFrom, actualTo] = createIntervalChart({
    period,
    series,
    yAxis,
    noDataText: 'No readings registered for the selected period',
    reserveYAxis: 2
  });

  return <HighStockChart className={styles.stretch} config={config} from={actualFrom} to={actualTo} />;
});
