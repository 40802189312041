import React, {useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Alert, FormGroup, Input, InputGroup, Label} from '../../components/bootstrap';
import {Icons} from '../../components/Icon';

import FormSaveButton from '../../components/inputs/FormSaveButton';
import {useTextInput} from '../../components/inputs/TextInput';
import {Button} from '../../components/ui/button';
import {Checkbox} from '../../components/ui/checkbox';
import {Undo} from '../../components/ui-lib/icons/small';
import {APIResponse} from '../../core/api';
import {useModals} from '../../modals/ModalContext';
import SelectPrinterDialog from '../../modals/SelectPrinterDialog';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {DeviceType} from '../../models/DeviceType';
import {IEVBoxProperties} from '../../models/PreconfigurationKit';
import {setPreference} from '../../redux/actions/preferences';
import {getPrinter, usePrinter} from '../../server/Printers';
import {FetchBehavior} from '../../utils/Fetcher';
import {FormContextProvider} from '../../utils/FormContext';
import {FormState, useFormState} from '../../utils/FormState';
import {useAppSelector} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {validateRequired, validateMatching} from '../../utils/Validation';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {CardActions} from '../components/CardActions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {
  PreconfigurationKits,
  getPreconfigurationKit,
  HardwareCTHub,
  HardwareSolidCoreGrid,
  HardwareSolidCoreCharger,
  HardwareCTHubGrid,
  HardwareCTHubCharger
} from './Kits';

const fieldOrder: string[] = [
  'gatewaySerialNumber',
  'powerBoxSerialNumber',
  'gridClosedCTSerialNumber',
  'carChargerClosedCTSerialNumber',
  'ctHubSerialNumber',
  'id'
];

function validateGatewaySerialNumber(gateway: DeviceType, serial: string, label: string) {
  if (serial === '') return T('validator.required', {name: label});

  if (gateway === DeviceType.Genius) {
    if (!/^50[012]0[0-9]{6}$/.test(serial)) {
      return 'Not a valid Genius serial number';
    }
  } else if (gateway === DeviceType.WifiConnect) {
    if (!/^51[01]0[0-9]{6}$/.test(serial)) {
      return 'Not a valid Wifi Connect serial number';
    }
  } else if (gateway === DeviceType.EthernetConnect) {
    if (!/^5130[0-9]{6}$/.test(serial)) {
      return 'Not a valid Connect serial number';
    }
  }
}

function getErrorMessage(errorCode: string) {
  switch (errorCode) {
    case 'NOT_FOUND':
      return 'Could not find a device with this serial number';
    default:
      return errorCode;
  }
}

function focusNext(form: FormState, currentField: string) {
  const index = fieldOrder.indexOf(currentField);
  if (index < 0) return;

  for (let i = index + 1; i < fieldOrder.length; i++) {
    const field = fieldOrder[i];
    if (form.getRef(field)) {
      form.focus(field);
      return;
    }
  }
}

function getGatewayLabel(gateway: DeviceType) {
  switch (gateway) {
    case DeviceType.Genius:
      return 'SMARTC-GENIUS (Genius Serial Number)';
    case DeviceType.WifiConnect:
      return 'SMARTC-WCONNECT (Wifi Connect Serial Number)';
    case DeviceType.EthernetConnect:
      return 'SMARTC-CONNECT (Connect Serial Number)';
    default:
      return `?${gateway}`;
  }
}

interface PreconfigurationCardProps extends ICardSettings {
  print?: boolean;
}

const Preconfiguration = (props: ICardProps<PreconfigurationCardProps>) => {
  const {fetch, settings, updateSettings} = props;

  const context = useAppContext();
  const modals = useModals();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const [printer, setPrinter] = usePrinter();
  const printerObject = getPrinter(printer);

  const batchCounter = useAppSelector(state => state.preferences.batchCounter);
  const form = useFormState();

  // Geen solar -> wifi connect = SMARTC-WCONNECT
  // Wel solar -> genius = SMARTC-GENIUS

  // CT HUB 1P = EV-IAC-1-1P
  // CT HUB 1P + SOLAR = EV-IAC-1-1PS
  // CT HUB 3PH = EV-IAC-1-S3P

  const [kit, setKit] = useState(PreconfigurationKits[0]);
  const print = settings.print || false;

  const setPrint = (print: boolean) => {
    updateSettings({print});
  };

  const handleClickedChoosePrinter = async () => {
    const selectedPrinter = await modals.show<string | undefined>(props => (
      <SelectPrinterDialog printer={printer} {...props} />
    ));
    if (selectedPrinter !== undefined) setPrinter(selectedPrinter);
  };

  const isThreePhase = kit.phase === 3;
  const hasCTHub = (kit.hardware & HardwareCTHub) > 0;
  const hasGridSolidCore = (kit.hardware & HardwareSolidCoreGrid) > 0;
  const hasChargerSolidCore = (kit.hardware & HardwareSolidCoreCharger) > 0;
  const hasGridCTHub = (kit.hardware & HardwareCTHubGrid) > 0;
  const hasChargerCTHub = (kit.hardware & HardwareCTHubCharger) > 0;

  const handleSerialFieldKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {name, value} = e.currentTarget;
    if (e.key === 'Enter') {
      focusNext(form, name);
      e.preventDefault();
    } else if (e.key < '0' || e.key > '9' || value.length >= 10) {
      e.preventDefault();
    }
  };

  const [idInput, id, resetId] = useTextInput('id', 'Member card (scan QR on member card)', '', validateRequired);

  const gatewaySerialNumberLabel = getGatewayLabel(kit.gateway);
  const [gatewaySerialNumberInput, gatewaySerialNumber, resetGatewaySerialNumber] = useTextInput(
    'gatewaySerialNumber',
    gatewaySerialNumberLabel,
    '',
    (value, label) => validateGatewaySerialNumber(kit.gateway, value, label),
    undefined,
    {onKeyPress: handleSerialFieldKeypress}
  );

  const [powerBoxSerialNumberInput, powerBoxSerialNumber, resetPowerBoxSerialNumber] = useTextInput(
    'powerBoxSerialNumber',
    'SMARTC-PBOX (Power Box serial number)',
    '',
    validateMatching(/^5400[0-9]{6}$/, 'Not a valid PowerBox serial number'),
    undefined,
    {onKeyPress: handleSerialFieldKeypress}
  );

  const [gridClosedCTSerialNumberInput, gridClosedCTSerialNumber, resetGridClosedCTSerialNumber] = useTextInput(
    'gridClosedCTSerialNumber',
    kit.gridCTHubLabel || 'EV-IAC-2-3PG (Grid Solid Core 3-Phase CT Serial Number)',
    '',
    isThreePhase
      ? validateMatching(hasGridCTHub ? /^5500[0-9]{6}$/ : /^5600[0-9]{6}$/, 'Not a valid Closed CT serial number')
      : undefined,
    undefined,
    {onKeyPress: handleSerialFieldKeypress}
  );

  const [
    carChargerClosedCTSerialNumberInput,
    carChargerClosedCTSerialNumber,
    resetCarChargerClosedCTSerialNumberInput
  ] = useTextInput(
    'carChargerClosedCTSerialNumber',
    kit.carChargerCTHubLabel || 'EV-IAC-2-3PEV (Car Charger Solid Core 3-Phase CT Serial Number)',
    '',
    isThreePhase
      ? validateMatching(hasChargerCTHub ? /^5500[0-9]{6}$/ : /^5600[0-9]{6}$/, 'Not a valid Closed CT serial number')
      : undefined,
    undefined,
    {onKeyPress: handleSerialFieldKeypress}
  );

  const [ctHubSerialNumberInput, ctHubSerialNumber, resetCtHubSerialNumber] = useTextInput(
    'ctHubSerialNumber',
    kit.ctHubLabel,
    '',
    hasCTHub ? validateMatching(/^5500[0-9]{6}$/, 'Not a valid CT Hub serial number') : undefined,
    undefined,
    {onKeyPress: handleSerialFieldKeypress}
  );

  const handleKitChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setKit(getPreconfigurationKit(e.currentTarget.value));
  };

  const handleClickedSave = async () => {
    const evBox: IEVBoxProperties = {
      kit: {
        type: kit.type,
        id,
        gridCTType: kit.gridCTType
      },
      gatewaySerialNumber,
      powerBoxSerialNumber,
      maximumCurrent: 32,
      minimumCurrent: 6
    };

    if (hasGridCTHub || hasGridSolidCore) {
      evBox.gridClosedCTSerialNumber = gridClosedCTSerialNumber;
    }
    if (hasChargerCTHub || hasChargerSolidCore) {
      evBox.carChargerClosedCTSerialNumber = carChargerClosedCTSerialNumber;
    }
    if (hasCTHub) evBox.ctHubSerialNumber = ctHubSerialNumber;

    try {
      await fetch(
        'apply',
        api =>
          api.preconfigureKit({
            maximumCurrent: 40,
            evBox
          }),
        '',
        FetchBehavior.SkipErrorHandling
      );

      if (print) {
        await context.api.printKitLabel(kit.id, gatewaySerialNumber, printer);
      }

      const counter = batchCounter + 1;
      setPreference(context, 'batchCounter', counter.toString());

      form.focus('gatewaySerialNumber');

      const message = `Preconfiguration kit with gateway ${gatewaySerialNumber} and username ${id} has been successfully created.`;
      NotificationManager.success(message);
      setErrorMessage('');
      setSuccessMessage(message);
      resetId();
      resetGatewaySerialNumber();
      resetPowerBoxSerialNumber();
      resetGridClosedCTSerialNumber();
      resetCarChargerClosedCTSerialNumberInput();
      resetCtHubSerialNumber();
    } catch (err_) {
      const err = err_ as APIResponse<never>;
      const error = err.error || '';
      if (err.statusCode === 400) {
        const isArrayError = error.startsWith('[');
        setErrorMessage(isArrayError ? '' : err.error);
        setSuccessMessage('');

        const serverErrors: {fieldName: string; error: string}[] = isArrayError ? JSON.parse(error) : [];
        serverErrors.forEach(error => {
          form.setServerError(error.fieldName, getErrorMessage(error.error));
        });
      } else if (err.statusCode === 409) {
        if (error.startsWith('User ')) {
          setErrorMessage('This member card code is already in use.');
          setSuccessMessage('');
        } else {
          setErrorMessage('Duplicate detected. Please put the kit aside for further analysis.');
          setSuccessMessage('');
        }
      } else {
        setErrorMessage('Error saving preconfiguration');
        setSuccessMessage('');
      }
    }
  };

  const handleClickedResetBatchCounter = () => {
    setPreference(context, 'batchCounter', '0');
  };

  const actions: CustomActions = () => (
    <CardActions>
      Batch counter: {batchCounter.toString()}{' '}
      <Button variant="secondary_default" title="Reset batch counter" onClick={handleClickedResetBatchCounter}>
        <Undo width={16} height={16} />
      </Button>
    </CardActions>
  );

  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      <FormContextProvider value={form}>
        <div style={{overflow: 'auto'}}>
          <FormGroup>
            <Label>Kit</Label>
            <InputGroup>
              <Input type="select" name="kit" value={kit.id} onChange={handleKitChanged}>
                {PreconfigurationKits.map(kit => (
                  <option key={kit.id} value={kit.id}>
                    {kit.name}
                  </option>
                ))}
              </Input>
            </InputGroup>
          </FormGroup>
          <Checkbox
            id="print-label"
            name="print-label"
            label={printerObject ? `Print label to ${printerObject.displayName}` : 'Print label'}
            extra={
              <Button variant="tertiary_link" onClick={handleClickedChoosePrinter}>
                Select printer
              </Button>
            }
            checked={print}
            onCheckedChange={setPrint}
            testId="print-label"
            wrapperClassName="tw-mb-4"
          />
          {gatewaySerialNumberInput}
          {powerBoxSerialNumberInput}
          {(hasGridSolidCore || hasGridCTHub) && gridClosedCTSerialNumberInput}
          {(hasChargerSolidCore || hasChargerCTHub) && carChargerClosedCTSerialNumberInput}
          {hasCTHub && ctHubSerialNumberInput}
          {idInput}
          {errorMessage && (
            <Alert fixed={false} color="danger">
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert fixed={false}>
              <p>{successMessage}</p>
              <p>Remember to add to the box:</p>
              <ul>
                {kit.bom.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Alert>
          )}
          <FormSaveButton onSave={handleClickedSave}>Save</FormSaveButton>
        </div>
      </FormContextProvider>
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.Preconfiguration,
  title: 'preconfiguration.title',
  description: 'preconfiguration.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 3,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Unaware,
  cardClass: Preconfiguration
};
export default CARD;
