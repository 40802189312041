import {SeriesAreaOptions, SeriesLineOptions} from 'highcharts/highstock';
import React, {useEffect, useMemo} from 'react';

import {createIntervalChart} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import {ChargingStation} from '../../models/ChargingStation';
import {IConsumptionValue} from '../../models/ConsumptionValue';
import {Interval} from '../../models/UsageValue';

import {unitIsPowerInsteadOfConsumption} from '../../models/UsageValue';

import {sumOfSeries} from '../../utils/Series';
import {getMonthIndex} from '../../utils/TimestampUtils';
import {Series, SeriesElement} from '../ElectricityValues/Series';

import {ParentConsumptionData} from './CardData';
import {createChargingStationSeries, getColor} from './ChargingStationGraph';

export interface IChargingStationConsumption {
  station: ChargingStation;
  consumption: IConsumptionValue[];
}

interface ChargingGroupGraphProps {
  data: ParentConsumptionData;
}

export const ChargingGroupGraph = React.memo((props: ChargingGroupGraphProps) => {
  const {stationsActive, stationsDataRearranged, period} = props.data;
  const {from, interval} = period;

  const unitAsPower = unitIsPowerInsteadOfConsumption(interval);
  const unit = unitAsPower ? 'kW' : 'kWh';

  const [series, max, totalPower] = useMemo(() => {
    const series: (SeriesAreaOptions | SeriesLineOptions)[] = [];
    const powerSeries: string[] = [];

    let max: number = 0;
    let index = 0;

    stationsActive.forEach((station, index) => {
      const chargingStation = station;
      if (!chargingStation) return;

      const ids = createChargingStationSeries(
        series,
        chargingStation,
        stationsDataRearranged.map(
          item =>
            item.data[station.serialNumber] || {
              timestamp: item.timestamp
            }
        ),
        unit,
        chargingStation.data.name,
        true,
        interval
      );
      for (var id of ids) powerSeries.push(id);
    });
    for (var serie of series) {
      max = (serie.data as [number, number][]).reduce((x, value) => Math.max(x, value[1]), max);
      serie.color = getColor(index++);
    }

    const totalPower = sumOfSeries(
      series
        .filter(x => x.id && powerSeries.includes(x.id) && x.data && x.data.length > 0)
        .map(x => x.data as [number, number][])
    );

    return [series, max, totalPower];
  }, [stationsActive, stationsDataRearranged, unit, interval]);

  const [config, actualFrom, actualTo] = createIntervalChart({
    period,
    series,
    yAxis: [
      {
        title: {text: unit},
        opposite: false,
        showEmpty: false,
        startOnTick: false,
        max: max < 1 ? 1 : null
      }
    ],
    navigatorSerie: {
      type: 'areaspline',
      data: totalPower
    }
  });

  return stationsActive.length === 0 ? <div /> : <HighStockChart from={actualFrom} to={actualTo} config={config} />;
});
