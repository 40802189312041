import React from 'react';

import './ModalHeaderActionable.scss';

import {T} from '../../utils/Internationalization';
import {Flex, IFlexProps} from '../Flex';

interface IModalHeaderActionableProps extends IFlexProps {
  toggle: () => void;
  headerContent?: JSX.Element;
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export class ModalHeaderActionable extends React.Component<IModalHeaderActionableProps> {
  render() {
    let {toggle, className, headerContent, children, ...props} = this.props;

    // Hook extra classes into the component
    className = ['dash-modal-header-actionable', className || ''].join(' ');

    return (
      <Flex className={className} {...props}>
        <h4>{children}</h4>
        {headerContent}
        <button type="button" className="close" aria-label={T('modal.close')} onClick={toggle}>
          <span aria-hidden="true">×</span>
        </button>
      </Flex>
    );
  }
}
