import {zodResolver} from '@hookform/resolvers/zod';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {NotificationManager} from 'react-notifications';
import {Form, Input} from 'reactstrap';

import {z} from 'zod';

import {useAppContext} from '../../app/context';
import {TextInputRHFGroup} from '../../components/inputs/TextInputRHF';
import {Button} from '../../components/ui/button';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {IChargingStation} from '../../models/ChargingStation';
import {reportError} from '../../utils/Errors';
import {FormProvider} from '../../utils/FormContext';
import {useDelayedEffect} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardChargingStation} from '../CardUtils';
import {CardActions} from '../components';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

type AdvenirSettings = ICardSettings;

const formSchema = z.object({
  userId: z
    .string()
    .regex(/^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/, T('advenir.userId.invalid'))
});

type FormValues = z.infer<typeof formSchema>;

function Card(props: ICardProps<AdvenirSettings>) {
  const {settings} = props;
  const {api} = useAppContext();

  const form = useForm<FormValues>({resolver: zodResolver(formSchema)});

  const chargingStation = useCardChargingStation(settings);
  const chargingStationSerial = chargingStation?.chargingStation?.serialNumber;

  const [serialNumber, setSerialNumber] = useState('');
  useEffect(() => {
    if (chargingStationSerial) setSerialNumber(chargingStationSerial);
  }, [chargingStationSerial]);

  const [chargingStationDetails, setChargingStationDetails] = useState<IChargingStation>();
  useDelayedEffect(
    () => {
      api.chargingStations
        .getBySerial(serialNumber)
        .then(setChargingStationDetails)
        .catch(x => {});
    },
    [serialNumber],
    500
  );

  useEffect(() => {
    form.setValue('userId', chargingStationDetails?.integrations?.advenirUserId || '');
  }, [chargingStationDetails]);

  const handleSave = (data: FormValues) => {
    if (chargingStationDetails === undefined) return;

    const locationId = chargingStationDetails.serviceLocation?.id;
    api.chargingStations
      .update(locationId, chargingStationDetails.serialNumber, {
        integrations: {
          advenirUserId: data.userId
        }
      })
      .then(() => NotificationManager.success(T('advenir.save.success')))
      .catch(e => reportError(e, T('advenir.save.failed')));
  };

  const actions: CustomActions = state => (
    <CardActions>
      <Input
        type="text"
        name="serialNumber"
        placeholder={T('deviceConfiguration.serialNumber')}
        value={serialNumber}
        onChange={e => setSerialNumber(e.currentTarget.value)}
        autoWidth={true}
      />
    </CardActions>
  );

  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      <p>{T('advenir.info')}</p>
      <FormProvider>
        <Form>
          <TextInputRHFGroup form={form} name="userId" label={T('advenir.userId')} />
          <Button onClick={form.handleSubmit(handleSave)}>{T('advenir.save')}</Button>
        </Form>
      </FormProvider>
    </CardView>
  );
}

const DEFAULT_CARD_SETTINGS: AdvenirSettings = {};

const CARD: ICardType<AdvenirSettings> = {
  type: CardTypeKey.Advenir,
  title: 'advenir.title',
  description: 'advenir.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.Aware,
  cardClass: Card
};
export default CARD;
