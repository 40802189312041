import dayjs from 'dayjs';

import 'dayjs/plugin/utc';
import {ChargingSessionExport} from '../../models/ChargingSessionExport';
import {translateChargingStationPaymentType} from '../../models/ChargingStation';
import {
  CalculatedNumberField,
  CalculatedStringField,
  DEFAULT_OPTIONS,
  IFieldOptions,
  IntegerField,
  ITableField,
  NumberField,
  StringField,
  TimestampFieldWithTimezone
} from '../../models/Table';

export function getColumns(timezone: string): ITableField<ChargingSessionExport>[] {
  return [
    new IntegerField('id', 'ID'),
    new StringField('uuid', 'MSP order ID'),
    new StringField('serialNumber', 'Serial number'),
    new IntegerField('position', 'Position'),
    new TimestampFieldWithTimezone('from', 'from', 'From', timezone),
    new TimestampFieldWithTimezone('to', 'to', 'To', timezone),
    new DurationField(),
    new StringField('status', 'Status'),
    new StringField('rfid', 'RFID'),
    new IntegerField('csmsTransactionId', 'Transaction ID'),
    new CalculatedStringField(
      'paymentType',
      'Payment type',
      item => item.paymentType && translateChargingStationPaymentType(item.paymentType, !!item.splitBillingAgreementId)
    ),
    new StringField('paymentMethod', 'Payment method'),
    new StringField('paymentStatus', 'Payment status'),
    new CalculatedNumberField(
      'discount',
      'Discount',
      item => (item.discount === undefined ? undefined : item.discount * 100),
      {unit: '%', digitsAfterComma: 0}
    ),
    new NumberField('hostCost', 'Host cost', {digitsAfterComma: 2}),
    new NumberField('operatorCost', 'Operator cost', {digitsAfterComma: 2}),
    new NumberField('driverCost', 'Driver cost', {digitsAfterComma: 2}),
    new StringField('currency', 'Currency'),
    new NumberField('energy', 'Energy', {unit: 'kWh', digitsAfterComma: 3}),
    new StringField('organizationName', 'Organization'),
    new NumberField('splitBillingRefund', 'Split billing refund', {
      digitsAfterComma: 2
    })
  ];
}

export class DurationField implements ITableField<ChargingSessionExport> {
  name: string;
  label: string;
  options: IFieldOptions;

  constructor() {
    this.name = 'duration';
    this.label = 'Duration';
    this.options = DEFAULT_OPTIONS;
  }

  renderCellContent(item: ChargingSessionExport) {
    return this.toString(item);
  }

  getExportCSVValue(item: ChargingSessionExport) {
    return this.toString(item) || '';
  }

  getExportExcelValue(item: ChargingSessionExport) {
    return this.toString(item) || '';
  }

  toString(item: ChargingSessionExport) {
    if (item.to === undefined) return undefined;

    const duration = dayjs.duration(item.to - item.from);
    const hours = duration.asHours() | 0;
    const minutes = duration.get('minutes');
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }

  sort = (a: ChargingSessionExport, b: ChargingSessionExport): number => {
    const aDuration = a.to === undefined ? undefined : dayjs.duration(a.to - a.from);
    const bDuration = b.to === undefined ? undefined : dayjs.duration(b.to - b.from);
    const aMillis = aDuration ? aDuration.asSeconds() : 0;
    const bMillis = bDuration ? bDuration.asSeconds() : 0;
    return aMillis - bMillis;
  };
}
