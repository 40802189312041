import {LoadManagementSettings} from '../../../api/LoadManagementAPI';
import {ChargingDisplayImageType} from '../../../models/ChargingDisplayImage';
import {ChargingStation, ChargingStationModule, ChargingStationPaymentType} from '../../../models/ChargingStation';
import {IHighLevelConfiguration} from '../../../models/HighLevelConfiguration';
import {ILocation} from '../../../models/Location';
import {Phase} from '../../../models/Phase';
import {T} from '../../../utils/Internationalization';

export interface PropertiesState {
  available: boolean;
  paymentTypes: ChargingStationPaymentType[];
  tariffs?: {
    energy?: number;
    fixed?: number;
    time?: number;
    currency?: string;
  };
  csms?: {
    url?: string;
    host?: string;
    port?: number;
    deviceActivated?: boolean;
  };
  brightness?: number;
  currentLimit: number;
  behindVoltageTransformer: boolean;
  floor: string;
  publiclyVisible: boolean;
  cableLocked: boolean;
  restrictedAccess?: boolean;
  offlineChargingAllowed: boolean;
  failSafeCurrent: number;
  connectors: ConnectorsState;
}

export class ConnectorsState {
  private connectors: {[smartDeviceId: string]: ConnectorState};

  constructor(connectors: {[smartDeviceId: string]: ConnectorState}) {
    this.connectors = connectors;
  }

  public get(smartDeviceId: string): ConnectorState | undefined {
    return this.connectors[smartDeviceId];
  }

  public updatedWith(smartDeviceId: string, updates: Partial<ConnectorState>): ConnectorsState {
    return new ConnectorsState({
      ...this.connectors,
      [smartDeviceId]: {
        ...this.connectors[smartDeviceId],
        ...updates
      }
    });
  }
}

export interface ConnectorState {
  minCurrent?: number;
  maxCurrent?: number;
  maxPower?: number;
  maxPowerRange?: number;
  chargingSpeed?: number;
  minExcessPercentage?: number;
  charging?: boolean;
  paused?: boolean;
  phaseAssignment?: string;
}

export interface StationDetails {
  chargingStation: ChargingStation;
  location: ILocation;
  controllers: ChargingStationModule[];
  loadManagementSettings: {[key: string]: LoadManagementSettings};
  highLevelConfiguration: IHighLevelConfiguration;
  chargingHub: ILocation;
  chargingParent: ILocation | undefined;
}

export const TYPE_MAP = {
  ULTRA_V2_SUPPORT: T('chargingStationConfiguration.manageDisplayImages.imageType.support'),
  ULTRA_V2_NOT_AVAILABLE: T('chargingStationConfiguration.manageDisplayImages.imageType.notAvailable'),
  ULTRA_V2_CONNECT_CABLE: T('chargingStationConfiguration.manageDisplayImages.imageType.connectCable'),
  CONFIGURATION: T('chargingStationConfiguration.manageDisplayImages.imageType.configuration'),
  CUSTOM: T('chargingStationConfiguration.manageDisplayImages.imageType.custom'),
  ULTRA_V2_WELCOME: T('chargingStationConfiguration.manageDisplayImages.imageType.welcome'),
  ULTRA_V2_SWIPE_OR_SCAN: T('chargingStationConfiguration.manageDisplayImages.imageType.swipeOrScan'),
  ULTRA_V2_SWIPE: T('chargingStationConfiguration.manageDisplayImages.imageType.swipe'),
  ULTRA_V2_AUTHENTICATING: T('chargingStationConfiguration.manageDisplayImages.imageType.authenticating'),
  ULTRA_V2_AUTHENTICATION_TIMEOUT: T(
    'chargingStationConfiguration.manageDisplayImages.imageType.authenticationTimeout'
  ),
  ULTRA_V2_CHARGING_ENDED: T('chargingStationConfiguration.manageDisplayImages.imageType.chargingEnded'),
  ULTRA_V2_ERROR: T('chargingStationConfiguration.manageDisplayImages.imageType.error'),
  ULTRA_V2_INITIALIZING: T('chargingStationConfiguration.manageDisplayImages.imageType.initializing'),
  ULTRA_V2_REINSERT_CABLE: T('chargingStationConfiguration.manageDisplayImages.imageType.reInsertCable')
};

export const IMAGE_STATUS_MAP = {
  DEFAULT: T('chargingStationConfiguration.manageDisplayImages.imageStatus.default'),
  UPLOADED: T('chargingStationConfiguration.manageDisplayImages.imageStatus.uploaded'),
  BUSY: T('chargingStationConfiguration.manageDisplayImages.imageStatus.busy'),
  CONFIRMED: T('chargingStationConfiguration.manageDisplayImages.imageStatus.confirmed'),
  FAILED: T('chargingStationConfiguration.manageDisplayImages.imageStatus.failed'),
  INVALID: T('chargingStationConfiguration.manageDisplayImages.imageStatus.invalid')
};

export function getDisplayImageTypeLabel(type: ChargingDisplayImageType): string {
  return TYPE_MAP[type];
}
