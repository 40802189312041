import mqtt from 'mqtt';
import {v1 as uuidv1} from 'uuid';

import {getMQTTUrl} from '../utils/AppParameters';

import {log} from '../utils/Logging';

import {TrackingType} from './LiveDataModels';

export enum MqttTopicType {
  Power = 'power',
  Tracking = 'tracking',
  Phasor = 'h1vector',
  Harmonics = 'powerquality'
}

export class MqttConnector {
  static getPowerTopic(uuid: string): string {
    return `servicelocation/${uuid}/power`;
  }

  static getTrackingTopic(uuid: string): string {
    return `servicelocation/${uuid}/tracking`;
  }

  static getPhasorTopic(uuid: string): string {
    return `servicelocation/${uuid}/h1vector`;
  }

  static getHarmonicsTopic(uuid: string): string {
    return `servicelocation/${uuid}/powerquality`;
  }

  static getTopic(uuid: string, type: MqttTopicType): string {
    return `servicelocation/${uuid}/${type}`;
  }

  mqttUrl: string;
  clientId: string = `dashboard-${uuidv1()}`;
  client?: MqttConnectorClient;

  constructor() {
    // Setup MQTT URL
    this.mqttUrl = `wss://${window.location.host}/mqtt`;

    // Use an insecure connection for local development
    if (window.location.protocol === 'http:') {
      this.mqttUrl = getMQTTUrl();
    }
  }

  subscribe(
    identifier: string,
    mqttUsername: string,
    mqttPassword: string,
    topic: string,
    tracking: Tracking | undefined,
    retained: boolean,
    connectedCallback: (subscription: MqttSubscription) => void,
    dataCallback: (data: any, topic: string) => void
  ): void {
    this._connect(identifier, mqttUsername, mqttPassword, client => {
      client.connectToTopic(topic, tracking, connectedCallback, dataCallback, retained);
    });
  }

  resendTracking(tracking: Tracking) {
    if (!this.client) return;

    this.client._startTracking(tracking);
  }

  closeAll() {
    if (!this.client) return;

    this.client.closeAll();
    this.client = undefined;
  }

  _connect(
    identifier: string,
    mqttUsername: string,
    mqttPassword: string,
    callback: (client: MqttConnectorClient) => void
  ) {
    if (this.client) {
      callback(this.client);
    } else {
      this.client = new MqttConnectorClient(this, identifier, this.mqttUrl, mqttUsername, mqttPassword);
      this.client.onConnect(() => callback(this.client!));
    }
  }

  _close(client: MqttConnectorClient) {
    if (this.client === client) this.closeAll();
  }
}

export interface Tracking {
  type: TrackingType;
  serialNumber?: string;
  serialNumbers?: string[];
  trackingLocationUUID: string;
}

export class MqttConnectorClient {
  connector: MqttConnector;
  clientId: string;
  client: mqtt.Client;
  connected: boolean = false;
  onConnected: Array<() => void> = [];
  subscriptions: Map<string, MqttSubscription[]> = new Map<string, MqttSubscription[]>();
  lastMessages: Map<string, any> = new Map<string, any>();
  refreshTrackingIntervalId?: NodeJS.Timeout;

  lastTrackingSent: {[key: string]: number} = {};

  constructor(
    connector: MqttConnector,
    identifier: string,
    mqttUrl: string,
    mqttUsername: string,
    mqttPassword: string
  ) {
    this.clientId = `dashboard-${identifier}-${uuidv1()}`;
    this.connector = connector;
    this.client = mqtt.connect(mqttUrl, {
      clientId: this.clientId,
      username: mqttUsername,
      password: mqttPassword
    });

    this.client.on('connect', () => {
      this.connected = true;
      for (let callback of this.onConnected) {
        callback();
      }

      this.refreshTrackingIntervalId = setTimeout(() => this.refreshTrackings(), 25 * 60 * 1000);
    });

    this.client.on('message', this.handleMessage);
  }

  handleMessage = (topic: string, message: Buffer) => {
    try {
      const json = JSON.parse(message.toString());
      const subscriptions = this.subscriptions.get(topic);
      if (subscriptions !== undefined) {
        subscriptions.forEach(subscription => subscription.callback(json, subscription.topic));
        this.lastMessages.set(topic, json);
      }
    } catch (err) {
      // Message could not be parsed
      log('mqttErrors', 'mqtt message could not be parsed', err);
    }
  };

  onConnect(callback: () => void) {
    if (this.connected) callback();
    else this.onConnected.push(callback);
  }

  refreshTrackings() {
    this.subscriptions.forEach((subscriptions, topic) => {
      const tracking = subscriptions[0].tracking;
      if (tracking) {
        this._startTracking(tracking);
      }
    });
  }

  connectToTopic(
    topic: string,
    tracking: Tracking | undefined,
    connectedCallback: (subscription: MqttSubscription) => void,
    dataCallback: (data: any, topic: string) => void,
    retained: boolean
  ): MqttSubscription {
    const subscription = new MqttSubscription(this, topic, tracking, dataCallback);
    let subscriptions = this.subscriptions.get(topic);
    if (subscriptions === undefined) {
      subscriptions = [subscription];
      if (tracking) {
        this._startTracking(tracking);
      }
      this.client.subscribe(topic, {qos: 0}, () => connectedCallback(subscription));
      this.subscriptions.set(topic, subscriptions);
    } else {
      connectedCallback(subscription);
      subscriptions.push(subscription);

      if (retained) {
        const lastMessage = this.lastMessages.get(topic);
        if (lastMessage) dataCallback(lastMessage, topic);
      }
    }
    return subscription;
  }

  closeAll() {
    for (const [, value] of this.subscriptions) {
      const subscriptions = [...value];
      subscriptions.forEach(subscription => this._close(subscription));
    }
  }

  _startTracking(tracking: Tracking, callback?: () => void) {
    const now = new Date().valueOf();
    const serialNumberKey = tracking.serialNumbers ? tracking.serialNumbers.join(',') : tracking.serialNumber;
    const key = `${tracking.trackingLocationUUID}/${serialNumberKey}/${tracking.type}`;
    if (key in this.lastTrackingSent && this.lastTrackingSent[key] + 1000 > now) {
      console.log(`Prevented spam tracking @ ${key}`);
      return;
    }
    this.lastTrackingSent[key] = now;

    const trackingMessage = {
      clientId: this.clientId,
      value: 'ON',
      serialNumber: tracking.serialNumbers === undefined ? tracking.serialNumber : undefined,
      serialNumbers: tracking.serialNumbers,
      type: tracking.type === TrackingType.CarCharging ? undefined : tracking.type
    };
    const topic = MqttConnector.getTrackingTopic(tracking.trackingLocationUUID);
    this._publish(topic, JSON.stringify(trackingMessage), {qos: 0}, callback);
  }

  _stopTracking(tracking: Tracking, callback: () => void) {
    const trackingMessage = {
      clientId: this.clientId,
      value: 'OFF',
      serialNumber: tracking.serialNumbers === undefined ? tracking.serialNumber : undefined,
      serialNumbers: tracking.serialNumbers,
      type: tracking.type === TrackingType.CarCharging ? undefined : tracking.type
    };
    const topic = MqttConnector.getTrackingTopic(tracking.trackingLocationUUID);
    this._publish(topic, JSON.stringify(trackingMessage), undefined, callback);
  }

  _publish(topic: string, message: string, options: mqtt.IClientPublishOptions = {qos: 0}, callback?: () => void) {
    if (!this.client) return;

    try {
      this.client.publish(topic, message, options, callback);
    } catch (err) {
      console.error(err);
    }
  }

  _close(subscription: MqttSubscription) {
    const subscriptions = this.subscriptions.get(subscription.topic);
    if (!subscriptions) {
      log('mqttErrors', 'Could not close topic subscription: topic not found');
      return;
    }

    const index = subscriptions.indexOf(subscription);
    if (index < 0) {
      log('mqttErrors', 'Could not close topic subscription: not found in subscription list for topic');
      return;
    }

    subscriptions.splice(index, 1);
    log('mqtt', `Closing subscription: (${subscription.tracking?.serialNumber}) ${subscription.topic}`);
    if (subscriptions.length === 0) {
      if (subscription.tracking) {
        this._stopTracking(subscription.tracking, () => {});
      }

      this.client.unsubscribe(subscription.topic);
      this.subscriptions.delete(subscription.topic);
      if (this.subscriptions.size === 0) {
        setTimeout(() => {
          if (this.subscriptions.size === 0) {
            if (this.refreshTrackingIntervalId) {
              clearTimeout(this.refreshTrackingIntervalId);
              this.refreshTrackingIntervalId = undefined;
            }

            this.client.end(true);
            this.connector._close(this);
          }
        }, 10 * 1000); // close connection after 10 seconds of not being used
      }
    }
  }
}

export class MqttSubscription {
  client: MqttConnectorClient;
  topic: string;
  tracking?: Tracking;
  callback: (data: any, topic: string) => void;

  constructor(
    client: MqttConnectorClient,
    topic: string,
    tracking: Tracking | undefined,
    callback: (data: any, topic: string) => void
  ) {
    this.client = client;
    this.topic = topic;
    this.tracking = tracking;
    this.callback = callback;
  }

  resendTrackingMessage() {
    if (!this.tracking) return;

    this.client._startTracking(this.tracking);
  }

  close(): void {
    log('mqtt', 'Close MqttSubscription');
    this.client._close(this);
  }
}
