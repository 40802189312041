import React, {useRef, useState, useMemo, useEffect, useCallback} from 'react';

import {useAppContext} from '../../app/context';
import API from '../../core/api';
import {ChargingStation, IChargingStation} from '../../models/ChargingStation';
import {expandSerialNumber} from '../../models/DeviceType';
import {
  ILocation,
  ILocationSearchResult,
  locationSummaryToSearchResult,
  LocationFunctionType,
  isChargingParent,
  chargingStationToSearchResult,
  ILocationSummary,
  isSameSearchResult
} from '../../models/Location';
import {useAppSelector, useDelayedEffect} from '../../utils/Hooks';
import {T, quantity} from '../../utils/Internationalization';
import {Hotkeys, HOTKEY_BLACKLIST} from '../../utils/Keys';
import {classes} from '../../utils/Styles';
import {Dropdown, DropdownItem, Input, DropdownMenu, DropdownToggle} from '../bootstrap';
import {Closeable} from '../Closeable';
import styles from '../header/LocationMenu.module.scss';
import {Checkbox} from '../ui/checkbox';

export function useChargingStationForLocation(
  api: API,
  location: ILocation | ILocationSummary | undefined
): [ChargingStation | undefined, () => void] {
  const [station, setStation] = useState<ChargingStation>();
  const serialNumber = location && location.chargingStation && location.chargingStation.serialNumber;

  const refresh = useCallback(() => {
    if (!serialNumber) {
      setStation(undefined);
      return;
    }

    api.chargingStations.getBySerial(serialNumber).then(station => setStation(new ChargingStation(station)));
  }, [api, serialNumber]);
  useEffect(() => refresh(), [refresh]);
  return [station, refresh];
}

interface LocationItemProps {
  currentLocation?: ILocation;
  location: ILocationSearchResult;
  onClickedLocation: (id: number, serialNumber?: string) => void;
}
const LocationItem = (props: LocationItemProps) => {
  const {currentLocation, location, onClickedLocation} = props;
  let serialNumber = location.chargingStation ? location.chargingStation.serialNumber : location.serialNumber;
  let {name, functionType} = location;
  const id = location.serviceLocationId;
  const isActive = currentLocation && currentLocation.id === location.serviceLocationId;
  const isInactiveLocation = location.active === false;
  if (isInactiveLocation) serialNumber = '';

  const key = id + (isInactiveLocation ? 'X' : '');

  return (
    <DropdownItem
      key={key}
      onClick={() => onClickedLocation(id, serialNumber)}
      disabled={isActive}
      className={isInactiveLocation ? styles.locationItemInactive : styles.locationItem}
    >
      {location.obsolete ? (
        <span className={styles.locationSerialMissing}>{serialNumber || T('locationSearch.deleted')}</span>
      ) : serialNumber ? (
        <span className={styles.locationSerial}>{serialNumber}</span>
      ) : (
        <span className={styles.locationSerialMissing}>
          {isChargingParent(functionType) ? T('locationSearch.chargingPark') : T('locationSearch.inactive')}
        </span>
      )}
      {name ? (
        <span className={styles.locationName}>{name}</span>
      ) : (
        <span className={styles.locationNameMissing}>{T.generic.na()}</span>
      )}
    </DropdownItem>
  );
};

interface LocationInputProps {
  id: string;
  inputId: string;
  inputWidth?: string | number;
  maxDropdownHeight?: string | number;
  location: ILocation | undefined;
  allowChargingStations: boolean;
  onLocationSelected: (id: number, serialNumber?: string) => Promise<unknown>;
  invalid?: boolean;
  filter?: (location: ILocationSearchResult) => boolean;
}

export default function LocationInput(props: LocationInputProps) {
  const {
    id,
    inputId,
    inputWidth = '100%',
    maxDropdownHeight,
    location,
    allowChargingStations,
    onLocationSelected,
    invalid,
    filter
  } = props;

  const {api} = useAppContext();
  const [userLocations, me] = useAppSelector(state => [state.locations.locations, state.me.me]);

  const locationInput = useRef<HTMLInputElement | HTMLTextAreaElement>();

  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState('');

  const [results, setResults] = useState<ILocationSearchResult[]>();
  const [isOpen, setOpen] = useState(false);
  const [searchObsolete, setSearchObsolete] = useState(false);
  const [loadingId, setLoadingId] = useState<number>();
  const [loadingSerial, setLoadingSerial] = useState<string>();

  useEffect(() => {
    const handleOpenSelector = () => {
      setOpen(true);
      if (locationInput.current) {
        locationInput.current.focus();
        locationInput.current.select();
      }
    };

    const handleCloseSelector = () => {
      setOpen(false);
      if (locationInput.current) locationInput.current.blur();
    };

    const handleKeyPressed = (event: KeyboardEvent) => {
      const isBlacklisted = event.target && HOTKEY_BLACKLIST.includes((event.target as any).type);

      // Open selector when pressing '/' and close when pressing escape
      if (!isBlacklisted && event.key === Hotkeys.LOCATION) {
        event.preventDefault();
        handleOpenSelector();
      } else if (event.key === Hotkeys.ESCAPE && isOpen) {
        handleCloseSelector();
      }
    };

    document.addEventListener('keydown', handleKeyPressed);
    return () => document.removeEventListener('keydown', handleKeyPressed);
  }, [isOpen]);

  const handleClose = () => {
    setResults(undefined);
    setFocused(false);
    setOpen(false);
  };

  const locationId = location && location.id;

  useEffect(() => {
    setLoadingId(undefined);
    setLoadingSerial(undefined);
    setQuery('');
  }, [locationId]);

  const handleClickLocation = useCallback(
    (id: number, serialNumber?: string) => {
      setLoadingId(id);
      setLoadingSerial(serialNumber);
      setQuery(serialNumber || '');

      onLocationSelected(id, serialNumber).then(() => {
        setOpen(false);
        setFocused(false);
        setResults(undefined);
      });
    },
    [onLocationSelected]
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Select the first available result and close the menu
    if (event.key === Hotkeys.ENTER && Array.isArray(results) && results.length > 0) {
      const {serviceLocationId, serialNumber} = results[0];
      handleClickLocation(serviceLocationId, serialNumber);
      setOpen(false);
    }
  };

  const handleQueryChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const {value} = event.currentTarget;
    const hasValue = value && value.length > 0;

    setQuery(hasValue ? value : '');
  };

  useDelayedEffect(
    () => {
      const searchRemoteLocations = (value: string, obsolete: boolean) => {
        const queries: Promise<ILocationSearchResult[]>[] = [];
        queries.push(api.searchLocations(value.trim(), 10, me ? me.isServiceDesk() && obsolete : false));

        const expanded = expandSerialNumber(value);
        if (expanded !== null) {
          queries.push(api.searchLocations(expanded, 10, me ? me.isServiceDesk() && obsolete : false));
        }
        if (me && me.isServiceDesk() && /^[0-9]+$/.test(value) && value.length < 10) {
          queries.push(
            api.locations
              .get(parseInt(value))
              .then(location => {
                if (location) {
                  return [
                    {
                      serviceLocationId: location.id,
                      name: location.name,
                      serialNumber: location.serialNumber,
                      active: location.serialNumber !== undefined,
                      obsolete: location.obsoleteTimestamp !== undefined,
                      functionType: location.functionType || LocationFunctionType.Standard,
                      chargingStation: location.chargingStation
                    }
                  ];
                } else {
                  return [];
                }
              })
              .catch(() => [])
          );
        }
        if (allowChargingStations && /^6[0-9]{9}[12]?$/.test(value)) {
          queries.push(
            api.chargingStations
              .getBySerial(value)
              .then(station => [chargingStationToSearchResult(station)])
              .catch(() => [])
          );
        }
        if (allowChargingStations && expanded?.startsWith('6')) {
          queries.push(
            api.chargingStations
              .getBySerial(expanded)
              .then(station => [chargingStationToSearchResult(station)])
              .catch(() => [])
          );
        }
        Promise.all(queries)
          .then(results =>
            results.reduce((result, locations) => {
              for (let location of locations) {
                if (!result.some(x => isSameSearchResult(x, location))) {
                  result.push(location);
                }
              }
              return result;
            }, [])
          )
          .then(locations => setResults(locations));
      };

      if (me.isServiceDesk() && query.length > 0) {
        searchRemoteLocations(query, searchObsolete);
      } else {
        setResults(undefined);
      }
    },
    [api, me, query, searchObsolete, allowChargingStations],
    500
  );

  const handleToggle = () => {
    // Flip open state if the input field is not focused
    const isMenuOpen = focused || !isOpen;

    // Check whether to reset query and results if value is closed
    if (!isMenuOpen && query.trim() === '') {
      setQuery('');
      setResults(undefined);
    }

    setOpen(isMenuOpen);
  };

  const handleClickedDropdown = () => {
    setOpen(true);
  };

  const handleFocus = () => {
    setFocused(true);
    setOpen(true);
  };

  const filteredLocations = useMemo(() => {
    let result = userLocations;
    if (!allowChargingStations) {
      result = result.filter(x => x.functionType !== LocationFunctionType.ChargingStation);
    }

    if (query.length === 0) return result;

    const queryLower = query.toLowerCase();
    const expanded = expandSerialNumber(query);
    return result.filter(location => {
      const {serialNumber, name, chargingStation} = location;
      return (
        (serialNumber && serialNumber.includes(query)) ||
        (expanded && serialNumber && expanded === serialNumber) ||
        (name && name.toLowerCase().includes(queryLower)) ||
        (chargingStation && chargingStation.serialNumber.includes(query))
      );
    });
  }, [query, allowChargingStations, userLocations]);

  const resultItems = useMemo(() => {
    let filteredResults = results || [];
    if (filter) filteredResults = filteredResults.filter(filter);

    return filteredResults.map(loc => (
      <LocationItem
        key={`${loc.serviceLocationId}-${loc.serialNumber}`}
        location={loc}
        currentLocation={location}
        onClickedLocation={handleClickLocation}
      />
    ));
  }, [results, location, filter, handleClickLocation]);

  const locationItems = useMemo(() => {
    return [
      ...filteredLocations.map(loc => (
        <LocationItem
          key={loc.id}
          location={locationSummaryToSearchResult(loc)}
          currentLocation={location}
          onClickedLocation={handleClickLocation}
        />
      ))
    ];
  }, [location, filteredLocations, handleClickLocation]);

  const renderStaticLocation = () => {
    if (!location) return null;

    const {name, serialNumber} = location;
    return (
      <Input
        id={inputId}
        type="text"
        bsSize="lg"
        plaintext
        readOnly
        title={T('locationSearch.serialNumber', {
          serial: serialNumber || T.generic.na()
        })}
        value={name}
        invalid={invalid}
      />
    );
  };

  const renderDynamicLocations = () => {
    let serialNumber = '';
    let name = '';
    const queryClosed = !isOpen && query.trim() === '';
    const showName = queryClosed || query === serialNumber;
    const isServiceDesk = me !== undefined && me.isServiceDesk();

    // Show the most relevant value
    let value = isOpen ? query || '' : '';
    let placeholder = T('locationSearch.searchLocations');
    let serial = '';

    if (location) {
      serialNumber = location.chargingStation ? location.chargingStation.serialNumber : location.serialNumber || '';
      name = location.name || '';
      serial =
        serialNumber ||
        (isChargingParent(location.functionType)
          ? `(${T('locationSearch.chargingPark')})`
          : `(${T('locationSearch.inactive')})`);

      if (!isOpen) placeholder = '';
    }

    return (
      <Dropdown isOpen={isOpen} toggle={handleToggle} style={{zIndex: 1}}>
        <DropdownToggle
          tag="div"
          onClick={handleClickedDropdown}
          data-toggle="dropdown"
          aria-expanded={isOpen}
          className={styles.location}
        >
          <Input
            type="text"
            autoWidth
            className={styles.locationSerial}
            autoComplete="off"
            title={showName ? name : ''}
            value={value}
            onKeyPress={handleKeyPress}
            onChange={handleQueryChanged}
            onFocus={handleFocus}
            placeholder={placeholder}
            innerRef={element => (locationInput.current = element || undefined)}
            style={{width: inputWidth}}
            invalid={invalid}
          />
          {location && !isOpen ? (
            <div className={styles.locationInfo}>
              <div id={inputId} className={styles.locationName}>
                {loadingId ? <span className="fal fa-sync fa-spin" /> : name}
              </div>
              <div className={styles.locationSerialInfo}>{loadingSerial || serial}</div>
            </div>
          ) : (
            <span id={inputId} style={{display: 'none'}}>
              {name}
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu>
          {!results && locationItems.length > 0 && (
            <div>
              <DropdownItem header className={classes(styles.myLocations, styles.inactiveItem)}>
                {isServiceDesk ? (
                  <Checkbox
                    id="search-deleted"
                    name="search-deleted"
                    label="Search deleted"
                    checked={searchObsolete}
                    onCheckedChange={setSearchObsolete}
                    wrapperClassName="!tw-mb-0"
                    testId="search-deleted"
                  />
                ) : (
                  <span>{T('locationSearch.myLocations')}</span>
                )}
              </DropdownItem>
              <DropdownItem divider />
              <div className={styles.locationsWrapper} style={{maxHeight: maxDropdownHeight}}>
                {locationItems}
              </div>
            </div>
          )}
          {results && (
            <div>
              <DropdownItem header>
                {isServiceDesk ? (
                  <Checkbox
                    id="search-deleted"
                    name="search-deleted"
                    label="Search deleted"
                    checked={searchObsolete}
                    onCheckedChange={setSearchObsolete}
                    wrapperClassName="!tw-mb-0"
                    testId="search-deleted"
                  />
                ) : (
                  <span>{T('locationSearch.locationResults')}</span>
                )}
              </DropdownItem>
              <DropdownItem divider />
              {resultItems}
            </div>
          )}
          {results !== undefined && results.length === 0 && (
            <p className={styles.noResults}>{T('locationSearch.noLocationsFound')}</p>
          )}
          <DropdownItem divider />
          <DropdownItem header className={styles.inactiveItem}>
            {results
              ? T('locationSearch.found', {
                  locations: quantity('location', results.length)
                })
              : T('locationSearch.typeToFilter')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  // Determine whether to show the dynamic location switcher
  const hasAccess = me && (me.isServiceDesk() || me.isPartnerUser());
  const isDynamic = hasAccess || userLocations.length > 1;

  return (
    <Closeable id={id} isOpen={isOpen} onClose={handleClose} className={styles.locationMenu}>
      {isDynamic && renderDynamicLocations()}
      {!isDynamic && renderStaticLocation()}
    </Closeable>
  );
}
