import {SeriesColumnOptions, SeriesLineOptions, YAxisOptions} from 'highcharts';
import React from 'react';

import {createIntervalChart, processIntervalData} from '../../components/Chart';
import HighStockChart from '../../components/HighStockChart';
import {ActivePeriod} from '../../components/PeriodSelector';
import {IPersistedTableSettings, ISelectedRow} from '../../components/Table';
import {IGasWaterReading} from '../../models/GasWaterReading';
import {IGasWaterDevice, ILocationSummary, SensorChannelType, IGasWaterChannel} from '../../models/Location';
import {Interval, getUnitLabel, showAsLineGraph} from '../../models/UsageValue';
import {BrandColors} from '../../utils/BrandColors';
import {T} from '../../utils/Internationalization';

import styles from './index.module.scss';

interface GasWaterReadingsChartProps {
  items: IGasWaterReading[];
  period: ActivePeriod;
  tableSettings: IPersistedTableSettings;
  sensor?: IGasWaterDevice;
}

function createGasWaterSeries(
  channel: IGasWaterChannel,
  data: [number, number | null | undefined][],
  interval: Interval,
  yAxis: YAxisOptions[]
): SeriesLineOptions | SeriesColumnOptions {
  const color = channel.type === SensorChannelType.Gas ? BrandColors.OrangeClementine : BrandColors.Blue;
  const unit = getUnitLabel(channel.unit);
  const name = channel.type === SensorChannelType.Gas ? `Gas [${unit}]` : `Water [${unit}]`;
  const type = channel.type === SensorChannelType.Gas ? 'Gas' : 'Water';
  const yAxisIndex = yAxis.length;

  yAxis.push({
    title: {
      text: unit
    },
    opposite: false
  });
  return {
    name,
    type: showAsLineGraph(interval) ? 'line' : 'column',
    data,
    color,
    marker: {enabled: false},
    tooltip: {
      pointFormat: `<span style="color:{point.color}">\u25CF</span> ${type}: <b>{point.y} ${unit}</b><br/>`
    },
    yAxis: yAxisIndex
  } as SeriesLineOptions | SeriesColumnOptions;
}

export const GasWaterReadingsChart = React.memo((props: GasWaterReadingsChartProps) => {
  const {items, period, tableSettings, sensor} = props;
  const {interval} = period;

  const humidityData = processIntervalData(
    items.map(item => [item.time, item.hum]),
    period
  );
  const temperatureData = processIntervalData(
    items.map(item => [item.time, item.tmp / 10]),
    period
  );
  const batteryData = processIntervalData(
    items.map(item => [item.time, item.bat]),
    period
  );
  const humidity = {
    id: 'humidity',
    name: `${T('gasOrWaterCard.field.humidity')} [%]`,
    type: showAsLineGraph(interval) ? 'line' : 'column',
    data: humidityData,
    color: BrandColors.Blue,
    marker: {enabled: false},
    tooltip: {
      pointFormat: `<span style="color:{point.color}">\u25CF</span> ${T(
        'gasOrWaterCard.field.humidity'
      )}: <b>{point.y} %</b><br/>`
    },
    yAxis: 0
  } as SeriesColumnOptions | SeriesLineOptions;
  const temperature = {
    id: 'temperature',
    name: `${T('gasOrWaterCard.field.temperature')} [°C]`,
    type: showAsLineGraph(interval) ? 'line' : 'column',
    data: temperatureData,
    color: BrandColors.Red,
    marker: {enabled: false},
    tooltip: {
      pointFormat: `<span style="color:{point.color}">\u25CF</span> ${T(
        'gasOrWaterCard.field.temperature'
      )}: <b>{point.y} °C</b><br/>`
    },
    yAxis: 1
  } as SeriesColumnOptions | SeriesLineOptions;
  const battery = {
    id: 'battery',
    name: `${T('gasOrWaterCard.field.battery')} [%]`,
    type: showAsLineGraph(interval) ? 'line' : 'column',
    data: batteryData,
    color: BrandColors.GreenEden,
    marker: {enabled: false},
    tooltip: {
      pointFormat: `<span style="color:{point.color}">\u25CF</span> ${T(
        'gasOrWaterCard.field.battery'
      )}: <b>{point.y} %</b><br/>`
    },
    yAxis: 0
  } as SeriesColumnOptions | SeriesLineOptions;

  const series: (SeriesColumnOptions | SeriesLineOptions)[] = [];
  const selectedColumns = new Set(
    tableSettings.columns.filter(column => column.visible).map(column => (column as ISelectedRow).name)
  );

  if (selectedColumns.has('hum')) series.push(humidity);
  if (selectedColumns.has('tmp')) series.push(temperature);
  if (selectedColumns.has('bat')) series.push(battery);

  const yAxis: YAxisOptions[] = [
    {
      title: {
        text: '%'
      },
      opposite: false
    },
    {
      title: {
        text: '°C'
      },
      opposite: true
    }
  ];
  if (sensor) {
    const channel1 = sensor.inputChannels.find(sensor => sensor.id === 1);
    const channel2 = sensor.inputChannels.find(sensor => sensor.id === 2);
    if (channel1 && channel1.enabled && selectedColumns.has('val1')) {
      const val1Data = processIntervalData(
        items.map(item => [item.time, item.val1 / channel1.pulsesPerUnit]),
        period
      );
      series.push(createGasWaterSeries(channel1, val1Data, interval, yAxis));
    }
    if (channel2 && channel2.enabled && selectedColumns.has('val2')) {
      const val2Data = processIntervalData(
        items.map(item => [item.time, item.val2 / channel2.pulsesPerUnit]),
        period
      );
      series.push(createGasWaterSeries(channel2, val2Data, interval, yAxis));
    }
  }

  const [config, actualFrom, actualTo] = createIntervalChart({
    period,
    series,
    yAxis,
    noDataText: T('gasOrWaterCard.error.noReadings'),
    navigatorSeries: ['humidity', 'battery']
  });

  return <HighStockChart className={styles.stretch} config={config} from={actualFrom} to={actualTo} />;
});
