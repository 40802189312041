import React, {useState, useCallback, useEffect} from 'react';

import {InputGroup, InputGroupAddon} from 'reactstrap';

import {useFormContext} from '../../utils/FormContext';
import {T} from '../../utils/Internationalization';
import {classes} from '../../utils/Styles';
import {TranslationKey} from '../../utils/TranslationTerms';
import {FieldValidator} from '../../utils/Validation';
import {Input} from '../bootstrap';

import FormInputGroup from './FormInputGroup';

import styles from './index.module.scss';

export const emptyNumber = Number.NaN;

export class NumberValue {
  static create(value?: number | null) {
    return new NumberValue(
      value === null || value === undefined || Number.isNaN(value) ? '' : value.toString(),
      value === null || value === undefined || Number.isNaN(value) ? null : value
    );
  }

  static none() {
    return new NumberValue('', null);
  }

  inputValue: string;
  numberValue: number | null;

  private constructor(inputValue: string, numberValue: number | null) {
    this.inputValue = inputValue;
    this.numberValue = numberValue;
  }

  withInputValue(value: string): NumberValue {
    if (value === '') {
      return new NumberValue(value, null);
    } else {
      const numValue = parseFloat(value.replace(',', '.'));
      // console.log('numValue', value, numValue);
      if (!isNaN(numValue)) return new NumberValue(value, numValue);
      else return new NumberValue(value, this.numberValue);
    }
  }

  value(): number | undefined {
    return this.numberValue === null ? undefined : this.numberValue;
  }
}

export function useNumberInput(
  name: string,
  label: string,
  initialValue: number | null,
  min?: number,
  max?: number,
  suffix?: string,
  props?: {
    autoFocus?: boolean;
    disabled?: boolean;
    info?: string;
    as?: 'number' | 'text';
  },
  className?: string,
  optional?: boolean
): [JSX.Element, number | null, (value: number) => void] {
  const [value, setValue] = useState<NumberValue>(NumberValue.create(initialValue));

  const setValueExternally = useCallback((value: number) => {
    setValue(NumberValue.create(value));
  }, []);

  return [
    <NumberInputGroup
      name={name}
      label={label}
      value={value}
      onChange={setValue}
      min={min}
      max={max}
      suffix={suffix}
      className={className}
      optional={optional}
      {...props}
    />,
    value.numberValue,
    setValueExternally
  ];
}

interface NumberInputGroupProps {
  name: string;
  label?: string;
  value: NumberValue;
  onChange: (value: NumberValue) => void;
  as?: 'number' | 'text';
  min?: number;
  max?: number;
  minError?: string;
  maxError?: string;
  suffix?: string;
  autoFocus?: boolean;
  className?: string;
  optional?: boolean;
  disabled?: boolean;
  validate?: FieldValidator;
  info?: string | JSX.Element;
  error?: string;
}

export function NumberInputGroup(props: NumberInputGroupProps) {
  const {
    name,
    label,
    value,
    onChange,
    as = 'number',
    min,
    minError,
    max,
    maxError,
    suffix,
    validate,
    optional = false,
    className,
    autoFocus,
    disabled
  } = props;

  const form = useFormContext();
  const info = props.info || (optional ? T('validatedInput.optional') : undefined);

  const validateNumber = (value: string) => {
    if (value.length === 0) {
      if (optional) {
        return undefined;
      } else {
        return T('validator.required', {name: label ?? name});
      }
    }

    const numValue = parseFloat(value.replace(',', '.'));
    if (isNaN(numValue)) return T('validator.invalidNumber', {name: label ?? name});
    if (min !== undefined && numValue < min) {
      return minError || T('validator.numberAtLeast', {min: min.toString()});
    }
    if (max !== undefined && numValue > max) {
      return maxError || T('validator.numberAtMost', {max: max.toString()});
    }
  };
  const error = validateNumber(value.inputValue) ?? (validate && validate(value.inputValue, label ?? '', optional));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.setError(name, error), [name, error]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => form.remove(name), [name]);

  const handleBlur = () => form.setErrorVisible(name, error !== undefined);

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    // console.log(`Number input: ${e.currentTarget.value}`);
    onChange(value.withInputValue(e.currentTarget.value));
  };

  const ref = useCallback((ref: HTMLInputElement | null) => form.setRef(name, ref), [form, name]);

  const shownError = props.error || form.getShownError(name);
  const input = (
    <Input
      type={as}
      name={name}
      value={value.inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      invalid={shownError !== undefined}
      min={min}
      max={max}
      innerRef={ref}
      autoFocus={autoFocus}
      disabled={disabled}
      onWheel={e => e.currentTarget.blur()}
    />
  );

  const component = (
    <FormInputGroup
      name={name}
      label={label ?? ''}
      error={shownError}
      className={classes(className, styles.noSpinner)}
      info={info}
    >
      {suffix ? (
        <InputGroup>
          {input}
          <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
        </InputGroup>
      ) : (
        input
      )}
    </FormInputGroup>
  );

  return component;
}

export function NumberInput(props: NumberInputGroupProps) {
  const {name, label, value, onChange, as = 'number', min, max, suffix, optional, autoFocus, disabled} = props;

  const form = useFormContext();

  const validate = (value: string) => {
    if (value.length === 0) {
      if (optional) {
        return undefined;
      } else {
        return T('validator.required', {name: label ?? name});
      }
    }

    const numValue = parseFloat(value.replace(',', '.'));
    if (isNaN(numValue)) return T('validator.invalidNumber', {name: label ?? name});
    if (min !== undefined && numValue < min) {
      return T('validator.numberAtLeast', {min: min.toString()});
    }
    if (max !== undefined && numValue > max) {
      return T('validator.numberAtMost', {max: max.toString()});
    }
  };
  const error = validate(value.inputValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.setError(name, error), [name, error]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => form.remove(name), [name]);

  const handleBlur = () => form.setErrorVisible(name, error !== undefined);

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    console.log(`Number input: ${e.currentTarget.value}`);
    onChange(value.withInputValue(e.currentTarget.value));
  };

  const ref = useCallback((ref: HTMLInputElement | null) => form.setRef(name, ref), [form, name]);

  const shownError = props.error || form.getShownError(name);
  const input = (
    <Input
      type={as}
      name={name}
      value={value.inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      invalid={shownError !== undefined}
      min={min}
      max={max}
      innerRef={ref}
      autoFocus={autoFocus}
      disabled={disabled}
      onWheel={e => e.currentTarget.blur()}
    />
  );

  return suffix ? (
    <InputGroup>
      {input}
      <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
    </InputGroup>
  ) : (
    input
  );
}
