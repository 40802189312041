import {useEffect, useRef, useState} from 'react';
import {SketchPicker} from 'react-color';

import {hexToRGBA} from '../../../utils/ColorUtils';

import {FormState} from '../../../utils/FormState';

import styles from './ColorSwatch.module.scss';

interface ColorSwatchProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const ColorSwatch = (props: ColorSwatchProps) => {
  const {value, onChange} = props;
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [swatchColor, setSwatchColor] = useState<string>(hexToRGBA(value));
  const [swatchWidth, setSwatchWidth] = useState<number>();

  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (container?.current) {
      setSwatchWidth(container?.current?.offsetWidth);
    }
  }, [container]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: any) => {
    setSwatchColor(hexToRGBA(color.hex));
    onChange(color.hex);
  };

  return (
    <div data-testid="color-swatch-input" className={styles.wrapper} ref={container}>
      <div className={styles.swatch} onClick={handleClick}>
        <div
          className={styles.color}
          style={{
            background: `${swatchColor}`,
            width: `${swatchWidth ? swatchWidth - 20 : 100}px`
          }}
        />
      </div>
      {displayColorPicker ? (
        <div className={styles.popover}>
          <div className={styles.cover} onClick={handleClose} />
          <SketchPicker color={swatchColor} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorSwatch;
